import { connect } from 'react-redux';

import * as homeActions from 'store/modules/home';
import * as homeSelectors from 'store/modules/home/selectors';
import TopHosts from './TopHosts';

const mapStateToProps = (state) => ({
  hosts: homeSelectors.getTopHosts(state),
  loading: homeSelectors.getTopHostsLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  onLoad: () => dispatch(homeActions.loadTopHosts()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TopHosts);
