import * as actionTypes from './actionTypes';
import initialState from './initialState';

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SET_EMAIL:
      return {
        ...state,
        email: payload,
      };

    case actionTypes.SUBSCRIBE:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.SUBSCRIBE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };

    case actionTypes.SUBSCRIBE_FAILED:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default: return state;
  }
};
