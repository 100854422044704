import { takeEvery } from 'redux-saga/effects';

import * as myTripsActions from 'store/modules/myTrips';
import { load } from './utils';

function* listenLoad() {
  yield takeEvery(myTripsActions.loadTrips.toString(), load);
}

export default listenLoad;
