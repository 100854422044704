import { takeEvery } from 'redux-saga/effects';

import * as profileActionTypes from 'store/modules/profile/actionTypes';

import { validateFieldValue } from './utils';

function* validatePhoto({ payload }) {
  yield validateFieldValue({ payload: { fieldName: 'photo', fieldValue: payload.url } });
}

export default function* listenUploadProfilePhotoSuccess() {
  yield takeEvery(profileActionTypes.UPLOAD_PHOTO_SUCCESS, validatePhoto);
}
