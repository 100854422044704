/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import initialState from './initialState';

export const myTripsSlice = createSlice({
  name: 'myCars',
  initialState,
  reducers: {
    load(state) {
      state.loading = true;
    },

    loadFailed(state) {
      state.loading = false;
    },

    loadSuccess(state, { payload }) {
      const { bookings, vehicles, counts } = payload;
      state.bookings = bookings;
      state.vehicles = vehicles;
      state.counts = counts;
      state.loading = false;
    },

    changeStatusFilter(state, { payload }) {
      state.statusFilter = payload;
      state.currentPage = 1;
    },

    setStatusFilter(state, { payload }) {
      state.statusFilter = payload;
    },

    loadPage(state) {
      state.pageLoading = true;
    },

    loadPageFailed(state) {
      state.pageLoading = false;
    },

    loadPageSuccess(state, { payload }) {
      state.pageLoading = false;
      state.currentPage = payload.pageNumber;
      state.bookings = payload.bookings;
    },
  },
});

const { actions, reducer } = myTripsSlice;

export const {
  load,
  loadFailed,
  loadSuccess,
  setStatusFilter,
  changeStatusFilter,
  loadPage,
  loadPageFailed,
  loadPageSuccess,
} = actions;

export default reducer;
