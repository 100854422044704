import { put, select } from 'redux-saga/effects';

import { history } from '@evee/evee-ui.services';

import * as appActions from 'store/modules/app/actions';
import * as authActions from 'store/modules/auth';
import * as authSelectors from 'store/modules/auth/selectors';
import * as signInActions from 'store/modules/auth/signIn';

const listingProcessPath = '/listing/process';

export function* listYourCar() {
  try {
    const isUserAuthorized = yield select(authSelectors.getIsUserAuthorized);
    if (isUserAuthorized) {
      history.push(listingProcessPath);
      return;
    }

    yield put(authActions.setSigningIn({ value: true, path: listingProcessPath }));
    yield put(signInActions.showSignIn());
  } catch (error) {
    yield put(appActions.showError(error.message));
  }
}
