import { DayPickerRangeController, isInclusivelyAfterDay } from 'react-dates';
import { useEffect, useState } from 'react';
import MomentPropTypes from 'react-moment-proptypes';
import PropTypes from 'prop-types';
import moment from 'moment';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

import { IconButton } from '@evee/evee-ui.base';

import './theme.css';
import styles from './styles';

const START_DATE_ID = 'startDate';
const END_DATE_ID = 'endDate';

const Calendar = ({
  value,
  dateRangeAvailable,
  daySize,
  vehicleUtcOffset,
  vehicleNoticePeriod,
  isMobile,
  classes,
  onChange,
  onNavigate,
  onDatesChange,
  ...rest
}) => {
  const { startDate, endDate } = value;
  const [focusedInput, setFocusedInput] = useState(START_DATE_ID);

  useEffect(() => {
    if (rest.focusedInput) {
      setFocusedInput(rest.focusedInput);
      return;
    }

    const focusedInputId =
      moment(value.startDate).isValid() === moment(value.endDate).isValid()
        ? START_DATE_ID
        : END_DATE_ID;

    setFocusedInput(focusedInputId);
  }, [value]);

  const prevButtonJsx = <IconButton icon={<ChevronLeft />} className={classes.prevButton} />;
  const nextButtonJsx = <IconButton icon={<ChevronRight />} className={classes.nextButton} />;

  return (
    <DayPickerRangeController
      hideKeyboardShortcutsPanel
      startDate={startDate}
      endDate={endDate}
      numberOfMonths={2}
      minimumNights={0}
      daySize={daySize || (isMobile ? 40 : 31)}
      firstDayOfWeek={1}
      minDate={moment()}
      orientation={isMobile ? 'vertical' : 'horizontal'}
      transitionDuration={isMobile ? 0 : 250}
      isOutsideRange={(day) => {
        let noticePeriodCheckFailed = false;
        if (vehicleUtcOffset > 0) {
          const vehicleLocalStartMoment = day
            .clone()
            .endOf('day')
            .utc(true)
            .add(-vehicleUtcOffset, 'hours');
          const hoursBeforeBooking = vehicleLocalStartMoment.diff(moment(), 'hours');
          noticePeriodCheckFailed = hoursBeforeBooking <= vehicleNoticePeriod;
        }
        return !isInclusivelyAfterDay(day, moment()) || noticePeriodCheckFailed;
      }}
      navPrev={prevButtonJsx}
      navNext={nextButtonJsx}
      focusedInput={focusedInput}
      onFocusChange={(focusedValue) => {
        setFocusedInput(dateRangeAvailable ? focusedValue || START_DATE_ID : START_DATE_ID);
      }}
      onNextMonthClick={(d) => onNavigate({ year: d.year(), month: d.month() })}
      onPrevMonthClick={(d) => onNavigate({ year: d.year(), month: d.month() })}
      onDatesChange={(e) => {
        onDatesChange({
          startDate: e.startDate,
          endDate: focusedInput === END_DATE_ID ? e.endDate : null,
        });
      }}
      {...rest}
    />
  );
};

Calendar.propTypes = {
  value: PropTypes.shape({
    startDate: PropTypes.oneOfType([MomentPropTypes.momentObj, PropTypes.instanceOf(Date)]),
    endDate: PropTypes.oneOfType([MomentPropTypes.momentObj, PropTypes.instanceOf(Date)]),
  }),
  classes: PropTypes.shape({
    prevButton: PropTypes.string.isRequired,
    nextButton: PropTypes.string.isRequired,
  }).isRequired,
  dateRangeAvailable: PropTypes.bool,
  isMobile: PropTypes.bool,
  daySize: PropTypes.number,
  vehicleUtcOffset: PropTypes.number,
  vehicleNoticePeriod: PropTypes.number,
  onChange: PropTypes.func,
  onNavigate: PropTypes.func,
  onDatesChange: PropTypes.func,
};

Calendar.defaultProps = {
  value: { startDate: null, endDate: null },
  dateRangeAvailable: true,
  isMobile: false,
  daySize: 0,
  vehicleUtcOffset: 0,
  vehicleNoticePeriod: 0,
  onChange: () => {},
  onNavigate: () => {},
  onDatesChange: () => {},
};

export default withStyles(styles)(Calendar);
