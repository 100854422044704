import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Box, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import Carousel from 'widgets/layout/Carousel';

import HostCard from './HostCard';

const TopHosts = ({ hosts, loading, onLoad }) => {
  const theme = useTheme();
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const cardsJsx = hosts.map((item, index) => (
    <React.Fragment key={index}>
      <HostCard host={item} />
    </React.Fragment>
  ));

  const loadingCardsJsx = new Array(3).fill({}).map((_, index) => (
    <React.Fragment key={index}>
      <HostCard loading={loading} />
    </React.Fragment>
  ));

  return (
    <Box display="flex" flexDirection="column">
      <Typography gutterBottom variant="h3" style={{ fontWeight: 500 }}>
        Top hosts
      </Typography>
      <Box position="relative">
        <Carousel
          showArrows
          partialVisible={isTabletOrMobile}
          desktopItemsCount={3}
          tabletItemsCount={2}
          length={hosts.length}
        >
          {loading ? loadingCardsJsx : cardsJsx}
        </Carousel>
      </Box>
    </Box>
  );
};

TopHosts.propTypes = {
  hosts: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  onLoad: PropTypes.func.isRequired,
};

TopHosts.defaultProps = {
  hosts: [],
  loading: false,
};

export default TopHosts;
