import { useMemo, useState } from 'react';
import _ from 'lodash';
import useResizeObserver from 'use-resize-observer';

export default (wait, customRef) => {
  const [size, setSize] = useState({});
  const onResize = useMemo(() => _.debounce(setSize, wait, { leading: true }), [wait]);
  const options = customRef ? { onResize, ref: customRef } : { onResize };
  const { ref } = useResizeObserver(options);

  return { ref, ...size };
};
