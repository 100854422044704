const styles = (theme) => ({
  actionArea: {
    height: '210px',
    '&:before': {
      position: 'absolute',
      top: '0',
      width: '100%',
      height: '100%',
      content: '""',
      opacity: '0',
      transitionDuration: 300,
      borderRadius: 'inherit',
    },
    '&:hover:before': {
      opacity: '0.1',
      backgroundColor: theme.palette.common.black,
    },
  },
  title: {
    margin: theme.spacing(2, 0),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
  image: {
    width: '100%',
    height: '210px',
    objectFit: 'cover',
    objectPosition: 'center',
    borderRadius: theme.shape.borderRadius,
  },
});

export default styles;
