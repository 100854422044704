/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const forgotPasswordSlice = createSlice({
  name: 'auth/forgotPassword',
  initialState: {},
  reducers: {
    showForgotPassword: (state) => {
      state.forgotPassword.open = true;
    },

    closeForgotPassword: (state) => {
      state.forgotPassword.open = false;
    },

    setForgotPasswordEmail: (state, { payload }) => {
      state.forgotPassword.email = payload;
    },

    forgotPassword: (state) => {
      state.forgotPassword.loading = true;
    },

    forgotPasswordSuccess: (state) => {
      state.forgotPassword.success = true;
      state.forgotPassword.open = false;
      state.forgotPassword.loading = false;
    },

    forgotPasswordFailed: (state, { payload }) => {
      state.forgotPassword.errorMessage = payload;
      state.forgotPassword.loading = false;
    },
  },
});

const { actions, reducer } = forgotPasswordSlice;

export const {
  showForgotPassword,
  closeForgotPassword,
  setForgotPasswordEmail,
  forgotPassword,
  forgotPasswordSuccess,
  forgotPasswordFailed,
} = actions;

export default reducer;
