import { createSelector } from 'reselect';

import { BOOKING_NOTICE_PERIOD } from '@evee/evee-ui.utils';

import { PhotoStatus } from './reducer';

export const getListing = (state) => state.listing;
export const getConditions = (state) => ({
  ...state.listing.conditions,
  documents: state.listing.vehicle.documents || [],
});
export const getCustomPrices = (state) => state.listing.customPrices;

export const getDictionaries = (state) => state.listing.dictionaries;
export const getVehicle = (state) => state.listing.vehicle;
export const getPhotos = (state) => state.listing.vehicle.photos;
export const getInstantBooking = (state) => state.listing.vehicle.instantBooking || {};

export const getPrimaryInfo = (state) => ({
  make: state.listing.vehicle.make || '',
  model: state.listing.vehicle.model || '',
  badge: state.listing.vehicle.badge || '',
  year: state.listing.vehicle.year || '',
  registrationNumber: state.listing.vehicle.registrationNumber || '',
  odometer: state.listing.vehicle.odometer,
  marketValue: state.listing.vehicle.marketValue,
});

export const getPrice = (state) => state.listing.vehicle.price || '';

export const getDeliveryInfo = (state) => ({
  deliveryAvailable: state.listing.vehicle.deliveryAvailable,
  deliveryRadius: state.listing.vehicle.deliveryRadius || '',
  deliveryPrice: state.listing.vehicle.deliveryPrice || '',
});

export const getDescription = (state) => state.listing.vehicle.description || '';

export const getLoading = (state) =>
  state.listing.occupations.loading ||
  state.listing.customPrices.loading ||
  (state.listing.vehicle.photos &&
    state.listing.vehicle.photos.some((p) => p.status === PhotoStatus.loading));

export const getDocumentLoading = (state) => state.listing.documentLoading;

export const getIsPublished = (state) => state.listing.isPublished;

export const getPhotosCount = createSelector(getVehicle, (vehicle) => vehicle?.photos?.length ?? 0);

export const getAppointmentsDialogOpen = (state) => state.listing.appointmentsDialogOpen;
export const getReturnChargeLevel = (state) => state.listing.vehicle.returnChargeLevel;
export const getInsuranceConfirmed = (state) => state.listing.vehicle.insuranceConfirmed;

export const getNoticePeriod = (state) =>
  state.listing.vehicle.noticePeriod || BOOKING_NOTICE_PERIOD;
