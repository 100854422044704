import { all, fork } from 'redux-saga/effects';

import customPricesSaga from './customPrices';
import listenHide from './listenHide';
import listenLoad from './listenLoad';
import listenRemove from './listenRemove';
import listenRemoveExtraLocation from './listenRemoveExtraLocation';
import listenRemovePhoto from './listenRemovePhoto';
import listenSave from './listenSave';
import listenSaveDraft from './listenSaveDraft';
import listenSetAppointmentsEvent from './listenCloseAppointmentsDialog';
import listenSetExtraLocation from './listenSetExtraLocation';
import listenSetField from './listenSetField';
import listenUploadDocument from './listenUploadDocument';
import listenUploadPhotos from './listenUploadPhotos';
import occupationsSaga from './occupations';

export default function* listingSaga() {
  yield all([
    fork(customPricesSaga),
    fork(listenHide),
    fork(listenLoad),
    fork(listenRemove),
    fork(listenRemoveExtraLocation),
    fork(listenRemovePhoto),
    fork(listenSave),
    fork(listenSaveDraft),
    fork(listenSetAppointmentsEvent),
    fork(listenSetExtraLocation),
    fork(listenSetField),
    fork(listenUploadDocument),
    fork(listenUploadPhotos),
    fork(occupationsSaga),
  ]);
}
