const styles = (theme) => ({
  itemsContainer: {
    backgroundColor: theme.palette.background.paper,
  },
  itemContainer: {
    height: '100%',
    padding: '16px 0',
  },
});

export default styles;
