import PropTypes from 'prop-types';

import { useMediaQuery } from '@material-ui/core';

import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';

const HomeHeader = ({ initiallyTransparent, hideOnScroll }) => {
  const isTabletOrMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  return isTabletOrMobile ? (
    <MobileHeader />
  ) : (
    <DesktopHeader initiallyTransparent={initiallyTransparent} hideOnScroll={hideOnScroll} />
  );
};

HomeHeader.propTypes = {
  initiallyTransparent: PropTypes.bool,
  hideOnScroll: PropTypes.bool,
};

HomeHeader.defaultProps = {
  initiallyTransparent: false,
  hideOnScroll: false,
};

export default HomeHeader;
