import PropTypes from 'prop-types';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';

import { Box, ButtonBase, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { Button } from '@evee/evee-ui.base';
import { DEFAULT_LOCATION_NAME } from '@evee/evee-ui.enums';

import DefaultDialog from 'widgets/layout/DefaultDialog';
import FullscreenCalendar from 'widgets/search/FullscreenCalendar';
import MobileSearchInputs from 'widgets/search/MobileSearchInputs';
import useSearchProps from 'store/modules/search/hooks/useSearchProps';

import styles from './styles';

const getSearchDescription = (tripDates) => {
  if (tripDates.flexible) {
    return 'Flexible dates';
  }

  const startMoment = moment(tripDates.from);
  const endMoment = moment(tripDates.to);

  const isSameMonth = startMoment.format('M') === endMoment.format('M');
  const dateFormat = isSameMonth ? 'D' : 'D MMM';

  const startDate = startMoment.isValid() ? startMoment.format(dateFormat) : '';
  const endDate = endMoment.isValid() ? endMoment.format(dateFormat) : '';
  const startTime = startMoment.isValid() ? startMoment.format('h A') : '';
  const endTime = endMoment.isValid() ? endMoment.format('h A') : '';

  const datesString = startDate === endDate ? startDate : `${startDate}-${endDate}`;

  return `${isSameMonth ? startMoment.format('MMM ') : ''}${datesString}, ${startTime}-${endTime}`;
};

const getShortLocation = (location) => {
  if (!location?.name || location?.name === DEFAULT_LOCATION_NAME) {
    return '';
  }
  return location?.name ?? '';
};

const SearchDialog = ({ classes }) => {
  const browserLocation = useLocation();
  const [tripDates, location, loading, onChangeLocation, onFieldChanged, onSearch] =
    useSearchProps();

  const [opened, setOpened] = useState(false);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const isSearchPage =
    browserLocation.pathname.includes('search') || browserLocation.pathname.includes('vehicles');

  const shortLocation = getShortLocation(location);

  const searchDescriptionJsx = (
    <>
      {shortLocation && <Box className={classes.ellipsisText}>{shortLocation}</Box>}
      <Box className={classes.ellipsisText}>{getSearchDescription(tripDates)}</Box>
    </>
  );

  const dialogActionsJsx = (
    <Button
      disabled={loading}
      text="Find cars"
      style={{ width: '100%' }}
      onClick={() => {
        onSearch();
        setOpened(false);
      }}
    />
  );

  return (
    <>
      <ButtonBase className={classes.searchButton} onClick={() => setOpened(true)}>
        <Typography align="left" className={classes.title}>
          {isSearchPage ? searchDescriptionJsx : 'Find cars...'}
        </Typography>
      </ButtonBase>

      <DefaultDialog
        fullScreen
        decorateBackground
        open={opened}
        title="Search"
        renderActions={() => dialogActionsJsx}
        onClose={() => setOpened(false)}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          className={classes.searchContainer}
        >
          <MobileSearchInputs
            tripDates={tripDates}
            location={location}
            onLocationChange={onChangeLocation}
            onFlexibleDatesChange={(v) => onFieldChanged('flexibleDates', v)}
            onPickUpClick={() => setCalendarOpen(true)}
            onDropOffClick={() => setCalendarOpen(true)}
          />
        </Box>
      </DefaultDialog>

      <FullscreenCalendar
        tripDates={tripDates}
        open={calendarOpen}
        onDatesChange={(v) => onFieldChanged('range', v)}
        onFlexibleChange={(v) => {
          onFieldChanged('flexibleDates', v);
        }}
        onClose={() => setCalendarOpen(false)}
      />
    </>
  );
};

SearchDialog.propTypes = {
  classes: PropTypes.shape({
    searchButton: PropTypes.string.isRequired,
    dialogContent: PropTypes.string.isRequired,
    closeButton: PropTypes.string.isRequired,
    searchContainer: PropTypes.string.isRequired,
    titleContainer: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    ellipsisText: PropTypes.string.isRequired,
  }).isRequired,
};

SearchDialog.defaultProps = {};

export default withStyles(styles)(SearchDialog);
