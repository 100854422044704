import PropTypes from 'prop-types';

import { Box, Grid, ListItemIcon, MenuItem, Typography } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  menuItem: {
    padding: theme.spacing(2, 1.5),
    borderRadius: theme.shape.borderRadius,
    height: '60px',
  },
});

const RegionItem = ({ region, currency, icon, expandIcon, classes, onClick, ...rest }) => (
  <MenuItem disableGutters className={classes.menuItem} onClick={onClick} {...rest}>
    <Grid container alignItems="center" spacing={0}>
      {icon && <ListItemIcon style={{ minWidth: '40px' }}>{icon}</ListItemIcon>}

      <Grid item xs>
        <Typography variant="body2">{region.language}</Typography>
        <Typography variant="body2" color="textSecondary">
          {region.name}
        </Typography>
      </Grid>

      <Grid item xs>
        <Typography variant="body2">{currency.name}</Typography>
        <Typography variant="body2" color="textSecondary">
          {currency.description}
        </Typography>
      </Grid>

      {expandIcon && (
        <Grid item xs>
          <Box display="flex" justifyContent="flex-end">
            <ChevronRight />
          </Box>
        </Grid>
      )}
    </Grid>
  </MenuItem>
);

RegionItem.propTypes = {
  region: PropTypes.shape({
    id: PropTypes.string,
    language: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  currency: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  icon: PropTypes.node,
  expandIcon: PropTypes.bool,
  classes: PropTypes.shape({
    menuItem: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func,
};

RegionItem.defaultProps = {
  region: {},
  currency: {},
  icon: null,
  expandIcon: false,
  onClick: () => {},
};

export default withStyles(styles)(RegionItem);
