import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import { Region } from '@evee/evee-ui.enums';

import useRegion from 'store/modules/app/hooks/useRegion';

const LinkTags = () => {
  const { region } = useRegion();
  const location = useLocation();

  return (
    <Helmet>
      <link
        rel="alternate"
        href={`https://${Region.australia.domain}${location.pathname}`}
        hrefLang="en-au"
      />

      <link
        rel="alternate"
        href={`https://${Region.newZealand.domain}${location.pathname}`}
        hrefLang="en-nz"
      />

      <link rel="canonical" href={`https://${region.domain}${location.pathname}`} />

      {location.pathname.includes('/search') && (
        <link rel="canonical" href={`https://${region.domain}/vehicles`} />
      )}
    </Helmet>
  );
};

LinkTags.propTypes = {};
LinkTags.defaultProps = {};

export default LinkTags;
