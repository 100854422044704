const prefix = 'profile';

export const SET_FIELD_VALUE = `${prefix}/SET_FIELD_VALUE`;
export const SET_PROFILE = `${prefix}/SET_PROFILE`;

export const UPLOAD_PHOTO = `${prefix}/UPLOAD_PHOTO`;
export const UPLOAD_PHOTO_SUCCESS = `${prefix}/UPLOAD_PHOTO_SUCCESS`;
export const UPLOAD_PHOTO_FAILED = `${prefix}/UPLOAD_PHOTO_FAILED`;

export const LOAD = `${prefix}/LOAD`;
export const LOAD_SUCCESS = `${prefix}/LOAD_SUCCESS`;
export const LOAD_FAILED = `${prefix}/LOAD_FAILED`;

export const COPY = `${prefix}/COPY`;

export const GOOGLE_CONNECT = `${prefix}/GOOGLE_CONNECT`;
export const FACEBOOK_CONNECT = `${prefix}/FACEBOOK_CONNECT`;

export const SAVE = `${prefix}/SAVE`;
export const SAVE_SUCCESS = `${prefix}/SAVE_SUCCESS`;

export const SET_ERRORS = `${prefix}/SET_ERRORS`;
export const REMOVE_FIELD_ERROR = `${prefix}/REMOVE_FIELD_ERROR`;
export const SET_FIELD_ERROR = `${prefix}/SET_FIELD_ERROR`;

export const SET_ABOUT = `${prefix}/SET_ABOUT`;
export const SET_NOTIFIED = `${prefix}/SET_NOTIFIED`;

export const SET_RECEIVE_PUSH_NOTIFICATIONS = `${prefix}/SET_RECEIVE_PUSH_NOTIFICATIONS`;

export const LOAD_PUSH_SUBSCRIPTION = `${prefix}/LOAD_PUSH_SUBSCRIPTION`;
export const SET_PUSH_SUBSCRIPTION = `${prefix}/SET_PUSH_SUBSCRIPTION`;
export const SET_PUSH_SUBSCRIPTION_SUCCESS = `${prefix}/SET_PUSH_SUBSCRIPTION_SUCCESS`;
export const SET_PUSH_SUBSCRIPTION_FAILED = `${prefix}/SET_PUSH_SUBSCRIPTION_FAILED`;

export const DEACTIVATE_ACCOUNT = `${prefix}/DEACTIVATE_ACCOUNT`;
export const SET_DEACTIVATE_DIALOG_OPEN = `${prefix}/SET_DEACTIVATE_DIALOG_OPEN`;
export const SET_DEACTIVATE_DIALOG_LOADING = `${prefix}/SET_DEACTIVATE_DIALOG_LOADING`;
export const SET_DEACTIVATE_DIALOG_REASON = `${prefix}/SET_DEACTIVATE_DIALOG_REASON`;

export const CHANGE_EMAIL = `${prefix}/CHANGE_EMAIL`;
export const CHANGE_EMAIL_SUCCESS = `${prefix}/CHANGE_EMAIL_SUCCESS`;
export const CHANGE_EMAIL_FAILED = `${prefix}/CHANGE_EMAIL_FAILED`;
