export default {
  loading: false,
  vehicle: {
    owner: {},
  },
  booking: {
    renter: {},
    delivery: {},
  },
};
