import { put, select, takeEvery } from 'redux-saga/effects';

import * as actionTypes from 'store/modules/auth';
import * as basketActions from 'store/modules/basket';
import * as basketSelectors from 'store/modules/basket/selectors';

function* signIn() {
  const signingIn = yield select(basketSelectors.getIsSigningIn);
  if (signingIn) {
    yield put(basketActions.setSigningIn(false));
    yield put(basketActions.createBasket());
  }
}

export default function* listenSignIn() {
  yield takeEvery(actionTypes.signInActions, signIn);
}
