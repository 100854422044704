const styles = (theme) => ({
  actions: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  actionButton: {
    marginTop: theme.spacing(4),
  },
});

export default styles;
