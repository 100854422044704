import { useDispatch } from 'react-redux';

import * as listYourCarActions from '../index';

export default function useListYourCar() {
  const dispatch = useDispatch();
  const onListYourCar = () => dispatch(listYourCarActions.listYourCar());

  return [onListYourCar];
}
