import * as actionTypes from './actionTypes';
import initialState from './initialState';

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.LOAD:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.LOAD_FAILED:
      return {
        ...state,
        loading: false,
      };

    case actionTypes.LOAD_SUCCESS:
      return {
        ...state,
        title: payload.title,
        content: payload.html,
        loading: false,
      };

    default: return state;
  }
};
