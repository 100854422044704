export const initialFilters = {
  makes: [],
  models: [],
  badges: [],
  price: [],
  features: [],
  sorting: [],
  airport: {},
  instantBooking: false,
  unlimitedMileage: false,
  freeFastCharging: false,
};

export default {
  loading: false,
  location: {},
  startLocation: {},
  vehicles: [],
  noResults: false,
  selectedVehicle: {},
  hoveredVehicle: {},
  selectedAirport: {},
  activeFilters: {
    ...initialFilters,
  },
  defaultFilters: {
    ...initialFilters,
  },
  availableFilters: {
    models: [],
    badges: [],
    airports: [],
  },
  editFilters: {
    ...initialFilters,
  },
  makesTree: [],
  sorting: [],
  filtersDialogOpen: false,
};
