export const getItems = () => [
  {
    icon: 'money',
    title: 'Affordable EV rentals',
    description: 'Find EVs from $70/day available in all major cities across Australia.',
  },
  {
    icon: 'listings',
    title: 'Large range of EVs',
    description: 'Rent Tesla’s, BYD’s, MG’s and many more from friendly hosts near you.',
  },
  {
    icon: 'umbrella',
    title: 'Worry free rentals',
    description: 'All rentals include comprehensive insurance and roadside assistance.',
  },
  {
    icon: 'securePayments',
    title: 'Free cancellation',
    description: 'Cancel for free up to 72 hours prior to the start of your rental.',
  },
  {
    icon: 'car',
    title: 'Delivery options available',
    description: 'Get your EV delivered to your doorstep or the airport.',
  },
  {
    icon: 'australia',
    title: 'Local customer support',
    description: 'Our Australian support team is here to help online and over the phone.',
  },
];
