const styles = () => ({
  avatar: {
    width: '24px',
    height: '24px',
  },
  icon: {
    marginLeft: '10px',
  },
});

export default styles;
