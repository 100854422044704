const prefix = 'search';

export const SET_FIELD_VALUE = `${prefix}/SET_FIELD_VALUE`;
export const SEARCH = `${prefix}/SEARCH`;
export const SET_QUERY_FILTERS = `${prefix}/SET_QUERY_FILTERS`;
export const SET_AIRPORT_SEARCH = `${prefix}/SET_AIRPORT_SEARCH`;
export const UPDATE_AIRPORT_SEARCH = `${prefix}/UPDATE_AIRPORT_SEARCH`;

export const CHANGE_LOCATION = `${prefix}/CHANGE_LOCATION`;
export const CHANGE_LOCATION_SUCCESS = `${prefix}/CHANGE_LOCATION_SUCCESS`;
export const CHANGE_LOCATION_FAILED = `${prefix}/CHANGE_LOCATION_FAILED`;
