import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Card, CardActionArea, Link, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';

import { formatPriceNumber } from '@evee/evee-ui.utils';

import styles from './styles';

const ModelCard = ({ name, price, thumbnail, searchQuery, loading, classes, ...rest }) => {
  const skeletonJsx = <Skeleton variant="rect" height="150px" style={{ marginLeft: '8px' }} />;

  const cardJsx = (
    <Link to={`/search?${searchQuery}`} underline="none" component={NavLink}>
      <Card elevation={0} {...rest}>
        <CardActionArea className={classes.actionArea}>
          <img src={thumbnail} className={classes.image} alt="" />
        </CardActionArea>
        <Typography variant="h6" style={{ marginTop: '16px' }}>
          {name}
        </Typography>
        {price > 0 && (
          <Typography color="textSecondary">from {formatPriceNumber(price)}/day</Typography>
        )}
      </Card>
    </Link>
  );

  return loading ? skeletonJsx : cardJsx;
};

ModelCard.propTypes = {
  name: PropTypes.string,
  price: PropTypes.number,
  thumbnail: PropTypes.string,
  searchQuery: PropTypes.string,
  loading: PropTypes.bool,
  classes: PropTypes.shape({
    image: PropTypes.string.isRequired,
    actionArea: PropTypes.string.isRequired,
  }).isRequired,
};
ModelCard.defaultProps = {
  name: '',
  price: 0,
  thumbnail: '',
  searchQuery: '',
  loading: false,
};

export default withStyles(styles)(ModelCard);
