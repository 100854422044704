import { all, call, put, select } from 'redux-saga/effects';
import moment from 'moment';

import { getCustomPriceDays, rangeIncludesCustomPrice } from '@evee/evee-ui.utils';
import { VehicleStatus } from '@evee/evee-ui.enums';
import { apiService } from '@evee/evee-ui.services';

import { showError, showRequestError } from 'store/modules/app/actions';

import {
  createFailed,
  createSuccess,
  load as loadCustomPrices,
  loadFailed,
  loadSuccess,
  removeFailed,
  removeSuccess,
  setCurrentMonth,
  setRange,
  setSelectedPriceRange,
  updateFailed,
  updateSuccess,
} from 'store/modules/listing/customPrices';

import {
  getCurrentMonth,
  getPriceRanges,
  getRange,
  getSelectedPriceRange,
} from 'store/modules/listing/customPrices/selectors';

import { getVehicle } from 'store/modules/listing/selectors';

export function* loadDefaultCustomPrices() {
  const currentMonth = yield select(getCurrentMonth);
  yield put(loadCustomPrices(currentMonth));
}

export function* load(action) {
  try {
    const { month, year } = action.payload;
    const vehicle = yield select(getVehicle);

    yield put(setCurrentMonth({ month, year }));

    if (!vehicle.id) {
      yield put(loadFailed());
    }

    let prices = [];

    if (vehicle.status !== VehicleStatus.draft) {
      const from = moment().set('year', year).set('month', month).startOf('month').toDate();
      const to = moment(from).add(1, 'month').toDate();

      const [current, next] = yield all([
        yield call(apiService.listing.getCustomPrices, vehicle.id, {
          month: moment(from).utc(true).month() + 1,
          year: moment(from).utc(true).year(),
        }),
        yield call(apiService.listing.getCustomPrices, vehicle.id, {
          month: moment(to).utc(true).month() + 1,
          year: moment(to).utc(true).year(),
        }),
      ]);

      prices = current.concat(next);
    }

    yield put(loadSuccess(prices));
    yield put(setRange());
  } catch (err) {
    yield put(loadFailed());
    yield put(showRequestError());
  }
}

export function* create({ payload: price }) {
  try {
    const vehicle = yield select(getVehicle);
    const range = yield select(getRange);

    const priceRange = yield call(apiService.listing.createCustomPrice, vehicle.id, {
      from: range.startDate,
      to: range.endDate,
      price,
    });

    yield put(setRange());
    yield put(createSuccess(priceRange));
  } catch (err) {
    yield put(createFailed());
    yield put(showError(err.message));
  }
}

export function* update({ payload: customPrice }) {
  try {
    const { id, price } = customPrice;
    const vehicle = yield select(getVehicle);
    const range = yield select(getRange);

    const priceRange = yield call(apiService.listing.updateCustomPrice, vehicle.id, id, {
      from: range.startDate,
      to: range.endDate,
      price,
    });

    yield put(updateSuccess(priceRange));
    yield put(setRange());
    yield put(setSelectedPriceRange());
  } catch (err) {
    yield put(updateFailed());
    yield put(showError(err.message));
  }
}

export function* remove({ payload: customPriceId }) {
  try {
    const vehicle = yield select(getVehicle);

    if (customPriceId && vehicle.id) {
      yield call(apiService.listing.deleteCustomPrice, vehicle.id, customPriceId);
      yield put(setRange());
      yield put(removeSuccess(customPriceId));
    }
  } catch (err) {
    yield put(removeFailed());
    yield put(showRequestError());
  }
}

export function* updateRange({ payload }) {
  try {
    const { startDate, endDate } = payload;
    const selectedRange = yield select(getSelectedPriceRange);
    let priceRanges = yield select(getPriceRanges);

    if (selectedRange?.id) {
      priceRanges = priceRanges.filter((pr) => pr.id !== selectedRange.id);
    }
    const priceDays = getCustomPriceDays(priceRanges);

    if (startDate <= endDate && !rangeIncludesCustomPrice(startDate, endDate, priceDays)) {
      yield put(setRange(startDate, endDate));
    }
  } catch (error) {
    yield put(showError(error.message));
  }
}

export function* selectRange(action) {
  try {
    const { startDate, endDate } = action.payload;
    const priceRanges = yield select(getPriceRanges);
    const priceDays = getCustomPriceDays(priceRanges);
    const date = moment(endDate || startDate).format('YYYY-MM-DDT00:00');
    const range = priceRanges.find((e) => {
      const from = moment(e.from);
      const to = moment(e.to).subtract(1, 'minutes');
      return moment(date).isBetween(from, to, 'day', '[]');
    });

    if (range) {
      yield put(setRange(moment(range.from), moment(range.to).subtract(1, 'minutes')));
      yield put(setSelectedPriceRange(range));
    } else if (!rangeIncludesCustomPrice(startDate, endDate, priceDays)) {
      yield put(setRange(startDate, endDate));
      yield put(setSelectedPriceRange());
    }
  } catch (err) {
    yield put(showError(err.message));
  }
}
