import { call, put, select } from 'redux-saga/effects';
import _ from 'lodash';

import { CustomerRoles } from '@evee/evee-ui.enums';
import { apiService } from '@evee/evee-ui.services';
import { stringCompare } from '@evee/evee-ui.utils';

import * as appActions from 'store/modules/app/actions';
import * as hostAllBookingsActions from 'store/modules/hostAllBookings';
import * as hostAllBookingsSelectors from 'store/modules/hostAllBookings/selectors';

const PAGE_SIZE = 24;

export function* load() {
  try {
    yield put(appActions.setLoading(true));

    const activeFilters = yield select(hostAllBookingsSelectors.getActiveFilters);
    const statusFilter = yield select(hostAllBookingsSelectors.getStatusFilter);
    const sorting = yield select(hostAllBookingsSelectors.getSorting);
    const pageNumber = yield select(hostAllBookingsSelectors.getCurrentPage);
    const vehiclesFilter = activeFilters.models.map((v) => v.id);

    const counts = yield call(apiService.booking.getStats, CustomerRoles.owner, vehiclesFilter);
    const bookings = yield call(
      apiService.booking.getMyTrips,
      CustomerRoles.owner,
      statusFilter,
      0,
      PAGE_SIZE * pageNumber,
      vehiclesFilter,
      sorting,
    );

    const vehicles = yield call(apiService.listing.getAll);
    yield put(
      hostAllBookingsActions.setAvailableFilters({
        models: vehicles
          .map((v) => ({
            id: v.id,
            name: _.compact([v.name, v.badge]).join(' '),
          }))
          .sort((a, b) => stringCompare(a.name, b.name)),
      }),
    );

    yield put(
      hostAllBookingsActions.loadSuccess({
        bookings,
        vehicles,
        counts,
      }),
    );
  } catch (error) {
    yield put(hostAllBookingsActions.loadFailed());
    yield put(appActions.showRequestError());
  } finally {
    yield put(appActions.setLoading(false));
  }
}

export function* loadMore({ payload: pageNumber }) {
  try {
    const filterValue = yield select(hostAllBookingsSelectors.getStatusFilter);
    const activeFilters = yield select(hostAllBookingsSelectors.getActiveFilters);
    const sorting = yield select(hostAllBookingsSelectors.getSorting);
    const vehiclesFilter = activeFilters.models.map((v) => v.id);

    const bookings = yield call(
      apiService.booking.getMyTrips,
      CustomerRoles.owner,
      filterValue,
      pageNumber - 1,
      PAGE_SIZE,
      vehiclesFilter,
      [sorting],
    );
    yield put(hostAllBookingsActions.loadMoreSuccess({ pageNumber, bookings }));
  } catch (error) {
    yield put(hostAllBookingsActions.loadMoreFailed());
  }
}

export function* loadBookings() {
  try {
    yield put(appActions.setLoading(true));
    const statusFilter = yield select(hostAllBookingsSelectors.getStatusFilter);
    const activeFilters = yield select(hostAllBookingsSelectors.getActiveFilters);
    const sorting = yield select(hostAllBookingsSelectors.getSorting);
    const vehiclesFilter = activeFilters.models.map((v) => v.id);

    const counts = yield call(apiService.booking.getStats, CustomerRoles.owner, vehiclesFilter);
    const bookings = yield call(
      apiService.booking.getMyTrips,
      CustomerRoles.owner,
      statusFilter,
      0,
      PAGE_SIZE,
      vehiclesFilter,
      [sorting],
    );

    yield put(hostAllBookingsActions.setCurrentPage(1));
    yield put(hostAllBookingsActions.setBookings(bookings));
    yield put(hostAllBookingsActions.setCounts(counts));
  } catch (error) {
    yield put(appActions.showRequestError());
  } finally {
    yield put(appActions.setLoading(false));
  }
}
