import moment from 'moment';

export const getLocation = (state) => state.search.location;
export const getDefaultFilters = (state) => state.search.defaultFilters;
export const getLoading = (state) => state.search.loading;
export const getAirportSearch = (state) => state.search.airportSearch;
export const getAllAirports = (state) => state.search.allAirports;

export const getTripDates = (state) => ({
  from: moment(state.search?.range?.start).toISOString(),
  to: moment(state.search?.range?.end).toISOString(),
  flexible: state.search.flexibleDates || false,
});

export const getFilters = (state) => ({
  from: state.search?.range?.start,
  to: state.search?.range?.end,
  flexibleDates: state.search.flexibleDates,
  location: state.search.location,
  makes: state.search.makes || [],
  models: state.search.models || [],
  badges: state.search.badges || [],
  features: state.search.features || [],
  price: state.search?.price || [],
  instantBooking: state.search?.instantBooking || false,
  unlimitedMileage: state.search?.unlimitedMileage || false,
  freeFastCharging: state.search?.freeFastCharging || false,
});
