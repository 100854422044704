import { takeEvery } from 'redux-saga/effects';

import * as actions from 'store/modules/bookingChange';

import { loadTotals } from './utils';

function* listenLoad() {
  yield takeEvery(actions.loadTotals.toString(), loadTotals);
}

export default listenLoad;
