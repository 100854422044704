import { getHomeUrl } from '@evee/evee-ui.utils';

import supportHrefs from 'common/constants/supportHrefs';
import useRegion from 'store/modules/app/hooks/useRegion';

import FooterColumn from '../utils/FooterColumn';
import FooterLink from '../utils/FooterLink';

const ExploreColumn = () => {
  const { region } = useRegion();

  return (
    <FooterColumn title="Explore">
      <FooterLink href={`${getHomeUrl(region)}/renting/`}>How renting works</FooterLink>

      <FooterLink href={`${getHomeUrl(region)}/giftcards/`}>Gift cards</FooterLink>

      <FooterLink href={`${getHomeUrl(region)}/blog/`}>Blog</FooterLink>

      <FooterLink href={`${getHomeUrl(region)}/partner/`}>Partner with us</FooterLink>

      <FooterLink href={supportHrefs[region.id].getHelpRenters}>Help Centre</FooterLink>
    </FooterColumn>
  );
};

export default ExploreColumn;
