const styles = (theme) => ({
  actionArea: {
    border: `1px solid ${theme.palette.border.main}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  avatar: {
    width: '3.5em',
    height: '3.5em',
  },
  ownerName: {
    marginLeft: '20px',
  },
  review: {
    minHeight: '100px',
    margin: '24px 0',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 4,
    '-webkit-box-orient': 'vertical',
  },
});

export default styles;
