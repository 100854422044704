import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { MapOutlined } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

import { Divider } from '@evee/evee-ui.base';
import { getHomeUrl } from '@evee/evee-ui.utils';
import { history } from '@evee/evee-ui.services';

import { NotificationType } from 'enumsTemp';
import supportHrefs from 'common/constants/supportHrefs';

import Item from '../../../utils/Item';
import NotificationsCount from '../../../utils/NotificationsCount';

import {
  Bolt,
  Calendar,
  Car,
  Envelope,
  File,
  Gift,
  Headphones,
  Lightbulb,
  Money,
  Notebooks,
  Settings,
  SignOut,
  User,
} from '../../../icons';

import styles from '../../styles';

const HostItems = ({ customerId, region, classes, onSignOut, onClose }) => {
  const location = useLocation();

  return (
    <>
      <Item
        bold
        label="Renting"
        icon={<Calendar />}
        selected={location.pathname === '/account/trips'}
        onClick={() => {
          history.push('/account/trips');
          onClose();
        }}
      />

      <Item
        bold
        label="Hosting"
        icon={<Money />}
        selected={location.pathname === '/account/cars'}
        onClick={() => {
          history.push('/account/cars');
          onClose();
        }}
      />

      <Item
        label="Messages"
        icon={<Envelope />}
        endDecoration={<NotificationsCount type={NotificationType.message} />}
        selected={location.pathname.includes('/account/messages')}
        onClick={() => {
          history.push('/account/messages');
          onClose();
        }}
      />

      <Divider className={classes.divider} />

      <Item
        label="Account"
        icon={<Settings />}
        selected={location.pathname === '/account/edit'}
        onClick={() => {
          history.push('/account/edit');
          onClose();
        }}
      />

      <Item
        label="Profile"
        icon={<User />}
        selected={location.pathname === `/account/${customerId}`}
        onClick={() => {
          history.push(`/account/${customerId}`);
          onClose();
        }}
      />

      <Item
        label="Rewards"
        icon={<Bolt />}
        endDecoration={<NotificationsCount type={NotificationType.rewards} />}
        selected={location.pathname === `/account/rewards`}
        onClick={() => {
          history.push('/account/rewards');
          onClose();
        }}
      />

      <Item
        label="List a car"
        icon={<Car />}
        selected={location.pathname === '/listing/process'}
        onClick={() => {
          history.push('/listing/process');
          onClose();
        }}
      />

      <Divider className={classes.divider} />

      <Item
        label="All evee vehicles"
        selected={location.pathname.includes('/vehicles') || location.pathname.includes('/search')}
        icon={<MapOutlined />}
        onClick={() => {
          history.push('/vehicles');
          onClose();
        }}
      />

      <Item
        label="How evee works"
        icon={<Lightbulb />}
        onClick={() => {
          window.location.href = `${getHomeUrl(region)}/renting`;
        }}
      />

      <Item
        label="Insurance"
        icon={<File />}
        onClick={() => {
          window.location.href = `${getHomeUrl(region)}/insurance`;
        }}
      />

      <Item
        label="Gift cards"
        icon={<Gift />}
        onClick={() => {
          window.location.href = `${getHomeUrl(region)}/giftcards`;
        }}
      />

      <Divider className={classes.divider} />

      <Item
        label="Contact support"
        icon={<Headphones />}
        onClick={() => {
          window.location.href = supportHrefs[region.id].contactSupport;
        }}
      />

      <Item
        label="Help Centre"
        icon={<Notebooks />}
        onClick={() => {
          window.location.href = supportHrefs[region.id].helpCenter;
        }}
      />

      <Item
        label="Log out"
        icon={<SignOut />}
        onClick={() => {
          onSignOut();
          onClose();
        }}
      />
    </>
  );
};

HostItems.propTypes = {
  customerId: PropTypes.string,
  region: PropTypes.object,
  classes: PropTypes.shape({
    divider: PropTypes.string.isRequired,
  }).isRequired,
  onSignOut: PropTypes.func,
  onClose: PropTypes.func,
};

HostItems.defaultProps = {
  customerId: '',
  region: {},
  onSignOut: () => {},
  onClose: () => {},
};

export default withStyles(styles)(HostItems);
