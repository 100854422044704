import * as actionTypes from './actionTypes';

export const load = () => ({
  type: actionTypes.LOAD,
});

export const search = () => ({
  type: actionTypes.SEARCH,
});

export const searchSuccess = (vehicles) => ({
  type: actionTypes.SEARCH_SUCCESS,
  payload: vehicles,
});

export const setActiveFilters = (filters) => ({
  type: actionTypes.SET_ACTIVE_FILTERS,
  payload: filters,
});

export const setDefaultFilters = (filters) => ({
  type: actionTypes.SET_DEFAULT_FILTERS,
  payload: filters,
});

export const filterBadges = (badges) => ({
  type: actionTypes.FILTER_BADGES,
  payload: badges,
});

export const setActiveFilterValue = (filterName, filterValue) => ({
  type: actionTypes.SET_ACTIVE_FILTER_VALUE,
  payload: { filterName, filterValue },
});

export const clearFilters = () => ({
  type: actionTypes.CLEAR_FILTERS,
});

export const setFieldValue = (fieldName, fieldValue) => ({
  type: actionTypes.SET_FIELD_VALUE,
  payload: { fieldName, fieldValue },
});

export const setLoading = (loading) => ({
  type: actionTypes.SET_LOADING,
  payload: loading,
});

export const setFiltersDialogOpen = (open) => ({
  type: actionTypes.SET_FILTERS_DIALOG_OPEN,
  payload: open,
});

export const setEditFiltersFieldValue = (fieldName, fieldValue) => ({
  type: actionTypes.SET_EDIT_FILTERS_FIELD_VALUE,
  payload: { fieldName, fieldValue },
});

export const resetEditFilters = () => ({
  type: actionTypes.RESET_EDIT_FILTERS,
});

export const setEditFilters = (filters) => ({
  type: actionTypes.SET_EDIT_FILTERS,
  payload: filters,
});

export const setDialogMakes = (makes) => ({
  type: actionTypes.SET_DIALOG_MAKES,
  payload: makes,
});

export const setDialogModels = (makes) => ({
  type: actionTypes.SET_DIALOG_MODELS,
  payload: makes,
});

export const setAvailableFilters = (filters) => ({
  type: actionTypes.SET_AVAILABLE_FILTERS,
  payload: filters,
});

export const sortByField = (fieldName, direction) => ({
  type: actionTypes.SORT_BY_FIELD,
  payload: { fieldName, direction },
});

export const resetSorting = () => ({
  type: actionTypes.RESET_SORTING,
});

export const setSelectedVehicle = (selectedVehicle) => ({
  type: actionTypes.SET_SELECTED_VEHICLE,
  payload: selectedVehicle,
});

export const setHoveredVehicle = (hoveredVehicle) => ({
  type: actionTypes.SET_HOVERED_VEHICLE,
  payload: hoveredVehicle,
});

export const setLocation = (location) => ({
  type: actionTypes.SET_LOCATION,
  payload: location,
});

export const setStartLocation = (location) => ({
  type: actionTypes.SET_START_LOCATION,
  payload: location,
});

export const setAirports = (airports) => ({
  type: actionTypes.SET_AIRPORTS,
  payload: airports,
});

export const setAirport = (airport) => ({
  type: actionTypes.SET_AIRPORT,
  payload: airport,
});

export const setSelectedAirport = (airport) => ({
  type: actionTypes.SET_SELECTED_AIRPORT,
  payload: airport,
});

export const setActiveAirport = (airport) => ({
  type: actionTypes.SET_ACTIVE_AIRPORT,
  payload: airport,
});
