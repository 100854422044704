import { all, call, put, select } from 'redux-saga/effects';
import moment from 'moment';

import { apiService, tagManager } from '@evee/evee-ui.services';

import * as appActions from 'store/modules/app/actions';
import * as appSelectors from 'store/modules/app/selectors';
import * as bookingActions from 'store/modules/booking/actions';
import * as bookingSelectors from 'store/modules/booking/selectors';
import * as vehicleActions from 'store/modules/vehicle/actions';
import * as vehicleSelectors from 'store/modules/vehicle/selectors';

import { redirectIfError, stringCompare } from '@evee/evee-ui.utils';
import { getDaysStr } from '@evee/evee-ui.utils/dist/bookingUtils';

const defaultStartMonth = moment.utc(moment()).startOf('month').toDate();
const defaultEndMonth = moment.utc(moment()).startOf('month').add(1, 'month').toDate();

function* loadVehicle(action) {
  try {
    yield put(appActions.setLoading(true));
    const currency = yield select(appSelectors.getCurrency);

    const vehicleId = action.payload;
    const tripDates = yield select(bookingSelectors.getTripDates);
    const location = yield select(bookingSelectors.getLocation);

    const vehicle = yield call(apiService.vehicle.getVehicle, vehicleId, currency.id);

    const features = apiService.dictionary.getFeatures();
    const sortedFeatures = features.sort((a, b) => stringCompare(a.name, b.name));

    yield put(vehicleActions.setVehicle(vehicle));
    yield put(vehicleActions.setAllFeatures(sortedFeatures));

    yield put(bookingActions.setTripDates(tripDates.start, tripDates.end));

    if (vehicle.deliveryAirport && location?.id === vehicle.deliveryAirport.id) {
      yield put(bookingActions.setLocation(vehicle.deliveryAirport));
    } else {
      yield put(bookingActions.setLocation(vehicle.deliveryAddress));
    }

    if (!vehicle.deleted) {
      if (tripDates.start && tripDates.end) {
        yield put(bookingActions.loadOccupiedDates(vehicleId));
      } else {
        yield put(bookingActions.loadOccupiedDates(vehicleId, defaultStartMonth, defaultEndMonth));
      }
    }

    yield put(vehicleActions.loadTotals());

    yield put(vehicleActions.loadVehicleSuccess());
    tagManager.pushDetail({
      ...vehicle,
      quantity: moment(tripDates.end).diff(moment(tripDates.start), 'days'),
    });
  } catch (error) {
    yield put(vehicleActions.loadVehicleFailed());
    yield put(appActions.showRequestError());

    redirectIfError(error);
  } finally {
    yield put(appActions.setLoading(false));
  }
}

function validateRideLength(start, end, rideLength) {
  const { minRideLength, maxRideLength } = rideLength;

  const hours = moment(end).diff(moment(start), 'hours');

  const tripStart = moment(start).startOf('day');
  const tripEnd = moment(end).startOf('day');
  const days = tripEnd.diff(tripStart, 'days');

  const maxDays = maxRideLength < 0 ? Number.POSITIVE_INFINITY : maxRideLength;
  const rideIsTooShort = minRideLength === 1 ? hours < 1 : days < minRideLength;

  if (rideIsTooShort || days > maxDays) {
    throw new Error(
      `The min./max. trip for this listing is from ${minRideLength} ${getDaysStr(
        minRideLength,
      )} to ${maxRideLength < 0 ? 'unlimited' : maxRideLength} ${
        maxRideLength < 0 ? '' : getDaysStr(maxDays)
      }`,
    );
  }
}

function* loadTotals() {
  try {
    const [tripDates, vehicleId, currency, rideLength] = yield all([
      select(bookingSelectors.getTripDates),
      select(vehicleSelectors.getVehicleId),
      select(appSelectors.getCurrency),
      select(vehicleSelectors.getRideLength),
    ]);

    if (vehicleId && moment(tripDates.start).isValid() && moment(tripDates.end).isValid()) {
      yield call(validateRideLength, tripDates.start, tripDates.end, rideLength);

      const params = {
        vehicleId,
        from: moment(tripDates.start).utc(true).format('YYYY-MM-DDTHH:mm'),
        to: moment(tripDates.end).utc(true).format('YYYY-MM-DDTHH:mm'),
        currency: currency.id,
      };
      const [totals, extension] = yield all([
        call(apiService.vehicle.getTotals, params),
        call(apiService.vehicle.getTotalsExtensions, params),
      ]);

      yield put(vehicleActions.setTotals(totals));
      yield put(bookingActions.setExtension(extension));
      yield put(bookingActions.setTripDatesAvailability(true));
    }
    yield put(vehicleActions.loadTotalsSuccess());
  } catch (error) {
    yield put(bookingActions.setTripDatesAvailability(false, error.message));
    yield put(appActions.showError(`Sorry, selected dates are not available. ${error.message}`));
    yield put(vehicleActions.loadTotalsFailed());
  }
}

export { loadVehicle, loadTotals };
