import { all, call, put, select } from 'redux-saga/effects';
import { ValidationError } from 'yup';

import { ErrorMessage } from '@evee/evee-ui.enums';
import { apiService } from '@evee/evee-ui.services';
import { extraPayment as extraPaymentModel } from '@evee/evee-ui.models';

import * as appActions from 'store/modules/app/actions';
import * as appSelectors from 'store/modules/app/selectors';
import * as authSelectors from 'store/modules/auth/selectors';
import * as authorisePaymentSelectors from 'store/modules/authorisePayment/selectors';
import * as bookingDetailsActions from 'store/modules/bookingDetails';
import * as bookingDetailsSelectors from 'store/modules/bookingDetails/selectors';
import * as extraPaymentsActions from 'store/modules/extraPayments';
import * as extraPaymentsSelectors from 'store/modules/extraPayments/selectors';

function* load() {
  try {
    const isAuthorized = yield select(authSelectors.getIsUserAuthorized);
    if (!isAuthorized) return;

    const currency = yield select(appSelectors.getCurrency);
    const bookingId = yield select(bookingDetailsSelectors.getBookingId);

    const [extraPayments, draft] = yield all([
      call(apiService.extraPayments.get, bookingId, currency.id),
      call(apiService.extraPayments.getDraft, bookingId),
    ]);

    if (draft) {
      yield put(extraPaymentsActions.setDraft(draft));
    }

    if (extraPayments) {
      yield put(extraPaymentsActions.setExtraPayments(extraPayments));
    }
  } catch (error) {
    yield put(appActions.showRequestError());
  }
}

function* uploadFile({ payload: file }) {
  try {
    const bookingId = yield select(bookingDetailsSelectors.getBookingId);
    const draftId = yield select(extraPaymentsSelectors.getDraftId);
    const draft = yield call(apiService.extraPayments.uploadFile, bookingId, draftId, file);

    yield put(extraPaymentsActions.uploadFileSuccess(draft));
  } catch (error) {
    yield put(extraPaymentsActions.uploadFileFailed());
    yield put(appActions.showRequestError());
  }
}

function* deleteFile({ payload: key }) {
  try {
    const draftId = yield select(extraPaymentsSelectors.getDraftId);
    const draft = yield call(apiService.extraPayments.deleteFile, draftId, key);
    yield put(extraPaymentsActions.deleteFileSuccess(draft));
  } catch (error) {
    yield put(extraPaymentsActions.deleteFileFailed());
    yield put(appActions.showRequestError());
  }
}

function* deleteDraft(draftId) {
  try {
    yield put(extraPaymentsActions.setDraftLoading(true));
    yield call(apiService.extraPayments.deleteDraft, draftId);
  } catch (error) {
    yield put(appActions.showRequestError());
  } finally {
    yield put(extraPaymentsActions.setDraftLoading(false));
  }
}

function* cancelDraft() {
  try {
    const draftId = yield select(extraPaymentsSelectors.getDraftId);
    if (draftId) {
      yield deleteDraft(draftId);
    }

    yield put(extraPaymentsActions.resetDraft());
  } catch (error) {
    yield put(appActions.showError(error.message));
  }
}

function* create() {
  try {
    const bookingId = yield select(bookingDetailsSelectors.getBookingId);
    const currency = yield select(appSelectors.getCurrency);
    const { items, documents, comments } = yield select(extraPaymentsSelectors.getDraft);

    const newExtraPayment = {
      bookingId,
      items,
      documents,
      comments,
    };

    yield extraPaymentModel.paymentsSchema.validate(items, {
      abortEarly: false,
      recursive: true,
    });

    const extraPayment = yield call(apiService.extraPayments.create, newExtraPayment, currency.id);

    yield put(extraPaymentsActions.createSuccess(extraPayment));
    yield put(extraPaymentsActions.resetDraft());
  } catch (error) {
    if (error instanceof ValidationError) {
      const validationErrors = {};
      error.inner.forEach((e) => {
        if (e.type === 'Is valid name') {
          const fieldPath = `name${e.params.originalValue.id}`;
          validationErrors[fieldPath] = e.message;
        } else if (e.type === 'Is valid amount') {
          const fieldPath = `amount${e.params.originalValue.id}`;
          validationErrors[fieldPath] = e.message;
        }
      });
      yield put(extraPaymentsActions.setErrors(validationErrors));
      yield put(appActions.showError(ErrorMessage.completeRequiredFields));
    } else {
      yield put(appActions.showError(error.message));
    }
    yield put(extraPaymentsActions.createFailed());
  }
}

function* accept({ payload: extraPaymentId }) {
  try {
    const currency = yield select(appSelectors.getCurrency);
    const selectedCard = yield select(authorisePaymentSelectors.getSelectedCard);
    yield put(extraPaymentsActions.setExtraPaymentLoading({ id: extraPaymentId, loading: true }));

    const extraPayment = yield call(
      apiService.extraPayments.accept,
      extraPaymentId,
      selectedCard,
      currency.id,
    );

    yield put(extraPaymentsActions.changeStatusSuccess(extraPayment));

    const hasWaitingExtraPayments = yield select(extraPaymentsSelectors.getHasWaitingExtraPayments);
    if (!hasWaitingExtraPayments) {
      yield put(bookingDetailsActions.setHasWaitingExtraPayments(false));
    }
  } catch (error) {
    yield put(extraPaymentsActions.setExtraPaymentLoading({ id: extraPaymentId, loading: false }));
    yield put(
      extraPaymentsActions.setFieldError({ fieldName: extraPaymentId, fieldValue: error.message }),
    );
  }
}

function* decline({ payload: extraPaymentId }) {
  try {
    const currency = yield select(appSelectors.getCurrency);
    yield put(extraPaymentsActions.setExtraPaymentLoading({ id: extraPaymentId, loading: true }));

    const extraPayment = yield call(apiService.extraPayments.decline, extraPaymentId, currency.id);
    yield put(extraPaymentsActions.changeStatusSuccess(extraPayment));

    const hasWaitingExtraPayments = yield select(extraPaymentsSelectors.getHasWaitingExtraPayments);
    if (!hasWaitingExtraPayments) {
      yield put(bookingDetailsActions.setHasWaitingExtraPayments(false));
    }
  } catch (error) {
    yield put(extraPaymentsActions.setExtraPaymentLoading({ id: extraPaymentId, loading: false }));
    yield put(
      extraPaymentsActions.setFieldError({ fieldName: extraPaymentId, fieldValue: error.message }),
    );
  }
}

function* cancel({ payload: extraPaymentId }) {
  try {
    const currency = yield select(appSelectors.getCurrency);
    yield put(extraPaymentsActions.setExtraPaymentLoading({ id: extraPaymentId, loading: true }));

    const extraPayment = yield call(apiService.extraPayments.cancel, extraPaymentId, currency.id);
    yield put(extraPaymentsActions.changeStatusSuccess(extraPayment));
  } catch (error) {
    yield put(extraPaymentsActions.setExtraPaymentLoading({ id: extraPaymentId, loading: false }));
    yield put(
      extraPaymentsActions.setFieldError({ fieldName: extraPaymentId, fieldValue: error.message }),
    );
  }
}

export { load, uploadFile, deleteFile, create, cancelDraft, accept, decline, cancel };
