import React, { createRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Box, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { TextInput } from '@evee/evee-ui.base';
import styles from './styles';

const keyCodes = {
  backspace: 8,
};

const EmailCode = ({ code, errorMessage, onChange, onPasteCode, classes, ...rest }) => {
  const [codeDigitsRefs, setCodeDigitsRefs] = useState([]);
  const errorInputClsx = clsx(errorMessage && classes.codeError);
  const focusedDigit = code.findIndex((e) => e === '');
  const keyDownHandler = (index, event) => {
    if (event.keyCode === keyCodes.backspace && index >= 1) {
      if (code[index] !== '') {
        onChange(index, '');
      } else {
        onChange(index - 1, '');
      }
    }
  };

  useEffect(() => {
    if (code.length && !codeDigitsRefs.length) {
      const refs = code.map((e, index) => ({ digit: index, ref: createRef() }));
      setCodeDigitsRefs(refs);
    }

    if (focusedDigit !== -1 && codeDigitsRefs.length) {
      codeDigitsRefs[focusedDigit].ref.current.focus();
    }
  }, [code]);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" {...rest}>
      <Typography paragraph variant="h6">
        Enter code
      </Typography>

      <Box display="flex" marginBottom={errorMessage ? '6px' : '0px'}>
        {codeDigitsRefs.length &&
          code.map((e, index) => (
            <React.Fragment key={index}>
              <TextInput
                type="number"
                value={e}
                inputClassName={classes.codeDigit}
                InputProps={{
                  inputRef: codeDigitsRefs[index].ref,
                  inputProps: {
                    autoFocus: index === focusedDigit,
                  },
                  classes: {
                    input: classes.input,
                    notchedOutline: errorInputClsx,
                  },
                  onPaste: (event) => {
                    event.preventDefault();
                    const data = (event.clipboardData || window.clipboardData).getData('text');
                    onPasteCode(data);
                  },
                }}
                onChange={(v) => {
                  const numValue = Number(v);
                  if (Number.isInteger(numValue) && numValue >= 0 && numValue <= 9) {
                    onChange(index, numValue);
                  }
                }}
                onKeyDown={(event) => keyDownHandler(index, event)}
              />
            </React.Fragment>
          ))}
      </Box>

      {errorMessage && <Typography color="error">{errorMessage}</Typography>}
    </Box>
  );
};

EmailCode.propTypes = {
  code: PropTypes.array,
  errorMessage: PropTypes.string,
  classes: PropTypes.shape({
    codeDigit: PropTypes.string.isRequired,
    codeError: PropTypes.string.isRequired,
    input: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func,
  onPasteCode: PropTypes.func,
};

EmailCode.defaultProps = {
  code: [],
  errorMessage: '',
  onChange: () => {},
  onPasteCode: () => {},
};

export default withStyles(styles)(EmailCode);
