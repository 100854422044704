import { createSelector } from 'reselect';

import { getExtrasTotal } from '@evee/evee-ui.utils';

import { getEmailVerified, getProfile } from '../profile/selectors';
import { getPhone, getStatus } from '../phone/selectors';

export const getIsSigningIn = (state) => state.basket.signingIn;
export const getBasketId = (state) => state.basket.booking.id;
export const getLoading = (state) => state.basket.loading;
export const getSteps = (state) => state.basket.form.steps;
export const getBooking = (state) => state.basket.booking;
export const getOwner = (state) => state.basket.booking.owner;
export const getVehicle = (state) => state.basket.booking.vehicle;
export const getDelivery = (state) => state.basket.booking.delivery;
export const getTotalsLoading = (state) => state.basket.totalsLoading;
export const getMessage = (state) => state.basket.booking.message || '';
export const getAppliedDiscountCode = (state) => state.basket.booking.appliedDiscountCode || '';
export const getAppliedCreditAmount = (state) => state.basket.booking.appliedCreditAmount || '';
export const getFormErrors = (state) => state.basket.form.errors || [];
export const getInstantBooking = (state) => state.basket.booking.instantBooking || false;
export const getAvailableExtras = (state) => state.basket.availableExtras || [];
export const getExtras = (state) => state.basket.booking.extras || [];

export const getBookingTotals = createSelector(getBooking, (booking) => ({
  bookingDaysPrices: booking.bookingDaysPrices || [],
  totalDiscount: booking.totalDiscount || 0,
  discountPercent: booking.discountPercent || 0,
  discountCodeAmount: booking.discountCodeAmount || 0,
  appliedCreditAmount: booking.appliedCreditAmount || 0,
  deliveryPrice: booking.deliveryPrice || 0,
  totalRental: booking.totalRental || 0,
  total: booking.total || 0,
  excessAmount: booking.excessAmount || 0,
  fee: booking.fee || 0,
  payout: booking.payout || 0,
  days: booking.days || 0,
  extend: booking.extend || {},
  extras: getExtrasTotal(booking),
}));

export const getYourInfo = createSelector(
  getProfile,
  getPhone,
  getStatus,
  getEmailVerified,
  (profile, phone, phoneStatus, emailVerified) => ({
    dateOfBirth: profile.dateOfBirth,
    address: profile.address,
    phone: phone.phoneNumber,
    phoneStatus,
    emailVerified,
  }),
);

export const getYourInfoStep = createSelector(getSteps, (steps) => ({
  active: steps.yourInfo.active,
  completed: steps.yourInfo.completed,
  disabled: !steps.yourInfo.completed && !steps.yourInfo.active,
}));

export const getLicenceStep = createSelector(getSteps, (steps) => ({
  active: steps.verification.active,
  completed: steps.verification.completed,
  disabled: !steps.verification.completed && !steps.verification.active,
}));

export const getExtrasStep = createSelector(getSteps, (steps) => ({
  active: steps.extras.active,
  completed: steps.extras.completed,
  disabled: !steps.extras.completed && !steps.extras.active,
}));

export const getPaymentStep = createSelector(getSteps, (steps) => ({
  active: steps.payment.active,
  completed: steps.payment.completed,
  disabled: !steps.yourInfo.completed || !steps.verification.completed || !steps.extras.completed,
}));
