import { createSelector } from 'reselect';
import { getProfile } from 'store/modules/profile/selectors';

import { getCustomerId } from '@evee/evee-ui.utils';

export const getIsUserAuthorized = (state) => state.auth.signedIn;
export const getIsSubscribed = (state) => !!state.auth.isSubscribed;
export const getIsSigningIn = (state) => state.auth.signingIn;
export const getSigningInPath = (state) => state.auth.signingInPath;

export const getUserId = createSelector(getProfile, (profile) => getCustomerId(profile));

export const getShowSubscribe = createSelector(
  getProfile,
  getIsSubscribed,
  (profile, isSubscribed) => (!profile && !isSubscribed) || (profile && !profile.receiveNewsletter),
);
