import { DeclarationStatus } from '@evee/evee-ui.enums';

export default {
  declaration: {
    status: DeclarationStatus.unverified,
    verifiedTill: null,
    questionnaire: [],
    failedQuestions: [],
    accepted: false,
  },
};
