import { all, call, fork, put, select, take } from 'redux-saga/effects';
import { ValidationError } from 'yup';

import { apiService, socket } from '@evee/evee-ui.services';
import { ErrorMessage } from '@evee/evee-ui.enums';
import { listing as listingModel } from '@evee/evee-ui.models';

import * as appActions from 'store/modules/app/actions';
import * as appSelectors from 'store/modules/app/selectors';
import * as authSelectors from 'store/modules/auth/selectors';
import * as paymentMethodsActions from 'store/modules/paymentMethods/actions';
import * as paymentMethodsSelectors from 'store/modules/paymentMethods/selectors';
import * as profileSelectors from 'store/modules/profile/selectors';

function* validateBillingAddress(address) {
  let isValid = true;
  try {
    yield listingModel.shortAddressSchema.validate(address);
    yield put(paymentMethodsActions.removeFieldError('billingAddress'));
  } catch (err) {
    if (err instanceof ValidationError) {
      yield put(paymentMethodsActions.setFieldError('billingAddress', err.message));
    }
    isValid = false;
  }

  return isValid;
}

export function* watchCardVerification() {
  try {
    const currentUserId = yield select(authSelectors.getUserId);

    const [updatedChannel, successChannel, failedChannel] = yield all([
      call(socket.createSocketChannel, socket.cardVerificationEvents.CARD_VERIFICATION_UPDATED),
      call(socket.createSocketChannel, socket.cardVerificationEvents.CARD_VERIFICATION_SUCCESS),
      call(socket.createSocketChannel, socket.cardVerificationEvents.CARD_VERIFICATION_FAILED),
    ]);

    yield all([
      fork(function* cardVerificationUpdated() {
        while (true) {
          const result = yield take(updatedChannel);
          const { card, customer } = result;
          if (customer && customer._id === currentUserId) {
            yield put(paymentMethodsActions.verifyFailed(card));
          }
        }
      }),
      fork(function* cardVerificationSuccess() {
        while (true) {
          const result = yield take(successChannel);
          const { card, customer } = result;
          if (customer && customer._id === currentUserId) {
            yield put(paymentMethodsActions.verifySuccess(card));
          }
        }
      }),
      fork(function* cardVerificationFailed() {
        while (true) {
          const result = yield take(failedChannel);
          const { card, customer } = result;
          if (customer && customer._id === currentUserId) {
            yield put(paymentMethodsActions.verifyTriesExceeded(card));
          }
        }
      }),
    ]);
  } catch (error) {
    yield put(appActions.showError(error.message));
  }
}

function* load() {
  try {
    const paymentMethods = yield call(apiService.paymentMethod.getAll);
    yield put(paymentMethodsActions.loadSuccess(paymentMethods));
    yield call(watchCardVerification);
  } catch (error) {
    yield put(appActions.showError(error.message));
    yield put(paymentMethodsActions.loadFailed());
  }
}

function* create({ payload }) {
  try {
    const currency = yield select(appSelectors.getCurrency);

    const [cardholderName, billingAddress, sameAsResidential, residentialAddress] = yield all([
      select(paymentMethodsSelectors.getCardholderName),
      select(paymentMethodsSelectors.getBillingAddress),
      select(paymentMethodsSelectors.getSameAsResidential),
      select(profileSelectors.getAddress),
    ]);

    const address = sameAsResidential ? residentialAddress : billingAddress;
    const isValidAddress = yield validateBillingAddress(address);

    if (!window.customerStripe) {
      throw new Error(ErrorMessage.commonError);
    }

    const result = yield call(window.customerStripe.createToken, payload, {
      name: cardholderName,
      address_line1: address.description,
      address_city: address.suburb, // TODO: currently contains short google address locality value.
      address_state: address.state,
      address_zip: address.postcode,
      address_country: address.countryCode,
    });

    if (result.error || !isValidAddress) {
      throw new Error(result?.error?.message || 'Please provide a valid billing address');
    }

    const paymentMethod = yield call(
      apiService.paymentMethod.create,
      result.token.id,
      address,
      currency.id,
    );

    yield put(paymentMethodsActions.createSuccess(paymentMethod));
    yield put(paymentMethodsActions.resetForm());
    yield put(paymentMethodsActions.verify(paymentMethod));
  } catch (error) {
    if (error instanceof ValidationError) {
      yield put(paymentMethodsActions.setFieldError('billingAddress', error.message));
    }
    yield put(appActions.showError(error.message));
    yield put(paymentMethodsActions.createFailed());
  }
}

function* setDefault({ payload }) {
  try {
    const defaultMethod = yield select(paymentMethodsSelectors.getDefaultPaymentMethod);
    if (defaultMethod?.id === payload) {
      return;
    }

    yield call(apiService.paymentMethod.setDefault, payload);
    yield put(paymentMethodsActions.setDefaultSuccess());
    yield put(paymentMethodsActions.load());
  } catch (error) {
    yield put(appActions.showError(error.message));
    yield put(paymentMethodsActions.setDefaultFailed());
  }
}

function* remove({ payload: id }) {
  try {
    yield call(apiService.paymentMethod.remove, id);
    yield put(paymentMethodsActions.removeSuccess());
    yield put(paymentMethodsActions.load());
  } catch (error) {
    yield put(appActions.showError(error.message));
    yield put(paymentMethodsActions.removeFailed());
  }
}

function* verify({ payload: paymentMethod }) {
  try {
    appActions.setLoading(true);

    const { id, verificationIntentSecret } = paymentMethod;
    const { error } = yield call(window.customerStripe.confirmPayment, {
      clientSecret: verificationIntentSecret,
      redirect: 'if_required',
      confirmParams: {
        payment_method: id,
      },
    });

    if (error) {
      yield put(paymentMethodsActions.verifyFailed(paymentMethod));
    }
  } catch (error) {
    yield put(appActions.showError(error.message));
    yield put(paymentMethodsActions.verifyFailed(paymentMethod));
  } finally {
    appActions.setLoading(false);
  }
}

export { validateBillingAddress, load, create, setDefault, remove, verify };
