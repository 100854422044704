import * as actionTypes from './actionTypes';

export const setFieldValue = (fieldName, fieldValue) => ({
  type: actionTypes.SET_FIELD_VALUE,
  payload: { fieldName, fieldValue },
});

export const setProfile = (profile) => ({
  type: actionTypes.SET_PROFILE,
  payload: profile,
});

export const uploadPhoto = (photo) => ({
  type: actionTypes.UPLOAD_PHOTO,
  payload: photo,
});

export const uploadPhotoSuccess = (photo) => ({
  type: actionTypes.UPLOAD_PHOTO_SUCCESS,
  payload: photo,
});

export const uploadPhotoFailed = () => ({
  type: actionTypes.UPLOAD_PHOTO_FAILED,
});

export const load = () => ({
  type: actionTypes.LOAD,
});

export const loadSuccess = () => ({
  type: actionTypes.LOAD_SUCCESS,
});

export const loadFailed = () => ({
  type: actionTypes.LOAD_FAILED,
});

export const copy = (message) => ({
  type: actionTypes.COPY,
  payload: message,
});

export const googleConnect = () => ({
  type: actionTypes.GOOGLE_CONNECT,
});

export const facebookConnect = () => ({
  type: actionTypes.FACEBOOK_CONNECT,
});

export const save = () => ({
  type: actionTypes.SAVE,
});

export const saveSuccess = () => ({
  type: actionTypes.SAVE_SUCCESS,
});

export const setErrors = (errors) => ({
  type: actionTypes.SET_ERRORS,
  payload: errors,
});

export const setFieldError = (path, message) => ({
  type: actionTypes.SET_FIELD_ERROR,
  payload: {
    path,
    message,
  },
});

export const removeFieldError = (path) => ({
  type: actionTypes.REMOVE_FIELD_ERROR,
  payload: path,
});

export const setAbout = (text) => ({
  type: actionTypes.SET_ABOUT,
  payload: text,
});

export const setNotified = (value) => ({
  type: actionTypes.SET_NOTIFIED,
  payload: value,
});

export const loadPushSubscription = () => ({
  type: actionTypes.LOAD_PUSH_SUBSCRIPTION,
});

export const setReceivePushNotifications = (value) => ({
  type: actionTypes.SET_RECEIVE_PUSH_NOTIFICATIONS,
  payload: value,
});

export const setPushSubscription = (value) => ({
  type: actionTypes.SET_PUSH_SUBSCRIPTION,
  payload: value,
});

export const setPushSubscriptionSuccess = () => ({
  type: actionTypes.SET_PUSH_SUBSCRIPTION_SUCCESS,
});

export const setPushSubscriptionFailed = () => ({
  type: actionTypes.SET_PUSH_SUBSCRIPTION_FAILED,
});

export const deactivateAccount = () => ({
  type: actionTypes.DEACTIVATE_ACCOUNT,
});

export const setDeactivateDialogOpen = (open) => ({
  type: actionTypes.SET_DEACTIVATE_DIALOG_OPEN,
  payload: open,
});

export const setDeactivateDialogLoading = (loading) => ({
  type: actionTypes.SET_DEACTIVATE_DIALOG_LOADING,
  payload: loading,
});

export const setDeactivateDialogReason = (reason) => ({
  type: actionTypes.SET_DEACTIVATE_DIALOG_REASON,
  payload: reason,
});

export const changeEmail = (email) => ({
  type: actionTypes.CHANGE_EMAIL,
  payload: email,
});

export const changeEmailSuccess = () => ({
  type: actionTypes.CHANGE_EMAIL_SUCCESS,
});

export const changeEmailFailed = (error) => ({
  type: actionTypes.CHANGE_EMAIL_FAILED,
  payload: error,
});
