import _ from 'lodash';
import { createSelector } from 'reselect';

export const getVehicles = (state) => state.searchPage.vehicles;
export const getAirports = (state) => state.searchPage.availableFilters.airports;
export const getLocation = (state) => state.searchPage.location;
export const getStartLocation = (state) => state.searchPage.startLocation;
export const getActiveFilters = (state) => state.searchPage.activeFilters;
export const getAvailableFilters = (state) => state.searchPage.availableFilters;
export const getDefaultFilters = (state) => state.searchPage.defaultFilters;
export const getEditFilters = (state) => state.searchPage.editFilters;
export const getMakesTree = (state) => state.searchPage.makesTree;
export const getAirport = (state) => state.searchPage.activeFilters.airport;
export const getSelectedAirport = (state) => state.searchPage.selectedAirport;

export const getActiveFiltersCount = createSelector(getActiveFilters, (filters) => {
  let count = 0;

  count += _.isEmpty(filters.makes) ? 0 : filters.makes.length;
  count += _.isEmpty(filters.models) ? 0 : filters.models.length;
  count += _.isEmpty(filters.badges) ? 0 : filters.badges.length;
  count += _.isEmpty(filters.features) ? 0 : filters.features.length;
  count += _.isEmpty(filters.price) ? 0 : 1;
  count += _.isEmpty(filters.year) ? 0 : 1;
  count += _.isEmpty(filters.airport.id) ? 0 : 1;
  count += filters.instantBooking ? 1 : 0;
  count += filters.unlimitedMileage ? 1 : 0;
  count += filters.freeFastCharging ? 1 : 0;

  return count;
});

export const getSelectedVehicle = (state) => state.searchPage.selectedVehicle;
export const getHoveredVehicle = (state) => state.searchPage.hoveredVehicle;

export const getLoading = (state) => state.searchPage.loading;
export const getNoResults = (state) => state.searchPage.noResults;
export const getFiltersDialogOpen = (state) => state.searchPage.filtersDialogOpen;
export const getSorting = (state) => state.searchPage.sorting;
