import PropTypes from 'prop-types';

import { Box, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { Button, Link } from '@evee/evee-ui.base';
import { formatPriceNumber, getHomeUrl } from '@evee/evee-ui.utils';
import { Region } from '@evee/evee-ui.enums';

import useListYourCar from 'store/modules/listYourCar/hooks/useListYourCar';
import useRegion from 'store/modules/app/hooks/useRegion';

import backgroundImg from './Images/list_your_car_mobile.jpg';
import styles from './styles';

const HostMobileListYourCar = ({ classes }) => {
  const [onListYourCar] = useListYourCar();

  const { region, currency } = useRegion();

  const titleJsx = (
    <>
      List your car,
      <br />
      earn extra cash
    </>
  );

  const subtitleJsx = (
    <>
      Earn up to {formatPriceNumber(2000)} {currency.id}/month by sharing your car with others
    </>
  );

  const nzTitleJsx = (
    <>
      Save up to $500
      <br />
      in commission fees
    </>
  );

  const nzSubtitleJsx = (
    <Box color="white">
      New Hosts in New Zealand will have their first $500 in evee commission fees waived, for a
      limited time. <br />
      <Link color="inherit" href={`${getHomeUrl(region)}/terms`}>
        Find out more{' '}
      </Link>
    </Box>
  );

  return (
    <Box className={classes.container}>
      <Box className={classes.titleContainer}>
        <Typography paragraph className={classes.title}>
          {region.id === Region.newZealand.id ? nzTitleJsx : titleJsx}
        </Typography>

        <Typography paragraph className={classes.subtitle}>
          {region.id === Region.newZealand.id ? nzSubtitleJsx : subtitleJsx}
        </Typography>

        <Button
          text="Start earning"
          size="small"
          style={{ width: '120px' }}
          onClick={onListYourCar}
        />
      </Box>
      <img src={backgroundImg} alt="" className={classes.image} />
    </Box>
  );
};

HostMobileListYourCar.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
    titleContainer: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
  }).isRequired,
};

HostMobileListYourCar.defaultProps = {};

export default withStyles(styles)(HostMobileListYourCar);
