const styles = (theme) => ({
  desktopContainer: {
    [theme.breakpoints.up('lg')]: {
      width: '450px',
    },
  },
  mobileContainer: {
    width: '100%',
  },
  titleRoot: {},
});

export default styles;
