import { all, call, put, select } from 'redux-saga/effects';
import moment from 'moment';

import { apiService } from '@evee/evee-ui.services';

import * as appActions from 'store/modules/app/actions';
import * as appSelectors from 'store/modules/app/selectors';
import * as authSelectors from 'store/modules/auth/selectors';
import * as bookingActions from 'store/modules/booking/actions';
import * as bookingChangeActions from 'store/modules/bookingChange';
import * as bookingChangeSelectors from 'store/modules/bookingChange/selectors';
import * as bookingDetailsActions from 'store/modules/bookingDetails';
import * as bookingDetailsSelectors from 'store/modules/bookingDetails/selectors';
import * as bookingSelectors from 'store/modules/booking/selectors';
import * as vehicleActions from 'store/modules/vehicle/actions';

function* load() {
  try {
    const isAuthorized = yield select(authSelectors.getIsUserAuthorized);
    if (!isAuthorized) return;

    const currency = yield select(appSelectors.getCurrency);
    const booking = yield select(bookingDetailsSelectors.getBooking);

    const changeRequest = yield call(apiService.changeRequest.get, booking.id, currency.id);

    yield put(bookingChangeActions.loadSuccess(changeRequest));
  } catch (error) {
    yield put(bookingChangeActions.loadFailed());
  }
}

function* loadDialog() {
  try {
    const currency = yield select(appSelectors.getCurrency);
    const booking = yield select(bookingDetailsSelectors.getBooking);

    // Load vehicle for change booking requests
    if (booking.vehicle.id) {
      const vehicle = yield call(apiService.vehicle.getVehicle, booking.vehicle.id, currency.id);
      yield put(vehicleActions.setVehicle(vehicle));

      // Set location and booking dates.
      yield put(bookingActions.setLocation(booking.delivery));
      yield put(bookingActions.setTripDates(booking.from, booking.to));
      yield put(
        bookingActions.loadOccupiedDates(booking.vehicle.id, null, null, {
          excludeBookingId: booking.id,
        }),
      );

      yield put(bookingChangeActions.loadTotals());
    }

    yield put(bookingChangeActions.loadDialogSuccess());
  } catch (error) {
    yield put(bookingChangeActions.loadDialogFailed());
    yield put(appActions.showRequestError());
  }
}

function* loadTotals() {
  try {
    const tripDates = yield select(bookingSelectors.getTripDates);
    const booking = yield select(bookingDetailsSelectors.getBooking);
    const currency = yield select(appSelectors.getCurrency);

    if (booking.id && moment(tripDates.start).isValid() && moment(tripDates.end).isValid()) {
      const totals = yield call(apiService.changeRequest.getTotals, {
        bookingId: booking.id,
        from: tripDates.start,
        to: tripDates.end,
        currency: currency.id,
      });

      yield put(bookingChangeActions.setTotals(totals));
      yield put(bookingActions.setTripDatesAvailability(true));
    }
    yield put(bookingChangeActions.loadTotalsSuccess());
  } catch (error) {
    yield put(bookingActions.setTripDatesAvailability(false, error.message));
    yield put(
      appActions.showError('Sorry, selected dates are not available. Please try a different ones.'),
    );
    yield put(bookingChangeActions.loadTotalsFailed);
  }
}

function* create() {
  try {
    const [currency, tripDates, booking, totals, message] = yield all([
      select(appSelectors.getCurrency),
      select(bookingSelectors.getTripDates),
      select(bookingDetailsSelectors.getBooking),
      select(bookingChangeSelectors.getTotals),
      select(bookingChangeSelectors.getMessage),
    ]);

    const request = yield call(
      apiService.changeRequest.create,
      booking.id,
      {
        from: tripDates.start,
        to: tripDates.end,
        total: totals.total,
        payout: totals.payout,
        bookingDaysPrices: totals.bookingDaysPrices,
        days: totals.days,
        fee: totals.fee,
      },
      currency.id,
      message,
    );

    const updatedBooking = yield call(apiService.booking.get, booking.id, currency.id);
    yield put(bookingDetailsActions.loadSuccess({ booking: updatedBooking }));

    yield put(bookingChangeActions.createSuccess(request));
  } catch (error) {
    yield put(bookingChangeActions.createFailed());
    yield put(appActions.showError(error.message));
  }
}

function* cancel() {
  try {
    const currency = yield select(appSelectors.getCurrency);
    const booking = yield select(bookingDetailsSelectors.getBooking);
    const request = yield select(bookingChangeSelectors.getRequest);

    yield call(apiService.changeRequest.cancel, booking.id, request._id, currency.id);
    yield put(bookingChangeActions.cancelSuccess());
  } catch (error) {
    yield put(bookingChangeActions.cancelFailed());
    yield put(appActions.showRequestError());
  }
}

function* accept() {
  try {
    const currency = yield select(appSelectors.getCurrency);
    const booking = yield select(bookingDetailsSelectors.getBooking);
    const request = yield select(bookingChangeSelectors.getRequest);

    yield call(apiService.changeRequest.accept, booking.id, request._id, currency.id);

    const updatedBooking = yield call(apiService.booking.get, booking.id, currency.id);
    yield put(bookingDetailsActions.loadSuccess({ booking: updatedBooking }));

    yield put(bookingChangeActions.acceptSuccess());
  } catch (error) {
    yield put(bookingChangeActions.acceptFailed());
    yield put(appActions.showRequestError());
  }
}

function* decline() {
  try {
    const currency = yield select(appSelectors.getCurrency);
    const booking = yield select(bookingDetailsSelectors.getBooking);
    const request = yield select(bookingChangeSelectors.getRequest);

    yield call(apiService.changeRequest.decline, booking.id, request._id, currency.id);
    yield put(bookingChangeActions.declineSuccess());
  } catch (error) {
    yield put(bookingChangeActions.declineFailed());
    yield put(appActions.showRequestError());
  }
}

export { load, loadDialog, loadTotals, create, cancel, accept, decline };
