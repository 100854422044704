import PropTypes from 'prop-types';


import { Box, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { Region } from '@evee/evee-ui.enums';
import useRegion from 'store/modules/app/hooks/useRegion';

import TextSlider from 'widgets/home/TextSlider';

import styles from './styles';

const Slogan = ({ classes }) => {
  const { region } = useRegion();

  const headerText = region.id === Region.australia.id
  ? 'Australia’s EV Rental & Car Sharing'
  : 'New Zealand\'s EV Rental & Car Sharing';

  return (
    <Box className={classes.container}>
      <Typography variant="h1" align="center">
        {headerText}
      </Typography>

      <TextSlider minHeight={72} justifyContent="center" className={classes.titleSecondary} />
    </Box>
  );
};

Slogan.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    titleSecondary: PropTypes.string.isRequired,
  }).isRequired,
};

Slogan.defaultProps = {};

export default withStyles(styles)(Slogan);
