const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginRight: '30px',
    cursor: 'pointer',
  },
  icon: {
    width: '100px',
    height: '64px',
    transform: 'scale(1.5)',
  },
};

export default styles;
