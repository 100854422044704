import React, { useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
} from '@material-ui/core';

import { useTheme, withStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ChevronLeft';
import CloseIcon from '@material-ui/icons/Close';

import { IconButton, Loader } from '@evee/evee-ui.base';

import useDebouncedResizeObserver from 'common/hooks/useDebouncedResizeObserver';
import useHideWebWidget from 'common/hooks/useHideWebWidget';

import styles from './styles';

const DefaultDialog = React.forwardRef(
  (
    {
      open,
      title,
      fullScreen,
      subTitleContent,
      renderActions,
      contentDividers,
      titleHeight,
      titleAlign,
      decorateBackground,
      loading,
      children,
      classes,
      onClose,
      ...rest
    },
    ref,
  ) => {
    const theme = useTheme();
    const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'));
    const { ref: paddingRef, height } = useDebouncedResizeObserver(10);
    const contentRef = useRef();
    const contentClsx = clsx(classes.contentRoot, fullScreen && classes.fullscreenContentRoot);
    const actionsClsx = clsx(
      classes.actionsRoot,
      fullScreen && height > 0 && classes.actionsRootFullscreen,
    );

    useHideWebWidget(open);

    useImperativeHandle(ref, () => ({
      scrollTo: (x, y) => {
        if (contentRef.current) {
          contentRef.current.scrollTo(x, y);
        }
      },
    }));

    return (
      <Dialog
        ref={ref}
        open={open}
        fullScreen={fullScreen}
        classes={{
          paper: !fullScreen ? classes.paper : '',
          paperFullScreen: decorateBackground ? classes.paperFullScreen : '',
        }}
        onClose={onClose}
        {...rest}
      >
        <DialogTitle
          disableTypography
          classes={{ root: classes.titleRoot }}
          style={{
            height: titleHeight > 0 ? titleHeight : 'initial',
            backgroundColor: fullScreen ? 'white' : 'inherit',
          }}
        >
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <Box display="flex" width="100%" marginBottom={title ? '16px' : 'auto'}>
              {isTabletOrMobile ? (
                <IconButton
                  type="text"
                  fontSize="small"
                  className={classes.backButton}
                  icon={<ArrowBackIcon />}
                  data-test-id="dialog-close-button"
                  onClick={onClose}
                />
              ) : (
                <IconButton
                  type="text"
                  fontSize="small"
                  className={classes.closeButton}
                  icon={<CloseIcon />}
                  data-test-id="dialog-close-button"
                  onClick={onClose}
                />
              )}
            </Box>

            <Typography align={titleAlign} variant="h4" style={{ width: '100%' }}>
              {title}
            </Typography>
          </Box>
        </DialogTitle>

        {children && (
          <DialogContent
            ref={contentRef}
            dividers={contentDividers}
            classes={{ root: contentClsx }}
            style={{ backgroundColor: fullScreen ? 'white' : 'inherit' }}
          >
            <Loader loading={loading}>{children}</Loader>
          </DialogContent>
        )}

        {subTitleContent}

        {renderActions && (
          <DialogActions className={actionsClsx}>{renderActions(loading)}</DialogActions>
        )}

        <Box ref={paddingRef} flex="1 1 auto" />
      </Dialog>
    );
  },
);

DefaultDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  fullScreen: PropTypes.bool,
  subTitleContent: PropTypes.node,
  renderActions: PropTypes.func,
  contentDividers: PropTypes.bool,
  titleHeight: PropTypes.number,
  titleAlign: PropTypes.string,
  decorateBackground: PropTypes.bool,
  loading: PropTypes.bool,
  children: PropTypes.node.isRequired,
  classes: PropTypes.shape({
    paper: PropTypes.string.isRequired,
    titleRoot: PropTypes.string.isRequired,
    contentRoot: PropTypes.string.isRequired,
    fullscreenContentRoot: PropTypes.string.isRequired,
    actionsRoot: PropTypes.string.isRequired,
    actionsRootFullscreen: PropTypes.string.isRequired,
    paperFullScreen: PropTypes.string.isRequired,
    closeButton: PropTypes.string.isRequired,
    backButton: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func,
};

DefaultDialog.defaultProps = {
  title: '',
  fullScreen: false,
  subTitleContent: null,
  renderActions: null,
  contentDividers: false,
  titleHeight: 0,
  titleAlign: 'left',
  decorateBackground: false,
  loading: false,
  onClose: () => {},
};

export default withStyles(styles)(DefaultDialog);
