export const getLoading = (state) => state.messagesPage.loading;

export const getChats = (state) => ({
  currentRentingPage: state.messagesPage.currentRentingPage,
  currentHostingPage: state.messagesPage.currentHostingPage,
  renting: state.messagesPage.rentingChats,
  hosting: state.messagesPage.hostingChats,
});

export const getMessages = (state) => state.messagesPage.messages;
export const getSelectedChat = (state) => state.messagesPage.selectedChat;
export const getChatLoading = (state) => state.messagesPage.chatLoading;
export const getMessage = (state) => state.messagesPage.message;
export const getFiles = (state) => state.messagesPage.files;
export const getActiveChatsTab = (state) => state.messagesPage.activeChatsTab;
export const getPageLoading = (state) => state.messagesPage.pageLoading;
export const getDetailsOpen = (state) => state.messagesPage.detailsOpen;
export const getDetailsClosed = (state) => state.messagesPage.detailsClosed;
