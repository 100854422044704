import customPrices from './customPrices/initialState';
import occupations from './occupations/initialState';

export default {
  isPublished: false,
  changed: false,
  lastDraftSave: null,
  vehicle: {
    freeFastCharging: false,
  },
  dictionaries: {
    vehicleModels: [],
    vehicleModelsPricing: [],
    featuresList: [],
    chargingPlugs: [],
  },
  occupations,
  customPrices,
  form: {
    errors: {},
  },
  conditions: {
    ctp: false,
    cof: false,
    nzta: false,
    ownership: false,
  },
  appointmentsDialogOpen: false,
  documentLoading: false,
};
