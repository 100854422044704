import PropTypes from 'prop-types';

import { Hidden } from '@material-ui/core';

const OnlyMobile = ({ children }) => <Hidden mdUp>{children}</Hidden>;

OnlyMobile.propTypes = {
  children: PropTypes.node.isRequired,
};

OnlyMobile.defaultProps = {};

export default OnlyMobile;
