import { call, put, select } from 'redux-saga/effects';

import { BookingStatusFilter, CustomerRoles, SortDirection } from '@evee/evee-ui.enums';
import { apiService } from '@evee/evee-ui.services';

import * as appActions from 'store/modules/app/actions';
import * as myCarsActions from 'store/modules/myCars';
import * as myCarsSelectors from 'store/modules/myCars/selectors';

const PAGE_SIZE = 4;
const ascSorting = [{ fieldName: 'from', direction: SortDirection.ascending }];
const descSorting = [{ fieldName: 'from', direction: SortDirection.descending }];

const getSorting = (statusFilter) => {
  if (
    statusFilter === BookingStatusFilter.requested ||
    statusFilter === BookingStatusFilter.upcoming
  ) {
    return ascSorting;
  }

  return descSorting;
};

export function* load() {
  try {
    yield put(appActions.setLoading(true));

    const counts = yield call(apiService.booking.getStats, CustomerRoles.owner);
    if (counts[BookingStatusFilter.requested] > 0) {
      yield put(myCarsActions.setStatusFilter(BookingStatusFilter.requested));
    }

    const statusFilter = yield select(myCarsSelectors.getStatusFilter);
    const bookings = yield call(
      apiService.booking.getMyTrips,
      CustomerRoles.owner,
      statusFilter,
      0,
      PAGE_SIZE,
      [],
      getSorting(statusFilter),
    );

    const listedCars = yield call(apiService.listing.getAll);

    yield put(
      myCarsActions.loadSuccess({
        bookings,
        vehicles: listedCars,
        counts,
      }),
    );

    const currentPage = yield select(myCarsSelectors.getCurrentPage);
    if (currentPage > 1) {
      yield put(myCarsActions.loadPage(currentPage));
    }
  } catch (error) {
    yield put(myCarsActions.loadFailed());
    yield put(appActions.showRequestError());
  } finally {
    yield put(appActions.setLoading(false));
  }
}

export function* loadBookings() {
  yield put(myCarsActions.loadPage(1));
}

export function* loadPage({ payload: pageNumber }) {
  try {
    const statusFilter = yield select(myCarsSelectors.getStatusFilter);
    const bookings = yield call(
      apiService.booking.getMyTrips,
      CustomerRoles.owner,
      statusFilter,
      pageNumber - 1,
      PAGE_SIZE,
      [],
      getSorting(statusFilter),
    );
    yield put(myCarsActions.loadPageSuccess({ pageNumber, bookings }));
  } catch (error) {
    yield put(myCarsActions.loadPageFailed());
  }
}
