import PropTypes from 'prop-types';
import { useState } from 'react';

import { withStyles } from '@material-ui/core/styles';

import { Button, Loader, TextInput } from '@evee/evee-ui.base';

import Dialog from '../../CommonDialog';
import styles from './styles';

const ResetPassword = ({
  forgotPassword,
  isMobile,
  loading,
  classes,
  onClose,
  onEmailChanged,
  onForgotPassword,
}) => {
  const { open, email, errorMessage } = forgotPassword;
  const [formatError, setFormatError] = useState(null);

  return (
    <Dialog open={open} title="Reset password" isMobile={isMobile} onClose={onClose}>
      <Loader loading={loading}>
        <TextInput
          value={email}
          label="E-mail"
          format="email"
          helperText={errorMessage || formatError}
          error={!!errorMessage || formatError}
          className={classes.input}
          onFormatError={setFormatError}
          onChange={onEmailChanged}
        />

        <Button
          text="Reset password"
          disabled={!email || formatError}
          className={classes.button}
          onClick={onForgotPassword}
        />
      </Loader>
    </Dialog>
  );
};

ResetPassword.propTypes = {
  forgotPassword: PropTypes.object,
  isMobile: PropTypes.bool,
  loading: PropTypes.bool,
  classes: PropTypes.shape({
    input: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func,
  onEmailChanged: PropTypes.func,
  onForgotPassword: PropTypes.func,
};

ResetPassword.defaultProps = {
  forgotPassword: {},
  isMobile: false,
  loading: false,
  onClose: () => {},
  onEmailChanged: () => {},
  onForgotPassword: () => {},
};

export default withStyles(styles)(ResetPassword);
