import { takeEvery } from 'redux-saga/effects';

import * as emailVerificationActions from 'store/modules/auth/verifyEmail';

import { pasteCode } from './utils';

function* listenPasteCode() {
  yield takeEvery(emailVerificationActions.pasteCode.toString(), pasteCode);
}

export default listenPasteCode;
