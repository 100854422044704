import { takeEvery } from 'redux-saga/effects';

import * as actionTypes from 'store/modules/booking/actionTypes';

import { loadOccupiedDates } from './utils';

function* listenOccupiedDates() {
  yield takeEvery(actionTypes.LOAD_OCCUPIED_DATES, loadOccupiedDates);
}

export default listenOccupiedDates;
