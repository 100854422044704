import { takeEvery } from 'redux-saga/effects';

import * as actionTypes from 'store/modules/app/actionTypes';

function onSetCurrency({ payload }) {
  if (payload?.id) {
    localStorage.setItem('evs.currency', payload.id);
  }
}

export default function* listenSetCurrency() {
  yield takeEvery(actionTypes.SET_CURRENCY, onSetCurrency);
}
