const styles = (theme) => ({
  root: {
    display: 'flex',
    padding: '2px 4px',
    borderRadius: theme.shape.borderRadius,
    marginBottom: '8px',
    alignItems: 'center',
    border: `1px solid ${theme.palette.border.main}`,
  },
  title: {
    ...theme.typography.h2,
    fontWeight: 500,
  },
  input: {
    padding: theme.spacing(1, 2),
    flex: 1,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  paper: {
    marginTop: theme.spacing(2),
    marginLeft: '-20px',
    padding: theme.spacing(0, 1),
    width: 'calc(100% + 44px)',
  },
});

export default styles;
