/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { acceptSuccess, cancelSuccess, declineSuccess } from 'store/modules/bookingChange';
import { addExtraSuccess, removeExtraSuccess } from 'store/modules/extras';
import { removeDriverSuccess, sendInviteSuccess } from 'store/modules/additionalDrivers';
import { authoriseSecurityDepositSuccess } from 'store/modules/authorisePayment';
import { createSuccess } from 'store/modules/extraPayments';
import { sendReviewSuccess } from 'store/modules/bookingReviews';
import { setStatus } from 'store/modules/bookingCard';

import initialState from './initialState';

export const bookingDetailsSlice = createSlice({
  name: 'bookingDetails',
  initialState,
  reducers: {
    load(state) {
      state.loading = true;
    },

    loadSuccess(state, { payload }) {
      state.booking = payload.booking;
      state.ownerProfile = payload.ownerProfile || state.ownerProfile;
      state.renterProfile = payload.renterProfile || state.renterProfile;
      state.loading = false;
    },

    loadFailed(state) {
      state.loading = false;
    },

    setLoading(state, { payload }) {
      state.loading = payload;
    },

    setHasWaitingExtraPayments(state, { payload }) {
      state.booking.hasWaitingExtraPayments = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createSuccess.type, (state, { payload }) => {
        if (state.booking.id === payload.bookingId) {
          state.booking.hasWaitingExtraPayments = true;
        }
      })
      .addCase(sendReviewSuccess.type, (state, { payload }) => {
        if (state.booking.id === payload) {
          state.booking.reviewSubmitted = true;
        }
      })
      .addCase(sendInviteSuccess.type, (state, { payload }) => {
        if (state.booking.id === payload.bookingId) {
          state.booking.additionalDriver = payload.additionalDriver;
        }
      })
      .addCase(removeDriverSuccess.type, (state, { payload }) => {
        if (state.booking.id === payload) {
          state.booking.additionalDriver = null;
        }
      })
      .addCase(setStatus.type, (state, { payload }) => {
        if (state.booking.id === payload.bookingId) {
          state.booking.status = payload.status;
        }
      })
      .addCase(authoriseSecurityDepositSuccess.type, (state) => {
        state.booking.hasWaitingSecurityDeposit = false;
      })
      .addCase(addExtraSuccess.type, (state, { payload }) => {
        state.booking.extras = payload;
      })
      .addCase(removeExtraSuccess.type, (state, { payload }) => {
        state.booking.extras = payload;
      })
      .addMatcher(
        (action) =>
          action.type === acceptSuccess.type ||
          action.type === declineSuccess.type ||
          action.type === cancelSuccess.type,
        (state) => {
          state.booking.hasWaitingChangeRequest = false;
        },
      );
  },
});

const { actions, reducer } = bookingDetailsSlice;

export const { load, loadSuccess, loadFailed, setLoading, setHasWaitingExtraPayments } = actions;

export default reducer;
