/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import initialState from './initialState';

export const messagesPageSlice = createSlice({
  name: 'messagesPage',
  initialState,
  reducers: {
    load(state) {
      state.loading = true;
    },

    loadFailed(state) {
      state.loading = false;
    },

    loadSuccess(state, { payload }) {
      const { rentingChats, hostingChats } = payload;
      state.rentingChats = rentingChats;
      state.hostingChats = hostingChats;
      state.loading = false;
    },

    loadChat(state, { payload }) {
      state.chatLoading = true;
      state.selectedChat = payload.chat;
    },

    loadChatSuccess(state, { payload }) {
      state.messages = payload.messages;
      state.chatLoading = false;

      const rentingChat = state.rentingChats.items.find((i) => i.id === state.selectedChat.id);
      const hostingChat = state.hostingChats.items.find((i) => i.id === state.selectedChat.id);

      const chat = rentingChat || hostingChat;
      if (chat) {
        chat.lastMessage.notificationId = undefined;
      }
    },

    loadChatFailed(state) {
      state.chatLoading = false;
      state.selectedChat = {};
    },

    loadMore(state) {
      state.pageLoading = true;
    },

    loadMoreSuccess(state, { payload }) {
      state.pageLoading = false;
      if (payload.type === 'owner') {
        state.currentHostingPage = payload.pageNumber;
        state.hostingChats.items = state.hostingChats.items.concat(payload.chats);
      } else {
        state.currentRentingPage = payload.pageNumber;
        state.rentingChats.items = state.rentingChats.items.concat(payload.chats);
      }
    },

    loadMoreFailed(state) {
      state.pageLoading = false;
    },

    setMessage(state, { payload }) {
      state.message = payload;
    },

    sendMessage(state) {
      state.chatLoading = true;
    },

    sendMessageSuccess(state, { payload }) {
      state.chatLoading = false;
      state.messages = [...state.messages, payload];
    },

    sendMessageFailed(state) {
      state.chatLoading = false;
    },

    deleteMessage(state) {
      state.chatLoading = true;
    },

    deleteMessageSuccess(state, { payload }) {
      state.chatLoading = false;
      state.messages = state.messages.filter((message) => message._id !== payload);
    },

    deleteMessageFailed(state) {
      state.chatLoading = false;
    },

    setFiles(state, { payload }) {
      state.files = payload;
    },

    addMessage(state, { payload }) {
      state.messages = [...state.messages, payload];
    },

    setChatTab(state, { payload }) {
      state.activeChatsTab = payload;
    },

    closeChatDialog(state) {
      state.selectedChat = {};
      state.messages = [];
    },

    uploadFiles(state) {
      state.chatLoading = true;
    },

    uploadFilesSuccess(state) {
      state.chatLoading = false;
    },

    uploadFilesFailed(state) {
      state.chatLoading = false;
    },

    addFile(state, { payload }) {
      state.files = [...state.files, payload];
    },

    removeFile(state, { payload }) {
      state.files = state.files.filter((file) => file.id !== payload.id);
    },

    setDetailsOpen(state, { payload }) {
      state.detailsOpen = payload;
    },

    setDetailsClosed(state, { payload }) {
      state.detailsClosed = payload;
    },

    openChatDialog() {
      // do nothing
    },

    deleteNotification() {
      // do nothing
    },

    deleteNotificationFailed() {
      // do nothing
    },

    setChatLastMessage(state, { payload }) {
      if (state.selectedChat.id === payload.chat._id) return;

      const rentingChat = state.rentingChats.items.find((i) => i.id === payload.chat._id);
      const hostingChat = state.hostingChats.items.find((i) => i.id === payload.chat._id);

      const chat = rentingChat || hostingChat;
      if (chat) {
        chat.lastMessage = payload.message;
      }
    },
  },
});

const { actions, reducer } = messagesPageSlice;

export const {
  load,
  loadFailed,
  loadSuccess,
  loadChat,
  loadChatSuccess,
  loadChatFailed,
  loadMore,
  loadMoreSuccess,
  loadMoreFailed,
  setMessage,
  setFiles,
  sendMessage,
  sendMessageFailed,
  sendMessageSuccess,
  deleteMessage,
  deleteMessageFailed,
  deleteMessageSuccess,
  uploadFiles,
  uploadFilesSuccess,
  uploadFilesFailed,
  addMessage,
  setChatTab,
  closeChatDialog,
  addFile,
  removeFile,
  setDetailsOpen,
  setDetailsClosed,
  openChatDialog,
  deleteNotification,
  deleteNotificationFailed,
  setChatLastMessage,
} = actions;

export default reducer;
