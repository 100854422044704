const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    height: '100%',
    width: '100%',
  },
  singleItemSlider: {
    transform: 'none !important',
  },
  itemContainer: {
    '& .MuiCard-root': {
      margin: '0 8px',
    },
  },
  buttonsContainer: {},
};

export default styles;
