import moment from 'moment';

export default {
  location: null,
  range: {
    start: moment()
      .add(1, 'days')
      .startOf('day')
      .add(10, 'hours')
      .toDate(),
    end: moment()
      .add(4, 'days')
      .startOf('day')
      .add(10, 'hours')
      .toDate(),
  },
  airportSearch: false,
  allAirports: [],
  flexibleDates: false,
  loading: false,
};
