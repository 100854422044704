import { all, call, put, select } from 'redux-saga/effects';
import moment from 'moment';

import { isBookedDate, isDisabledDate } from '@evee/evee-ui.utils';
import { apiService } from '@evee/evee-ui.services';

import { showError } from 'store/modules/app/actions';

import {
  dateProcessingFailed,
  dateProcessingSuccess,
  load,
  loadFailed,
  loadSuccess,
  setCurrentMonth,
  setOccupationsLoading,
  setRange,
} from 'store/modules/listing/occupations';

import {
  getCurrentMonth,
  getOccupiedRanges,
  getRange,
} from 'store/modules/listing/occupations/selectors';

import { getVehicle } from 'store/modules/listing/selectors';

export function* loadDefaultDates() {
  const currentMonth = yield select(getCurrentMonth);
  yield put(load(currentMonth));
}

export function* processLoadOccupations(action) {
  try {
    const { month, year } = action.payload;
    const vehicle = yield select(getVehicle);

    yield put(setCurrentMonth({ month, year }));

    if (vehicle.id) {
      const from = moment().set('year', year).set('month', month).startOf('month').toDate();
      const to = moment(from).add(1, 'month').toDate();

      const [current, next] = yield all([
        yield call(apiService.listing.getOccupations, {
          vehicleId: vehicle.id,
          month: moment(from).utc(true).month() + 1,
          year: moment(from).utc(true).year(),
        }),
        yield call(apiService.listing.getOccupations, {
          vehicleId: vehicle.id,
          month: moment(to).utc(true).month() + 1,
          year: moment(to).utc(true).year(),
        }),
      ]);

      const dates = current.concat(next);
      yield put(loadSuccess(dates));
    } else {
      yield put(loadFailed());
    }
  } catch (err) {
    yield put(loadFailed());
  }
}

function* processDate({ action, method }) {
  const date = action.payload;
  const vehicle = yield select(getVehicle);
  yield call(method, { vehicleId: vehicle.id, from: date, to: date });
  yield put(dateProcessingSuccess());
}

function* processRange(from, to, method) {
  const vehicle = yield select(getVehicle);
  yield call(method, { vehicleId: vehicle.id, from, to });
  yield put(dateProcessingSuccess());
}

export function* dateToggled(action) {
  try {
    const date = action.payload;
    const occupiedRanges = yield select(getOccupiedRanges);
    const currentMonth = yield select(getCurrentMonth);

    yield put(setOccupationsLoading(true));

    if (isBookedDate(date, occupiedRanges)) return;

    const method = isDisabledDate(date, occupiedRanges)
      ? apiService.listing.deleteOccupation
      : apiService.listing.addOccupation;
    yield call(processDate, { action, method });

    yield put(load(currentMonth));
  } catch (error) {
    yield put(dateProcessingFailed());
  }
}

export function* rangeChanged() {
  try {
    const occupiedRanges = yield select(getOccupiedRanges);
    const selectedRange = yield select(getRange);
    const currentMonth = yield select(getCurrentMonth);

    yield put(setOccupationsLoading(true));

    const method = isDisabledDate(selectedRange.startDate, occupiedRanges)
      ? apiService.listing.deleteOccupation
      : apiService.listing.addOccupation;
    yield call(processRange, selectedRange.startDate, selectedRange.endDate, method);

    yield put(load(currentMonth));
  } catch (error) {
    yield put(dateProcessingFailed());
    yield put(showError(error.message));
  } finally {
    yield put(
      setRange({
        startDate: null,
        endDate: null,
      }),
    );
  }
}
