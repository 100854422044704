const styles = (theme) => ({
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: `${theme.shape.borderRadius * 2}px`,
    marginBottom: theme.spacing(2),
  },
  title: {
    ...theme.typography.h3,
    color: 'inherit',
    fontWeight: 500,
  },
  subtitle: {
    ...theme.typography.subtitle1,
    color: 'inherit',
    fontWeight: 400,
    maxWidth: '80%',
  },
});

export default styles;
