export default {
  loading: false,
  rentingChats: {
    items: [],
    totalPages: 0,
    total: 0,
  },
  hostingChats: {
    items: [],
    totalPages: 0,
    total: 0,
  },
  currentRentingPage: 1,
  currentHostingPage: 1,
  pageLoading: false,
  messages: [],
  message: '',
  files: [],
  selectedChat: {},
  chatLoading: false,
  activeChatsTab: 0,
  detailsOpen: true,
  detailsClosed: false,
};
