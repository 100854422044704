import { all, call, fork, put, select } from 'redux-saga/effects';

import { ListingDocumentType } from '@evee/evee-ui.enums';
import { apiService } from '@evee/evee-ui.services';

import {
  removePhotoFailed,
  removePhotoSuccess,
  uploadDocumentFailed,
  uploadDocumentSuccess,
  uploadPhotoFailed,
  uploadPhotoSuccess,
} from 'store/modules/listing/actions';

import { getVehicle } from 'store/modules/listing/selectors';
import { showError } from 'store/modules/app/actions';

import { validateFieldValue } from './common';

function* uploadPhoto(listingId, { key, name, data }) {
  try {
    const photoKey = `vehicles/${listingId}/${name}`;
    const url = `${process.env.REACT_APP_FILES_DOMAIN}/${photoKey}`;

    yield call(apiService.listing.uploadFile, url, 'image/jpeg', data);

    yield put(uploadPhotoSuccess(key, { key: photoKey, url }));
  } catch (err) {
    yield put(uploadPhotoFailed(key));
    yield put(showError(`Failed to upload ${name}`));
  }
}

export function* uploadPhotos({ payload }) {
  const vehicle = yield select(getVehicle);
  try {
    yield all(
      payload.map((file) =>
        fork(function* upload() {
          yield call(uploadPhoto, vehicle.id, file);
        }),
      ),
    );
  } finally {
    yield validateFieldValue({ payload: { fieldName: 'photos', fieldValue: vehicle.photos } });
  }
}

export function* removePhoto({ payload: photoKey }) {
  try {
    const url = `${process.env.REACT_APP_FILES_DOMAIN}/${photoKey}`;
    yield call(apiService.listing.removeFile, url);
    yield put(removePhotoSuccess(photoKey));
  } catch (err) {
    yield put(removePhotoFailed(photoKey));
  } finally {
    const vehicle = yield select(getVehicle);
    yield validateFieldValue({ payload: { fieldName: 'photos', fieldValue: vehicle.photos } });
  }
}

export function* uploadDocument({ payload }) {
  const vehicle = yield select(getVehicle);
  const { name, type, data } = payload;
  try {
    const fileKey = `vehicleDocuments/${vehicle.id}_${Date.now()}.${name.split('.').pop()}`;
    const url = `${process.env.REACT_APP_FILES_DOMAIN}/${fileKey}`;

    yield call(apiService.listing.uploadFile, url, type, data);
    const documents = yield call(
      apiService.listing.attachDocument,
      vehicle.id,
      url,
      ListingDocumentType.registration,
    );

    yield put(uploadDocumentSuccess(documents));
  } catch (err) {
    yield put(uploadDocumentFailed());
    yield put(showError(`Failed to upload ${name}`));
  }
}
