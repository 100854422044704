/* eslint-disable no-param-reassign */
import { createAction, createReducer, createSlice } from '@reduxjs/toolkit';
import reduceReducers from 'reduce-reducers';

import forgotPasswordReducer from './forgotPassword';
import resetPasswordReducer from './resetPassword';
import signInReducer from './signIn';
import signUpReducer from './signUp';
import updatePasswordReducer from './updatePassword';
import verifyEmailReducer from './verifyEmail';

import * as forgotPassword from './forgotPassword';
import * as resetPassword from './resetPassword';
import * as signIn from './signIn';
import * as signUp from './signUp';
import * as verifyEmail from './verifyEmail';

import initialState from './initialState';

createReducer();

export const cancelSignInActions = [
  signIn.closeSignIn.toString(),
  signUp.closeSignUp.toString(),
  forgotPassword.closeForgotPassword.toString(),
];

export const signInActions = [signIn.signInSuccess.toString(), signUp.signUpSuccess.toString()];

export const setPersonalInfo = createAction('auth/setPersonalInfo');
export const setReceiveNewsletter = createAction('auth/setReceiveNewsletter');

export const authSlice = createSlice({
  name: 'auth',
  initialState: {},
  reducers: {
    signOut: (state) => {
      state.signedIn = false;
    },

    setIsSubscribed: (state, { payload }) => {
      state.isSubscribed = payload;
    },

    setSigningIn: (state, { payload }) => {
      state.signingIn = payload.value;
      state.signingInPath = payload.path || '';
    },

    setSignedIn: (state, { payload }) => {
      state.signedIn = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.showSignIn.toString(), (state) => {
        state.signUp.open = false;
        state.forgotPassword.open = false;
        state.resetPassword.open = false;
      })
      .addCase(signUp.showSignUp.toString(), (state) => {
        state.signIn.open = false;
        state.forgotPassword.open = false;
        state.resetPassword.open = false;
        state.verifyEmail.open = false;
      })
      .addCase(forgotPassword.showForgotPassword.toString(), (state) => {
        state.signIn.open = false;
        state.signUp.open = false;
        state.resetPassword.open = false;
        state.verifyEmail.open = false;
      })
      .addCase(resetPassword.showResetPassword.toString(), (state) => {
        state.signIn.open = false;
        state.signUp.open = false;
        state.forgotPassword.open = false;
        state.verifyEmail.open = false;
      })
      .addCase(verifyEmail.showVerifyEmail.toString(), (state) => {
        state.signIn.open = false;
        state.signUp.open = false;
        state.forgotPassword.open = false;
        state.resetPassword.open = false;
      })
      .addMatcher(
        (action) => signInActions.includes(action.type),
        (state) => {
          state.signIn.open = false;
          state.signUp.open = false;
          state.signedIn = true;
        },
      );
  },
});

const { actions, reducer } = authSlice;

export const { signOut, setIsSubscribed, setSigningIn, setSignedIn } = actions;

export default reduceReducers(
  initialState,
  reducer,
  signInReducer,
  signUpReducer,
  forgotPasswordReducer,
  resetPasswordReducer,
  verifyEmailReducer,
  updatePasswordReducer,
);
