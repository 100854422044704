import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactLinkify from 'react-linkify';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Link,
  Typography,
  useMediaQuery,
} from '@material-ui/core';

import { useTheme, withStyles } from '@material-ui/core/styles';
import { ExpandMore } from '@material-ui/icons';

import { Divider } from '@evee/evee-ui.base';
import styles from './styles';

const isEveeHref = (href) => href && href.includes('evee.com.au');

const Faq = ({ questions, classes }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [expandedQuestion, setExpandedQuestion] = useState('');

  const renderLink = (href, text) => {
    if (!isEveeHref(href)) {
      return text;
    }

    return (
      <Link underline="always" href={href}>
        {text}
      </Link>
    );
  };

  const renderQuestion = ({ question, answer }, i) => (
    <React.Fragment key={i}>
      <Grid item xs={12}>
        <Accordion
          square
          elevation={0}
          expanded={expandedQuestion === question}
          classes={{ root: classes.root, expanded: classes.expanded }}
          onChange={(e, expanded) => {
            if (expanded) {
              setExpandedQuestion(question);
            } else {
              setExpandedQuestion('');
            }
          }}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="body1" component="h2" style={{ fontWeight: 500 }}>
              {question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2">
              <ReactLinkify componentDecorator={renderLink}>{answer}</ReactLinkify>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </React.Fragment>
  );

  return (
    <Box display="flex" flexDirection="column">
      <Typography gutterBottom variant="h3" style={{ marginBottom: '32px' }}>
        Frequently Asked Questions
      </Typography>
      <Grid container spacing={2}>
        {isMobile ? (
          questions.map(renderQuestion)
        ) : (
          <>
            <Grid item md={6}>
              <Divider />
              {questions.slice(0, Math.ceil(questions.length / 2)).map(renderQuestion)}
            </Grid>
            <Grid item md={6}>
              <Divider />
              {questions
                .slice(Math.ceil(questions.length / 2), questions.length)
                .map(renderQuestion)}
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

Faq.propTypes = {
  questions: PropTypes.array,
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    expanded: PropTypes.string.isRequired,
  }).isRequired,
};

Faq.defaultProps = {
  questions: [],
};

export default withStyles(styles)(Faq);
