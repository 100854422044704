/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { CHANGE_EMAIL_SUCCESS } from '../profile/actionTypes';
import { CHECK_VERIFICATION_CODE_SUCCESS } from '../phone/actionTypes';
import { setDeclarationAccepted } from '../profile/declaration';
import { verifyLicenceSuccess } from '../profile/licence';

import initialState from './initialState';

export const myTripsSlice = createSlice({
  name: 'personalInfoPage',
  initialState,
  reducers: {
    load(state) {
      state.loading = true;
    },

    setEditField(state, { payload }) {
      state.editFields = {
        ...initialState.editFields,
        [payload]: true,
      };
    },

    setProfileCopy(state, { payload }) {
      state.profileCopy = payload;
    },

    resetEditFields(state) {
      state.editFields = initialState.editFields;
      state.profileCopy = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(CHANGE_EMAIL_SUCCESS, (state) => {
        state.editFields.email = false;
      })
      .addCase(verifyLicenceSuccess.toString(), (state) => {
        state.editFields.licence = false;
      })
      .addCase(setDeclarationAccepted.toString(), (state, { payload }) => {
        if (payload) {
          state.editFields.declaration = false;
        }
      })
      .addCase(CHECK_VERIFICATION_CODE_SUCCESS, (state) => {
        state.editFields.phone = false;
      });
  },
});

const { actions, reducer } = myTripsSlice;

export const { load, setEditField, resetEditFields, setProfileCopy } = actions;

export default reducer;
