import React from 'react';
import { SvgIcon } from '@material-ui/core';

import theme from 'theme';

const Bolt = (props) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M16.5584 7.16675C16.4963 7.02401 16.3955 6.90152 16.2673 6.81315C16.1392 6.72478 15.9889 6.67406 15.8334 6.66675H12.0167L13.0751 2.71675C13.1088 2.59314 13.1135 2.46343 13.089 2.33769C13.0644 2.21195 13.0112 2.09357 12.9334 1.99175C12.8558 1.8909 12.7561 1.80918 12.6419 1.75284C12.5278 1.6965 12.4023 1.66705 12.2751 1.66675H6.44173C6.25359 1.6603 6.06882 1.71775 5.9175 1.82973C5.76618 1.9417 5.65723 2.10161 5.60839 2.28342L3.37506 10.6167C3.34135 10.7404 3.3366 10.8701 3.36117 10.9958C3.38574 11.1215 3.43897 11.2399 3.51673 11.3417C3.59521 11.4437 3.69625 11.5261 3.81191 11.5825C3.92757 11.6389 4.05472 11.6677 4.18339 11.6667H7.40839L5.90006 17.2834C5.85064 17.4645 5.86383 17.657 5.93748 17.8297C6.01113 18.0024 6.14094 18.1452 6.30586 18.2348C6.47078 18.3245 6.66116 18.3559 6.84615 18.3239C7.03113 18.2919 7.19988 18.1983 7.32506 18.0584L16.4084 8.05842C16.5166 7.93998 16.5882 7.79286 16.6148 7.63469C16.6414 7.47653 16.6219 7.31405 16.5584 7.16675V7.16675ZM8.40006 14.4001L9.29173 11.0667C9.32543 10.9431 9.33019 10.8134 9.30562 10.6877C9.28105 10.562 9.22782 10.4436 9.15006 10.3417C9.07243 10.2409 8.97273 10.1592 8.85861 10.1028C8.7445 10.0465 8.61899 10.0171 8.49173 10.0167H5.29173L7.07506 3.33342H11.1834L10.1251 7.28341C10.0911 7.40929 10.0871 7.54139 10.1135 7.66908C10.1398 7.79677 10.1958 7.9165 10.2769 8.01863C10.3579 8.12076 10.4618 8.20244 10.5802 8.25711C10.6985 8.31179 10.8281 8.33792 10.9584 8.33342H13.9334L8.40006 14.4001Z"
      fill={theme.palette.primary.main}
    />
  </SvgIcon>
);

Bolt.displayName = 'BoltIcon';
Bolt.muiName = 'SvgIcon';

export default Bolt;
