import backgroundImg from 'widgets/home/images/insurance_background.jpg';

const styles = (theme) => ({
  container: {
    display: 'flex',
    height: '330px',
    borderRadius: theme.shape.borderRadiusMedium,
    backgroundImage: `url(${backgroundImg})`,
    backgroundPosition: '250px center',
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: `${theme.shape.borderRadiusMedium}px 250px 250px ${theme.shape.borderRadiusMedium}px`,
    backgroundColor: theme.palette.secondary.main,
    minWidth: '450px',
    width: '50%',
    padding: theme.spacing(6),
    color: theme.palette.common.white,
  },
  title: {
    ...theme.typography.h3,
    color: 'inherit',
    fontWeight: 500,
  },
  subtitle: {
    ...theme.typography.body1,
    color: theme.palette.text.primary,
    fontWeight: 400,
  },
});

export default styles;
