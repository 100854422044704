import PropTypes from 'prop-types';

import ArrowLeft from '@material-ui/icons/ChevronLeft';
import ArrowRight from '@material-ui/icons/ChevronRight';
import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { IconButton } from '@evee/evee-ui.base';

import styles from './styles';

const ButtonGroup = ({ next, previous, classes, ...rest }) => {
  const indent = 0;
  const {
    carouselState: { currentSlide, slidesToShow, totalItems },
  } = rest;
  return currentSlide === 0 && currentSlide + slidesToShow >= totalItems ? null : (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      flexDirection="row"
      className={classes.container}
      style={{ height: 'auto' }}
    >
      <IconButton
        disabled={currentSlide === 0}
        icon={<ArrowLeft fontSize="small" />}
        className={classes.button}
        style={{ left: indent }}
        onClick={previous}
      />
      <IconButton
        disabled={currentSlide + slidesToShow >= totalItems}
        icon={<ArrowRight fontSize="small" />}
        className={classes.button}
        style={{ right: indent }}
        onClick={next}
      />
    </Box>
  );
};

ButtonGroup.propTypes = {
  next: PropTypes.func.isRequired,
  previous: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
  }).isRequired,
};

ButtonGroup.defaultProps = {};

export default withStyles(styles)(ButtonGroup);
