import PropTypes from 'prop-types';
import Typewriter from 'typewriter-effect';

import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { Region } from '@evee/evee-ui.enums';
import useRegion from 'store/modules/app/hooks/useRegion';

import styles from './styles';

const COMMON_STRINGS = [
  'for a romantic getaway',
  'to try before you buy',
  'for your next family adventure',
  'for a business trip',
];

const TextSlider = ({ className, classes, ...rest }) => {
  const { region } = useRegion();

  const regionalStrings = region.id === Region.australia.id
    ? ['to explore Australia']
    : ['to explore New Zealand'];
    
  return (
    <Box width="100%" display="flex" {...rest}>
      <Typewriter
        options={{
          strings: [
            ...COMMON_STRINGS,
            ...regionalStrings,
          ].map((s) => `<span class=${className}>${s}</span>`),
          autoStart: true,
          cursor: '',
          loop: true,
          deleteSpeed: 10,
          delay: 50,
        }}
      />
    </Box>
  );
};

TextSlider.propTypes = {
  classes: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
};

TextSlider.defaultProps = {};

export default withStyles(styles)(TextSlider);
