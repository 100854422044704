/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import initialState from './initialState';

export const additionalDriversSlice = createSlice({
  name: 'additionalDrivers',
  initialState,
  reducers: {
    load: () => {
      // nothing to do here
    },

    sendInvite(state) {
      state.loading = true;
    },

    sendInviteSuccess(state) {
      state.open = false;
      state.loading = false;
    },

    sendInviteFailed(state) {
      state.loading = false;
    },

    removeDriver(state) {
      state.loading = true;
    },

    removeDriverSuccess(state) {
      state.loading = false;
    },

    removeDriverFailed(state) {
      state.loading = false;
    },

    setOpen(state, { payload }) {
      state.open = payload;
    },

    setEmail(state, { payload }) {
      state.email = payload;
    },

    setDriver(state, { payload }) {
      state.driver = payload;

      if (payload?.email) {
        state.email = payload.email;
      }
    },
  },
});

const { actions, reducer } = additionalDriversSlice;

export const {
  load,
  sendInvite,
  sendInviteSuccess,
  sendInviteFailed,
  removeDriver,
  removeDriverSuccess,
  removeDriverFailed,
  setOpen,
  setEmail,
  setDriver,
} = actions;

export default reducer;
