import { useSelector } from 'react-redux';

import * as appSelectors from 'store/modules/app/selectors';

export default function useRegion() {
  const region = useSelector(appSelectors.getRegion);
  const currency = useSelector(appSelectors.getCurrency);

  return { region, currency };
}
