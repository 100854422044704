import { createRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Box, Fade, Paper, useMediaQuery } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { Divider, IconButton, Switch } from '@evee/evee-ui.base';
import { formatTripDate } from '@evee/evee-ui.utils';

import SearchCalendar from 'widgets/search/SearchCalendar';
import SearchDateInput from 'widgets/search/SearchDateInput';
import SearchIcon from 'common/icons/Search';
import SearchLocationInput from 'widgets/search/SearchLocationInput';
import useSearchProps from 'store/modules/search/hooks/useSearchProps';

import styles from './styles';

const SearchBar = ({ hidden, classes }) => {
  const [tripDates, location, loading, onChangeLocation, onFieldChanged, onSearch] =
    useSearchProps();

  const showFlexibleLabel = useMediaQuery((theme) => theme.breakpoints.up(1440));
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const ref = createRef();

  const calendarValue = {
    start: tripDates.from && moment(tripDates.from),
    end: tripDates.to && moment(tripDates.to),
  };

  const tripStart = formatTripDate(tripDates.from);
  const tripEnd = formatTripDate(tripDates.to);

  useEffect(() => {
    setAnchorEl(ref.current);
  }, [ref]);

  return (
    <Fade in={!hidden}>
      <Box display="flex" alignItems="center">
        <Paper elevation={0} ref={ref} className={classes.root}>
          <SearchLocationInput
            value={location}
            placeholder="Where are you renting?"
            className={classes.input}
            classes={{ paper: classes.paper }}
            onChange={onChangeLocation}
          />

          {!tripDates.flexible && (
            <>
              <Divider className={classes.divider} orientation="vertical" />

              <SearchDateInput
                value={`${tripStart} - ${tripEnd}`}
                label="Dates"
                className={classes.input}
                InputProps={{
                  placeholder: 'Selected dates',
                }}
                onClick={() => setOpen(true)}
              />
            </>
          )}

          <IconButton
            disabled={loading}
            size="small"
            color="secondary"
            icon={<SearchIcon style={{ width: '16px', height: '16px' }} />}
            className={classes.iconButton}
            onClick={onSearch}
          />
        </Paper>

        <Switch
          variant="subtitle1"
          label={showFlexibleLabel ? "I'm flexible" : null}
          checked={tripDates.flexible}
          classes={{ container: classes.flexible }}
          onChange={(v) => onFieldChanged('flexibleDates', v)}
        />

        <SearchCalendar
          daySize={34}
          value={calendarValue}
          open={open}
          anchorEl={anchorEl}
          PopoverProps={{
            style: {
              zIndex: 1300,
              marginTop: '8px',
            },
          }}
          onChange={(v) => {
            onFieldChanged('range', v);
          }}
          onClose={() => {
            setOpen(false);
          }}
        />
      </Box>
    </Fade>
  );
};

SearchBar.propTypes = {
  hidden: PropTypes.bool.isRequired,
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    input: PropTypes.string.isRequired,
    divider: PropTypes.string.isRequired,
    iconButton: PropTypes.string.isRequired,
    flexible: PropTypes.string.isRequired,
    paper: PropTypes.string.isRequired,
  }).isRequired,
};

SearchBar.defaultProps = {};

export default withStyles(styles)(SearchBar);
