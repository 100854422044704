import { connect } from 'react-redux';

import { setHeaderNotification } from 'store/modules/app/actions';

import HeaderNotification from './HeaderNotification';

const mapStateToProps = (state) => ({
  open: state.app.headerNotification,
});

const mapDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(setHeaderNotification(false)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderNotification);
