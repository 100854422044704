import { call, put, select } from 'redux-saga/effects';

import { apiService, history, storage, tagManager } from '@evee/evee-ui.services';

import * as appActions from 'store/modules/app/actions';
import * as authActions from 'store/modules/auth';
import * as authSelectors from 'store/modules/auth/selectors';
import * as signInActions from 'store/modules/auth/signIn';
import * as signInSelectors from 'store/modules/auth/signIn/selectors';
import { showError } from 'store/modules/app/actions';

export function* authorizedRouteSignIn({ payload: path }) {
  try {
    const isUserAuthorized = yield select(authSelectors.getIsUserAuthorized);
    if (isUserAuthorized) {
      history.push(path);
      return;
    }

    yield put(authActions.setSigningIn({ value: true, path }));
    yield put(signInActions.showSignIn());
  } catch (error) {
    yield put(appActions.showError(error.message));
  }
}

export function* facebookSignIn({ payload: newUser }) {
  try {
    const user = yield call(apiService.customer.facebookSignIn);

    if (newUser) {
      tagManager.pushSignUp({
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        method: 'Facebook',
      });
    } else {
      tagManager.pushSignIn({
        email: user.email,
        method: 'Facebook',
      });
    }

    yield put(signInActions.signInSuccess(user));
  } catch (err) {
    yield put(showError(err.message));
    yield put(signInActions.signInFailed());
  }
}

export function* googleSignIn({ payload: newUser }) {
  try {
    const user = yield call(apiService.customer.googleSignIn);

    if (newUser) {
      tagManager.pushSignUp({
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        method: 'Google',
      });
    } else {
      tagManager.pushSignIn({
        email: user.email,
        method: 'Google',
      });
    }

    yield put(signInActions.signInSuccess(user));
  } catch (err) {
    yield put(signInActions.signInFailed());
  }
}

function* handleRememberMeChanged(value) {
  if (!value) {
    yield call(storage.creds.remove);
  }
}

export function* signInFieldChanged(action) {
  const {
    payload: { fieldName, value },
  } = action;

  if (fieldName === 'rememberMe') {
    yield call(handleRememberMeChanged, value);
  }
}

export function* signIn() {
  try {
    const { email, password, rememberMe } = yield select(signInSelectors.getSignIn);

    const user = yield call(apiService.customer.signIn, {
      email,
      password,
    });

    if (rememberMe) {
      yield call(storage.creds.save, { email, password });
    }

    tagManager.pushSignIn({
      email,
      method: 'Standard',
    });

    yield put(signInActions.signInSuccess(user));
  } catch (err) {
    yield put(signInActions.signInFailed(err.message));
  }
}
