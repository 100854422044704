import PropTypes from 'prop-types';
import { useState } from 'react';

import { Box, Divider, Grid, Link, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme, withStyles } from '@material-ui/core/styles';

import { Button, TextInput } from '@evee/evee-ui.base';
import { getLegalEntity } from '@evee/evee-ui.utils';

import { RegionSelector } from 'widgets/home';
import useSubscribeProps from 'store/modules/subscribe/hooks/useSubscribeProps';

import BCorpLogo from './images/B-Corp-Logo.png';
import EveeColumn from './EveeColumn';
import ExploreColumn from './ExploreColumn';
import HostingColumn from './HostingColumn';
import SocialButtons from './SocialButtons';
import TopCitiesColumn from './TopCitiesColumn';
import styles from './styles';

const Footer = ({ region, showSubscribe, classes }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [formatError, setFormatError] = useState(null);
  const [email, onSubscribe, onEmailChanged] = useSubscribeProps();
  const containerClass = isMobile ? classes.mobileContainer : classes.desktopContainer;

  const connectJsx = (
    <>
      <Typography variant="body1" className={classes.header}>
        Let&apos;s connect
      </Typography>
      <SocialButtons />
    </>
  );

  const signupJsx = (
    <>
      <Typography paragraph variant="body1" style={{ fontWeight: 500 }}>
        Join our newsletter
      </Typography>

      <Typography gutterBottom variant="body1" style={{ fontSize: '13px' }}>
        Get EV news and tips right to your inbox
      </Typography>

      <Box display="flex" flexWrap="nowrap" width="100%">
        <TextInput
          value={email}
          InputProps={{
            placeholder: 'Email Address',
            className: classes.signupInput,
            style: {
              borderRadius: `0 ${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
            },
          }}
          format="email"
          error={!!formatError}
          helperText={formatError}
          onChange={onEmailChanged}
          onFormatError={setFormatError}
        />

        <Button
          text="Subscribe"
          classes={{
            root: classes.signupButton,
          }}
          onClick={() => {
            if (email && !formatError) {
              onSubscribe();
            } else if (!email) {
              setFormatError('Please enter email address');
            }
          }}
        />
      </Box>
    </>
  );

  const bCorpLogo = (
    <Link
      href="https://www.bcorporation.net/en-us/find-a-b-corp/company/evee-australia-pty-ltd"
      target="_blank"
      className={classes.bCorpLogo}
    >
      <img src={BCorpLogo} alt="Certified B Corporation" />
    </Link>
  );

  return (
    <Box display="flex" justifyContent="center" alignItems="start" className={containerClass}>
      <Box className={classes.contentContainer}>
        {isMobile && (
          <>
            <Box
              display="flex"
              flexWrap="wrap"
              justifyContent="space-between"
              style={{ margin: '16px 0' }}
            >
              <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                <SocialButtons padding="0 12px" />
                <RegionSelector />
              </Box>
            </Box>
            <Divider />
          </>
        )}

        <Box
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          justifyContent="space-between"
          style={{ marginBottom: theme.spacing(!isMobile ? 5 : 0) }}
        >
          <ExploreColumn />

          <HostingColumn />

          <EveeColumn />

          <TopCitiesColumn />

          {!isMobile && (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              minWidth={showSubscribe ? '30%' : 'auto'}
            >
              {showSubscribe ? signupJsx : connectJsx}
              {bCorpLogo}
            </Box>
          )}
        </Box>

        <Divider style={{ marginBottom: theme.spacing(isMobile ? 1 : 3) }} />

        <Grid container alignItems="center">
          {isMobile && (
            <Grid item container xs={12} justifyContent="center">
              {bCorpLogo}
            </Grid>
          )}

          <Grid item xs={isMobile ? 12 : 6}>
            <Typography
              color="textSecondary"
              align={isMobile ? 'center' : 'left'}
              className={classes.legalInfo}
            >
              {getLegalEntity(region)}
            </Typography>
          </Grid>

          {!isMobile && (
            <Grid item xs={6}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                style={{ textAlign: isMobile ? 'start' : 'end' }}
              >
                {showSubscribe && <SocialButtons />}
                <RegionSelector />
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

Footer.propTypes = {
  showSubscribe: PropTypes.bool,
  region: PropTypes.object,
  classes: PropTypes.shape({
    legalInfo: PropTypes.string.isRequired,
    mobileContainer: PropTypes.string.isRequired,
    desktopContainer: PropTypes.string.isRequired,
    contentContainer: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    signupInput: PropTypes.string.isRequired,
    signupButton: PropTypes.string.isRequired,
    bCorpLogo: PropTypes.string.isRequired,
  }).isRequired,
};

Footer.defaultProps = {
  region: {},
  showSubscribe: false,
};

export default withStyles(styles)(Footer);
