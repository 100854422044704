/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import initialState from './initialState';

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    loadReviews(state) {
      state.reviews.loading = true;
    },

    loadReviewsSuccess(state, action) {
      state.reviews.loading = false;
      state.reviews.items = action.payload;
    },

    loadReviewsFailed(state) {
      state.reviews = initialState.reviews;
    },

    loadFeaturedLocations(state) {
      state.featuredLocations.loading = true;
    },

    loadFeaturedLocationsSuccess(state, action) {
      state.featuredLocations.loading = false;
      state.featuredLocations.items = action.payload;
    },

    loadFeaturedModels(state) {
      state.featuredModels.loading = true;
    },

    loadFeaturedModelsSuccess(state, action) {
      state.featuredModels.loading = false;
      state.featuredModels.items = action.payload;
    },

    loadFeaturedModelsFailed(state) {
      state.featuredModels = initialState.featuredModels;
    },

    loadFeaturedLocationsFailed(state) {
      state.featuredLocations = initialState.featuredLocations;
    },

    loadBlogPosts(state) {
      state.blogPosts.loading = true;
    },

    loadBlogPostsSuccess(state, action) {
      state.blogPosts.loading = false;
      state.blogPosts.posts = action.payload;
    },

    loadBlogPostsFailed(state) {
      state.blogPosts = initialState.blogPosts;
    },

    loadTopHosts(state) {
      state.topHosts.loading = true;
    },

    loadTopHostsSuccess(state, action) {
      state.topHosts.loading = false;
      state.topHosts.items = action.payload;
    },

    loadTopHostsFailed(state) {
      state.topHosts = initialState.topHosts;
    },

    loadTopCities(state) {
      state.topCities.loading = true;
    },

    loadTopCitiesSuccess(state, action) {
      state.topCities.loading = false;
      state.topCities.items = action.payload;
    },

    loadTopCitiesFailed(state) {
      state.topCities = initialState.topCities;
    },

    loadPartners(state) {
      state.partners.loading = true;
    },

    loadPartnersSuccess(state, action) {
      state.partners.loading = false;
      state.partners.items = action.payload;
    },

    loadPartnersFailed(state) {
      state.partners = initialState.partners;
    },

    setSpecialOffer(state, action) {
      state.specialOffer = {
        ...action.payload,
        open: true,
      };
    },

    closeSpecialOffer: (state) => {
      state.specialOffer.open = false;
    },
  },
});

const { actions, reducer } = homeSlice;

export const {
  loadReviews,
  loadReviewsSuccess,
  loadReviewsFailed,
  loadFeaturedLocations,
  loadFeaturedLocationsSuccess,
  loadFeaturedLocationsFailed,
  loadFeaturedModels,
  loadFeaturedModelsSuccess,
  loadFeaturedModelsFailed,
  loadBlogPosts,
  loadBlogPostsSuccess,
  loadBlogPostsFailed,
  loadTopHosts,
  loadTopHostsSuccess,
  loadTopHostsFailed,
  setSpecialOffer,
  closeSpecialOffer,
  loadTopCities,
  loadTopCitiesSuccess,
  loadTopCitiesFailed,
  loadPartners,
  loadPartnersSuccess,
  loadPartnersFailed,
} = actions;

export default reducer;
