import { alpha, lighten } from '@material-ui/core/styles';

const styles = (theme) => ({
  menuButton: {
    padding: '6px 7px',
    borderRadius: '40px',
    whiteSpace: 'nowrap',
    backgroundColor: lighten(theme.palette.primary.main, 0.95),
  },
  transparentMenuButton: {
    backgroundColor: alpha(lighten(theme.palette.primary.main, 0.2), 0.4),
  },
});

export default styles;
