import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';

import { socket } from '@evee/evee-ui.services';

import './index.css';

import reduxStore from './store';
import saga from './saga';

import App from './app';
import rootSaga from './saga/rootSaga';

import * as serviceWorker from './serviceWorker';

socket.init();
saga.run(rootSaga);

ReactDOM.render(
  <Provider store={reduxStore}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
