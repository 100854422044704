import { call, put } from 'redux-saga/effects';

import * as ownerPageActions from 'store/modules/hostPage';
import { apiService } from '@evee/evee-ui.services';

function* loadFeaturedVehicles() {
  try {
    const response = yield call(apiService.vehicle.getFeatured);
    yield put(ownerPageActions.loadFeaturedVehiclesSuccess(response));
  } catch (error) {
    yield put(ownerPageActions.loadFeaturedVehiclesFailed());
  }
}

export { loadFeaturedVehicles };
