const styles = (theme) => ({
  desktopContainer: {
    backgroundColor: theme.palette.background.default,
    paddingTop: '56px',
    paddingBottom: '24px',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      padding: '32px 16px',
    },
  },
  mobileContainer: {
    backgroundColor: theme.palette.background.default,
    padding: '0 10px 24px 20px',
    height: '100%',
  },
  contentContainer: {
    width: '1200px',
  },
  legalInfo: {
    fontSize: '12px',
    margin: '15px 0 10px 0',
  },
  button: {
    ...theme.typography.body1,
    padding: '0px',
    minWidth: '0px',
    fontWeight: 'bold',
  },
  footerLink: {
    ...theme.typography.body1,
    padding: '0px',
    minWidth: '0px',
    margin: '8px 0',
  },
  text: {
    margin: theme.spacing(1, 0),
  },
  header: {
    marginBottom: '15px',
    fontWeight: 500,
  },
  signupButton: {
    minWidth: '100px',
    height: '40px',
    boxShadow: 'none',
    borderRadius: `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`,
  },
  signupInput: {
    height: '40px',
    borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
  },
  bCorpLogo: {
    alignSelf: 'flex-end',
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'center',
      margin: theme.spacing(4, 0),
    },
  },
});

export default styles;
