import { createSelector } from 'reselect';

export const getOccupations = (state) => state.listing.occupations;

export const getCurrentMonth = createSelector(
  getOccupations,
  (occupations) => occupations.currentMonth,
);

export const getRange = createSelector(getOccupations, (occupations) => occupations.range);

export const getOccupiedRanges = createSelector(
  getOccupations,
  (occupations) => occupations.occupiedRanges,
);

export const getLoading = createSelector(getOccupations, (occupations) => occupations.loading);
