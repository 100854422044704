import broadsheet from './images/broadsheet.svg';
import cleantTechnica from './images/cleant-technica.svg';
import gq from './images/gq.svg';
import sevenNews from './images/7news.svg';
import theDriven from './images/thedriven.svg';
import waToday from './images/watoday.svg';

export default [
  {
    image: gq,
    href: 'https://www.gq.com.au/lifestyle/cars/you-can-now-hire-a-tesla-model-3-as-soon-as-the-first-aussie-shipment-arrives/news-story/b77811cc77df248faf77b76ff321e8af',
  },
  {
    image: broadsheet,
    href: 'https://www.broadsheet.com.au/national/city-file/article/australias-first-100-per-cent-electric-car-sharing-company-evee-like-airbnb-teslas',
  },
  {
    image: sevenNews,
    href: 'https://www.youtube.com/watch?v=-vPZh5Va8yw',
  },
  {
    image: theDriven,
    href: 'https://thedriven.io/2022/01/24/evee-australian-electric-car-sharing-platform-doubles-in-size-to-100-vehicles/',
  },
  {
    image: waToday,
    href: 'https://www.watoday.com.au/national/western-australia/rent-a-tesla-ev-car-sharing-arrives-in-wa-20191012-p53038.html',
  },
  {
    image: cleantTechnica,
    href: 'https://cleantechnica.com/2020/08/17/electric-rentals-carsharing-in-australia-interview-with-slava-kozlovskii-founder-ceo-of-evee/',
  },
];
