const styles = (theme) => ({
  container: {
    padding: '8px !important',
    width: '190px !important',
  },
  paper: {
    marginTop: '10px',
    boxShadow: '0px 0px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06)',
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius,
    '& :first-child': {
      marginRight: theme.spacing(2),
    },
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
});

export default styles;
