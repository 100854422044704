import { put } from 'redux-saga/effects';

import { history } from '@evee/evee-ui.services';

import * as paymentsAndPayoutsActions from 'store/modules/paymentsAndPayoutsPage';
import { showError } from 'store/modules/app/actions';

export function* openPayouts() {
  try {
    yield put(paymentsAndPayoutsActions.setCurrentTab(1));
    history.push('/account/payments-and-payouts');
  } catch (err) {
    yield put(showError(err.message));
  }
}
