const styles = (theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '100px',
    transition: 'width 0.25s ease-out',
  },
  input: {
    padding: theme.spacing(1.5, 2),
    flex: '1 0 auto',
  },
  divider: {
    height: 28,
    margin: 4,
  },
  findButton: {
    minWidth: '125px',
    borderRadius: '56px',
    marginRight: '4px',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
  },
  flexibleSwitch: {
    minWidth: '150px',
  },
  locationInput: {
    border: 'none',
    fontWeight: 500,
  },
  paper: {
    marginTop: '20px',
    marginLeft: -theme.spacing(2),
    minWidth: '400px',
    padding: theme.spacing(0, 2),
    borderRadius: theme.shape.borderRadiusLarge,
  },
});

export default styles;
