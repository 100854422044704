import { BookingStatusFilter } from '@evee/evee-ui.enums';

const bookingsItem = {
  items: [],
  totalPages: 0,
  total: 0,
};

export default {
  loading: false,
  pageLoading: false,
  statusFilter: BookingStatusFilter.upcoming,
  currentPage: 1,
  bookings: {
    upcoming: { ...bookingsItem },
    ongoing: { ...bookingsItem },
    completed: { ...bookingsItem },
    cancelled: { ...bookingsItem },
  },
};
