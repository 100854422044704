import { call, put, select } from 'redux-saga/effects';

import { apiService } from '@evee/evee-ui.services';

import * as appActions from 'store/modules/app/actions';
import * as appSelectors from 'store/modules/app/selectors';
import * as authorisePaymentActions from 'store/modules/authorisePayment';
import * as authorisePaymentSelectors from 'store/modules/authorisePayment/selectors';
import * as bookingDetailsSelectors from 'store/modules/bookingDetails/selectors';
import * as extrasActions from 'store/modules/extras';

function* loadBookingExtras({ payload: booking }) {
  try {
    const availableExtras = yield call(apiService.extras.getVehicleExtras, {
      vehicleId: booking?.vehicle?.id,
      from: booking.from,
      to: booking.to,
      currency: booking.currency,
    });

    yield put(extrasActions.loadSuccess(availableExtras));
  } catch (error) {
    yield put(extrasActions.loadFailed());
    yield put(appActions.showRequestError());
  }
}

function* addExtra({ payload }) {
  try {
    yield put(authorisePaymentActions.setLoading(true));

    const currency = yield select(appSelectors.getCurrency);
    const selectedCard = yield select(authorisePaymentSelectors.getSelectedCard);
    const { bookingId, extra } = payload;

    const extras = yield call(
      apiService.booking.addExtra,
      bookingId,
      currency.id,
      extra,
      selectedCard,
    );

    yield put(extrasActions.addExtraSuccess(extras));
    yield put(authorisePaymentActions.setOpen(false));
  } catch (error) {
    yield put(appActions.showError(error.message));
  } finally {
    yield put(authorisePaymentActions.setLoading(false));
    yield put(extrasActions.addExtraFailed());
  }
}

function* removeExtra({ payload }) {
  try {
    const bookingId = yield select(bookingDetailsSelectors.getBookingId);
    const currency = yield select(appSelectors.getCurrency);

    yield call(apiService.booking.removeExtra, bookingId, currency.id, payload);

    yield put(extrasActions.removeExtraSuccess());
  } catch (error) {
    yield put(appActions.showError(error.message));
  } finally {
    yield put(extrasActions.removeExtraFailed());
  }
}

export { loadBookingExtras, addExtra, removeExtra };
