import { PaymentMethodStatus } from '@evee/evee-ui.enums';

import * as actionTypes from './actionTypes';

import initialState from './initialState';

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.LOAD:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.LOAD_SUCCESS:
      return {
        ...state,
        methods: [...payload],
        loading: false,
      };

    case actionTypes.LOAD_FAILED:
      return {
        ...state,
        methods: [],
        loading: false,
      };

    case actionTypes.SET_CREATE_DIALOG_OPEN:
      return {
        ...state,
        createDialog: {
          ...state.createDialog,
          open: payload,
        },
      };

    case actionTypes.CREATE:
      return {
        ...state,
        createDialog: {
          ...state.createDialog,
          loading: true,
        },
      };

    case actionTypes.CREATE_SUCCESS:
      return {
        ...state,
        methods: [...state.methods, payload],
        createDialog: {
          ...state.createDialog,
          loading: false,
          open: false,
        },
      };

    case actionTypes.CREATE_FAILED:
      return {
        ...state,
        createDialog: {
          ...state.createDialog,
          loading: false,
        },
      };

    case actionTypes.SET_CARDHOLDER_NAME:
      return {
        ...state,
        createDialog: {
          ...state.createDialog,
          cardholderName: payload,
        },
      };

    case actionTypes.SET_BILLING_ADDRESS:
      return {
        ...state,
        createDialog: {
          ...state.createDialog,
          billingAddress: {
            ...payload,
          },
        },
      };

    case actionTypes.SET_SAME_AS_RESIDENTIAL:
      return {
        ...state,
        createDialog: {
          ...state.createDialog,
          sameAsResidential: payload,
        },
      };

    case actionTypes.SET_FIELD_ERROR:
      return {
        ...state,
        createDialog: {
          ...state.createDialog,
          errors: {
            ...state.createDialog.errors,
            [payload.path]: payload.message,
          },
        },
      };

    case actionTypes.REMOVE_FIELD_ERROR: {
      return {
        ...state,
        createDialog: {
          ...state.createDialog,
          errors: {
            ...state.createDialog.errors,
            [payload]: undefined,
          },
        },
      };
    }

    case actionTypes.RESET_FORM: {
      return {
        ...state,
        createDialog: {
          ...state.createDialog,
          cardholderName: '',
          sameAsResidential: true,
          billingAddress: {},
          errors: {},
        },
      };
    }

    case actionTypes.VERIFY: {
      const methods = [...state.methods];
      const index = methods.findIndex((m) => m.id === payload.id);
      methods.splice(index, 1, {
        ...payload,
        verifyProcessing: true,
      });

      return {
        ...state,
        methods,
      };
    }

    case actionTypes.VERIFY_SUCCESS: {
      const methods = [...state.methods];
      const index = methods.findIndex((m) => m.id === payload.id);
      methods.splice(index, 1, {
        ...payload,
        status: PaymentMethodStatus.verified,
        verifyProcessing: false,
        verifyError: false,
      });

      return {
        ...state,
        methods,
      };
    }

    case actionTypes.VERIFY_FAILED: {
      const methods = [...state.methods];
      const index = methods.findIndex((m) => m.id === payload.id);
      methods.splice(index, 1, {
        ...payload,
        verifyProcessing: false,
        verifyError: true,
      });

      return {
        ...state,
        methods,
      };
    }

    case actionTypes.VERIFY_TRIES_EXCEEDED: {
      const methods = [...state.methods];
      const index = methods.findIndex((m) => m.id === payload.id);
      methods.splice(index, 1, {
        ...payload,
        verifyProcessing: false,
        verifyError: false,
      });

      return {
        ...state,
        methods,
      };
    }

    default:
      return state;
  }
};
