/* eslint-disable no-param-reassign */
import { LicenceStatus } from '@evee/evee-ui.enums';
import { createSlice } from '@reduxjs/toolkit';

import initialState from './initialState';

export const licenceSlice = createSlice({
  name: 'profile/licence',
  initialState,
  reducers: {
    loadLicence: () => {
      // nothing to do
    },

    verifyLicence: (state) => {
      state.licenceLoading = true;
    },

    verifyLicenceSubmitted: (state, { payload }) => {
      state.licence.status = payload.status;
      state.licenceLoading = false;
    },

    verifyLicenceSuccess: (state, { payload }) => {
      state.licence.status = payload.status;
      state.licence.countryCode = payload.countryCode;
      state.licence.number = payload.number;
      state.licence.verifiedTill = payload.verifiedTill;
      state.licenceLoading = false;
    },

    verifyLicenceFailed: (state) => {
      state.licence.status = LicenceStatus.noLicence;
      state.licence.countryCode = '';
      state.licence.number = '';
      state.licence.verifiedTill = null;
      state.licenceLoading = false;
    },

    verifyLicenceError: (state) => {
      state.licenceLoading = false;
    },
  },
});

const { actions, reducer } = licenceSlice;

export const {
  loadLicence,
  verifyLicence,
  verifyLicenceSubmitted,
  verifyLicenceSuccess,
  verifyLicenceFailed,
  verifyLicenceError,
} = actions;

export default reducer;
