import PropTypes from 'prop-types';
import _ from 'lodash';
import { nanoid } from 'nanoid';

import { Box, FormControl, InputBase, InputLabel, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { apiService, storage } from '@evee/evee-ui.services';
import { AsyncAutocomplete } from '@evee/evee-ui.base';
import { CURRENT_LOCATION } from '@evee/evee-ui.utils';
import { LocationType } from '@evee/evee-ui.enums';

import Building from 'common/icons/Building';
import LocationArrowIcon from 'common/icons/LocationArrow';
import Plane from 'widgets/booking/LocationInput/DeliveryOptions/Icons/Plane';
import SearchIcon from 'common/icons/Search';
import useRegion from 'store/modules/app/hooks/useRegion';

import styles from './styles';

const SearchLocationInput = ({
  value,
  multiline,
  placeholder,
  className,
  classes,
  onChange,
  ...rest
}) => {
  const { region } = useRegion();

  const getDefaultOptions = () => {
    const geolocationAvailable = 'geolocation' in navigator;
    const current = geolocationAvailable ? CURRENT_LOCATION : undefined;
    const latestSearch = storage.latestSearch.get();

    const defaultLocations = latestSearch
      ? [current, { _id: nanoid(), icon: LocationType.search, ...JSON.parse(latestSearch) }]
      : [current];

    return _.compact(defaultLocations);
  };

  const getOptionIconJsx = (icon) => {
    switch (icon) {
      case LocationType.searchHistory:
        return <SearchIcon />;

      case LocationType.current:
        return <LocationArrowIcon />;

      case LocationType.airport:
        return <Plane />;

      case LocationType.country:
      case LocationType.city:
      case LocationType.suburb:
        return <Building />;

      default:
        return null;
    }
  };

  const renderLocationOptionJsx = (option) => (
    <Box className={classes.optionContainer}>
      {getOptionIconJsx(option.icon)}
      <Typography noWrap>{option.name}</Typography>
    </Box>
  );

  const renderLocationInputJsx = (params) => (
    <div ref={params.InputProps.ref}>
      <InputBase
        fullWidth
        placeholder={placeholder || region.name}
        inputProps={{ ...params.inputProps, style: { padding: 0 } }}
        style={{
          padding: '4px 0 0 0',
        }}
      />
    </div>
  );

  return (
    <FormControl className={className} {...rest}>
      <InputLabel shrink style={{ fontWeight: 500 }}>
        Location
      </InputLabel>
      <AsyncAutocomplete
        noOptionsText="No matching location found"
        captionText="Start typing to choose location"
        requestMethod={apiService.location.getSearchLocations}
        defaultOptions={getDefaultOptions()}
        displayField="name"
        value={value}
        renderOption={renderLocationOptionJsx}
        renderInput={renderLocationInputJsx}
        classes={{ paper: classes.paper, listbox: classes.listbox, option: classes.option }}
        onChange={onChange}
      />
    </FormControl>
  );
};

SearchLocationInput.propTypes = {
  value: PropTypes.object,
  multiline: PropTypes.bool,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  classes: PropTypes.shape({
    paper: PropTypes.string.isRequired,
    option: PropTypes.string.isRequired,
    optionContainer: PropTypes.string.isRequired,
    listbox: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func,
};

SearchLocationInput.defaultProps = {
  value: {},
  multiline: false,
  placeholder: '',
  className: '',
  onChange: () => {},
};

export default withStyles(styles)(SearchLocationInput);
