import { takeEvery } from 'redux-saga/effects';

import * as profileActionsTypes from 'store/modules/profile/actionTypes';

import { load } from './utils';

function* listenLoad() {
  yield takeEvery(profileActionsTypes.LOAD, load);
}

export default listenLoad;
