export default {
  editFields: {
    fullName: false,
    dateOfBirth: false,
    email: false,
    phone: false,
    address: false,
    licence: false,
    declaration: false,
  },
  profileCopy: {},
};
