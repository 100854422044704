/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import * as extrasActions from 'store/modules/extras';
import initialState from './initialState';

export const bookingConfirmationSlice = createSlice({
  name: 'bookingConfirmation',
  initialState,
  reducers: {
    load(state) {
      state.loading = true;
    },

    loadSuccess(state, { payload }) {
      state.booking = payload.booking;
      state.vehicle = payload.vehicle;
      state.loading = false;
    },

    loadFailed(state) {
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(extrasActions.addExtraSuccess.toString(), (state, { payload }) => {
      state.booking.extras = payload;
    });
  },
});

const { actions, reducer } = bookingConfirmationSlice;

export const { load, loadSuccess, loadFailed } = actions;

export default reducer;
