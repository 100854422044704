import { connect } from 'react-redux';

import {
  closeSignIn,
  facebookSignIn,
  googleSignIn,
  setSignInFieldValue,
  signIn,
} from 'store/modules/auth/signIn';

import * as signInSelectors from 'store/modules/auth/signIn/selectors';
import { showForgotPassword } from 'store/modules/auth/forgotPassword';
import { showSignUp } from 'store/modules/auth/signUp';

import SignIn from './SignIn';

const mapStateToProps = (state) => ({
  signIn: signInSelectors.getSignIn(state),
  loading: signInSelectors.getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  onSignUp: () => dispatch(showSignUp()),
  onSignIn: () => dispatch(signIn()),
  onClose: () => dispatch(closeSignIn()),
  onFacebookSignIn: () => dispatch(facebookSignIn()),
  onFieldChanged: (fieldName, fieldValue) =>
    dispatch(setSignInFieldValue({ fieldName, fieldValue })),
  onForgotPassword: () => dispatch(showForgotPassword()),
  onGoogleSignIn: () => dispatch(googleSignIn()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
