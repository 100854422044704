export const getStripeAccount = (state) => state.payouts.stripeAccount;
export const getBirthDate = (state) => state.payouts.birthDate;
export const getLoading = (state) => state.payouts.loading;
export const getAddPayoutOpen = (state) => state.payouts.addAccountDialog.open;
export const getDialogLoading = (state) => state.payouts.addAccountDialog.loading;
export const getBankAccounts = (state) => state.payouts.bankAccounts;
export const getHasBankAccounts = (state) => state?.payouts?.bankAccounts?.length > 0;
export const getIsCompleteUserProfile = (state) => state.payouts.isCompleteUserProfile;
export const getErrors = (state) => state.payouts.form.errors;

export const getAccountData = (state) => ({
  accountName: state.payouts.addAccountDialog.accountName,
  bsb: state.payouts.addAccountDialog.bsb,
  accountNumber: state.payouts.addAccountDialog.accountNumber,
});
