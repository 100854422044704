import { call, put } from 'redux-saga/effects';

import { apiService } from '@evee/evee-ui.services';

import * as appActions from 'store/modules/app/actions';
import * as profileActions from 'store/modules/profile/actions';

function urlBase64ToUint8Array(base64String) {
  var padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  var base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  var rawData = window.atob(base64);
  var outputArray = new Uint8Array(rawData.length);

  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

const requestPushNotificationsPermission = async () => {
  return new Promise(function (resolve, reject) {
    const permissionResult = Notification.requestPermission((result) => {
      resolve(result);
    });

    if (permissionResult) {
      permissionResult.then(resolve, reject);
    }
  });
};

const createSubscription = async (registration) => {
  let subscription;
  try {
    const subscribeOptions = {
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(process.env.REACT_APP_VAPID_PUBLIC),
    };

    subscription = await registration.pushManager.subscribe(subscribeOptions);
    await apiService.pushSubscription.saveSubscription(subscription);
  } catch (error) {
    await subscription.unsubscribe();
    throw new Error("Couldn't subscribe to push notifications");
  }
};

const deleteSubscription = async (registration) => {
  try {
    const subscription = await registration.pushManager.getSubscription();
    await apiService.pushSubscription.disconnectSubscription(subscription.endpoint);
    await subscription.unsubscribe();
  } catch (error) {
    throw new Error("Couldn't delete push subscription");
  }
};

export function* loadPushSubscription() {
  try {
    if (!navigator.serviceWorker) {
      return;
    }

    const registration = yield navigator.serviceWorker.getRegistration();
    if (!registration) {
      return;
    }

    if (!registration.pushManager) {
      return;
    }

    const subscription = yield registration.pushManager.getSubscription();

    if (subscription?.endpoint) {
      const result = yield call(
        apiService.pushSubscription.subscriptionConnected,
        subscription.endpoint,
      );

      if (!result.connected) {
        yield put(profileActions.setReceivePushNotifications(false));
        yield subscription.unsubscribe();
      } else if (Notification.permission === 'granted') {
        yield put(profileActions.setReceivePushNotifications(true));
      }

    } else {
      yield put(profileActions.setReceivePushNotifications(false));
    }
  } catch (error) {
    yield put(appActions.showError(error.message));
  }
}

export function* setPushSubscription({ payload: pushEnabled }) {
  try {
    yield put(appActions.setLoading(true));

    const permissionResult = yield call(requestPushNotificationsPermission);
    if (permissionResult !== 'granted') {
      throw new Error('You must grant permission to show notifications');
    }

    if (!navigator.serviceWorker) {
      throw new Error('Service worker is not available');
    }

    const registration = yield navigator.serviceWorker.getRegistration();

    if (pushEnabled) {
      const subscribed = yield registration.pushManager.getSubscription();
      if (subscribed) {
        return;
      }

      yield call(createSubscription, registration);
      yield put(profileActions.setReceivePushNotifications(true));
    } else {
      yield call(deleteSubscription, registration);
      yield put(profileActions.setReceivePushNotifications(false));
    }
  } catch (error) {
    yield put(appActions.showError(error.message));
  } finally {
    yield put(appActions.setLoading(false));
  }
}
