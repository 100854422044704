import { LicenceStatus } from '@evee/evee-ui.enums';

export default {
  licence: {
    status: LicenceStatus.noLicence,
    countryCode: '',
    number: '',
    verifiedTill: null,
  },
};
