const styles = (theme) => ({
  '@keyframes growingLine': {
    '0%': {
      width: '0',
    },
    '100%': {
      width: '50%',
    },
  },
  indicator: {
    height: '3px',
    animation: `$growingLine 5000ms ease-out infinite`,
  },
});

export default styles;
