import { takeEvery } from 'redux-saga/effects';

import * as actions from 'store/modules/bookingConfirmation';

import { load } from './utils';

function* listenLoad() {
  yield takeEvery(actions.load.type, load);
}

export default listenLoad;
