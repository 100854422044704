export default {
  selectedExtraPayment: '',
  extraPayments: [],
  form: {
    errors: {},
  },
  draft: {
    items: [],
    documents: [],
    fee: 0,
    total: 0,
    comments: '',
    open: false,
    loading: false,
  },
};
