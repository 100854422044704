export default {
  loading: false,
  accountInfo: {
    id: null,
    photo: '',
    about: '',
    averageRating: null,
    createdDate: null,
    fullName: '',
    vehicles: [],
    reviews: {
      asOwner: [],
      asRenter: [],
    },
  },
};
