/* eslint-disable no-param-reassign */
import { createAction, createSlice } from '@reduxjs/toolkit';

import initialState from './initialState';

export const openPayouts = createAction('paymentsAndPayoutsPage/openPayouts');

export const paymentsAndPayoutsPageSlice = createSlice({
  name: 'paymentsAndPayoutsPage',
  initialState,
  reducers: {
    setCurrentTab(state, { payload }) {
      state.currentTab = payload;
    },
  },
});

const { actions, reducer } = paymentsAndPayoutsPageSlice;

export const { setCurrentTab } = actions;

export default reducer;
