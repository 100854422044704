import * as actionTypes from './actionTypes';

export const showError = (message) => ({
  type: actionTypes.SHOW_ERROR,
  payload: message,
});

export const showRequestError = () => ({
  type: actionTypes.SHOW_ERROR,
  payload: 'Request was unsuccessful',
});

export const showSuccess = (message) => ({
  type: actionTypes.SHOW_SUCCESS,
  payload: message,
});

export const closeToast = () => ({
  type: actionTypes.CLOSE_TOAST,
});

export const setLoading = (loading) => ({
  type: actionTypes.SET_LOADING,
  payload: loading,
});

export const appInit = () => ({
  type: actionTypes.APP_INIT,
});

export const appInitComplete = () => ({
  type: actionTypes.APP_INIT_COMPLETE,
});

export const acceptCookies = () => ({
  type: actionTypes.ACCEPT_COOKIES,
});

export const setHeaderNotification = (payload) => ({
  type: actionTypes.SET_HEADER_NOTIFICATION,
  payload,
});

export const setRegion = (payload) => ({
  type: actionTypes.SET_REGION,
  payload,
});

export const setCurrency = (payload) => ({
  type: actionTypes.SET_CURRENCY,
  payload,
});

export const setNotificationCounts = (payload) => ({
  type: actionTypes.SET_NOTIFICATION_COUNTS,
  payload,
});

export const incrementNotificationCount = ({ type, count = 1 }) => ({
  type: actionTypes.INCREMENT_NOTIFICATION_COUNT,
  payload: { type, count },
});

export const decrementNotificationCount = ({ type, count = 1 }) => ({
  type: actionTypes.DECREMENT_NOTIFICATION_COUNT,
  payload: { type, count },
});
