import moment from 'moment';

export default {
  loading: false,
  earnedPoints: 0,
  requiredPoints: 10,
  rewardAmount: 200,
  expirationDate: moment().add(1, 'years').toISOString(),
  completedReferrals: 0,
};
