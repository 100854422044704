import * as actionTypes from './actionTypes';

export const load = () => ({
  type: actionTypes.LOAD,
});

export const loadSuccess = (methods) => ({
  type: actionTypes.LOAD_SUCCESS,
  payload: methods,
});

export const loadFailed = () => ({
  type: actionTypes.LOAD_FAILED,
});

export const setCreateDialogOpen = (opened) => ({
  type: actionTypes.SET_CREATE_DIALOG_OPEN,
  payload: opened,
});

export const create = (token) => ({
  type: actionTypes.CREATE,
  payload: token,
});

export const createSuccess = (method) => ({
  type: actionTypes.CREATE_SUCCESS,
  payload: method,
});

export const createFailed = () => ({
  type: actionTypes.CREATE_FAILED,
});

export const setDefault = (id) => ({
  type: actionTypes.SET_DEFAULT,
  payload: id,
});

export const setDefaultSuccess = () => ({
  type: actionTypes.SET_DEFAULT_SUCCESS,
});

export const setDefaultFailed = () => ({
  type: actionTypes.SET_DEFAULT_FAILED,
});

export const remove = (id) => ({
  type: actionTypes.REMOVE,
  payload: id,
});

export const removeSuccess = () => ({
  type: actionTypes.REMOVE_SUCCESS,
});

export const removeFailed = () => ({
  type: actionTypes.REMOVE_FAILED,
});

export const setCardholderName = (name) => ({
  type: actionTypes.SET_CARDHOLDER_NAME,
  payload: name,
});

export const setBillingAddress = (address) => ({
  type: actionTypes.SET_BILLING_ADDRESS,
  payload: address,
});

export const setSameAsResidential = (sameAsResidential) => ({
  type: actionTypes.SET_SAME_AS_RESIDENTIAL,
  payload: sameAsResidential,
});

export const setFieldError = (path, message) => ({
  type: actionTypes.SET_FIELD_ERROR,
  payload: {
    path,
    message,
  },
});

export const removeFieldError = (path) => ({
  type: actionTypes.REMOVE_FIELD_ERROR,
  payload: path,
});

export const resetForm = () => ({
  type: actionTypes.RESET_FORM,
});

export const verify = (paymentMethod) => ({
  type: actionTypes.VERIFY,
  payload: paymentMethod,
});

export const verifySuccess = (paymentMethod) => ({
  type: actionTypes.VERIFY_SUCCESS,
  payload: paymentMethod,
});

export const verifyFailed = (paymentMethod) => ({
  type: actionTypes.VERIFY_FAILED,
  payload: paymentMethod,
});

export const verifyTriesExceeded = (paymentMethod) => ({
  type: actionTypes.VERIFY_TRIES_EXCEEDED,
  payload: paymentMethod,
});
