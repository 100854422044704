const prefix = 'paymentMethods';

export const LOAD = `${prefix}/LOAD`;
export const LOAD_SUCCESS = `${prefix}/LOAD_SUCCESS`;
export const LOAD_FAILED = `${prefix}/LOAD_FAILED`;

export const CREATE = `${prefix}/CREATE`;
export const CREATE_SUCCESS = `${prefix}/CREATE_SUCCESS`;
export const CREATE_FAILED = `${prefix}/CREATE_FAILED`;

export const SET_CREATE_DIALOG_OPEN = `${prefix}/SET_CREATE_DIALOG_OPEN`;

export const SET_DEFAULT = `${prefix}/SET_DEFAULT`;
export const SET_DEFAULT_SUCCESS = `${prefix}/SET_DEFAULT_SUCCESS`;
export const SET_DEFAULT_FAILED = `${prefix}/SET_DEFAULT_FAILED`;

export const REMOVE = `${prefix}/REMOVE`;
export const REMOVE_SUCCESS = `${prefix}/REMOVE_SUCCESS`;
export const REMOVE_FAILED = `${prefix}/REMOVE_FAILED`;

export const SET_CARDHOLDER_NAME = `${prefix}/SET_CARDHOLDER_NAME`;
export const SET_BILLING_ADDRESS = `${prefix}/SET_BILLING_ADDRESS`;
export const SET_SAME_AS_RESIDENTIAL = `${prefix}/SET_SAME_AS_RESIDENTIAL`;

export const SET_FIELD_ERROR = `${prefix}/SET_FIELD_ERROR`;
export const REMOVE_FIELD_ERROR = `${prefix}/REMOVE_FIELD_ERROR`;
export const RESET_FORM = `${prefix}/RESET_FORM`;

export const VERIFY = `${prefix}/VERIFY`;
export const VERIFY_SUCCESS = `${prefix}/VERIFY_SUCCESS`;
export const VERIFY_FAILED = `${prefix}/VERIFY_FAILED`;
export const VERIFY_TRIES_EXCEEDED = `${prefix}/VERIFY_TRIES_EXCEEDED`;
