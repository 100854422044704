import { call, put, select } from 'redux-saga/effects';

import { apiService } from '@evee/evee-ui.services';

import * as appActions from 'store/modules/app/actions';
import * as appSelectors from 'store/modules/app/selectors';
import * as bookingConfirmationActions from 'store/modules/bookingConfirmation';

function* load({ payload: bookingId }) {
  try {
    const currency = yield select(appSelectors.getCurrency);
    const booking = yield call(apiService.booking.get, bookingId, currency.id);
    const vehicle = yield call(apiService.vehicle.getVehicle, booking.vehicle.id, currency.id);

    yield put(bookingConfirmationActions.loadSuccess({ booking, vehicle }));
    yield put(appActions.setLoading(false));
  } catch (error) {
    yield put(bookingConfirmationActions.loadFailed());
    yield put(appActions.showRequestError());
  }
}

export { load };
