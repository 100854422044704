import { useDispatch } from 'react-redux';

import * as singInActions from 'store/modules/auth/signIn';

export default function useAuthorizedRouteSignIn() {
  const dispatch = useDispatch();
  const onAuthorizedRouteSignIn = (path) => dispatch(singInActions.authorizedRouteSignIn(path));

  return onAuthorizedRouteSignIn;
}
