import { lighten } from '@material-ui/core/styles';

const styles = (theme) => ({
  paper: {
    boxShadow: 'none',
    borderRadius: theme.shape.borderRadiusMedium,
  },
  titleRoot: {
    padding: theme.spacing(3, 4),
  },
  contentRoot: {
    padding: theme.spacing(2, 4, 5, 4),
  },
  fullscreenContentRoot: {
    flex: '0 1 auto',
  },
  actionsRoot: {
    boxShadow: 'none',
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2, 4),
    backgroundColor: theme.palette.common.white,
  },
  actionsRootFullscreen: {
    borderRadius: '0 0 25px 25px',
  },
  paperFullScreen: {
    backgroundColor: lighten(theme.palette.primary.main, 0.35),
  },
  closeButton: {
    marginLeft: '-16px',
  },
  backButton: {
    marginLeft: '-16px',
  },
});

export default styles;
