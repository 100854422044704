import { NavLink } from 'react-router-dom';

import { Link } from '@evee/evee-ui.base';

import EveeProtectLink from 'widgets/layout/EveeProtectLink';
import { Faq } from 'widgets/home';

const RenterFaq = () => {
  const questions = [
    {
      question: 'Can I ask the host a question before making a booking?',
      answer:
        "If you want to contact a host, you'll have to submit a booking request first (this opens up a direct chat line where you can ask any questions you might have). You'll only be charged for the booking if the host confirms the trip.",
    },
    {
      question: 'This is my first time in an EV - what do I need to know?',
      answer:
        "When you pick up the car from the host, they'll go over all the main features with you. This handover is also a great opportunity to ask any questions you have about driving electric, e.g. 'how do I charge the car?'.",
    },
    {
      question: 'Can I get the car delivered?',
      answer:
        'Some hosts offer airport delivery (or delivery to a personal address) for an additional fee. Please check the vehicle listing page to see if the host delivers to your location.',
    },
    {
      question: 'Do I need to buy insurance?',
      answer: (
        <>
          All vehicles rented through evee have insurance included in the rental price, with
          variable damage liability depending on the vehicle type. You can choose to add on{' '}
          <EveeProtectLink /> to cover the damage liability if there is an accident, or for
          protection against things such as minor parking damage, wheel scrapes, and windscreen
          damage or replacements.
        </>
      ),
    },
    {
      question: 'What if I damage the car?',
      answer:
        "It is the renter's responsibility to report any damages to the host as soon as possible. evee will capture your refundable deposit for minor damages and charge the full damage liability amount for major accidents while we determine liability and repair costs. Any remaining balance will be refunded to you at the end of the assessment.",
    },
    {
      question: 'How do I add a driver to my booking?',
      answer: (
        <>
          All additional drivers must make an account on evee, upload their licence and complete our
          driver declaration via the{' '}
          <Link to="account/personal-info" component={NavLink} style={{ fontWeight: 'normal' }}>
            Personal info
          </Link>{' '}
          accounts page (otherwise they will not be covered by insurance).
        </>
      ),
    },
  ];

  return <Faq questions={questions} />;
};

RenterFaq.propTypes = {};

RenterFaq.defaultProps = {};

export default RenterFaq;
