import { mobileHeaderHeightPx } from 'common/constants/ui';

const styles = (theme) => ({
  container: {
    zIndex: '1240 !important',
  },
  listContainer: {
    margin: `${mobileHeaderHeightPx} 0 8px 0`,
  },
  itemsContainer: {
    padding: theme.spacing(1, 2),
  },
  divider: {
    margin: '8px auto',
    width: '98%',
  },
});

export default styles;
