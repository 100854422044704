import { createSelector } from 'reselect';

import { BOOKING_NOTICE_PERIOD, DEFAULT_VEHICLE_UTC_OFFSET } from '@evee/evee-ui.utils';
import { ChargingPlugType } from '@evee/evee-ui.enums';

import { getLocation, getTripDates } from '../booking/selectors';

export const getVehicleId = (state) => state.vehicle.vehicleDetails.id;
export const getVehicle = (state) => state.vehicle;
export const getVehicleDetails = (state) => state.vehicle.vehicleDetails;
export const getAllFeatures = (state) => state.vehicle.allFeatures;

export const getCapacity = (state) => ({
  people: state.vehicle.vehicleDetails.peopleCapacity,
  doors: state.vehicle.vehicleDetails.doors,
});

export const getSummary = (state) => ({
  make: state.vehicle.vehicleDetails.make,
  model: state.vehicle.vehicleDetails.model,
  price: state.vehicle.vehicleDetails.price,
  year: state.vehicle.vehicleDetails.year,
  badge: state.vehicle.vehicleDetails.badge,
  averageRating: state.vehicle.vehicleDetails.averageRating,
  tripsCount: state.vehicle.vehicleDetails.tripsCount,
  reviewsCount: state.vehicle.vehicleDetails.reviews.length,
});

export const getBookingData = createSelector(
  getTripDates,
  getLocation,
  getVehicleId,
  (tripDates, location, vehicleId) => ({
    delivery: location,
    from: tripDates.start,
    to: tripDates.end,
    vehicleId,
  }),
);

export const getDefaultLocation = createSelector(
  getVehicleDetails,
  (vehicle) => vehicle.deliveryAddress,
);

export const getAirportLocation = createSelector(
  getVehicleDetails,
  (vehicle) => vehicle.deliveryAirport,
);

export const getExtraLocations = createSelector(
  getVehicleDetails,
  (vehicle) => vehicle.extraLocations,
);

export const getLoading = (state) => state.vehicle.loading;

export const getDeliveryAvailable = createSelector(
  getVehicleDetails,
  (vehicle) => vehicle.deliveryAvailable,
);

export const getDelivery = createSelector(getVehicleDetails, (vehicle) => ({
  address: vehicle.deliveryAddress,
  radius: vehicle.deliveryRadius,
  price: vehicle.deliveryPrice,
}));

export const getRideLength = createSelector(getVehicleDetails, (details) => ({
  minRideLength: details.minRideLength || 0,
  maxRideLength: details.maxRideLength || 0,
}));

export const getRentalCoverage = createSelector(getVehicleDetails, (details) => ({
  insuranceType: details.insuranceType,
  minimumDrivingAge: details.minimumDrivingAge || 0,
  damageLiability: details.damageLiability || 0,
  commercialHost: details.commercialHost,
}));

export const getRentalConditions = createSelector(getVehicleDetails, (details) => ({
  minRideLength: details.minRideLength || 0,
  maxRideLength: details.maxRideLength || 0,
  deliveryAvailable: details.deliveryAvailable,
  radius: details.deliveryRadius,
  petFriendly: details.features.some((f) => f.icon === 'pet-friendly'),
  returnChargeLevel: details.returnChargeLevel || 0,
  commercialHost: details.commercialHost,
}));

export const getDailyMileage = createSelector(getVehicleDetails, (details) => ({
  distanceIncluded: details.distanceIncluded || 0,
  unlimitedMileage: details.unlimitedMileage,
  pricePerKm: details.pricePerKm || 0,
}));

export const getCharging = createSelector(getVehicleDetails, (details) => ({
  realDrivingRange: details.realDrivingRange,
  freeFastCharging: !!details.freeFastCharging,
  fastChargingProviders: details.fastChargingProviders || [],
  rapidPlugs: details.compatibleChargingPlugs.filter((p) => p.type === ChargingPlugType.rapid),
  standardPlugs: details.compatibleChargingPlugs.filter(
    (p) => p.type === ChargingPlugType.standard,
  ),
}));

export const getVehicleNoticePeriod = (state) =>
  state.vehicle.vehicleDetails.noticePeriod || BOOKING_NOTICE_PERIOD;

export const getTotals = (state) => state.vehicle.totals;

export const getTotalsLoading = (state) => state.vehicle.totals.loading;

export const getVehicleUtcOffset = createSelector(
  getDefaultLocation,
  (location) => location?.utcOffset ?? DEFAULT_VEHICLE_UTC_OFFSET,
);

export const getBookingTotals = createSelector(getTotals, getLocation, (totals, location) => ({
  total: totals.total + location.price,
  totalRental: totals.totalRental + location.price,
  discountPercent: totals.discountPercent,
  days: totals.days,
  bookingDaysPrices: totals.bookingDaysPrices,
}));
