import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useState } from 'react';

import { Box, Drawer, Typography } from '@material-ui/core';
import { ChevronLeft, Language } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

import { Currency, Region } from '@evee/evee-ui.enums';
import { Divider, IconButton, TabPanel } from '@evee/evee-ui.base';
import { customerHasOwnerRole } from '@evee/evee-ui.utils';

import { RegionItem } from 'widgets/home';
import useAuth from 'store/modules/auth/hooks/useAuth';
import useRegion from 'store/modules/app/hooks/useRegion';

import HostItems from './HostItems';
import RenterItems from './RenterItems';
import UnauthorizedItems from './UnauthorizedItems';
import styles from './styles';

const MobileMenu = ({ menuOpened, classes, onClose }) => {
  const { currentUser, signedIn, onSignIn, onSignUp, onSignOut } = useAuth();
  const { region } = useRegion();
  const [currentSlide, setCurrentSlide] = useState(0);
  const hasOwnerRole = customerHasOwnerRole(currentUser);

  const handleChangeSlide = (index) => {
    setCurrentSlide(index);
  };

  const selectRegionItems = (
    <>
      <IconButton
        icon={<ChevronLeft style={{ width: '32px', height: '32px' }} />}
        style={{ padding: '0', marginLeft: '-8px' }}
        onClick={() => setCurrentSlide(0)}
      />

      <Typography variant="h4" style={{ margin: '20px 0 34px 0px' }}>
        Select region
      </Typography>

      <RegionItem
        selected={region.id === Region.australia.id}
        region={Region.australia}
        currency={Currency.aud}
        onClick={() => {
          if (region.id !== Region.australia.id) {
            window.location.href = `https://${Region.australia.domain}`;
          }
        }}
      />

      <RegionItem
        selected={region.id === Region.newZealand.id}
        region={Region.newZealand}
        currency={Currency.nzd}
        onClick={() => {
          if (region.id !== Region.newZealand.id) {
            window.location.href = `https://${Region.newZealand.domain}`;
          }
        }}
      />
    </>
  );

  const regionSelectorJsx = (
    <>
      <Divider className={classes.divider} />

      <RegionItem
        expandIcon
        icon={<Language />}
        region={region}
        currency={Currency}
        onClick={() => {
          setCurrentSlide(1);
        }}
      />
    </>
  );

  const getAuthorisedItems = () =>
    hasOwnerRole ? (
      <>
        <HostItems customerId={currentUser.id} onSignOut={onSignOut} onClose={onClose} />
        {regionSelectorJsx}
      </>
    ) : (
      <>
        <RenterItems customerId={currentUser.id} onSignOut={onSignOut} onClose={onClose} />
        {regionSelectorJsx}
      </>
    );

  const items = signedIn ? (
    getAuthorisedItems()
  ) : (
    <>
      <UnauthorizedItems onSignUp={onSignUp} onSignIn={onSignIn} onClose={onClose} />
      {regionSelectorJsx}
    </>
  );

  return (
    <>
      <Drawer
        variant="temporary"
        anchor="top"
        open={menuOpened}
        className={classes.container}
        PaperProps={{
          style: { borderRadius: '0 0 25px 25px' },
        }}
        onClose={onClose}
      >
        <Box className={classes.listContainer}>
          <Divider />
          <SwipeableViews index={currentSlide} onChangeIndex={handleChangeSlide}>
            <TabPanel value={currentSlide} index={0} className={classes.itemsContainer}>
              {items}
            </TabPanel>

            <TabPanel value={currentSlide} index={1} className={classes.itemsContainer}>
              {selectRegionItems}
            </TabPanel>
          </SwipeableViews>
        </Box>
      </Drawer>
    </>
  );
};

MobileMenu.propTypes = {
  menuOpened: PropTypes.bool,
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
    listContainer: PropTypes.string.isRequired,
    itemsContainer: PropTypes.string.isRequired,
    divider: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func,
};

MobileMenu.defaultProps = {
  menuOpened: false,
  onClose: () => {},
};

export default withStyles(styles)(MobileMenu);
