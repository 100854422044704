import * as actionTypes from './actionTypes';
import initialState from './initialState';

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SHOW_ERROR:
      return {
        ...state,
        toast: {
          open: true,
          message: payload,
          type: 'error',
        },
      };

    case actionTypes.SHOW_SUCCESS:
      return {
        ...state,
        toast: {
          open: true,
          message: payload,
          type: 'success',
        },
      };

    case actionTypes.CLOSE_TOAST:
      return {
        ...state,
        toast: {
          ...state.toast,
          open: false,
        },
      };

    case actionTypes.SET_LOADING:
      return {
        ...state,
        isLoading: payload,
      };

    case actionTypes.APP_INIT_COMPLETE:
      return {
        ...state,
        isInitComplete: true,
      };

    case actionTypes.ACCEPT_COOKIES:
      return {
        ...state,
        cookiesAccepted: true,
      };

    case actionTypes.SET_HEADER_NOTIFICATION:
      return {
        ...state,
        headerNotification: payload,
      };

    case actionTypes.SET_REGION:
      return {
        ...state,
        region: payload,
      };

    case actionTypes.SET_CURRENCY:
      return {
        ...state,
        currency: payload,
      };

    case actionTypes.SET_NOTIFICATION_COUNTS:
      return {
        ...state,
        notificationCounts: { ...state.notificationCounts, ...payload },
      };

    case actionTypes.INCREMENT_NOTIFICATION_COUNT:
      const count = state.notificationCounts[payload.type] + payload.count;
      return {
        ...state,
        notificationCounts: { ...state.notificationCounts, [payload.type]: count },
      };

    case actionTypes.DECREMENT_NOTIFICATION_COUNT: {
      let count = state.notificationCounts[payload.type] - payload.count;
      count = count < 0 ? 0 : count;
      return {
        ...state,
        notificationCounts: { ...state.notificationCounts, [payload.type]: count },
      };
    }

    default:
      return state;
  }
};
