import { takeEvery } from 'redux-saga/effects';

import * as actionTypes from 'store/modules/listing/occupations';
import { rangeChanged } from './utils';

export default function* listenSetRange() {
  yield takeEvery(
    (action) =>
      action.type === actionTypes.setRange.toString() &&
      action.payload.startDate &&
      action.payload.endDate,
    rangeChanged,
  );
}
