import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import SignIn from './SignIn';
import SignUp from './SignUp';
import VerifyEmail from './VerifyEmail';

const AuthDialogs = () => {
  const theme = useTheme();
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down(900));

  return (
    <>
      <SignIn isMobile={isTabletOrMobile} />
      <SignUp isMobile={isTabletOrMobile} />
      <ForgotPassword isMobile={isTabletOrMobile} />
      <ResetPassword isMobile={isTabletOrMobile} />
      <VerifyEmail isMobile={isTabletOrMobile} />
    </>
  );
};

export default AuthDialogs;
