const styles = (theme) => ({
  paper: {},
  listbox: {
    maxHeight: '60vh',
  },
  option: {
    margin: theme.spacing(2, 0),
  },
  optionContainer: {
    display: 'flex',
    alignItems: 'center',
    '& >:first-child': {
      marginRight: theme.spacing(1),
    },
    width: '100%',
  },
});

export default styles;
