const styles = {
  prevButton: {
    position: 'absolute',
    top: '6px',
    left: '0px',
  },
  nextButton: {
    position: 'absolute',
    top: '6px',
    right: '0px',
  },
};

export default styles;
