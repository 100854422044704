import PropTypes from 'prop-types';

import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { Link } from '@evee/evee-ui.base';

import styles from './styles';

const FooterLink = ({ href, children, classes, ...rest }) => (
  <Link underline="hover" href={href} className={classes.link} {...rest}>
    <Typography variant="body1" color="textSecondary" style={{ fontSize: '13px' }}>
      {children}
    </Typography>
  </Link>
);

FooterLink.propTypes = {
  href: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.shape({
    link: PropTypes.string.isRequired,
  }).isRequired,
};

FooterLink.defaultProps = {
  href: '',
  children: null,
};

export default withStyles(styles)(FooterLink);
