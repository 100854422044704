import * as emailVerificationActions from 'store/modules/auth/verifyEmail';

import { takeEvery } from 'redux-saga/effects';
import { verifyEmail } from './utils';

function* listenVerifyEmail() {
  yield takeEvery(emailVerificationActions.verifyEmail.toString(), verifyEmail);
}

export default listenVerifyEmail;
