const styles = (theme) => ({
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxHeight: '350px',
    '&> :not(:last-child)': {
      marginBottom: '16px',
    },
  },
  description: {
    fontSize: '18px',
    color: theme.palette.text.primary,
  },
  titleRoot: {
    paddingBottom: '0px',
  },
});

export default styles;
