import { all, fork } from 'redux-saga/effects';

import listenFiltersDialogOpen from './listenFiltersDialogOpen';
import listenLoad from './listenLoad';
import listenResetEditFilters from './listenResetEditFilters';
import listenSearch from './listenSearch';
import listenSetDialogMakes from './listenSetDialogMakes';
import listenSetDialogModels from './listenSetDialogModels';
import listenSetLocation from './listenSetLocation';
import listenSetSelectedVehicle from './listenSetSelectedVehicle';

export default function* searchPageSaga() {
  yield all([
    fork(listenFiltersDialogOpen),
    fork(listenLoad),
    fork(listenResetEditFilters),
    fork(listenSearch),
    fork(listenSetDialogMakes),
    fork(listenSetDialogModels),
    fork(listenSetLocation),
    fork(listenSetSelectedVehicle),
  ]);
}
