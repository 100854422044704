import { Currency, Region } from '@evee/evee-ui.enums';

export default {
  isLoading: false,
  isInitComplete: false,
  cookiesAccepted: false,
  headerNotification: false,
  notificationCounts: {
    message: 0,
    rewards: 0,
  },
  region: Region.australia,
  currency: Currency.aud,
  toast: {
    open: false,
    message: '',
    type: '',
  },
};
