import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useState } from 'react';

import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined';
import { withStyles } from '@material-ui/core/styles';

import { Button, Menu } from '@evee/evee-ui.base';
import { Currency, Region } from '@evee/evee-ui.enums';

import RegionItem from './RegionItem';
import styles from './styles';

const RegionSelector = ({ value, color, isTransparent, className, classes, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const buttonClsx = clsx(classes.button, className, isTransparent && classes.transparentButton);

  const handleToggle = (event) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
    }

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        type="text"
        text={value.name}
        size="small"
        startIcon={<LanguageOutlinedIcon color={color} fontSize="large" />}
        color={color}
        className={buttonClsx}
        onClick={handleToggle}
        {...rest}
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        openDirection="bottom"
        classes={{ list: classes.container, paper: classes.paper }}
        onClose={handleClose}
      >
        <RegionItem
          selected={value.id === Region.australia.id}
          region={Region.australia}
          currency={Currency.aud}
          onClick={() => {
            if (value.id !== Region.australia.id) {
              window.location.href = `https://${Region.australia.domain}`;
            }
          }}
        />

        <RegionItem
          selected={value.id === Region.newZealand.id}
          region={Region.newZealand}
          currency={Currency.nzd}
          onClick={() => {
            if (value.id !== Region.newZealand.id) {
              window.location.href = `https://${Region.newZealand.domain}`;
            }
          }}
        />
      </Menu>
    </>
  );
};

RegionSelector.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  color: PropTypes.string,
  isTransparent: PropTypes.bool,
  className: PropTypes.string,
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
    paper: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
    transparentButton: PropTypes.string.isRequired,
  }).isRequired,
};

RegionSelector.defaultProps = {
  value: {},
  color: 'primary',
  isTransparent: false,
  className: '',
};

export default withStyles(styles)(RegionSelector);
