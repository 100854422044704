const styles = {
  label: {
    fontSize: '12px',
    marginRight: '5px',
    fontWeight: '500',
  },
  combobox: {
    width: '100px',
  },
};

export default styles;
