import React from 'react';
import { SvgIcon } from '@material-ui/core';

import theme from 'theme';

const Envelope = (props) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M15.8332 3.33325H4.1665C3.50346 3.33325 2.86758 3.59664 2.39874 4.06549C1.9299 4.53433 1.6665 5.17021 1.6665 5.83325V14.1666C1.6665 14.8296 1.9299 15.4655 2.39874 15.9344C2.86758 16.4032 3.50346 16.6666 4.1665 16.6666H15.8332C16.4962 16.6666 17.1321 16.4032 17.6009 15.9344C18.0698 15.4655 18.3332 14.8296 18.3332 14.1666V5.83325C18.3332 5.17021 18.0698 4.53433 17.6009 4.06549C17.1321 3.59664 16.4962 3.33325 15.8332 3.33325V3.33325ZM4.1665 4.99992H15.8332C16.0542 4.99992 16.2661 5.08772 16.4224 5.244C16.5787 5.40028 16.6665 5.61224 16.6665 5.83325L9.99984 9.89992L3.33317 5.83325C3.33317 5.61224 3.42097 5.40028 3.57725 5.244C3.73353 5.08772 3.94549 4.99992 4.1665 4.99992V4.99992ZM16.6665 14.1666C16.6665 14.3876 16.5787 14.5996 16.4224 14.7558C16.2661 14.9121 16.0542 14.9999 15.8332 14.9999H4.1665C3.94549 14.9999 3.73353 14.9121 3.57725 14.7558C3.42097 14.5996 3.33317 14.3876 3.33317 14.1666V7.73325L9.5665 11.5416C9.69319 11.6147 9.83689 11.6532 9.98317 11.6532C10.1295 11.6532 10.2732 11.6147 10.3998 11.5416L16.6665 7.73325V14.1666Z"
      fill={theme.palette.primary.main}
    />
  </SvgIcon>
);

Envelope.displayName = 'EnvelopeIcon';
Envelope.muiName = 'SvgIcon';

export default Envelope;
