const styles = () => ({
  container: {
    position: 'absolute',
    width: '100%',
    height: '48px',
    top: '-48px',
  },
  button: {
    zIndex: 100,
  },
});

export default styles;
