import * as actionTypes from './actionTypes';

export const load = (id) => ({
  type: actionTypes.LOAD,
  payload: id,
});

export const loadSuccess = (listing) => ({
  type: actionTypes.LOAD_SUCCESS,
  payload: listing,
});

export const loadDraftSuccess = (draft) => ({
  type: actionTypes.LOAD_DRAFT_SUCCESS,
  payload: draft,
});

export const loadFailed = () => ({
  type: actionTypes.LOAD_FAILED,
});

export const setFieldValue = (fieldName, fieldValue) => ({
  type: actionTypes.SET_FIELD_VALUE,
  payload: { fieldName, fieldValue },
});

export const save = () => ({
  type: actionTypes.SAVE,
});

export const saveSuccess = () => ({
  type: actionTypes.SAVE_SUCCESS,
});

export const saveFailed = (error) => ({
  type: actionTypes.SAVE_FAILED,
  payload: error,
});

export const remove = () => ({
  type: actionTypes.REMOVE,
});

export const hide = () => ({
  type: actionTypes.HIDE,
});

export const saveDraft = () => ({
  type: actionTypes.SAVE_DRAFT,
});

export const saveDraftSuccess = () => ({
  type: actionTypes.SAVE_DRAFT_SUCCESS,
});

export const saveDraftFailed = (error) => ({
  type: actionTypes.SAVE_DRAFT_FAILED,
  payload: error,
});

export const setDictionary = (name, value) => ({
  type: actionTypes.SET_DICTIONARY,
  payload: { name, value },
});

export const uploadPhotos = (photos) => ({
  type: actionTypes.UPLOAD_PHOTOS,
  payload: photos,
});

export const uploadPhotoSuccess = (tempKey, photo) => ({
  type: actionTypes.UPLOAD_PHOTO_SUCCESS,
  payload: { tempKey, photo },
});

export const uploadPhotoFailed = (key) => ({
  type: actionTypes.UPLOAD_PHOTO_FAILED,
  payload: key,
});

export const uploadDocument = (file) => ({
  type: actionTypes.UPLOAD_DOCUMENT,
  payload: file,
});

export const uploadDocumentSuccess = (file) => ({
  type: actionTypes.UPLOAD_DOCUMENT_SUCCESS,
  payload: file,
});

export const uploadDocumentFailed = (file) => ({
  type: actionTypes.UPLOAD_DOCUMENT_FAILED,
  payload: file,
});

export const removePhoto = (photo) => ({
  type: actionTypes.REMOVE_PHOTO,
  payload: photo,
});

export const removePhotoSuccess = (photo) => ({
  type: actionTypes.REMOVE_PHOTO_SUCCESS,
  payload: photo,
});

export const removePhotoFailed = (photo) => ({
  type: actionTypes.REMOVE_PHOTO_FAILED,
  payload: photo,
});

export const setErrors = (errors) => ({
  type: actionTypes.SET_ERRORS,
  payload: errors,
});

export const setFieldError = (path, message) => ({
  type: actionTypes.SET_FIELD_ERROR,
  payload: {
    path,
    message,
  },
});

export const removeFieldError = (path) => ({
  type: actionTypes.REMOVE_FIELD_ERROR,
  payload: path,
});

export const createExtraLocation = (location) => ({
  type: actionTypes.CREATE_EXTRA_LOCATION,
  payload: location,
});

export const setExtraLocation = (location) => ({
  type: actionTypes.SET_EXTRA_LOCATION,
  payload: location,
});

export const removeExtraLocation = (location) => ({
  type: actionTypes.REMOVE_EXTRA_LOCATION,
  payload: location,
});

export const setConditionField = (fieldName, fieldValue) => ({
  type: actionTypes.SET_CONDITION_FIELD,
  payload: { fieldName, fieldValue },
});

export const showAppointmentsDialog = (payload) => ({
  type: actionTypes.SET_APPOINTMENTS_DIALOG_OPEN,
  payload,
});

export const closeAppointmentsDialog = () => ({
  type: actionTypes.CLOSE_APPOINTMENTS_DIALOG,
});
