import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Card, CardActionArea, Link, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';

import { getQueryString } from 'saga/search/utils';

import styles from './styles';

const LocationCard = ({ location, tripDates, loading, classes }) => {
  const { name, thumbnail, vehiclesCount } = location;
  const query = getQueryString(location, { ...tripDates, flexibleDates: true });

  const cardJsx = (
    <Link to={`/search?${query}`} underline="none" component={NavLink}>
      <Card elevation={0}>
        <CardActionArea className={classes.actionArea}>
          <img src={thumbnail} className={classes.image} alt="" />
        </CardActionArea>
        <Typography variant="h5" className={classes.cityName}>
          {name}
        </Typography>
        <Typography variant="caption">
          {vehiclesCount} {vehiclesCount > 1 ? 'cars' : 'car'} available
        </Typography>
      </Card>
    </Link>
  );

  const skeletonJsx = <Skeleton variant="rect" width="95%" height="190px" />;

  return loading ? skeletonJsx : cardJsx;
};

LocationCard.propTypes = {
  location: PropTypes.object,
  tripDates: PropTypes.object,
  loading: PropTypes.bool,
  classes: PropTypes.shape({
    cityName: PropTypes.string.isRequired,
    actionArea: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  }).isRequired,
};

LocationCard.defaultProps = {
  location: {},
  tripDates: {},
  loading: false,
};

export default withStyles(styles)(LocationCard);
