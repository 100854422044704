import { takeEvery } from 'redux-saga/effects';

import * as actions from 'store/modules/bookingDetails';
import { loadBookingDetails } from './utils';

function* listenLoad() {
  yield takeEvery(actions.load.type, loadBookingDetails);
}

export default listenLoad;
