const prefix = 'phone';

export const LOAD = `${prefix}/LOAD`;
export const SET_PHONE_NUMBER = `${prefix}/SET_PHONE_NUMBER`;
export const SET_VERIFICATION_CODE = `${prefix}/SET_VERIFICATION_CODE`;

export const SEND_VERIFICATION_CODE = `${prefix}/SEND_VERIFICATION_CODE`;
export const SEND_VERIFICATION_CODE_SUCCESS = `${prefix}/SEND_VERIFICATION_CODE_SUCCESS`;
export const SEND_VERIFICATION_CODE_FAILED = `${prefix}/SEND_VERIFICATION_CODE_FAILED`;

export const CHECK_VERIFICATION_CODE = `${prefix}/CHECK_VERIFICATION_CODE`;
export const CHECK_VERIFICATION_CODE_SUCCESS = `${prefix}/CHECK_VERIFICATION_CODE_SUCCESS`;
export const CHECK_VERIFICATION_CODE_FAILED = `${prefix}/CHECK_VERIFICATION_CODE_FAILED`;

export const CLEAR_PHONE_NUMBER = `${prefix}/CLEAR_PHONE_NUMBER`;
export const RESET_PHONE = `${prefix}/RESET_PHONE`;
export const SET_ERROR = `${prefix}/SET_ERROR`;
