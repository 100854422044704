import { call, put, select } from 'redux-saga/effects';

import { ErrorMessage } from '@evee/evee-ui.enums';
import { apiService } from '@evee/evee-ui.services';

import * as appActions from 'store/modules/app/actions';
import * as verifyEmailActions from 'store/modules/auth/verifyEmail';
import * as verifyEmailSelectors from 'store/modules/auth/verifyEmail/selectors';
import { getEmail } from 'store/modules/profile/selectors';

const VERIFICATION_CODE_LENGTH = 4;

async function getCodeFromClipboard(data) {
  if (Number.isInteger(Number(data)) && data.length === VERIFICATION_CODE_LENGTH) {
    return Array.from(data);
  }

  return [];
}

export function* pasteCode({ payload }) {
  try {
    if (!payload) {
      return;
    }

    const code = yield call(getCodeFromClipboard, payload);

    if (code.length) {
      yield put(verifyEmailActions.setCode(code));
    }
  } catch (error) {
    yield put(appActions.showError(ErrorMessage.commonError));
  }
}

export function* sendEmailVerification() {
  try {
    yield call(apiService.customer.sendEmailVerification);
    yield put(verifyEmailActions.sendEmailVerificationSuccess());
    yield put(appActions.showSuccess('Email sent'));
  } catch (error) {
    yield put(verifyEmailActions.sendEmailVerificationFailed());
    yield put(appActions.showError(ErrorMessage.commonError));
  }
}

export function* showVerifyEmail() {
  const email = yield select(getEmail);
  if (!email) {
    return;
  }

  yield put(verifyEmailActions.setProfileEmail(email));
}

export function* verifyEmail() {
  try {
    const code = yield select(verifyEmailSelectors.getCode);
    yield call(apiService.customer.verifyEmail, code.join(''));
    yield put(verifyEmailActions.verifyEmailSuccess());
  } catch (error) {
    yield put(verifyEmailActions.verifyEmailFailed(error.message));
  }
}
