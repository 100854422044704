import PropTypes from 'prop-types';

import { Card, CardActionArea, Link, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';

import { LazyContent } from '@evee/evee-ui.base';

import styles from './styles';

const BlogPostCard = ({ post, loading, classes }) => {
  const cardJsx = (
    <Link href={post.url} target="_blank" underline="none">
      <Card elevation={0}>
        <CardActionArea className={classes.actionArea}>
          <LazyContent>
            <img src={post.thumbnail} className={classes.image} alt="" />
          </LazyContent>
        </CardActionArea>
        <Typography variant="h5" align="left" className={classes.title}>
          {post.title}
        </Typography>
      </Card>
    </Link>
  );

  const skeletonJsx = <Skeleton variant="rect" width="95%" height="190px" />;

  return loading ? skeletonJsx : cardJsx;
};

BlogPostCard.propTypes = {
  post: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
    thumbnail: PropTypes.string,
    publishedDate: PropTypes.string,
  }),
  loading: PropTypes.bool,
  classes: PropTypes.shape({
    actionArea: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  }).isRequired,
};

BlogPostCard.defaultProps = {
  post: {},
  loading: false,
};

export default withStyles(styles)(BlogPostCard);
