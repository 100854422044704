import { takeLatest } from 'redux-saga/effects';

import * as actionTypes from 'store/modules/hostAllBookings';

import { loadBookings } from './utils';

export default function* listenFiltersChange() {
  yield takeLatest(
    [
      actionTypes.setStatusFilter.toString(),
      actionTypes.setActiveFilterFieldValue.toString(),
    ],
    loadBookings,
  );
}
