import { call, put } from 'redux-saga/effects';

import { apiService } from '@evee/evee-ui.services';

import * as appActions from 'store/modules/app/actions';
import * as rewardsPageActions from 'store/modules/rewardsPage';
import { showError } from 'store/modules/app/actions';

import { NotificationType } from 'enumsTemp';

function* deleteNotification(id) {
  if (!id) {
    return;
  }

  try {
      yield call(apiService.notification.remove, id);
      yield put(appActions.decrementNotificationCount({ type: NotificationType.rewards }));
  } catch {
  }
}

export function* load() {
  try {
    const reward = yield call(apiService.customer.getRewards);
    yield put(rewardsPageActions.loadSuccess(reward));
    yield call(deleteNotification, reward.notificationId);
  } catch (error) {
    yield put(showError(error.message));
    yield put(rewardsPageActions.loadFailed());
  }
}
