const styles = (theme) => ({
  root: {
    '&$expanded': {
      margin: 0,
      '&:not(:last-child)': {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
      '&::before': {
        opacity: 1,
        backgroundColor: theme.palette.divider,
      },
    },
    '&::before': {
      backgroundColor: theme.palette.divider,
    },
    backgroundColor: theme.palette.background.default,
  },
  expanded: {},
});

export default styles;
