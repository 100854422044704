import { all, fork } from 'redux-saga/effects';

import listenChangeLocation from './listenChangeLocation';
import listenSearch from './listenSearch';
import listenSetQueryFilters from './listenSetQueryFilters';
import listenUpdateAirportSearch from './listenUpdateAirportSearch';

export default function* searchSaga() {
  yield all([
    fork(listenChangeLocation),
    fork(listenSearch),
    fork(listenSetQueryFilters),
    fork(listenUpdateAirportSearch),
  ]);
}
