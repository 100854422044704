export default {
  currentMonth: {
    year: new Date().getFullYear(),
    month: new Date().getMonth(),
  },
  range: {
    startDate: null,
    endDate: null,
  },
  occupiedRanges: [],
  loading: false,
};
