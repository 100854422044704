import { connect } from 'react-redux';

import { acceptCookies } from 'store/modules/app/actions';

import CookieNotification from './CookieNotification';

const mapStateToProps = (state) => ({
  cookiesAccepted: state.app.cookiesAccepted,
});

const mapDispatchToProps = (dispatch) => ({
  acceptCookies: () => dispatch(acceptCookies()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CookieNotification);
