export default {
  open: false,
  loading: false,
  form: {
    review: '',
    rating: 0,
    loading: false,
  },
  data: {
    reviews: [],
    waitingForReview: false,
  },
};
