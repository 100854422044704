import PropTypes from 'prop-types';

import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { Button } from '@evee/evee-ui.base';

import CheckIcon from './icons/Check';
import styles from './styles';

const VerifiedEmail = ({ isMobile, profileEmail, classes, onClose }) => (
  <>
    <Typography
      align="center"
      color="textSecondary"
      style={{ maxWidth: isMobile ? '100%' : '60%' }}
    >
      Your email address{' '}
      <Typography display="inline" style={{ fontWeight: 500 }}>
        {profileEmail}
      </Typography>{' '}
      was successfully verified.
    </Typography>

    <CheckIcon className={classes.checkIcon} />

    <Button fullWidth text="Continue" className={classes.actionButton} onClick={onClose} />
  </>
);

VerifiedEmail.propTypes = {
  profileEmail: PropTypes.string,
  isMobile: PropTypes.bool,
  classes: PropTypes.shape({
    actionButton: PropTypes.string.isRequired,
    checkIcon: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func,
};

VerifiedEmail.defaultProps = {
  profileEmail: '',
  isMobile: false,
  onClose: () => {},
};

export default withStyles(styles)(VerifiedEmail);
