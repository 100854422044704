import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useEffect } from 'react';

import { Box, Collapse, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import useDebouncedResizeObserver from 'common/hooks/useDebouncedResizeObserver';

import useRegion from 'store/modules/app/hooks/useRegion';

import CommonMetaTags from 'widgets/layout/CommonMetaTags';
import Footer from 'widgets/layout/Footer';
import HeaderNotification from 'widgets/layout/HeaderNotification';
import LinkTags from 'widgets/layout/LinkTags';
import SpecialOffer from 'widgets/dialogs/SpecialOffer';

import {
  CountryAcknowledgement,
  FeaturedIn,
  HomeHeader,
  ListYourCar,
  MobileListYourCar,
  SectionContainer,
  TopBlogPosts,
} from 'widgets/home';

import OnlyDesktop from 'widgets/layout/Visibility/OnlyDesktop';
import OnlyMobile from 'widgets/layout/Visibility/OnlyMobile';
import OnlyTabletAndDesktop from 'widgets/layout/Visibility/OnlyTabletAndDesktop';

import FeaturedLocations from './FeaturedLocations';
import FeaturedModels from './FeaturedModels';
import MobileSearch from './Search/MobileSearch';
import RenterFaq from './RenterFaq';
import Search from './Search';
import Subscribe from './Subscribe';
import TopHosts from './TopHosts';

import {
  getBackgroundImage,
  getCustomerEmail,
  getOrganizationStructuredDataJson,
  getResetPasswordToken,
  getSpecialOfferFromLocation,
} from './utils';

import AllServices from './AllServices';
import ServicesMenu from './ServicesMenu';
import Slogan from './Slogan';

const useStyles = makeStyles({
  rootDesktop: (props) => ({
    backgroundImage: `url(${props.bgImage})`,
    backgroundAttachment: 'fixed',
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
    [props.theme.breakpoints.down('md')]: {
      backgroundSize: 'contain',
      backgroundPosition: 'initial',
    },
  }),
  rootMobile: {
    backgroundImage: '#fff',
  },
  section: {
    paddingTop: '0px',
  },
  servicesSection: (props) => ({
    padding: '0px',
    backgroundColor: `${props.theme.palette.background.main}`,
  }),
  sloganSection: (props) => ({
    padding: props.theme.spacing(8, 0),
  }),
});

const HomePage = ({
  showSubscribe,
  location,
  onResetPasswordToken,
  onResetPassword,
  onSpecialOffer,
  onSignUp,
}) => {
  const theme = useTheme();
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { region } = useRegion();
  const { ref, width: bgWidth } = useDebouncedResizeObserver(10);
  const bgImage = getBackgroundImage(region);

  const classes = useStyles({ bgImage, bgWidth, theme });
  const root = isMobile ? classes.rootMobile : classes.rootDesktop;

  useEffect(() => {
    const processResetPassword = () => {
      const token = getResetPasswordToken(location);
      if (token) {
        onResetPasswordToken(token);
        onResetPassword();
      }
    };

    const processSpecialOffer = () => {
      const specialOffer = getSpecialOfferFromLocation(location);
      if (specialOffer) {
        onSpecialOffer(specialOffer);
      }
    };

    const processSignUp = () => {
      const email = getCustomerEmail(location);
      if (email) {
        onSignUp(email);
      }
    };

    processResetPassword();
    processSpecialOffer();
    processSignUp();
  }, [location, onSpecialOffer, onResetPassword, onResetPasswordToken]);

  return (
    <Box
      ref={ref}
      className={root}
      style={{
        backgroundPositionY: isTabletOrMobile || !bgWidth ? 0 : -Math.floor(0.42 * bgWidth - 504),
      }}
    >
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(getOrganizationStructuredDataJson())}
        </script>
      </Helmet>

      <LinkTags />

      <CommonMetaTags />

      {!isTabletOrMobile &&
        moment().isBetween('2024-03-26T12:00:00+11:00', '2024-05-09T22:00:00+10:00') && (
          <HeaderNotification />
        )}

      <HomeHeader initiallyTransparent />

      <OnlyMobile>
        <SectionContainer>
          <MobileSearch />
        </SectionContainer>
      </OnlyMobile>

      <OnlyTabletAndDesktop>
        <Search />

        <SectionContainer className={classes.servicesSection}>
          <ServicesMenu />
        </SectionContainer>

        <SectionContainer className={classes.sloganSection}>
          <Slogan />
        </SectionContainer>
      </OnlyTabletAndDesktop>

      <SectionContainer className={classes.section}>
        <FeaturedModels />
      </SectionContainer>

      <SectionContainer className={classes.section}>
        <AllServices />
      </SectionContainer>

      <SectionContainer className={classes.section}>
        <FeaturedLocations />
      </SectionContainer>

      <OnlyDesktop>
        <SectionContainer backgroundColor="background.main" style={{ padding: '32px 0px' }}>
          <FeaturedIn />
        </SectionContainer>
      </OnlyDesktop>

      <Collapse in={showSubscribe}>
        <SectionContainer>
          <Subscribe />
        </SectionContainer>
      </Collapse>

      <SectionContainer className={classes.section}>
        <TopHosts />
      </SectionContainer>

      <SectionContainer className={classes.section}>
        {isMobile ? <MobileListYourCar /> : <ListYourCar />}
      </SectionContainer>

      <SectionContainer className={classes.section}>
        <TopBlogPosts />
      </SectionContainer>

      <SectionContainer className={classes.section}>
        <RenterFaq />
      </SectionContainer>

      <CountryAcknowledgement />

      <SpecialOffer isMobile={isTabletOrMobile} />
      <Footer />
    </Box>
  );
};

HomePage.propTypes = {
  showSubscribe: PropTypes.bool,
  location: PropTypes.object,
  onResetPasswordToken: PropTypes.func,
  onResetPassword: PropTypes.func,
  onSpecialOffer: PropTypes.func,
  onSignUp: PropTypes.func.isRequired,
};

HomePage.defaultProps = {
  showSubscribe: false,
  location: {},
  onResetPasswordToken: () => {},
  onResetPassword: () => {},
  onSpecialOffer: () => {},
};

export default HomePage;
