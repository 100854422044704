import { getHomeUrl } from '@evee/evee-ui.utils';

import supportHrefs from 'common/constants/supportHrefs';
import useRegion from 'store/modules/app/hooks/useRegion';

import FooterColumn from '../utils/FooterColumn';
import FooterLink from '../utils/FooterLink';

const EveeColumn = () => {
  const { region } = useRegion();

  return (
    <FooterColumn title="evee">
      <FooterLink href={`${getHomeUrl(region)}/about`}>About</FooterLink>

      <FooterLink href={`${getHomeUrl(region)}/impact`}>Impact</FooterLink>

      <FooterLink href={`${getHomeUrl(region)}/terms`}>Terms</FooterLink>

      <FooterLink href={`${getHomeUrl(region)}/privacy`}>Privacy</FooterLink>

      <FooterLink href={supportHrefs[region.id].contactSupport} target="_blank">
        Contact us
      </FooterLink>

      <FooterLink href={`${getHomeUrl(region)}/feedback`}>Give feedback</FooterLink>
    </FooterColumn>
  );
};

export default EveeColumn;
