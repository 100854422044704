import { ExtraPaymentStatuses } from 'common/enums';

export const getDraftLoading = (state) => state.extraPayments.draft.loading;
export const getExtraPayments = (state) => state.extraPayments.extraPayments || [];
export const getSelectedExtraPayment = (state) => state.extraPayments.selectedExtraPayment;
export const getForm = (state) => state.extraPayments.form;
export const getLoading = (state) => state.extraPayments.extraPayments.some((exp) => exp.loading);

export const getHasWaitingExtraPayments = (state) =>
  state.extraPayments.extraPayments.some((exp) => exp.status === ExtraPaymentStatuses.waiting);

export const getDraft = (state) => state.extraPayments.draft || {};
export const getDraftId = (state) => state.extraPayments.draft.id;
