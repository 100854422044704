import { darken } from '@material-ui/core/styles';

const styles = (theme) => ({
  link: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: darken(theme.palette.background.main, 0.08),
    },
  },
  activeLink: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
});

export default styles;
