export default {
  reviews: {
    loading: false,
    items: [],
  },
  featuredLocations: {
    loading: false,
    items: [],
  },
  specialOffer: {
    title: '',
    description: '',
    open: false,
  },
  blogPosts: {
    loading: false,
    posts: [],
  },
  featuredModels: {
    loading: false,
    items: [],
  },
  topHosts: {
    loading: false,
    items: [],
  },
  topCities: {
    loading: false,
    items: [],
  },
  partners: {
    loading: false,
    items: [],
  },
};
