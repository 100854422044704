import { connect } from 'react-redux';

import * as homeSelectors from 'store/modules/home/selectors';
import { loadBlogPosts } from 'store/modules/home';

import TopBlogPosts from './TopBlogPosts';

const mapStateToProps = (state) => ({
  loading: homeSelectors.getBlogPostsLoading(state),
  blogPosts: homeSelectors.getBlogPosts(state),
});

const mapDispatchToProps = (dispatch) => ({
  onLoad: () => dispatch(loadBlogPosts()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TopBlogPosts);
