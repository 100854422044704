import { createRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { formatTripDate } from '@evee/evee-ui.utils';

import SearchCalendar from 'widgets/search/SearchCalendar';
import { headerHeightPx } from 'common/constants/ui';
import useSearchProps from 'store/modules/search/hooks/useSearchProps';

import SearchBar from './SearchBar';
import styles from './styles';

const Search = ({ classes }) => {
  const [tripDates, location, loading, onChangeLocation, onFieldChanged, onSearch] =
    useSearchProps();

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const ref = createRef();

  const calendarValue = {
    start: tripDates.from && moment(tripDates.from),
    end: tripDates.to && moment(tripDates.to),
  };

  const tripStart = formatTripDate(tripDates.from);
  const tripEnd = formatTripDate(tripDates.to);

  useEffect(() => {
    setAnchorEl(ref.current);
  }, [ref]);

  return (
    <Box display="flex" justifyContent="center">
      <Box
        display="flex"
        flexDirection="column"
        height={`calc(500px - ${headerHeightPx})`}
        alignItems="center"
      >
        <Box display="flex" flexDirection="column" alignItems="center" marginTop="56px">
          <SearchBar
            ref={ref}
            pickUpDate={tripStart}
            dropOffDate={tripEnd}
            location={location}
            flexibleDates={tripDates.flexible}
            loading={loading}
            onLocationChange={onChangeLocation}
            onWhenClick={() => {
              setOpen(true);
            }}
            onFlexibleDatesToggle={(v) => {
              onFieldChanged('flexibleDates', v);
            }}
            onSearch={onSearch}
          />
        </Box>
      </Box>
      <SearchCalendar
        value={calendarValue}
        open={open}
        anchorEl={anchorEl}
        PaperProps={{ style: { padding: '32px 62px 40px' } }}
        PopoverProps={{
          style: {
            marginTop: '8px',
          },
        }}
        onClose={() => {
          setOpen(false);
        }}
        onChange={(v) => {
          onFieldChanged('range', v);
        }}
      />
    </Box>
  );
};

Search.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

Search.defaultProps = {};

export default withStyles(styles)(Search);
