import { call, put, select, takeEvery } from 'redux-saga/effects';

import { getEarningCalculatorLocations } from '@evee/evee-ui.utils';

import * as actionTypes from 'store/modules/hostPage';
import * as appSelectors from 'store/modules/app/selectors';
import { showRequestError } from 'store/modules/app/actions';

function* load() {
  try {
    const region = yield select(appSelectors.getRegion);
    const earningCalculatorLocations = yield call(getEarningCalculatorLocations, region);
    yield put(actionTypes.setSearchLocations(earningCalculatorLocations));
  } catch (err) {
    showRequestError(err.message);
  }
}

export default function* listenLoad() {
  yield takeEvery(actionTypes.load, load);
}
