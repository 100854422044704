import { all, fork } from 'redux-saga/effects';

import listenCreate from './listenCreate';
import listenLoad from './listenLoad';
import listenRemove from './listenRemove';
import listenSelectRange from './listenSelectRange';
import listenUpdate from './listenUpdate';
import listenUpdateRange from './listenUpdateRange';

export default function* customPricesSaga() {
  yield all([
    fork(listenLoad),
    fork(listenCreate),
    fork(listenSelectRange),
    fork(listenUpdate),
    fork(listenRemove),
    fork(listenUpdateRange),
  ]);
}
