import { connect } from 'react-redux';

import * as appSelectors from 'store/modules/app/selectors';
import * as authSelectors from 'store/modules/auth/selectors';

import Footer from './Footer';

const mapStateToProps = (state) => ({
  region: appSelectors.getRegion(state),
  showSubscribe: authSelectors.getShowSubscribe(state),
});

export default connect(mapStateToProps, null)(Footer);
