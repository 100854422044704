import { call, put, select } from 'redux-saga/effects';
import { ValidationError } from 'yup';
import { loadStripe } from '@stripe/stripe-js';

import { Currency, ErrorMessage } from '@evee/evee-ui.enums';
import { apiService, history } from '@evee/evee-ui.services';
import { profile as profileModel } from '@evee/evee-ui.models';

import * as appActions from 'store/modules/app/actions';
import * as authActions from 'store/modules/auth';
import * as declarationActions from 'store/modules/profile/declaration';
import * as profileActions from 'store/modules/profile/actions';
import * as profileSelectors from 'store/modules/profile/selectors';
import { showError } from 'store/modules/app/actions';

export function* uploadPhoto({ payload }) {
  try {
    const result = yield call(apiService.customer.uploadAvatar, payload);
    yield put(profileActions.uploadPhotoSuccess(result));
  } catch (error) {
    yield put(profileActions.uploadPhotoFailed());
    yield put(showError('Could not upload photo'));
  }
}

export function* load() {
  try {
    yield put(appActions.setLoading(true));

    const user = yield call(apiService.customer.me);

    yield put(profileActions.setProfile(user));
    yield put(declarationActions.resetQuestionnaire());
    yield put(profileActions.loadPushSubscription());
    yield put(profileActions.loadSuccess());
  } catch (error) {
    yield put(profileActions.loadFailed());
    yield put(appActions.showRequestError());
  } finally {
    yield put(appActions.setLoading(false));
  }
}

export function* copy({ payload }) {
  try {
    yield put(appActions.showSuccess(payload));
  } catch (error) {
    yield put(showError(error.message));
  }
}

function* connectSocialAccount(method) {
  try {
    const updatedProfile = yield call(method);
    yield put(profileActions.setProfile(updatedProfile));
  } catch (error) {
    yield put(showError(error.message));
  }
}

export function* connectFacebook() {
  yield connectSocialAccount(apiService.customer.linkFacebookAccount);
}

export function* connectGoogle() {
  yield connectSocialAccount(apiService.customer.linkGoogleAccount);
}

export function* save() {
  try {
    const profileData = yield select(profileSelectors.getFormData);
    yield put(appActions.setLoading(true));

    yield profileModel.profileSchema.validate(profileData, {
      abortEarly: false,
      recursive: true,
    });

    yield call(apiService.customer.update, profileData);
    yield put(profileActions.saveSuccess());
    yield put(appActions.showSuccess('Account details saved successfully.'));
  } catch (err) {
    yield put(appActions.setLoading(false));
    if (err instanceof ValidationError) {
      const validationErrors = Object.assign(
        ...err.inner.map((e) => ({
          [e.path]: e.message,
        })),
      );
      yield put(profileActions.setErrors(validationErrors));
      yield put(appActions.showError(ErrorMessage.completeRequiredFields));
    } else {
      yield put(showError(err.message));
    }
  } finally {
    yield put(appActions.setLoading(false));
  }
}

export function* validateFieldValue({ payload }) {
  try {
    yield profileModel.profileSchema.fields[payload.fieldName].validate(payload.fieldValue);
    yield put(profileActions.removeFieldError(payload.fieldName));
  } catch (err) {
    if (err instanceof ValidationError) {
      yield put(profileActions.setFieldError(payload.fieldName, err.message));
    }
  }
}

export function* deactivateAccount() {
  try {
    const profile = yield select(profileSelectors.getProfile);
    const reason = yield select(profileSelectors.getDeactivateDialogReason);

    yield call(apiService.customer.deactivate, reason);

    yield put(authActions.signOut());
    history.push(
      `/account/deactivate/${profile.id}?&date=${JSON.stringify(
        new Date(),
      )}&joined=${JSON.stringify(profile.createdDate || new Date())}`,
    );
  } catch (err) {
    yield put(appActions.showError(err.message));
  } finally {
    yield put(profileActions.setDeactivateDialogOpen(false));
  }
}

export function* loadCustomerStripe({ payload }) {
  try {
    if (window.customerStripe) {
      return;
    }

    if (payload?.stripeCustomerAccounts?.length) {
      let publicKey = process.env.REACT_APP_STRIPE_TOKEN_AUD;

      if (payload.stripeCustomerAccounts[0].currency === Currency.nzd.id) {
        publicKey = process.env.REACT_APP_STRIPE_TOKEN_NZD;
      }

      window.customerStripe = yield call(loadStripe, publicKey);
      return;
    }

    window.customerStripe = window.stripe;
  } catch (error) {
    yield put(appActions.showError(ErrorMessage.commonError));
  }
}

export function* changeEmail({ payload: email }) {
  try {
    yield put(appActions.setLoading(true));

    yield call(apiService.customer.changeEmail, email);

    yield put(profileActions.removeFieldError('email'));
    yield put(profileActions.changeEmailSuccess());
    yield put(appActions.showSuccess('Email changed'));
  } catch (error) {
    yield put(
      profileActions.setErrors({
        email: error.message,
      }),
    );
    yield put(profileActions.changeEmailFailed(error.message));
  } finally {
    yield put(appActions.setLoading(false));
  }
}
