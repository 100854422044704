const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '1100px',
    margin: '0 auto',
  },
  title: {
    ...theme.typography.h1,
    fontWeight: 500,
  },
  titleSecondary: {
    ...theme.typography.h1,
    fontWeight: 500,
    color: theme.palette.secondary.main,
  },
});

export default styles;
