export default {
  open: false,
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  errorMessage: '',
  loading: false,
  reCaptchaV2: {},
  reCaptchaV3: {},
  errors: {},
};
