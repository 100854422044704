import PropTypes from 'prop-types';

import { Box, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { Divider } from '@evee/evee-ui.base';
import { Region } from '@evee/evee-ui.enums';

import useRegion from 'store/modules/app/hooks/useRegion';

import SectionContainer from '../utils/SectionContainer';
import styles from './styles';

const CountryAcknowledgement = ({ classes }) => {
  const { region } = useRegion();

  if (region.id === Region.newZealand.id) {
    return null;
  }

  return (
    <SectionContainer className={classes.sectionContainer}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Divider className={classes.divider} />
        <Typography align="center" variant="body2">
          evee acknowledges Traditional Owners of Country throughout Australia and recognises their
          continuing connection to lands, waters and communities. We pay our respects to Aboriginal
          and Torres Strait Islander cultures; and to Elders past and present. We acknowledge
          sovereignty was never ceded, and that this land which we live, work and travel on always
          was and always will be Aboriginal land.
        </Typography>
      </Box>
    </SectionContainer>
  );
};

CountryAcknowledgement.propTypes = {
  classes: PropTypes.shape({
    sectionContainer: PropTypes.string.isRequired,
    divider: PropTypes.string.isRequired,
  }).isRequired,
};

CountryAcknowledgement.defaultProps = {};

export default withStyles(styles)(CountryAcknowledgement);
