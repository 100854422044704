import { createSelector } from 'reselect';

export const getTotals = (state) => state.bookingChange.totals;
export const getRequest = (state) => state.bookingChange.request;
export const getMessage = (state) => state.bookingChange.message;
export const getLoading = (state) => state.bookingChange.loading;

export const getDialog = (state) => state.bookingChange.dialog;

export const getDialogOpen = createSelector(
  getDialog,
  (dialog) => dialog.open,
);

export const getDialogLoading = createSelector(
  getDialog,
  (dialog) => dialog.loading,
);
