import { connect } from 'react-redux';

import {
  closeForgotPassword,
  forgotPassword,
  setForgotPasswordEmail,
} from 'store/modules/auth/forgotPassword';

import * as forgotPasswordSelectors from 'store/modules/auth/forgotPassword/selectors';
import ForgotPassword from './ForgotPassword';

const mapStateToProps = (state) => ({
  forgotPassword: forgotPasswordSelectors.getForgotPassword(state),
  loading: forgotPasswordSelectors.getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  onEmailChanged: (email) => dispatch(setForgotPasswordEmail(email)),
  onClose: () => dispatch(closeForgotPassword()),
  onForgotPassword: () => dispatch(forgotPassword()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
