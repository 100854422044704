import { Suspense, useEffect } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
import { Router } from 'react-router-dom';
import { connect } from 'react-redux';

import { CssBaseline, Fade } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/core/styles';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Backdrop } from '@evee/evee-ui.base';
import { history } from '@evee/evee-ui.services';

import CookieNotification from 'widgets/layout/CookieNotification';
import ErrorFallback from 'widgets/layout/ErrorFallback';
import ScrollToTop from 'widgets/layout/ScrollToTop';
import Toast from 'widgets/layout/Toast';

import { appInit } from 'store/modules/app/actions';

import AuthDialogs from '../widgets/dialogs/AuthDialogs';
import RootRouter from '../pages/router';
import theme from '../theme';

const App = ({ onLoad, isInitComplete, isLoading }) => {
  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Fade mountOnEnter in={isInitComplete} timeout={500}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Suspense fallback={<Backdrop loading />}>
              <Router history={history}>
                <ScrollToTop>
                  <RootRouter />
                </ScrollToTop>
              </Router>
            </Suspense>
          </ErrorBoundary>
          <Toast />
          <AuthDialogs />
          <Backdrop loading={isLoading} />
          <CookieNotification />
        </MuiPickersUtilsProvider>
      </Fade>
      <Backdrop transparent={false} loading={!isInitComplete} />
    </ThemeProvider>
  );
};

App.propTypes = {
  onLoad: PropTypes.func.isRequired,
  isInitComplete: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ app }) => ({
  ...app,
});

const mapDispatchToProps = (dispatch) => ({
  onLoad: () => dispatch(appInit()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
