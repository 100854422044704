const styles = (theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    maxWidth: '300px',
    // margin: '0 auto',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
  },
  icon: {
    marginBottom: '20px',
    width: '48px',
    height: '48px',
  },
});

export default styles;
