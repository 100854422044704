import { createSelector } from 'reselect';

const getDeclaration = (state) => state.profile.declaration || {};

const getQuestionnaire = createSelector(
  getDeclaration,
  (declaration) => declaration.questionnaire || [],
);

const getDeclarationStatus = createSelector(getDeclaration, (declaration) => declaration.status);

export { getDeclaration, getDeclarationStatus, getQuestionnaire };
