import PropTypes from 'prop-types';
import React from 'react';

import { Box, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import { Button } from '@evee/evee-ui.base';

import Carousel from 'widgets/layout/Carousel';

const LandingCarousel = ({
  title,
  items,
  linkText,
  linkTarget,
  loading,
  renderCard,
  carouselProps,
}) => {
  const theme = useTheme();
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const cardsJsx = items.map((e, index) => (
    <React.Fragment key={index}>{renderCard(e)}</React.Fragment>
  ));

  const loadingCardsJsx = new Array(3).map((_, index) => (
    <React.Fragment key={index}>{renderCard({}, loading)}</React.Fragment>
  ));

  const cardsCarouselJsx = (
    <Box position="relative">
      <Carousel
        showArrows
        desktopItemsCount={4}
        tabletItemsCount={3}
        partialVisible={isTabletOrMobile}
        length={items.length}
        {...carouselProps}
      >
        {loading ? loadingCardsJsx : cardsJsx}
      </Carousel>
    </Box>
  );

  return (
    <Box display="flex" flexDirection="column">
      <Typography gutterBottom variant="h3" style={{ fontWeight: 500 }}>
        {title}
      </Typography>

      {cardsCarouselJsx}

      <Button
        text={linkText}
        href={linkTarget}
        color="primary"
        type="outlined"
        style={{ maxWidth: isMobile ? '100%' : '190px' }}
      />
    </Box>
  );
};

LandingCarousel.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
  linkText: PropTypes.string,
  linkTarget: PropTypes.string,
  loading: PropTypes.bool,
  renderCard: PropTypes.func,
  carouselProps: PropTypes.object,
};

LandingCarousel.defaultProps = {
  title: '',
  items: [],
  linkText: '',
  linkTarget: '',
  loading: false,
  renderCard: () => {},
  carouselProps: {},
};

export default LandingCarousel;
