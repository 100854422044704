import { connect } from 'react-redux';

import { closeSpecialOffer } from 'store/modules/home/index';

import SpecialOffer from './SpecialOffer';

const mapStateToProps = ({ home: { specialOffer } }) => ({
  ...specialOffer,
});

const mapDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(closeSpecialOffer()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SpecialOffer);
