import { connect } from 'react-redux';

import * as homeActions from 'store/modules/home';
import * as homeSelectors from 'store/modules/home/selectors';
import FeaturedModels from './FeaturedModels';

const mapStateToProps = (state) => ({
  featuredModels: homeSelectors.getFeaturedModels(state),
  loading: homeSelectors.getFeaturedModelsLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  onLoad: () => dispatch(homeActions.loadFeaturedModels()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeaturedModels);
