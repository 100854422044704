import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { MapOutlined } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

import { Divider } from '@evee/evee-ui.base';
import { getHomeUrl } from '@evee/evee-ui.utils';
import { history } from '@evee/evee-ui.services';

import BatteryIcon from 'common/icons/Battery';
import { NotificationType } from 'enumsTemp';
import supportHrefs from 'common/constants/supportHrefs';
import useRegion from 'store/modules/app/hooks/useRegion';

import Item from '../../utils/Item';
import NotificationsCount from '../../utils/NotificationsCount';

import {
  Bolt,
  Calendar,
  Car,
  Envelope,
  File,
  Gift,
  Headphones,
  Lightbulb,
  Notebooks,
  Settings,
  SignOut,
  User,
} from '../../icons';

import styles from '../styles';

const RenterItems = ({ customerId, classes, onSignOut, onClose }) => {
  const location = useLocation();
  const { region } = useRegion();

  return (
    <>
      <Item
        bold
        selected={location.pathname === '/account/trips'}
        label="Renting"
        icon={<Calendar />}
        onClick={() => {
          history.push('/account/trips');
          onClose();
        }}
      />

      <Item
        selected={location.pathname === '/account/messages'}
        label="Messages"
        icon={<Envelope />}
        endDecoration={<NotificationsCount type={NotificationType.message} />}
        onClick={() => {
          history.push('/account/messages');
          onClose();
        }}
      />

      <Divider className={classes.divider} />

      <Item
        selected={location.pathname === '/account/edit'}
        label="Account"
        icon={<Settings />}
        onClick={() => {
          history.push('/account/edit');
          onClose();
        }}
      />

      <Item
        selected={location.pathname === `/account/${customerId}`}
        label="Profile"
        icon={<User />}
        onClick={() => {
          history.push(`/account/${customerId}`);
          onClose();
        }}
      />

      <Item
        label="Rewards"
        icon={<Bolt />}
        endDecoration={<NotificationsCount type={NotificationType.rewards} />}
        selected={location.pathname === `/account/rewards`}
        onClick={() => {
          history.push('/account/rewards');
          onClose();
        }}
      />

      <Item
        selected={location.pathname === '/car-host'}
        label="Become a host"
        icon={<Car />}
        onClick={() => {
          history.push('/car-host');
          onClose();
        }}
      />

      <Divider className={classes.divider} />

      <Item
        label="All evee vehicles"
        selected={location.pathname.includes('/vehicles') || location.pathname.includes('/search')}
        icon={<MapOutlined />}
        onClick={() => {
          history.push('/vehicles');
          onClose();
        }}
      />

      <Item
        label="How evee works"
        icon={<Lightbulb />}
        onClick={() => {
          window.location.href = `${getHomeUrl(region)}/renting`;
        }}
      />

      <Item
        label="Insurance"
        icon={<File />}
        onClick={() => {
          window.location.href = `${getHomeUrl(region)}/insurance`;
        }}
      />

      <Item
        label="Charging guide"
        icon={<BatteryIcon />}
        onClick={() => {
          window.location.href = `${getHomeUrl(region)}/blog/your-evee-intro-guide-to-charging`;
        }}
      />

      <Item
        label="Gift cards"
        icon={<Gift />}
        onClick={() => {
          window.location.href = `${getHomeUrl(region)}/giftcards`;
        }}
      />

      <Divider className={classes.divider} />

      <Item
        label="Contact support"
        icon={<Headphones />}
        onClick={() => {
          window.location.href = supportHrefs[region.id].contactSupport;
        }}
      />

      <Item
        label="Help Centre"
        icon={<Notebooks />}
        onClick={() => {
          window.location.href = supportHrefs[region.id].helpCenter;
        }}
      />

      <Item label="Log out" icon={<SignOut />} onClick={onSignOut} />
    </>
  );
};

RenterItems.propTypes = {
  customerId: PropTypes.string,
  classes: PropTypes.shape({
    divider: PropTypes.string.isRequired,
  }).isRequired,
  onSignOut: PropTypes.func,
  onClose: PropTypes.func,
};

RenterItems.defaultProps = {
  customerId: '',
  onSignOut: () => {},
  onClose: () => {},
};

export default withStyles(styles)(RenterItems);
