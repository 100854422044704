import PropTypes from 'prop-types';
import React from 'react';
import { SvgIcon } from '@material-ui/core';
import theme from 'theme';

const Wall = ({ htmlColor, ...rest }) => (
  <SvgIcon viewBox="0 0 20 20" {...rest}>
    <g id="x-twitter 1">
      <path
        id="Vector"
        d="M15.2031 1.875H17.9609L11.9375 8.75781L19.0234 18.125H13.4766L9.12891 12.4453L4.16016 18.125H1.39844L7.83984 10.7617L1.04688 1.875H6.73437L10.6602 7.06641L15.2031 1.875ZM14.2344 16.4766H15.7617L5.90234 3.4375H4.26172L14.2344 16.4766Z"
        fill={htmlColor}
      />
    </g>
  </SvgIcon>
);
Wall.displayName = 'TwitterIcon';
Wall.muiName = 'SvgIcon';
Wall.propTypes = {
  htmlColor: PropTypes.string,
};
Wall.defaultProps = {
  htmlColor: theme.palette.primary.main,
};

export default Wall;
