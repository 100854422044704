import { connect } from 'react-redux';

import * as signUpSelectors from 'store/modules/auth/signUp/selectors';
import { facebookSignIn, googleSignIn, showSignIn } from 'store/modules/auth/signIn';

import {
  closeSignUp,
  resetReCaptchaV2,
  setSignUpFieldValue,
  signUp,
  verifyReCaptchaV2,
  verifyReCaptchaV3,
} from 'store/modules/auth/signUp';

import SignUp from './SignUp';

const mapStateToProps = (state) => ({
  reCaptchaV2: signUpSelectors.getReCaptchaV2(state),
  reCaptchaV3: signUpSelectors.getReCaptchaV3(state),
  loading: signUpSelectors.getLoading(state),
  signUp: signUpSelectors.getSignUp(state),
});

const mapDispatchToProps = (dispatch) => ({
  onSignIn: () => dispatch(showSignIn()),
  onSignUp: () => dispatch(signUp()),
  onFacebookSignUp: () => dispatch(facebookSignIn(true)),
  onGoogleSignUp: () => dispatch(googleSignIn(true)),
  onVerifyReCaptchaV2: (t) => dispatch(verifyReCaptchaV2(t)),
  onVerifyReCaptchaV3: (t) => dispatch(verifyReCaptchaV3(t)),
  onResetReCaptchaV2: () => dispatch(resetReCaptchaV2()),
  onFieldChanged: (fieldName, fieldValue) =>
    dispatch(setSignUpFieldValue({ fieldName, fieldValue })),
  onClose: () => dispatch(closeSignUp()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
