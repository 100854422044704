import PropTypes from 'prop-types';

import { Box, ListItemText, MenuItem } from '@material-ui/core';
import { useTheme, withStyles } from '@material-ui/core/styles';

import styles from './styles';

const Item = ({ label, bold, icon, endDecoration, classes, onClick, ...rest }) => {
  const theme = useTheme();

  return (
    <MenuItem disableGutters className={classes.menuItem} onClick={onClick} {...rest}>
      <Box className={classes.itemContainer}>
        {icon}
        <ListItemText
          primary={label}
          primaryTypographyProps={{
            variant: 'body2',
            style: {
              fontWeight: bold
                ? theme.typography.fontWeightMedium
                : theme.typography.fontWeightRegular,
            },
          }}
        />
      </Box>
      {endDecoration}
    </MenuItem>
  );
};

Item.propTypes = {
  label: PropTypes.string,
  bold: PropTypes.bool,
  icon: PropTypes.node,
  endDecoration: PropTypes.node,
  classes: PropTypes.shape({
    menuItem: PropTypes.string.isRequired,
    itemContainer: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func,
};

Item.defaultProps = {
  label: '',
  bold: false,
  icon: null,
  endDecoration: null,
  onClick: () => {},
};

export default withStyles(styles)(Item);
