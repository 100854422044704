import PropTypes from 'prop-types';

import { Box, Collapse, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

import { Button, IconButton } from '@evee/evee-ui.base';

import CountdownTimer from '../CountdownTimer';
import styles from './styles';

const HeaderNotification = ({ open, classes, onClose }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const closeIconJsx = <CloseIcon fontSize="small" className={classes.closeIcon} />;

  const getOfferText = () => {
    if (isMobile) {
      return (
        <>
          <b>Invest in evee!</b>
        </>
      );
    }

    return (
      <>
        <b>
          Own a share of evee! Express interest and get the chance to invest. Offer closes in
        </b>
      </>
    );
  };

  return (
    <Collapse in={open} className={classes.container}>
      <Grid container className={classes.gridContainer}>
        <Grid item xs={isMobile ? false : 1} />

        <Grid item xs>
          <Box display="flex" alignItems="center" justifyContent={isMobile ? 'left' : 'center'}>
            <Typography color="primary" style={{ padding: isMobile ? '16px' : '4px 16px' }}>
              {getOfferText()}
            </Typography>

            {!isMobile && (
              <CountdownTimer endDate="2024-05-09T22:00:00+10:00" className={classes.countdown} />
            )}

            <Button
              size={isMobile ? 'small' : 'medium'}
              color="primary"
              type="contained"
              text="Learn more"
              href="https://www.birchal.com/company/evee"
              target="_blank"
            />
          </Box>
        </Grid>

        <Grid item xs={isMobile ? 2 : 1}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton icon={closeIconJsx} onClick={() => onClose()} />
          </Box>
        </Grid>
      </Grid>
    </Collapse>
  );
};

HeaderNotification.propTypes = {
  open: PropTypes.bool,
  classes: PropTypes.shape({
    gridContainer: PropTypes.string.isRequired,
    container: PropTypes.string.isRequired,
    closeIcon: PropTypes.string.isRequired,
    countdown: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func,
};

HeaderNotification.defaultProps = {
  open: false,
  onClose: () => {},
};

export default withStyles(styles)(HeaderNotification);
