/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import initialState from './initialState';

export const rewardsPageSlice = createSlice({
  name: 'rewardsPage',
  initialState,
  reducers: {
    load(state) {
      state.loading = true;
    },

    loadFailed(state) {
      state.loading = false;
    },

    loadSuccess(state, { payload }) {
      const { earnedPoints, requiredPoints, rewardAmount, expirationDate, completedReferrals } =
        payload;

      state.loading = false;
      state.earnedPoints = earnedPoints;
      state.requiredPoints = requiredPoints;
      state.rewardAmount = rewardAmount;
      state.expirationDate = expirationDate;
      state.completedReferrals = completedReferrals;
    },
  },
});

const { actions, reducer } = rewardsPageSlice;

export const { load, loadFailed, loadSuccess } = actions;

export default reducer;
