import { takeEvery } from 'redux-saga/effects';

import * as basketActions from 'store/modules/basket';

import { validateFieldValue } from './utils';

function* validateMessage({ payload }) {
  if (payload.fieldName === 'message') {
    yield validateFieldValue({ payload });
  }
}

export default function* listenSetBasketField() {
  yield takeEvery(basketActions.setFieldValue.toString(), validateMessage);
}
