import { PhoneVerificationStatus } from '@evee/evee-ui.enums';

import { getDefaultPhoneCode } from '@evee/evee-ui.utils';

import * as actionTypes from './actionTypes';
import * as appActionsTypes from 'store/modules/app/actionTypes';

import initialState from './initialState';

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SET_PHONE_NUMBER:
      return {
        ...state,
        phoneNumber: payload.phoneNumber,
        status: payload.status || state.status,
      };

    case actionTypes.SET_VERIFICATION_CODE:
      return {
        ...state,
        verificationCode: payload,
      };

    case actionTypes.SEND_VERIFICATION_CODE:
    case actionTypes.CHECK_VERIFICATION_CODE:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.SEND_VERIFICATION_CODE_SUCCESS:
      return {
        ...state,
        status: PhoneVerificationStatus.waiting,
        loading: false,
      };

    case actionTypes.CHECK_VERIFICATION_CODE_SUCCESS:
      return {
        ...state,
        status: PhoneVerificationStatus.verified,
        loading: false,
      };

    case actionTypes.SEND_VERIFICATION_CODE_FAILED:
    case actionTypes.CHECK_VERIFICATION_CODE_FAILED:
      return {
        ...state,
        loading: false,
      };

    case actionTypes.RESET_PHONE:
      return {
        ...initialState,
      };

    case actionTypes.SET_ERROR:
      return {
        ...state,
        error: payload,
      };

    case appActionsTypes.SET_REGION: {
      return {
        ...state,
        phoneNumber: {
          ...state.phoneNumber,
          code: getDefaultPhoneCode(payload),
        },
      };
    }

    default:
      return state;
  }
};
