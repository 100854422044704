import PropTypes from 'prop-types';
import ReactMultiCarousel from 'react-multi-carousel';

import 'react-multi-carousel/lib/styles.css';

import { withStyles } from '@material-ui/core/styles';

import ButtonGroup from './ButtonGroup';
import getResponsiveSettings from './responsiveSettings';
import styles from './styles';

const Carousel = ({
  partialVisible,
  children,
  desktopItemsCount,
  tabletItemsCount,
  infinite,
  className,
  autoPlay,
  showArrows,
  length,
  defaultArrows,
  carouselRef,
  classes,
  ...rest
}) => (
  <ReactMultiCarousel
    renderButtonGroupOutside
    ref={carouselRef}
    customButtonGroup={
      showArrows ? <ButtonGroup classes={{ container: classes.buttonsContainer }} /> : null
    }
    className={className}
    responsive={getResponsiveSettings(desktopItemsCount, tabletItemsCount)}
    partialVisible={partialVisible}
    infinite={infinite}
    arrows={defaultArrows}
    autoPlay={autoPlay}
    autoPlaySpeed={15000}
    containerClass={classes.container}
    sliderClass={length <= 1 ? classes.singleItemSlider : ''}
    itemClass={classes.itemContainer}
    {...rest}
  >
    {children}
  </ReactMultiCarousel>
);

Carousel.propTypes = {
  partialVisible: PropTypes.bool,
  className: PropTypes.string,
  infinite: PropTypes.bool,
  autoPlay: PropTypes.bool,
  showArrows: PropTypes.bool,
  desktopItemsCount: PropTypes.number,
  tabletItemsCount: PropTypes.number,
  length: PropTypes.number,
  defaultArrows: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  carouselRef: PropTypes.func,
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
    buttonsContainer: PropTypes.string.isRequired,
    itemContainer: PropTypes.string.isRequired,
    singleItemSlider: PropTypes.string.isRequired,
  }).isRequired,
};

Carousel.defaultProps = {
  showArrows: false,
  partialVisible: false,
  infinite: false,
  autoPlay: false,
  desktopItemsCount: 1,
  tabletItemsCount: 1,
  length: 1,
  carouselRef: () => {},
  className: '',
  defaultArrows: false,
};

export default withStyles(styles)(Carousel);
