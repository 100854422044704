import PropTypes from 'prop-types';
import { useState } from 'react';

import { Box, Typography, useTheme, withStyles } from '@material-ui/core/';
import FacebookIcon from '@material-ui/icons/Facebook';

import { Button, CheckBox, PasswordInput, TextInput } from '@evee/evee-ui.base';

import GoogleIcon from 'common/icons/Google';

import Dialog from '../../CommonDialog';
import styles from './styles';

const SignIn = ({
  isMobile,
  loading,
  signIn,
  classes,
  onSignIn,
  onSignUp,
  onClose,
  onFacebookSignIn,
  onFieldChanged,
  onForgotPassword,
  onGoogleSignIn,
}) => {
  const theme = useTheme();
  const [formatError, setFormatError] = useState(null);
  const { email, password, open, errorMessage, rememberMe } = signIn;

  const actions = () => (
    <>
      <Typography className={classes.footerText}>Don&apos;t have an account yet?</Typography>
      <Button text="Sign Up" color="primary" type="outlined" onClick={onSignUp} />
    </>
  );

  const socialButtonClass = isMobile ? classes.mobileSocialButton : classes.desktopSocialButton;
  const emailError = !errorMessage?.includes('password') ? errorMessage : formatError || '';
  const passwordError = errorMessage?.includes('password') ? errorMessage : '';
  const canSignIn = email && password && !formatError;

  const onEnter = () => {
    if (!canSignIn) {
      return;
    }

    onSignIn();
  };

  return (
    <Dialog
      decorateBackground
      open={open}
      isMobile={isMobile}
      data-test-id="log-in-dialog"
      title="Log in"
      loading={loading}
      actions={actions}
      onClose={onClose}
    >
      <TextInput
        value={email}
        label="E-mail"
        style={{ marginBottom: '15px' }}
        helperText={emailError}
        error={!!emailError}
        format="email"
        name="email"
        onChange={(v) => onFieldChanged('email', v)}
        onEnter={onEnter}
        onFormatError={(e) => setFormatError(e)}
      />

      <PasswordInput
        value={password}
        label="Password"
        helperText={passwordError}
        error={!!passwordError}
        name="password"
        onChange={(v) => onFieldChanged('password', v)}
        onEnter={onEnter}
      />

      <Box
        style={{ margin: '8px 0' }}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <CheckBox
          checked={rememberMe}
          label="Remember me"
          onChange={(v) => onFieldChanged('rememberMe', v)}
        />
        <Button
          text="Forgot password?"
          type="text"
          color="primary"
          className={classes.forgotButton}
          onClick={onForgotPassword}
        />
      </Box>

      <Button text="Log in" disabled={!canSignIn} onClick={onSignIn} />

      <Box style={{ marginTop: '20px' }}>
        <Button
          text="Log in with Facebook"
          startIcon={<FacebookIcon className={classes.facebookIcon} />}
          className={socialButtonClass}
          style={{
            color: theme.palette.common.white,
            backgroundColor: theme.palette.common.navyBlue,
            borderRadius: !isMobile
              ? `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`
              : '',
          }}
          onClick={onFacebookSignIn}
        />
        <Button
          color="primary"
          text="Log in with Google"
          type="outlined"
          startIcon={<GoogleIcon />}
          className={socialButtonClass}
          style={{
            borderRadius: !isMobile
              ? `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`
              : '',
          }}
          onClick={onGoogleSignIn}
        />
      </Box>
    </Dialog>
  );
};

SignIn.propTypes = {
  signIn: PropTypes.object,
  isMobile: PropTypes.bool,
  loading: PropTypes.bool,
  classes: PropTypes.shape({
    forgotButton: PropTypes.string.isRequired,
    footerText: PropTypes.string.isRequired,
    mobileSocialButton: PropTypes.string.isRequired,
    desktopSocialButton: PropTypes.string.isRequired,
    facebookIcon: PropTypes.string.isRequired,
  }).isRequired,
  onSignUp: PropTypes.func,
  onSignIn: PropTypes.func,
  onClose: PropTypes.func,
  onFacebookSignIn: PropTypes.func,
  onFieldChanged: PropTypes.func,
  onForgotPassword: PropTypes.func,
  onGoogleSignIn: PropTypes.func,
};

SignIn.defaultProps = {
  signIn: {},
  isMobile: false,
  loading: false,
  onSignUp: () => {},
  onSignIn: () => {},
  onClose: () => {},
  onFacebookSignIn: () => {},
  onFieldChanged: () => {},
  onForgotPassword: () => {},
  onGoogleSignIn: () => {},
};

export default withStyles(styles)(SignIn);
