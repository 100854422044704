import { connect } from 'react-redux';

import * as verifyEmailActions from 'store/modules/auth/verifyEmail';
import * as verifyEmailSelectors from 'store/modules/auth/verifyEmail/selectors';

import ChangeEmail from './ChangeEmail';

const mapStateToProps = (state) => ({
  email: verifyEmailSelectors.getEmail(state),
});

const mapDispatchToProps = (dispatch) => ({
  onChangeEmail: (v) => dispatch(verifyEmailActions.setEmail(v)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeEmail);
