import { connect } from 'react-redux';

import * as appActions from 'store/modules/app/actions';
import { getRegion } from 'store/modules/app/selectors';

import RegionSelector from './RegionSelector';

const mapStateToProps = (state) => ({
  value: getRegion(state),
});

const mapDispatchToProps = (dispatch) => ({
  onChange: (v) => dispatch(appActions.setRegion(v)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegionSelector);
