import { nanoid } from 'nanoid';
import reduceReducers from 'reduce-reducers';

import * as actionTypes from './actionTypes';
import customPricesReducer from './customPrices';
import occupationsReducer from './occupations';

import initialState from './initialState';

export const PhotoStatus = {
  loading: 'loading',
  failed: 'failed',
};

const listingReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.LOAD_SUCCESS:
      return {
        ...state,
        vehicle: payload,
        isPublished: true,
        conditions: {
          ctp: true,
          nzta: true,
          cof: true,
          ownership: true,
        },
      };

    case actionTypes.LOAD_DRAFT_SUCCESS:
      return {
        ...state,
        vehicle: payload,
        isPublished: false,
        conditions: initialState.conditions,
      };

    case actionTypes.SAVE_DRAFT:
      return {
        ...state,
      };

    case actionTypes.SAVE_DRAFT_SUCCESS:
      return {
        ...state,
        changed: false,
        lastDraftSave: new Date(),
      };

    case actionTypes.SAVE_DRAFT_FAILED:
      return {
        ...state,
        changed: true,
      };

    case actionTypes.SET_FIELD_VALUE:
      return {
        ...state,
        changed: true,
        vehicle: { ...state.vehicle, [payload.fieldName]: payload.fieldValue },
      };

    case actionTypes.SET_DICTIONARY:
      return {
        ...state,
        dictionaries: { ...state.dictionaries, [payload.name]: payload.value },
      };

    case actionTypes.UPLOAD_PHOTOS:
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          photos: [
            ...state.vehicle.photos,
            ...payload.map((ph) => ({
              ...ph,
              status: PhotoStatus.loading,
            })),
          ],
        },
      };

    case actionTypes.UPLOAD_PHOTO_SUCCESS: {
      const { tempKey, photo } = payload;
      const photos = [...state.vehicle.photos];
      const index = photos.findIndex((ph) => ph.key === tempKey);
      photos.splice(index, 1, photo);

      return {
        ...state,
        changed: true,
        vehicle: {
          ...state.vehicle,
          photos,
        },
      };
    }

    case actionTypes.UPLOAD_PHOTO_FAILED: {
      const photos = [...state.vehicle.photos];
      const index = photos.findIndex((ph) => ph.key === payload);
      photos.splice(index, 1);

      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          photos,
        },
      };
    }

    case actionTypes.REMOVE_PHOTO: {
      const photos = [...state.vehicle.photos];
      const index = photos.findIndex((ph) => ph.key === payload);
      photos.splice(index, 1, {
        ...photos[index],
        status: PhotoStatus.loading,
      });

      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          photos,
        },
      };
    }

    case actionTypes.REMOVE_PHOTO_SUCCESS: {
      const photos = [...state.vehicle.photos];
      const index = photos.findIndex((ph) => ph.key === payload);
      photos.splice(index, 1);

      return {
        ...state,
        changed: true,
        vehicle: {
          ...state.vehicle,
          photos,
        },
      };
    }

    case actionTypes.REMOVE_PHOTO_FAILED: {
      const photos = [...state.vehicle.photos];
      const index = photos.findIndex((ph) => ph.key === payload);
      photos.splice(index, 1, {
        ...photos[index],
        status: PhotoStatus.failed,
      });

      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          photos,
        },
      };
    }
    case actionTypes.UPLOAD_DOCUMENT:
    case actionTypes.UPLOAD_DOCUMENT_FAILED: {
      return {
        ...state,
        documentLoading: true,
      };
    }

    case actionTypes.UPLOAD_DOCUMENT_SUCCESS: {
      return {
        ...state,
        changed: true,
        documentLoading: false,
        vehicle: {
          ...state.vehicle,
          documents: payload,
        },
      };
    }

    case actionTypes.SET_ERRORS: {
      return {
        ...state,
        form: {
          ...state.form,
          errors: payload,
        },
      };
    }

    case actionTypes.SET_FIELD_ERROR: {
      return {
        ...state,
        form: {
          ...state.form,
          errors: {
            ...state.form.errors,
            [payload.path]: payload.message,
          },
        },
      };
    }

    case actionTypes.REMOVE_FIELD_ERROR: {
      return {
        ...state,
        form: {
          ...state.form,
          errors: {
            ...state.form.errors,
            [payload]: undefined,
          },
        },
      };
    }

    case actionTypes.CREATE_EXTRA_LOCATION: {
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          extraLocations: [...state.vehicle.extraLocations, { id: nanoid(), description: '' }],
        },
      };
    }

    case actionTypes.SET_EXTRA_LOCATION: {
      const extraLocations = [...state.vehicle.extraLocations];
      const index = extraLocations.findIndex((l) => l.id === payload.id);
      extraLocations.splice(index, 1, payload);
      return {
        ...state,
        changed: true,
        vehicle: {
          ...state.vehicle,
          extraLocations: [...extraLocations],
        },
      };
    }

    case actionTypes.REMOVE_EXTRA_LOCATION: {
      const extraLocations = [...state.vehicle.extraLocations];
      const index = extraLocations.findIndex((l) => l.id === payload.id);
      extraLocations.splice(index, 1);
      return {
        ...state,
        changed: true,
        vehicle: {
          ...state.vehicle,
          extraLocations: [...extraLocations],
        },
      };
    }

    case actionTypes.SET_CONDITION_FIELD: {
      return {
        ...state,
        conditions: {
          ...state.conditions,
          [payload.fieldName]: payload.fieldValue,
        },
      };
    }

    case actionTypes.SET_APPOINTMENTS_DIALOG_OPEN: {
      return {
        ...state,
        appointmentsDialogOpen: payload,
      };
    }

    default:
      return state;
  }
};

export default reduceReducers(
  initialState,
  listingReducer,
  customPricesReducer,
  occupationsReducer,
);
