import React from 'react';
import { SvgIcon } from '@material-ui/core';

import theme from 'theme';

const Umbrella = ({ ...rest }) => (
  <SvgIcon htmlColor="#fff" viewBox="0 0 56 56" fill="none" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.4121 46.2505C20.4121 49.4208 22.9918 52 26.1616 52C29.3315 52 31.9112 49.4208 31.9112 46.2505V43.9788C31.9112 43.5229 31.5417 43.1529 31.0853 43.1529C30.6289 43.1529 30.2599 43.5229 30.2599 43.9788V46.2505C30.2599 48.5103 28.421 50.3487 26.1616 50.3487C23.9023 50.3487 22.0639 48.5098 22.0639 46.2505V27.9829C21.5064 27.9663 20.9554 27.9682 20.4121 27.9865V46.2505ZM20.4121 9.02946C20.6169 8.94106 20.8133 8.86455 20.9995 8.79883C21.3668 8.93 21.7215 9.07799 22.0639 9.24161V4.82588C22.0639 4.36947 21.6944 4 21.238 4C20.7816 4 20.4121 4.36947 20.4121 4.82588V9.02946Z"
      fill={theme.palette.primary.main}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0039 28.9616C18.5266 27.8344 22.9953 27.7574 27.4103 28.7308C22.5186 27.4748 17.7934 27.9364 14.0039 28.9616ZM30.6166 29.6256C30.7971 29.6866 30.9776 29.7494 31.158 29.814C34.9047 28.6843 38.6372 28.6758 42.3551 29.814C42.3551 18.3619 33.0846 9.07514 21.6387 9.04883C29.4175 12.3732 31.1524 23.5444 30.6166 29.6256ZM20.2765 9.08964C9.42051 9.76733 0.825195 18.7873 0.825195 29.814C4.23787 28.7632 7.67181 28.5964 11.1379 29.814C11.2245 29.7841 11.3111 29.7545 11.3976 29.7254C10.8612 15.7835 16.7308 10.6832 20.2765 9.08964Z"
      fill={theme.palette.secondary.main}
    />
    <path
      d="M42.3558 30.6411C42.275 30.6411 42.1938 30.6288 42.1139 30.6047C38.585 29.5242 34.9805 29.5247 31.3973 30.6061C31.2272 30.6572 31.0467 30.652 30.8804 30.5929C24.5393 28.3222 17.9871 28.3232 11.4079 30.5962C11.2307 30.6576 11.0403 30.6562 10.8641 30.5943C7.79676 29.5171 4.59197 29.5209 1.06874 30.6047C0.816907 30.6822 0.546179 30.6354 0.334984 30.4795C0.124261 30.3241 0 30.0779 0 29.8157C0 17.9103 9.68523 8.22461 21.5901 8.22461C33.4955 8.22461 43.1812 17.9103 43.1812 29.8157C43.1812 30.0775 43.057 30.3236 42.8467 30.4795C42.7031 30.5858 42.5306 30.6411 42.3558 30.6411ZM21.2382 27.2374C24.5889 27.2374 27.9104 27.8072 31.1818 28.9478C34.6276 27.9546 38.0932 27.88 41.5002 28.7224C40.9313 18.2335 32.2189 9.8759 21.5901 9.8759C10.9642 9.8759 2.25275 18.2316 1.68106 28.7177C5.02806 27.8701 8.13505 27.9457 11.1386 28.943C14.5186 27.8058 17.893 27.2374 21.2382 27.2374Z"
      fill={theme.palette.primary.main}
    />
    <path
      d="M11.3401 29.7649C10.8898 29.7649 10.5213 29.4025 10.5151 28.9508C10.4584 24.905 10.9994 11.4603 20.3464 8.29568C20.7754 8.14968 21.2469 8.38119 21.3929 8.81303C21.5389 9.24535 21.3074 9.71404 20.8755 9.86004C12.585 12.667 12.1135 25.1639 12.1664 28.9272C12.1726 29.3831 11.8083 29.7583 11.3524 29.7649C11.3481 29.7649 11.3443 29.7649 11.3401 29.7649Z"
      fill={theme.palette.primary.main}
    />
    <path
      d="M30.7055 29.8832C30.7003 29.8832 30.6956 29.8832 30.6908 29.8832C30.2354 29.8751 29.8716 29.499 29.8796 29.0431C29.9462 25.2572 29.5144 12.6847 21.1714 9.86022C20.7396 9.71422 20.5081 9.24553 20.6541 8.81322C20.8005 8.38138 21.2683 8.14939 21.7006 8.29586C31.1042 11.4794 31.6018 25.003 31.5309 29.0719C31.5229 29.5231 31.1543 29.8832 30.7055 29.8832Z"
      fill={theme.palette.primary.main}
    />
    <path
      d="M37.7881 9.56029C38.1766 9.56029 38.4912 9.24535 38.4912 8.85717V5.69531C38.4912 5.30676 38.1766 4.99219 37.7881 4.99219C37.3999 4.99219 37.085 5.30676 37.085 5.69531V8.85717C37.085 9.24535 37.3999 9.56029 37.7881 9.56029Z"
      fill={theme.palette.primary.main}
    />
    <path
      d="M6.50586 11.6105C6.89441 11.6105 7.20898 11.2959 7.20898 10.9073V9.11914C7.20898 8.73059 6.89441 8.41602 6.50586 8.41602C6.11731 8.41602 5.80273 8.73059 5.80273 9.11914V10.9073C5.80273 11.2959 6.11767 11.6105 6.50586 11.6105Z"
      fill={theme.palette.primary.main}
    />
    <path
      d="M10.3027 16.7944C10.6913 16.7944 11.0059 16.4799 11.0059 16.0913V4.70312C11.0059 4.31457 10.6913 4 10.3027 4C9.91418 4 9.59961 4.31457 9.59961 4.70312V16.0913C9.59961 16.4799 9.91455 16.7944 10.3027 16.7944Z"
      fill={theme.palette.primary.main}
    />
    <path
      d="M34.2353 16.7952C34.6239 16.7952 34.9385 16.4807 34.9385 16.0921V9.5039C34.9385 9.11536 34.6239 8.80078 34.2353 8.80078C33.8468 8.80078 33.5322 9.11536 33.5322 9.5039V16.0921C33.5322 16.4807 33.8472 16.7952 34.2353 16.7952Z"
      fill={theme.palette.primary.main}
    />
    <path
      d="M28.0957 7.29919C28.4843 7.29919 28.7988 6.98461 28.7988 6.59606V4.70312C28.7988 4.31457 28.4843 4 28.0957 4C27.7072 4 27.3926 4.31457 27.3926 4.70312V6.59606C27.3926 6.98461 27.7072 7.29919 28.0957 7.29919Z"
      fill={theme.palette.primary.main}
    />
    <path
      d="M41.4922 16.2859C41.8807 16.2859 42.1953 15.9709 42.1953 15.5828V13.6895C42.1953 13.3013 41.8807 12.9863 41.4922 12.9863C41.104 12.9863 40.7891 13.3013 40.7891 13.6895V15.5828C40.7891 15.9709 41.104 16.2859 41.4922 16.2859Z"
      fill={theme.palette.primary.main}
    />
    <path
      d="M0.703123 16.2859C1.09167 16.2859 1.40625 15.9709 1.40625 15.5828V13.6895C1.40625 13.3013 1.09167 12.9863 0.703123 12.9863C0.314941 12.9863 0 13.3013 0 13.6895V15.5828C0 15.9709 0.314941 16.2859 0.703123 16.2859Z"
      fill={theme.palette.primary.main}
    />
  </SvgIcon>
);

Umbrella.displayName = 'UmbrellaIcon';
Umbrella.muiName = 'SvgIcon';

export default Umbrella;
