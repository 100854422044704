import { alpha, lighten } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    padding: theme.spacing(1),
    minWidth: '300px',
  },
  paper: {
    marginTop: '10px',
    boxShadow: '0px 0px 10px 1px rgba(0, 0, 0, 0.1), 0px 3px 14px 2px rgba(0, 0, 0, 0.06)',
  },
  button: {
    borderRadius: '25px',
  },
  transparentButton: {
    '&:hover': {
      backgroundColor: alpha(lighten(theme.palette.primary.main, 0.2), 0.4),
    },
  },
});

export default styles;
