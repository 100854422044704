import { NavLink } from 'react-router-dom';

import { getHomeUrl } from '@evee/evee-ui.utils';

import supportHrefs from 'common/constants/supportHrefs';
import useRegion from 'store/modules/app/hooks/useRegion';

import FooterColumn from '../utils/FooterColumn';
import FooterLink from '../utils/FooterLink';

const HostingColumn = () => {
  const { region } = useRegion();

  return (
    <FooterColumn title="Hosting">
      <FooterLink component={NavLink} to="/car-host">
        List your car
      </FooterLink>

      <FooterLink href={`${getHomeUrl(region)}/hosting/`}>How it works</FooterLink>

      <FooterLink href={`${getHomeUrl(region)}/insurance/`}>Insurance</FooterLink>

      <FooterLink href={`${getHomeUrl(region)}/pricing/`}>Pricing</FooterLink>

      <FooterLink href={supportHrefs[region.id].getHelpHosts}>FAQ&apos;s</FooterLink>
    </FooterColumn>
  );
};

export default HostingColumn;
