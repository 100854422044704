import PropTypes from 'prop-types';
import React from 'react';

import { Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { Button, Divider, Switch } from '@evee/evee-ui.base';

import SearchDateInput from 'widgets/search/SearchDateInput';
import SearchLocationInput from 'widgets/search/SearchLocationInput';

import styles from './styles';

const SearchBar = React.forwardRef(
  (
    {
      pickUpDate,
      dropOffDate,
      location,
      flexibleDates,
      loading,
      classes,
      onLocationChange,
      onWhenClick,
      onFlexibleDatesToggle,
      onSearch,
    },
    ref,
  ) => (
    <Paper ref={ref} className={classes.root} style={{ width: flexibleDates ? '650px' : '820px' }}>
      <SearchLocationInput
        value={location}
        placeholder="City, airport or suburb"
        className={classes.input}
        classes={{ paper: classes.paper }}
        onChange={onLocationChange}
      />

      {!flexibleDates && (
        <>
          <Divider className={classes.divider} orientation="vertical" />

          <SearchDateInput
            value={pickUpDate}
            label="Start date"
            className={classes.input}
            InputProps={{
              placeholder: 'Add dates',
            }}
            style={{ flex: '0 1 20%' }}
            onClick={onWhenClick}
          />

          <Divider className={classes.divider} orientation="vertical" />

          <SearchDateInput
            value={dropOffDate}
            label="End date"
            className={classes.input}
            InputProps={{
              placeholder: 'Add dates',
            }}
            style={{ flex: '1 1 20%' }}
            onClick={onWhenClick}
          />
        </>
      )}

      <Switch
        label="I'm flexible"
        variant="subtitle1"
        classes={{ container: classes.flexibleSwitch }}
        checked={flexibleDates}
        onChange={onFlexibleDatesToggle}
      />

      <Button
        text="Find cars"
        size="large"
        disabled={loading}
        className={classes.findButton}
        onClick={onSearch}
      />
    </Paper>
  ),
);

SearchBar.propTypes = {
  pickUpDate: PropTypes.string,
  dropOffDate: PropTypes.string,
  location: PropTypes.object,
  flexibleDates: PropTypes.bool,
  loading: PropTypes.bool,
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    input: PropTypes.string.isRequired,
    divider: PropTypes.string.isRequired,
    iconButton: PropTypes.string.isRequired,
    flexibleSwitch: PropTypes.string.isRequired,
    locationInput: PropTypes.string.isRequired,
    paper: PropTypes.string.isRequired,
  }).isRequired,
  onLocationChange: PropTypes.func,
  onWhenClick: PropTypes.func,
  onFlexibleDatesToggle: PropTypes.func,
  onSearch: PropTypes.func,
};

SearchBar.defaultProps = {
  pickUpDate: '',
  dropOffDate: '',
  location: {},
  flexibleDates: false,
  loading: false,
  onLocationChange: () => {},
  onWhenClick: () => {},
  onFlexibleDatesToggle: () => {},
  onSearch: () => {},
};

export default withStyles(styles)(SearchBar);
