import PropTypes from 'prop-types';
import { useState } from 'react';

import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { Button, TextInput } from '@evee/evee-ui.base';

import useEditEmail from 'store/modules/profile/hooks/useEditEmail';

import styles from './styles';

const ChangeEmail = ({ email, classes, onChangeEmail }) => {
  const { errors, onUpdateEmail } = useEditEmail();
  const [formatError, setFormatError] = useState(null);

  return (
    <>
      {' '}
      <Typography color="textSecondary" align="center" style={{ marginBottom: '24px' }}>
        Please enter a new email address to receive a verification code.
      </Typography>
      <TextInput
        value={email}
        label="Email"
        format="email"
        helperText={errors.email || formatError}
        error={!!errors.email || formatError}
        onFormatError={setFormatError}
        onChange={onChangeEmail}
      />
      <Button
        fullWidth
        disabled={!email || formatError}
        text="Send code"
        className={classes.actionButton}
        onClick={() => onUpdateEmail(email)}
      />
    </>
  );
};

ChangeEmail.propTypes = {
  email: PropTypes.string,
  classes: PropTypes.shape({
    actionButton: PropTypes.string.isRequired,
  }).isRequired,
  onChangeEmail: PropTypes.func,
};

ChangeEmail.defaultProps = {
  email: '',
  onChangeEmail: () => {},
};

export default withStyles(styles)(ChangeEmail);
