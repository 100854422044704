const styles = (theme) => ({
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius,
  },
  itemContainer: {
    display: 'flex',
    alignItems: 'center',
    '& :first-child': {
      marginRight: theme.spacing(2),
    },
  },
});

export default styles;
