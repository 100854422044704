const styles = (theme) => ({
  sectionContainer: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(4),
  },
  divider: {
    width: '10%',
    marginBottom: theme.spacing(4),
  },
});

export default styles;
