import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { AppBar, Box, Fade, Toolbar } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { Button } from '@evee/evee-ui.base';

import { RegionSelector } from 'widgets/home';
import supportHrefs from 'common/constants/supportHrefs';
import useScroll from 'common/hooks/useScroll';

import useAuth from 'store/modules/auth/hooks/useAuth';
import useListYourCar from 'store/modules/listYourCar/hooks/useListYourCar';
import useRegion from 'store/modules/app/hooks/useRegion';

import AccountMenu from '../../Menu/DesktopMenu/AccountMenu';
import DesktopLogo from '../../utils/DesktopLogo';
import HamburgerMenu from '../../utils/HamburgerMenu';
import MainMenu from '../../Menu/DesktopMenu/MainMenu';
import styles from './styles';

const DesktopHeader = ({ initiallyTransparent, hideOnScroll, classes }) => {
  const scrollY = useScroll();
  const { currentUser, signedIn } = useAuth();
  const [transparent, setTransparent] = useState(initiallyTransparent);
  const [visible, setVisible] = useState(true);
  const [onListYourCar] = useListYourCar();
  const { region } = useRegion();

  const mainColor = transparent ? 'inherit' : 'primary';
  const appBarClassName = transparent ? classes.transparentAppBar : classes.defaultAppBar;
  const buttonClsx = clsx(classes.button, transparent && classes.transparentButton);
  const listYourCarClsx = clsx(classes.listYourCar, transparent && classes.transparentListYourCar);
  const regionClsx = clsx(classes.region, transparent && classes.transparentRegion);

  useEffect(() => {
    if (hideOnScroll) {
      setVisible(scrollY === 0);
      return;
    }

    if (initiallyTransparent) {
      setTransparent(scrollY <= window.innerHeight);
    }
  }, [initiallyTransparent, hideOnScroll, scrollY]);

  return (
    <Fade in={visible} timeout={500}>
      <AppBar position="sticky" className={appBarClassName}>
        <Toolbar className={classes.toolbar}>
          <Box className={classes.headerBlockContainer}>
            <DesktopLogo isTransparent={transparent} />
          </Box>
          <Box className={classes.headerBlockContainer}>
            {currentUser && signedIn ? (
              <>
                <RegionSelector
                  color={mainColor}
                  isTransparent={transparent}
                  className={regionClsx}
                />

                <Button
                  text="List your car"
                  size="small"
                  className={listYourCarClsx}
                  onClick={onListYourCar}
                />

                <HamburgerMenu buttonProps={{ color: mainColor, transparent }} menu={AccountMenu} />
              </>
            ) : (
              <>
                <Button
                  type="text"
                  text="Help Centre"
                  size="small"
                  color={mainColor}
                  href={supportHrefs[region.id].helpCenter}
                  target="_blank"
                  className={buttonClsx}
                />

                <RegionSelector
                  color={mainColor}
                  isTransparent={transparent}
                  className={regionClsx}
                />

                <Button
                  text="List your car"
                  size="small"
                  className={listYourCarClsx}
                  onClick={onListYourCar}
                />

                <HamburgerMenu buttonProps={{ color: mainColor, transparent }} menu={MainMenu} />
              </>
            )}
          </Box>
        </Toolbar>
      </AppBar>
    </Fade>
  );
};

DesktopHeader.propTypes = {
  initiallyTransparent: PropTypes.bool,
  hideOnScroll: PropTypes.bool,
  classes: PropTypes.shape({
    defaultAppBar: PropTypes.string.isRequired,
    transparentAppBar: PropTypes.string.isRequired,
    toolbar: PropTypes.string.isRequired,
    headerBlockContainer: PropTypes.string.isRequired,
    listYourCar: PropTypes.string.isRequired,
    transparentListYourCar: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
    transparentButton: PropTypes.string.isRequired,
    region: PropTypes.string.isRequired,
    transparentRegion: PropTypes.string.isRequired,
  }).isRequired,
};

DesktopHeader.defaultProps = {
  initiallyTransparent: false,
  hideOnScroll: false,
};

export default withStyles(styles)(DesktopHeader);
