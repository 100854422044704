import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Box, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { Button } from '@evee/evee-ui.base';
import { formatPriceNumber } from '@evee/evee-ui.utils';

import useRegion from 'store/modules/app/hooks/useRegion';

import styles from './styles';

const ListYourCar = ({ classes }) => {
  const { currency } = useRegion();

  const titleJsx = (
    <>
      List your EV,
      <br />
      earn extra cash
    </>
  );

  const subtitleJsx = (
    <>
      Earn up to {formatPriceNumber(2000)} {currency.id}/month by sharing your car with others
    </>
  );

  return (
    <Box className={classes.container}>
      <Box className={classes.leftColumn}>
        <Box maxWidth="300px">
          <Typography paragraph className={classes.title}>
            {titleJsx}
          </Typography>

          <Typography paragraph className={classes.subtitle}>
            {subtitleJsx}
          </Typography>

          <Button
            text="Rent out your EV"
            size="large"
            color="primary"
            component={NavLink}
            to="/car-host"
            style={{ width: '180px' }}
          />
        </Box>
      </Box>
    </Box>
  );
};

ListYourCar.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
    leftColumn: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
  }).isRequired,
};

ListYourCar.defaultProps = {};

export default withStyles(styles)(ListYourCar);
