export default {
  loading: false,
  booking: {},
  ownerProfile: {},
  renterProfile: {},
  availableExtras: [],
  inviteDriverDialog: {
    open: false,
    loading: false,
    email: '',
  },
};
