import createSagaMiddleware from 'redux-saga';
import createSagaMonitor from '@clarketm/saga-monitor';

// configuration
const config = {
  level: 'log',
  effectTrigger: false,
  effectResolve: false,
  actionDispatch: true,
};

const sagaMiddleware = createSagaMiddleware(process.env.NODE_ENV === 'development' ? {
  sagaMonitor: createSagaMonitor(config),
} : {});

export default sagaMiddleware;
