export const getFeaturedLocations = (state) => state.home.featuredLocations.items;
export const getFeaturedLocationsLoading = (state) => state.home.featuredLocations.loading;

export const getFeaturedModels = (state) => state.home.featuredModels.items;
export const getFeaturedModelsLoading = (state) => state.home.featuredModels.loading;

export const getTopHosts = (state) => state.home.topHosts.items;
export const getTopHostsLoading = (state) => state.home.topHosts.loading;
export const getTopCites = (state) => state.home.topCities.items;

export const getBlogPosts = (state) => state.home.blogPosts.posts;
export const getBlogPostsLoading = (state) => state.home.blogPosts.loading;

export const getPartners = (state) => state.home.partners.items;
export const getPartnersLoading = (state) => state.home.partners.loading;
