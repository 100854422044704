const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius * 2,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '24px',
    height: '300px',
    color: theme.palette.common.white,
    [theme.breakpoints.down(300)]: {
      height: '400px',
    },
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: `0 0 ${theme.shape.borderRadius * 2}px ${theme.shape.borderRadius * 2}px`,
  },
  title: {
    ...theme.typography.h3,
    color: 'inherit',
    fontWeight: 500,
  },
  subtitle: {
    ...theme.typography.subtitle1,
    color: 'inherit',
    fontWeight: 300,
    maxWidth: '80%',
  },
});

export default styles;
