const styles = {
  input: {
    marginBottom: '15px',
  },
  button: {
    marginBottom: '20px',
  },
  contentContainer: {
    width: '450px',
  },
};

export default styles;
