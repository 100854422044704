import PropTypes from 'prop-types';

import { Box, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { Button, LinkButton } from '@evee/evee-ui.base';

import EmailCode from './EmailCode';
import styles from './styles';

const EnterCode = ({
  code,
  profileEmail,
  errorMessage,
  classes,
  onPasteCode,
  onSetCodeDigit,
  onSendEmailVerification,
  onEditEmail,
  onVerifyEmail,
}) => (
  <>
    <Typography paragraph color="textSecondary" align="center">
      Please check your inbox for the verification code sent to{' '}
      <Typography display="inline" color="textPrimary" style={{ fontWeight: 500 }}>
        {profileEmail}
      </Typography>
    </Typography>

    <EmailCode
      code={code}
      errorMessage={errorMessage}
      onPasteCode={onPasteCode}
      onChange={(index, value) => onSetCodeDigit(index, value)}
    />

    <Button
      fullWidth
      disabled={code.some((e) => e === '')}
      text="Verify"
      className={classes.actionButton}
      onClick={onVerifyEmail}
    />

    <Typography gutterBottom color="textSecondary" style={{ marginTop: '32px' }}>
      Can’t find it? Please check your spam folder.
    </Typography>

    <Box className={classes.actions}>
      <LinkButton disabled={!profileEmail} text="Resend email" onClick={onSendEmailVerification} />

      <Typography color="textSecondary" style={{ margin: '0 8px' }}>
        or
      </Typography>

      <LinkButton
        text="Change email"
        onClick={() => {
          onEditEmail(true);
        }}
      />
    </Box>
  </>
);

EnterCode.propTypes = {
  code: PropTypes.array,
  profileEmail: PropTypes.string,
  errorMessage: PropTypes.string,
  classes: PropTypes.shape({
    actions: PropTypes.string.isRequired,
    actionButton: PropTypes.string.isRequired,
  }).isRequired,
  onSendEmailVerification: PropTypes.func,
  onVerifyEmail: PropTypes.func,
  onEditEmail: PropTypes.func,
  onSetCodeDigit: PropTypes.func,
  onPasteCode: PropTypes.func,
};

EnterCode.defaultProps = {
  code: [],
  profileEmail: '',
  errorMessage: '',
  onSendEmailVerification: () => {},
  onVerifyEmail: () => {},
  onEditEmail: () => {},
  onSetCodeDigit: () => {},
  onPasteCode: () => {},
};

export default withStyles(styles)(EnterCode);
