import { takeEvery } from 'redux-saga/effects';

import * as vehicleActionsTypes from 'store/modules/vehicle/actionTypes';

import { loadVehicle } from './utils';

function* listenLoadVehicle() {
  yield takeEvery(vehicleActionsTypes.LOAD_VEHICLE, loadVehicle);
}

export default listenLoadVehicle;
