import { all, call, put, select } from 'redux-saga/effects';

import { apiService } from '@evee/evee-ui.services';

import * as appActions from 'store/modules/app/actions';
import * as bookingDetailsActions from 'store/modules/bookingDetails';
import * as bookingDetailsSelectors from 'store/modules/bookingDetails/selectors';
import * as bookingReviewsActions from 'store/modules/bookingReviews';
import * as bookingReviewsSelectors from 'store/modules/bookingReviews/selectors';

function* ensureBookingLoaded(bookingId) {
  try {
    let booking = yield select(bookingDetailsSelectors.getBooking);
    if (booking?.id === bookingId) {
      return;
    }

    booking = yield call(apiService.booking.get, bookingId);
    yield put(bookingDetailsActions.loadSuccess(booking));
  } catch (error) {
    yield put(bookingDetailsActions.loadFailed());
    yield put(appActions.showRequestError());
  }
}

function* loadBookingReviews({ payload: bookingId }) {
  try {
    const [reviews] = yield all([
      call(apiService.booking.getReviews, bookingId),
      call(ensureBookingLoaded, bookingId),
    ]);

    yield put(bookingReviewsActions.loadSuccess(reviews));
  } catch (error) {
    yield put(bookingReviewsActions.loadFailed());
    yield put(appActions.showRequestError());
  }
}

function* sendReview() {
  try {
    const [{ review, rating }, bookingId] = yield all([
      select(bookingReviewsSelectors.getForm),
      select(bookingDetailsSelectors.getBookingId),
    ]);

    if (!rating) {
      throw new Error('Please select a rating');
    }

    if (!review) {
      throw new Error('Please fill in the review field');
    }

    yield call(apiService.booking.createReview, bookingId, review, rating);

    yield put(bookingReviewsActions.sendReviewSuccess(bookingId));
    yield put(bookingReviewsActions.load(bookingId));
  } catch (error) {
    yield put(appActions.showError(error.message));
    yield put(bookingReviewsActions.sendReviewFailed());
  }
}

export { loadBookingReviews, sendReview };
