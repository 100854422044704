import { all, call, put, select } from 'redux-saga/effects';
import { ValidationError } from 'yup';

import * as appSelectors from 'store/modules/app/selectors';
import * as listingActions from 'store/modules/listing/actions';
import * as listingSelectors from 'store/modules/listing/selectors';
import * as profileSelectors from 'store/modules/profile/selectors';
import { setLoading, showError } from 'store/modules/app/actions';

import { ErrorMessage, Region, VehicleStatus } from '@evee/evee-ui.enums';
import { apiService, history } from '@evee/evee-ui.services';
import { customerHasOwnerRole, redirectIfError, stringCompare } from '@evee/evee-ui.utils';
import { listing as listingModel } from '@evee/evee-ui.models';

import { getPayoutIncreasementItems } from 'utilsTemp';

function* getListing(id) {
  if (id) {
    return yield call(apiService.listing.get, id);
  }

  const draft = yield call(apiService.listing.getLastDraft);
  if (draft && Object.keys(draft).length !== 0) {
    return draft;
  }

  return yield call(apiService.listing.create);
}

export function* loadListing(id) {
  try {
    yield put(setLoading(true));
    const listing = yield call(getListing, id);
    if (listing.status === VehicleStatus.draft) {
      yield put(listingActions.loadDraftSuccess(listing));
    } else {
      yield put(listingActions.loadSuccess(listing));
    }
  } catch (err) {
    yield put(listingActions.loadFailed(err.message));
    yield put(showError(err.message));
    redirectIfError(err);
  } finally {
    yield put(setLoading(false));
  }
}

export function* loadDictionaries() {
  try {
    // Values for this features are set through other controls
    const excludedFeatures = [
      'doors',
      'seats',
      'ride-length',
      'vehicle-range',
      'free-charging',
      'vehicle-delivery',
    ];

    const models = yield call(apiService.dictionary.getVehicleModels);
    const modelsPricing = yield call(apiService.dictionary.getVehicleModelsPricing);
    const chargingPlugs = apiService.dictionary.getChargingPlugs();
    const features = apiService.dictionary.getFeatures();

    yield put(
      listingActions.setDictionary(
        'vehicleModels',
        models.sort((a, b) => stringCompare(a.make.name, b.make.name)),
      ),
    );

    yield put(listingActions.setDictionary('vehicleModelsPricing', modelsPricing));

    yield put(
      listingActions.setDictionary(
        'chargingPlugs',
        chargingPlugs.sort((a, b) => stringCompare(a.name, b.name)),
      ),
    );

    yield put(
      listingActions.setDictionary(
        'featuresList',
        features
          .filter((v) => !excludedFeatures.includes(v.icon))
          .sort((a, b) => stringCompare(a.name, b.name)),
      ),
    );
  } catch (err) {
    yield put(showError(err.message));
  }
}

function* checkConditionsAccepted(region) {
  const conditions = yield select(listingSelectors.getConditions);

  const conditionsAccepted =
    region.id === Region.newZealand.id
      ? conditions.cof && conditions.ownership && conditions.nzta
      : conditions.ctp && conditions.ownership;

  if (!conditionsAccepted) {
    throw new Error('You must accept the listing conditions');
  }
}

export function* save() {
  try {
    yield put(setLoading(true));

    const [vehicle, region, profile] = yield all([
      select(listingSelectors.getVehicle),
      select(appSelectors.getRegion),
      select(profileSelectors.getProfile),
    ]);


    // Workaround to ignore price validation for dynamic pricing vehicles
    // TODO: move to the model validation after moving from bit.cloud
    const vehicleResult = {
      ...vehicle,
      price: vehicle.dynamicPricing
      ? 100
      : vehicle.price,
    };

    const hasOwnerRole = customerHasOwnerRole(profile);

    yield listingModel.listingSchema.validate(vehicleResult, {
      abortEarly: false,
      recursive: true,
      context: {
        region,
      },
    });

    yield call(checkConditionsAccepted, region);
    yield call(apiService.listing.publish, vehicleResult.id, vehicleResult);

    if (!hasOwnerRole) {
      yield put(setLoading(false));
      yield put(listingActions.showAppointmentsDialog(true));
    } else {
      history.push('/account/cars');
    }

    yield put(listingActions.saveSuccess());
  } catch (err) {
    yield put(setLoading(false));
    if (err instanceof ValidationError) {
      const validationErrors = {};
      err.inner.forEach((e) => {
        const fieldPath = e.path.startsWith('extraLocations')
          ? `deliveryAddress${e.params.originalValue.id}`
          : e.path;
        validationErrors[fieldPath] = e.message;
      });
      yield put(listingActions.setErrors(validationErrors));
      yield put(showError(ErrorMessage.completeRequiredFields));
    } else {
      yield put(showError(err.message));
    }
  }
}

export function* remove() {
  try {
    yield put(setLoading(true));
    const vehicle = yield select(listingSelectors.getVehicle);
    yield call(apiService.listing.remove, vehicle.id);
    history.push('/account/cars');
  } catch (err) {
    yield put(setLoading(false));
    yield put(showError(err.message));
  }
}

export function* hide() {
  try {
    yield put(setLoading(true));
    const vehicle = yield select(listingSelectors.getVehicle);
    yield call(apiService.listing.hide, vehicle.id);
    history.push('/account/cars');
  } catch (err) {
    yield put(setLoading(false));
    yield put(showError(err.message));
  }
}

export function* saveDraft() {
  try {
    const listing = yield select(listingSelectors.getListing);
    if (!listing.changed || listing.isPublished) {
      return;
    }
    yield call(apiService.listing.saveDraft, listing.vehicle.id, listing.vehicle);
    yield put(listingActions.saveDraftSuccess());
  } catch (err) {
    yield put(listingActions.saveDraftFailed(err.message));
  }
}

export function* onSetField({ payload }) {
  yield refreshPayoutValue({ payload });
  yield validateFieldValue({ payload });
}


const getVehicleFeeDiscount = (vehicle) => {
  if (!vehicle) {
    return 0;
  }

  return getPayoutIncreasementItems(vehicle).reduce((acc, { active, amount }) => {
    return active ? acc + amount : acc;
  }, 0);
};

export function* refreshPayoutValue({ payload }) {
  const { fieldName } = payload;
  if (fieldName === 'payoutPercent') {
    return;
  }

  const vehicle = yield select(listingSelectors.getVehicle);
  const { basePayoutPercent } = vehicle;
  const feeDiscounts = getVehicleFeeDiscount(vehicle);

  yield put(listingActions.setFieldValue('payoutPercent', basePayoutPercent + feeDiscounts));
}

export function* validateFieldValue({ payload }) {
  try {
    const region = yield select(appSelectors.getRegion);
    yield listingModel.listingSchema.fields[payload.fieldName].validate(payload.fieldValue, {
      context: { region },
    });
    yield put(listingActions.removeFieldError(payload.fieldName));
  } catch (err) {
    if (err instanceof ValidationError) {
      yield put(listingActions.setFieldError(payload.fieldName, err.message));
    }
  }
}

export function* validateExtraLocation({ payload }) {
  try {
    const region = yield select(appSelectors.getRegion);
    yield listingModel.addressSchema.validate(payload, { context: { region } });
    yield put(listingActions.removeFieldError(`deliveryAddress${payload.id}`));
  } catch (err) {
    if (err instanceof ValidationError) {
      yield put(listingActions.setFieldError(`deliveryAddress${payload.id}`, err.message));
    }
  }
}

export function* removeExtraLocation({ payload }) {
  yield put(listingActions.removeFieldError(`deliveryAddress${payload.id}`));
}

export function* closeAppointmentsDialog() {
  try {
    yield put(listingActions.showAppointmentsDialog(false));
    history.push('/account/cars');
  } catch (err) {
    yield put(showError(ErrorMessage.commonError));
  }
}
