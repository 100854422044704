import PropTypes from 'prop-types';
import _ from 'lodash';

import { Badge } from '@material-ui/core';

import useNotificationCounts from 'store/modules/app/hooks/useNotificationCounts';

const NotificationsCount = ({ hidden, children, type, ...rest }) => {
  const counts = useNotificationCounts();

  const getResultCounts = () => {
    if (!counts) {
      return 0;
    }

    if (type) {
      return counts[type] || 0;
    }

    return _.reduce(counts, (acc, count) => acc + count, 0);
  };

  const resultCounts = getResultCounts();

  return (
    <Badge
      invisible={hidden || !resultCounts}
      badgeContent={resultCounts}
      color="secondary"
      max={9}
      {...rest}
    >
      {children}
    </Badge>
  );
};

NotificationsCount.propTypes = {
  hidden: PropTypes.bool,
  children: PropTypes.node,
  type: PropTypes.string,
};

NotificationsCount.defaultProps = {
  hidden: false,
  children: null,
};

export default NotificationsCount;
