import { takeEvery } from 'redux-saga/effects';

import * as profileActionTypes from 'store/modules/profile/actionTypes';

import { validateFieldValue } from './utils';

function* validatePersonalInfo({ payload }) {
  if (payload.fieldName === 'dateOfBirth' || payload.fieldName === 'address') {
    yield validateFieldValue({ payload });
  }
}

export default function* listenSetProfileField() {
  yield takeEvery(profileActionTypes.SET_FIELD_VALUE, validatePersonalInfo);
}
