import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Avatar, Box, Card, CardActionArea, Link, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';

import { LazyContent, RatingDetails } from '@evee/evee-ui.base';

import styles from './styles';

const HostCard = ({ host, loading, classes }) => {
  const {
    hostThumbnail,
    hostNickname,
    averageRating,
    totalReviewsCount,
    latestReview,
    latestReviewDate,
    hostName,
    latestReviewer,
    id,
  } = host;

  const accountUrl = `/account/${id}`;
  const skeletonJsx = <Skeleton variant="rect" width="95%" height="288px" />;

  const cardJsx = (
    <Link to={accountUrl} underline="none" component={NavLink}>
      <Card elevation={0}>
        <CardActionArea className={classes.actionArea}>
          <Box display="flex" alignItems="center">
            <LazyContent>
              <Avatar className={classes.avatar} src={hostThumbnail}>
                {hostNickname}
              </Avatar>
            </LazyContent>

            <Box display="flex" flexDirection="column">
              <Box
                display="flex"
                flexDirection="column"
                alignItems="start"
                className={classes.ownerName}
              >
                <Typography
                  align="left"
                  variant="body1"
                  style={{ fontSize: '1rem', fontWeight: 'bold' }}
                >
                  {hostName}
                </Typography>

                {averageRating ? (
                  <RatingDetails readOnly value={averageRating} reviewsCount={totalReviewsCount} />
                ) : null}
              </Box>
            </Box>
          </Box>

          <Typography variant="body1" className={classes.review}>
            {latestReview}
          </Typography>

          <Typography variant="body1" component="div" style={{ minHeight: '20px' }}>
            <Typography display="inline">{latestReviewer}</Typography> {'\u2027'}{' '}
            {moment(latestReviewDate).format('MMM yyyy')}
          </Typography>
        </CardActionArea>
      </Card>
    </Link>
  );

  return loading ? skeletonJsx : cardJsx;
};

HostCard.propTypes = {
  host: PropTypes.object,
  loading: PropTypes.bool,
  classes: PropTypes.shape({
    actionArea: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired,
    ownerName: PropTypes.string.isRequired,
    review: PropTypes.string.isRequired,
  }).isRequired,
};
HostCard.defaultProps = {
  host: {},
  loading: false,
};

export default withStyles(styles)(HostCard);
