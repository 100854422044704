import { all, fork } from 'redux-saga/effects';

import listenAccept from './listenAccept';
import listenCancel from './listenCancel';
import listenCreate from './listenCreate';
import listenDecline from './listenDecline';
import listenDeleteFile from './listenDeleteFile';
import listenLoad from './listenLoad';
import listenResetDraft from './listenResetDraft';
import listenSetPayment from './validation/listenSetPayment';
import listenUploadFile from './listenUploadFile';

export default function* extraPaymentsSaga() {
  yield all([
    fork(listenAccept),
    fork(listenCancel),
    fork(listenCreate),
    fork(listenDecline),
    fork(listenDeleteFile),
    fork(listenLoad),
    fork(listenResetDraft),
    fork(listenSetPayment),
    fork(listenUploadFile),
  ]);
}
