import React from 'react';
import { SvgIcon } from '@material-ui/core';

const BatteryFull = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      d="M7 7L7 20C7 20.5304 7.21071 21.0391 7.58579 21.4142C7.96086 21.7893 8.46957 22 9 22L15 22C15.5304 22 16.0391 21.7893 16.4142 21.4142C16.7893 21.0391 17 20.5304 17 20L17 7C17 6.46957 16.7893 5.96086 16.4142 5.58579C16.0391 5.21071 15.5304 5 15 5L9 5C8.46957 5 7.96086 5.21071 7.58579 5.58579C7.21071 5.96086 7 6.46957 7 7ZM15 7L15 20L9 20L9 7L15 7ZM10 3C10 3.26522 10.1054 3.51957 10.2929 3.70711C10.4804 3.89464 10.7348 4 11 4L13 4C13.2652 4 13.5196 3.89464 13.7071 3.70711C13.8946 3.51957 14 3.26522 14 3C14 2.73478 13.8946 2.48043 13.7071 2.29289C13.5196 2.10536 13.2652 2 13 2L11 2C10.7348 2 10.4804 2.10536 10.2929 2.29289C10.1054 2.48043 10 2.73478 10 3Z"
    />
    <rect x="10" y="19" width="2" height="4" rx="1" transform="rotate(-90 10 19)" fill="#439167" />
    <rect x="10" y="16" width="2" height="4" rx="1" transform="rotate(-90 10 16)" fill="#439167" />
    <rect x="10" y="13" width="2" height="4" rx="1" transform="rotate(-90 10 13)" fill="#439167" />
    <rect x="10" y="10" width="2" height="4" rx="1" transform="rotate(-90 10 10)" fill="#439167" />
  </SvgIcon>
);

BatteryFull.displayName = 'BatteryFullIcon';
BatteryFull.muiName = 'SvgIcon';

export default BatteryFull;
