import { connect } from 'react-redux';

import {
  closeResetPassword,
  resetPassword,
  setResetPasswordFieldValue,
} from 'store/modules/auth/resetPassword';

import * as resetPasswordSelectors from 'store/modules/auth/resetPassword/selectors';
import ResetPassword from './ResetPassword';

const mapStateToProps = (state) => ({
  resetPassword: resetPasswordSelectors.getResetPassword(state),
  loading: resetPasswordSelectors.getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(closeResetPassword()),
  onFieldChanged: (fieldName, fieldValue) =>
    dispatch(setResetPasswordFieldValue({ fieldName, fieldValue })),
  onResetPassword: () => dispatch(resetPassword()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
