import React from 'react';
import { SvgIcon } from '@material-ui/core';

import theme from 'theme';

const Lightbulb = (props) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M14.2418 2.35002C13.4754 1.71967 12.5779 1.26837 11.6148 1.02905C10.6518 0.789732 9.64745 0.768424 8.67511 0.966683C7.38043 1.2282 6.1926 1.86841 5.26235 2.80608C4.3321 3.74376 3.70134 4.93663 3.45011 6.23335C3.26665 7.20562 3.29999 8.20639 3.54775 9.16429C3.79551 10.1222 4.25158 11.0136 4.88345 11.775C5.46985 12.4367 5.80598 13.2829 5.83345 14.1667V16.6667C5.83345 17.3297 6.09684 17.9656 6.56568 18.4345C7.03452 18.9033 7.67041 19.1667 8.33345 19.1667H11.6668C12.3298 19.1667 12.9657 18.9033 13.4345 18.4345C13.9034 17.9656 14.1668 17.3297 14.1668 16.6667V14.325C14.1947 13.3493 14.5532 12.412 15.1834 11.6667C16.2877 10.3007 16.8083 8.55403 16.6319 6.80637C16.4556 5.05871 15.5967 3.45126 14.2418 2.33335V2.35002ZM12.5001 16.6667C12.5001 16.8877 12.4123 17.0997 12.256 17.2559C12.0998 17.4122 11.8878 17.5 11.6668 17.5H8.33345C8.11243 17.5 7.90047 17.4122 7.74419 17.2559C7.58791 17.0997 7.50011 16.8877 7.50011 16.6667V15.8333H12.5001V16.6667ZM13.8918 10.6333C13.0539 11.6272 12.565 12.8684 12.5001 14.1667H10.8334V11.6667C10.8334 11.4457 10.7457 11.2337 10.5894 11.0774C10.4331 10.9211 10.2211 10.8333 10.0001 10.8333C9.7791 10.8333 9.56714 10.9211 9.41086 11.0774C9.25458 11.2337 9.16678 11.4457 9.16678 11.6667V14.1667H7.50011C7.47813 12.8901 7.0059 11.6623 6.16678 10.7C5.61315 10.0367 5.24086 9.2412 5.08621 8.39114C4.93156 7.54108 4.99982 6.66545 5.28435 5.84962C5.56888 5.0338 6.05997 4.30564 6.70972 3.73612C7.35947 3.16661 8.14571 2.77518 8.99178 2.60002C9.71891 2.4503 10.4702 2.46439 11.1913 2.64124C11.9123 2.8181 12.5848 3.15329 13.1602 3.62248C13.7355 4.09167 14.1991 4.68308 14.5173 5.35378C14.8356 6.02448 15.0005 6.75764 15.0001 7.50002C15.0063 8.64157 14.6144 9.74957 13.8918 10.6333V10.6333Z"
      fill={theme.palette.primary.main}
    />
  </SvgIcon>
);

Lightbulb.displayName = 'LightbulbIcon';
Lightbulb.muiName = 'SvgIcon';

export default Lightbulb;
