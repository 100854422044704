import * as actionTypes from './actionTypes';

import initialState from './initialState';

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_TRIP_DATES:
      return {
        ...state,
        tripDates: {
          ...state.tripDates,
          start: payload.start,
          end: payload.end,
          extended: payload.extended,
        },
      };

    case actionTypes.SET_TRIP_DATES_AVAILABILITY:
      return {
        ...state,
        tripDates: {
          ...state.tripDates,
          available: payload.available,
          errorMessage: payload.errorMessage,
        },
      };

    case actionTypes.SET_LOCATION:
      return {
        ...state,
        location: payload,
      };

    case actionTypes.SET_OCCUPIED_DATES:
      return {
        ...state,
        occupiedDates: payload,
      };

    case actionTypes.SET_EXTENSION:
      return {
        ...state,
        extension: payload,
      };

    default:
      return state;
  }
};
