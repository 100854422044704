import React from 'react';
import { SvgIcon } from '@material-ui/core';

import theme from 'theme';

const Headphones = (props) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M16.6665 10.9833V9.16667C16.6665 7.39856 15.9641 5.70286 14.7139 4.45262C13.4636 3.20238 11.7679 2.5 9.99984 2.5C8.23173 2.5 6.53603 3.20238 5.28579 4.45262C4.03555 5.70286 3.33317 7.39856 3.33317 9.16667V10.9833C2.84688 11.1553 2.42567 11.4734 2.12729 11.8941C1.8289 12.3148 1.66795 12.8175 1.6665 13.3333V15C1.6665 15.663 1.9299 16.2989 2.39874 16.7678C2.86758 17.2366 3.50346 17.5 4.1665 17.5H6.6665C6.88752 17.5 7.09948 17.4122 7.25576 17.2559C7.41204 17.0996 7.49984 16.8877 7.49984 16.6667V11.6667C7.49984 11.4457 7.41204 11.2337 7.25576 11.0774C7.09948 10.9211 6.88752 10.8333 6.6665 10.8333H4.99984V9.16667C4.99984 7.84058 5.52662 6.56881 6.4643 5.63113C7.40199 4.69345 8.67376 4.16667 9.99984 4.16667C11.3259 4.16667 12.5977 4.69345 13.5354 5.63113C14.4731 6.56881 14.9998 7.84058 14.9998 9.16667V10.8333H13.3332C13.1122 10.8333 12.9002 10.9211 12.7439 11.0774C12.5876 11.2337 12.4998 11.4457 12.4998 11.6667V16.6667C12.4998 16.8877 12.5876 17.0996 12.7439 17.2559C12.9002 17.4122 13.1122 17.5 13.3332 17.5H15.8332C16.4962 17.5 17.1321 17.2366 17.6009 16.7678C18.0698 16.2989 18.3332 15.663 18.3332 15V13.3333C18.3317 12.8175 18.1708 12.3148 17.8724 11.8941C17.574 11.4734 17.1528 11.1553 16.6665 10.9833ZM5.83317 12.5V15.8333H4.1665C3.94549 15.8333 3.73353 15.7455 3.57725 15.5893C3.42097 15.433 3.33317 15.221 3.33317 15V13.3333C3.33317 13.1123 3.42097 12.9004 3.57725 12.7441C3.73353 12.5878 3.94549 12.5 4.1665 12.5H5.83317ZM16.6665 15C16.6665 15.221 16.5787 15.433 16.4224 15.5893C16.2661 15.7455 16.0542 15.8333 15.8332 15.8333H14.1665V12.5H15.8332C16.0542 12.5 16.2661 12.5878 16.4224 12.7441C16.5787 12.9004 16.6665 13.1123 16.6665 13.3333V15Z"
      fill={theme.palette.primary.main}
    />
  </SvgIcon>
);

Headphones.displayName = 'HeadphonesIcon';
Headphones.muiName = 'SvgIcon';

export default Headphones;
