import { connect } from 'react-redux';

import * as verifyEmailActions from 'store/modules/auth/verifyEmail';
import * as verifyEmailSelectors from 'store/modules/auth/verifyEmail/selectors';

import VerifyEmail from './VerifyEmail';

const mapStateToProps = (state) => ({
  open: verifyEmailSelectors.getOpen(state),
  verified: verifyEmailSelectors.getVerified(state),
  editEmail: verifyEmailSelectors.getEditEmail(state),
  profileEmail: verifyEmailSelectors.getProfileEmail(state),
  loading: verifyEmailSelectors.getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  onEditEmail: (v) => dispatch(verifyEmailActions.setEditEmail(v)),
  onClose: () => dispatch(verifyEmailActions.closeVerifyEmail()),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail);
