import { call, put } from 'redux-saga/effects';

import { apiService } from '@evee/evee-ui.services';
import { redirectIfError } from '@evee/evee-ui.utils';

import * as appActions from 'store/modules/app/actions';
import * as staticPageActions from 'store/modules/staticPage/actions';

function* load(action) {
  const pageName = action.payload;

  try {
    yield put(appActions.setLoading(true));
    const result = yield call(apiService.staticPage.get, pageName);
    yield put(staticPageActions.loadSuccess(result));
  } catch (error) {
    yield put(staticPageActions.loadFailed());
    yield put(appActions.showRequestError());
    redirectIfError(error);
  } finally {
    yield put(appActions.setLoading(false));
  }
}

export { load };
