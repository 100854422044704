import { createSelector } from 'reselect';

import { fullRefundBefore, noRefundAfter } from '@evee/evee-ui.utils';

const getTripDates = (state) => ({
  start: state.booking.tripDates.start,
  end: state.booking.tripDates.end,
  available: state.booking.tripDates.available,
  extended: state.booking.tripDates.extended,
  errorMessage: state.booking.tripDates.errorMessage,
});

const getLocation = (state) => state.booking.location;

const getLoading = (state) => state.booking.loading;

const getOccupiedDates = (state) => state.booking.occupiedDates;

const getFullRefundBefore = createSelector(getTripDates, (tripDates) =>
  fullRefundBefore(tripDates?.start),
);

const getNoRefundAfter = createSelector(getTripDates, (tripDates) =>
  noRefundAfter(tripDates?.start),
);

const getExtension = (state) => state.booking.extension || {};

export {
  getTripDates,
  getLocation,
  getLoading,
  getOccupiedDates,
  getFullRefundBefore,
  getNoRefundAfter,
  getExtension,
};
