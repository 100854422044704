import PropTypes from 'prop-types';

import { Box, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import BenefitIcon from '../Icons/BenefitIcon';
import styles from './styles';

const BenefitItem = ({ icon, title, description, url, linkText, classes }) => (
  <Box className={classes.container}>
    <BenefitIcon name={icon} className={classes.icon} />
    <Box className={classes.description}>
      <Box>
        <Typography gutterBottom variant="h6">
          {title}
        </Typography>

        <Typography paragraph align="left">
          {description}
        </Typography>
      </Box>
    </Box>
  </Box>
);

BenefitItem.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string,
  linkText: PropTypes.string,
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
  }).isRequired,
};

BenefitItem.defaultProps = {
  icon: '',
  title: '',
  description: '',
  url: '',
  linkText: '',
};

export default withStyles(styles)(BenefitItem);
