import PropTypes from 'prop-types';

import { Box, Fade } from '@material-ui/core';
import { useTheme, withStyles } from '@material-ui/core/styles';

import { history } from '@evee/evee-ui.services';

import BrandNameIcon from 'common/icons/BrandName';

import styles from './styles';

const DesktopLogo = ({ isTransparent, classes }) => {
  const theme = useTheme();

  const logoJsx = <BrandNameIcon htmlColor={theme.palette.common.white} className={classes.icon} />;

  const brandNameJsx = (
    <Fade in={!isTransparent} timeout={500}>
      <BrandNameIcon htmlColor={theme.palette.text.primary} className={classes.icon} />
    </Fade>
  );

  return (
    <Box className={classes.container} onClick={() => history.push('/')}>
      {isTransparent ? logoJsx : brandNameJsx}
    </Box>
  );
};

DesktopLogo.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
  }).isRequired,
  isTransparent: PropTypes.bool.isRequired,
};

export default withStyles(styles)(DesktopLogo);
