/* eslint-disable no-param-reassign */
import { createAction, createSlice } from '@reduxjs/toolkit';
import { apiService } from '@evee/evee-ui.services';
import initialState from './initialState';

export const submitDriverDeclaration = createAction('profile/declaration/submitDriverDeclaration');

export const declarationSlice = createSlice({
  name: 'profile/declaration',
  initialState,
  reducers: {
    setDeclaration: (state, { payload }) => {
      state.declaration = {
        ...state.declaration,
        ...payload,
      };
    },

    setDeclarationAccepted: (state, { payload }) => {
      state.declaration = {
        ...state.declaration,
        accepted: payload,
      };
    },

    resetQuestionnaire: (state) => {
      state.declaration.questionnaire = apiService.dictionary
        .getDriverQuestionnaire()
        .map((q) => ({ ...q, answer: '' }));
    },

    setQuestionAnswer: (state, { payload }) => {
      const { id, value } = payload;
      const questionnaire = [...state.declaration.questionnaire];
      const index = questionnaire.findIndex((q) => q.id === id);
      questionnaire.splice(index, 1, {
        ...questionnaire[index],
        answer: value,
      });

      state.declaration.questionnaire = questionnaire;
    },
  },
});

const { actions, reducer } = declarationSlice;

export const {
  setDeclaration,
  setDeclarationAccepted,
  resetQuestionnaire,
  setQuestionAnswer,
} = actions;

export default reducer;
