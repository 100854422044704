import { takeEvery } from 'redux-saga/effects';

import * as licenceActionTypes from 'store/modules/profile/licence';

import { validateFieldValue } from './utils';

function* validateLicence({ payload }) {
  yield validateFieldValue({
    payload: {
      fieldName: 'licence',
      fieldValue: payload,
    },
  });
}

export default function* listenUploadLicenceSuccess() {
  yield takeEvery(licenceActionTypes.verifyLicenceSubmitted.toString(), validateLicence);
}
