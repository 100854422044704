/* eslint-disable react/prop-types */
import { Redirect, Route } from 'react-router-dom';

import { storage } from '@evee/evee-ui.services';

import useAuthorizedRouteSignIn from 'saga/auth/hooks/useAuthorizedRouteSignIn';

export default ({ component: Component, ...rest }) => {
  const currentUser = storage.user.get();
  const onAuthorizedRouteSignIn = useAuthorizedRouteSignIn();

  const renderRoute = (props) => {
    if (currentUser) {
      return <Component {...props} />;
    }

    onAuthorizedRouteSignIn(props.location.pathname);

    return (
      <Redirect
        to={{
          pathname: '/',
          state: { from: props.location },
        }}
      />
    );
  };

  return <Route render={renderRoute} {...rest} />;
};
