import PropTypes from 'prop-types';
import { useState } from 'react';

import { Box, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { Button, Loader, TextInput } from '@evee/evee-ui.base';

import useSubscribeProps from 'store/modules/subscribe/hooks/useSubscribeProps';

import styles from './styles';

const Subscribe = ({ loading, classes }) => {
  const [formatError, setFormatError] = useState(null);
  const [email, onSubscribe, onEmailChanged] = useSubscribeProps();

  const titleJsx = (
    <Box display="flex" alignItems="center" className={classes.titleContainer}>
      <Typography gutterBottom variant="h3" className={classes.title}>
        Win a day in your dream EV!
      </Typography>
      <Typography className={classes.subtitle}>
        Sign up to our monthly newsletter and go in the draw to win.
      </Typography>
    </Box>
  );

  return (
    <Loader loading={loading}>
      <Grid container>
        <Grid item container className={classes.gridContainer}>
          <Grid item md xs={12}>
            {titleJsx}
          </Grid>

          <Grid item md xs={12}>
            <Box className={classes.inputContainer}>
              <Box className={classes.controlsContainer}>
                <TextInput
                  value={email}
                  InputProps={{
                    placeholder: 'Email Address',
                    className: classes.input,
                  }}
                  format="email"
                  error={!!formatError}
                  helperText={formatError}
                  onChange={onEmailChanged}
                  onFormatError={setFormatError}
                />
                <Button
                  text="Subscribe"
                  classes={{
                    root: classes.button,
                  }}
                  onClick={() => {
                    if (email && !formatError) {
                      onSubscribe();
                    } else if (!email) {
                      setFormatError('Please enter email address');
                    }
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Loader>
  );
};

Subscribe.propTypes = {
  loading: PropTypes.bool,
  classes: PropTypes.shape({
    gridContainer: PropTypes.string.isRequired,
    titleContainer: PropTypes.string.isRequired,
    controlsContainer: PropTypes.string.isRequired,
    inputContainer: PropTypes.string.isRequired,
    input: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
  }).isRequired,
};

Subscribe.defaultProps = {
  loading: false,
};

export default withStyles(styles)(Subscribe);
