import { takeEvery } from 'redux-saga/effects';

import * as profileActionsTypes from 'store/modules/profile/actionTypes';

import { changeEmail } from './utils';

function* listenChangeEmail() {
  yield takeEvery(profileActionsTypes.CHANGE_EMAIL, changeEmail);
}

export default listenChangeEmail;
