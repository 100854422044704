import { connect } from 'react-redux';

import * as verifyEmailActions from 'store/modules/auth/verifyEmail';
import * as verifyEmailSelectors from 'store/modules/auth/verifyEmail/selectors';

import EnterCode from './EnterCode';

const mapStateToProps = (state) => ({
  open: verifyEmailSelectors.getOpen(state),
  verified: verifyEmailSelectors.getVerified(state),
  code: verifyEmailSelectors.getCode(state),
  email: verifyEmailSelectors.getEmail(state),
  editEmail: verifyEmailSelectors.getEditEmail(state),
  profileEmail: verifyEmailSelectors.getProfileEmail(state),
  errorMessage: verifyEmailSelectors.getErrorMessage(state),
  loading: verifyEmailSelectors.getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  onSendEmailVerification: () => dispatch(verifyEmailActions.sendEmailVerification()),
  onVerifyEmail: () => dispatch(verifyEmailActions.verifyEmail()),
  onEditEmail: (v) => dispatch(verifyEmailActions.setEditEmail(v)),
  onChangeEmail: (v) => dispatch(verifyEmailActions.setEmail(v)),
  onSetCodeDigit: (index, value) => dispatch(verifyEmailActions.setCodeDigit({ index, value })),
  onPasteCode: (v) => dispatch(verifyEmailActions.pasteCode(v)),
  onClose: () => dispatch(verifyEmailActions.closeVerifyEmail()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EnterCode);
