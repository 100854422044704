import PropTypes from 'prop-types';
import { useState } from 'react';

import { AppBar, Box, ButtonBase, Toolbar } from '@material-ui/core';
import { useTheme, withStyles } from '@material-ui/core/styles';

import { Button } from '@evee/evee-ui.base';
import { history } from '@evee/evee-ui.services';

import useAuth from 'store/modules/auth/hooks/useAuth';
import useRegion from 'store/modules/app/hooks/useRegion';

import LogoIcon from 'common/icons/Logo';
import { RegionSelector } from 'widgets/home';
import supportHrefs from 'common/constants/supportHrefs';
import useHideWebWidget from 'common/hooks/useHideWebWidget';

import HamburgerButton from '../../utils/HamburgerMenu/HamburgerButton';
import MobileMenu from '../../Menu/MobileMenu';
import styles from './styles';

const MobileHeader = ({ classes }) => {
  const [menuOpened, setMenuOpened] = useState(false);
  const { currentUser, signedIn } = useAuth();
  const { region } = useRegion();
  const theme = useTheme();

  useHideWebWidget(menuOpened);

  return (
    <>
      <AppBar
        position="sticky"
        className={classes.defaultAppBar}
        style={{
          boxShadow: menuOpened && 'none',
        }}
      >
        <Toolbar className={classes.toolbar}>
          <ButtonBase style={{ marginRight: '16px' }} onClick={() => history.push('/')}>
            <LogoIcon htmlColor={theme.palette.primary.main} className={classes.logoIcon} />
          </ButtonBase>

          <Box>
            {currentUser && signedIn ? (
              <Box display="flex" alignItems="center">
                <Button
                  type="text"
                  text="Help Centre"
                  size="small"
                  href={supportHrefs[region.id].helpCenter}
                  target="_blank"
                  className={classes.button}
                />
                <RegionSelector className={classes.button} />
                <HamburgerButton
                  color="primary"
                  className={classes.menuButton}
                  onClick={() => {
                    setMenuOpened((value) => !value);
                  }}
                />
                Í
              </Box>
            ) : (
              <Box display="flex" alignItems="center">
                <Button
                  type="text"
                  text="Help Centre"
                  size="small"
                  href={supportHrefs[region.id].helpCenter}
                  target="_blank"
                  className={classes.button}
                />

                <RegionSelector className={classes.button} />

                <HamburgerButton
                  color="primary"
                  className={classes.menuButton}
                  onClick={() => {
                    setMenuOpened((value) => !value);
                  }}
                />
              </Box>
            )}
          </Box>
        </Toolbar>
      </AppBar>

      <MobileMenu
        menuOpened={menuOpened}
        onClick={() => setMenuOpened(false)}
        onClose={() => setMenuOpened(false)}
      />
    </>
  );
};

MobileHeader.propTypes = {
  classes: PropTypes.shape({
    toolbar: PropTypes.string.isRequired,
    defaultAppBar: PropTypes.string.isRequired,
    logoIcon: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
    menuButton: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles)(MobileHeader);
