import { Box, Typography } from '@material-ui/core';

const ErrorFallback = () => (
  <Box
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100vw',
      height: '100vh',
    }}
  >
    <Typography align="center" variant="h3">
      Something went wrong. Try to refresh the page.
    </Typography>
  </Box>
);

ErrorFallback.propTypes = {};
ErrorFallback.defaultProps = {};

export default ErrorFallback;
