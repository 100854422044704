/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import initialState from './initialState';

export const bookingChangeSlice = createSlice({
  name: 'bookingChange',
  initialState,
  reducers: {
    load(state) {
      state.loading = true;
    },

    loadSuccess(state, { payload }) {
      state.request = payload || {};
      state.loading = false;
    },

    loadFailed(state) {
      state.loading = false;
    },

    setTotals(state, { payload }) {
      state.totals = payload;
    },

    setMessage(state, { payload }) {
      state.message = payload;
    },

    setDialogOpen(state, { payload }) {
      state.dialog.open = payload;
    },

    setDialogLoading(state, { payload }) {
      state.dialog.loading = payload;
    },

    loadTotals(state) {
      state.totals.loading = true;
    },

    loadTotalsSuccess(state) {
      state.totals.loading = false;
    },

    loadTotalsFailed(state) {
      state.totals = {};
      state.totals.loading = false;
    },

    create(state) {
      state.dialog.loading = true;
    },

    createSuccess(state, { payload }) {
      state.request = payload;
      state.dialog.loading = false;
      state.dialog.open = false;
    },

    createFailed(state) {
      state.dialog.loading = false;
    },

    cancel(state) {
      state.loading = true;
    },

    cancelSuccess(state) {
      state.request = initialState.request;
      state.totals = initialState.totals;
      state.loading = false;
    },

    cancelFailed(state) {
      state.loading = false;
    },

    accept(state) {
      state.loading = true;
    },

    acceptSuccess(state) {
      state.request = initialState.request;
      state.totals = initialState.totals;
    },

    acceptFailed(state) {
      state.loading = false;
    },

    decline(state) {
      state.loading = true;
    },

    declineSuccess(state) {
      state.request = initialState.request;
      state.totals = initialState.totals;
    },

    declineFailed(state) {
      state.loading = false;
    },

    loadDialog(state) {
      state.dialog.loading = true;
    },

    loadDialogSuccess(state) {
      state.dialog.loading = false;
    },

    loadDialogFailed(state) {
      state.dialog.loading = false;
    },
  },
});

const { actions, reducer } = bookingChangeSlice;

export const {
  load,
  loadSuccess,
  loadFailed,
  setTotals,
  setMessage,
  setDialogOpen,
  setDialogLoading,
  loadTotals,
  loadTotalsSuccess,
  loadTotalsFailed,
  create,
  createSuccess,
  createFailed,
  cancel,
  cancelSuccess,
  cancelFailed,
  accept,
  acceptSuccess,
  acceptFailed,
  decline,
  declineSuccess,
  declineFailed,
  loadDialog,
  loadDialogSuccess,
  loadDialogFailed,
} = actions;

export default reducer;
