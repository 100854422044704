import { all, call, put, select } from 'redux-saga/effects';
import moment from 'moment';

import { apiService, history, storage, tagManager } from '@evee/evee-ui.services';

import { CheckoutSteps, DiscountType } from '@evee/evee-ui.enums';
import { basket as basketModel } from '@evee/evee-ui.models';
import { redirectIfError } from '@evee/evee-ui.utils';

import * as appActions from 'store/modules/app/actions';
import * as appSelectors from 'store/modules/app/selectors';
import * as authActions from 'store/modules/auth';
import * as authSelectors from 'store/modules/auth/selectors';
import * as basketActions from 'store/modules/basket';
import * as basketSelectors from 'store/modules/basket/selectors';
import * as bookingActions from 'store/modules/booking/actions';
import * as bookingSelectors from 'store/modules/booking/selectors';
import * as declarationActions from 'store/modules/profile/declaration';
import * as declarationSelectors from 'store/modules/profile/declaration/selectors';
import * as licenceSelectors from 'store/modules/profile/licence/selectors';
import * as profileActions from 'store/modules/profile/actions';
import * as signInActions from 'store/modules/auth/signIn';
import * as vehicleActions from 'store/modules/vehicle/actions';
import * as vehicleSelectors from 'store/modules/vehicle/selectors';

import { completeValidSteps, validateBasket } from './validation/utils';

function* createBasket() {
  try {
    const bookingData = yield select(vehicleSelectors.getBookingData);
    const isUserAuthorized = yield select(authSelectors.getIsUserAuthorized);
    const currency = yield select(appSelectors.getCurrency);

    if (isUserAuthorized) {
      yield put(appActions.setLoading(true));
      const response = yield call(apiService.basket.createBasket, bookingData, currency.id);
      yield put(basketActions.createBasketSuccess(response));

      storage.basket.save(response._id);
      tagManager.pushCheckout(1, 'Request booking', response);
      history.push('/checkout');
    } else {
      yield put(basketActions.setSigningIn(true));
      yield put(signInActions.showSignIn());
    }
  } catch (error) {
    yield put(basketActions.createBasketFailed());
    yield put(appActions.showError(error.message));
  } finally {
    yield put(appActions.setLoading(false));
  }
}

function* pushInitialCheckoutStep() {
  const [yourInfoStep, licenceStep, paymentStep] = yield all([
    select(basketSelectors.getYourInfoStep),
    select(basketSelectors.getLicenceStep),
    select(basketSelectors.getPaymentStep),
  ]);

  const booking = yield select(basketSelectors.getBooking);
  // todo: Due to confusing Tag Manager configuration, we need to pass the booking object here.
  if (yourInfoStep.active) {
    tagManager.pushCheckout(2, 'Your info', booking);
  }

  if (licenceStep.active) {
    tagManager.pushCheckout(3, 'Driver licence', booking);
  }

  if (paymentStep.active) {
    tagManager.pushCheckout(4, 'Payment', booking);
  }
}

function* loadBasket({ payload }) {
  try {
    const currency = yield select(appSelectors.getCurrency);
    const tripDates = yield select(bookingSelectors.getTripDates);
    const isExtendedTripDates = tripDates ? tripDates.extended : false;

    yield put(appActions.setLoading(true));

    const [profile, basket] = yield all([
      call(apiService.customer.me),
      call(apiService.basket.get, payload, currency.id),
    ]);

    // Load vehicle if we ended up on the page through a direct link.
    let vehicleId = yield select(vehicleSelectors.getVehicleId);
    if (vehicleId !== basket.vehicle.id) {
      const vehicle = yield call(apiService.vehicle.getVehicle, basket.vehicle.id, currency.id);
      yield put(vehicleActions.setVehicle(vehicle));
      vehicleId = vehicle.id;
    }

    // Set location and booking dates.
    yield put(bookingActions.setLocation(basket.delivery));
    yield put(bookingActions.setTripDates(basket.from, basket.to, isExtendedTripDates));
    yield put(bookingActions.loadOccupiedDates(basket.vehicle.id));

    yield put(profileActions.setProfile(profile));
    yield put(declarationActions.resetQuestionnaire());
    yield put(basketActions.setBasket(basket));

    if (
      basket.id &&
      moment(basket.from).isValid() &&
      moment(basket.to).isValid() &&
      basket.delivery
    ) {
      yield put(bookingActions.setTripDatesAvailability(true));

      const availableExtras = yield call(apiService.extras.getVehicleExtras, {
        vehicleId,
        from: basket.from,
        to: basket.to,
        currency: currency.id,
      });

      yield put(basketActions.setAvailableExtras(availableExtras));
    }

    yield completeValidSteps();
    yield pushInitialCheckoutStep();

    const prefilledCoupon = yield call(storage.discountCode.get);
    if (prefilledCoupon) {
      yield put(basketActions.applyDiscount(DiscountType.coupon, prefilledCoupon));
    }

    yield put(basketActions.loadSuccess());
  } catch (error) {
    yield put(appActions.showRequestError());
    yield put(basketActions.loadFailed());
    redirectIfError(error, [401, 404]);
  } finally {
    yield put(appActions.setLoading(false));
  }
}

function* completeYourInfo() {
  const yourInfo = yield select(basketSelectors.getYourInfo);
  yield validateBasket(basketModel.yourInfoSchema, yourInfo);

  const updateInfo = {
    dateOfBirth: yourInfo.dateOfBirth,
    address: yourInfo.address,
  };
  yield call(apiService.customer.update, updateInfo);

  yield put(authActions.setPersonalInfo(updateInfo));
  tagManager.pushCheckout(3, 'Driver licence');
}

function* completeVerification() {
  const [licence, declarationStatus] = yield all([
    select(licenceSelectors.getLicence),
    select(declarationSelectors.getDeclarationStatus),
  ]);

  // validate licence and declaration status
  const licenceAndDeclarationData = {
    licence,
    declarationStatus,
  };
  yield validateBasket(basketModel.licenceAndDeclarationSchema, licenceAndDeclarationData);
  tagManager.pushCheckout(4, 'Payment');
}

function* completeStep({ payload }) {
  try {
    yield put(appActions.setLoading(true));

    switch (payload.step) {
      case CheckoutSteps.yourInfo:
        yield completeYourInfo();
        break;

      case CheckoutSteps.verification:
        yield completeVerification();
        break;

      default:
        break;
    }

    yield put(basketActions.completeStepSuccess());
    yield put(basketActions.setStepComplete({ step: payload.step, nextStep: payload.nextStep }));
  } catch (error) {
    yield put(basketActions.completeStepFailed());
    yield put(appActions.showError(error.message));
  } finally {
    yield put(appActions.setLoading(false));
  }
}

function* finaliseRequest({ payload: card }) {
  try {
    yield put(appActions.setLoading(true));
    const basketId = yield select(basketSelectors.getBasketId);
    const message = yield select(basketSelectors.getMessage);
    const vehicle = yield select(vehicleSelectors.getVehicleDetails);
    const cfclick = yield call(storage.cfClick.get);

    const paymentData = {
      message,
      defaultPaymentMethod: card,
    };
    yield validateBasket(basketModel.paymentSchema, paymentData);

    const result = yield call(apiService.booking.create, basketId, card.id, message, cfclick);
    tagManager.pushBookingEvent('Request booking', `${result._id} - ${vehicle.name}`);
    tagManager.pushTransaction(result, vehicle);
    tagManager.pushPurchase(result, vehicle);

    yield call(storage.cfClick.remove);
    yield call(storage.discountCode.remove);
    yield call(storage.basket.remove); // remove current basketId
    history.push(`/bookingConfirmation?id=${result.id}`);
  } catch (error) {
    yield put(appActions.showError(error.message));
    yield put(appActions.setLoading(false));
  }
}

function* applyDiscount({ payload }) {
  try {
    const { discountType, value } = payload;
    yield put(appActions.setLoading(true));
    const basketId = yield select(basketSelectors.getBasketId);
    const currency = yield select(appSelectors.getCurrency);

    let result;
    if (discountType === DiscountType.credit) {
      result = yield call(apiService.basket.applyCredit, basketId, value, currency.id);
    } else if (discountType === DiscountType.coupon) {
      result = yield call(apiService.basket.applyDiscountCode, basketId, value, currency.id);
    } else {
      throw new Error('Unknown discount type');
    }

    yield put(basketActions.applyDiscountSuccess(result));
  } catch (error) {
    yield put(basketActions.applyDiscountFailed());
    yield put(appActions.showError(error.message));
  } finally {
    yield put(appActions.setLoading(false));
  }
}

function* cancelDiscount({ payload }) {
  try {
    yield put(appActions.setLoading(true));
    const basketId = yield select(basketSelectors.getBasketId);
    const currency = yield select(appSelectors.getCurrency);

    let result;
    if (payload === DiscountType.credit) {
      result = yield call(apiService.basket.cancelCredit, basketId, currency.id);
    } else if (payload === DiscountType.coupon) {
      result = yield call(apiService.basket.cancelDiscountCode, basketId, currency.id);
    } else {
      throw new Error('Unknown discount type');
    }
    yield put(basketActions.cancelDiscountSuccess(result));
  } catch (error) {
    yield put(basketActions.cancelDiscountFailed());
    yield put(appActions.showError(error.message));
  } finally {
    yield put(appActions.setLoading(false));
  }
}

function* safeUpdateMessage(effect) {
  try {
    return { response: yield effect };
  } catch (error) {
    return { error };
  }
}

function* updateMessage() {
  const [message, basketId] = yield all([
    select(basketSelectors.getMessage),
    select(basketSelectors.getBasketId),
  ]);
  yield safeUpdateMessage(call(apiService.basket.update, basketId, { message }));
}

function* updateBasketTotals() {
  try {
    const [tripDates, location, basketId, vehicle, currency] = yield all([
      select(bookingSelectors.getTripDates),
      select(bookingSelectors.getLocation),
      select(basketSelectors.getBasketId),
      select(basketSelectors.getVehicle),
      select(appSelectors.getCurrency),
    ]);

    if (
      basketId &&
      moment(tripDates.start).isValid() &&
      moment(tripDates.end).isValid() &&
      location
    ) {
      const from = moment(tripDates.start).utc(true).format('YYYY-MM-DDTHH:mm');
      const to = moment(tripDates.end).utc(true).format('YYYY-MM-DDTHH:mm');

      const [result, extension, availableExtras] = yield all([
        call(apiService.basket.update, basketId, {
          from,
          to,
          delivery: location,
        }),
        call(apiService.vehicle.getTotalsExtensions, {
          vehicleId: vehicle.id,
          from,
          to,
          currency: currency.id,
        }),
        call(apiService.extras.getVehicleExtras, {
          vehicleId: vehicle.id,
          from,
          to,
          currency: currency.id,
        }),
      ]);

      yield put(basketActions.setBasket(result));
      yield put(bookingActions.setExtension(extension));
      yield put(bookingActions.setTripDatesAvailability(true));
      yield put(basketActions.setAvailableExtras(availableExtras));
    }
    yield put(basketActions.loadTotalsSuccess());
  } catch (error) {
    yield put(bookingActions.setTripDatesAvailability(false, error.message));
    yield put(basketActions.loadTotalsFailed());
  }
}

function* setActiveStep({ payload: activeStep }) {
  const booking = yield select(basketSelectors.getBooking);
  // todo: Due to confusing tagmanager configuration, we need to pass the basket object here.
  if (activeStep === CheckoutSteps.yourInfo) {
    tagManager.pushCheckout(2, 'Your info', booking);
  }

  if (activeStep === CheckoutSteps.verification) {
    tagManager.pushCheckout(3, 'Driver licence', booking);
  }

  if (activeStep === CheckoutSteps.payment) {
    tagManager.pushCheckout(4, 'Payment', booking);
  }
}

function* addExtra({ payload }) {
  try {
    yield put(appActions.setLoading(true));

    const basketId = yield select(basketSelectors.getBasketId);
    const currency = yield select(appSelectors.getCurrency);

    const extra = yield call(apiService.basket.createExtra, basketId, currency.id, payload);

    yield put(basketActions.setExtras(extra));
  } catch (error) {
    yield put(appActions.showError(error.message));
  } finally {
    yield put(appActions.setLoading(false));
  }
}

function* removeExtra({ payload }) {
  try {
    yield put(appActions.setLoading(true));

    const basketId = yield select(basketSelectors.getBasketId);
    const currency = yield select(appSelectors.getCurrency);

    yield call(apiService.basket.removeExtra, basketId, currency.id, payload);

    yield put(basketActions.setExtras([]));
  } catch (error) {
    yield put(appActions.showError(error.message));
  } finally {
    yield put(appActions.setLoading(false));
  }
}

export {
  createBasket,
  loadBasket,
  completeStep,
  finaliseRequest,
  applyDiscount,
  cancelDiscount,
  updateMessage,
  updateBasketTotals,
  setActiveStep,
  addExtra,
  removeExtra,
};
