import { call, put, select } from 'redux-saga/effects';

import { apiService } from '@evee/evee-ui.services';

import * as appActions from 'store/modules/app/actions';
import * as authorisePaymentActions from 'store/modules/authorisePayment';
import * as authorisePaymentSelectors from 'store/modules/authorisePayment/selectors';
import * as bookingDetailsSelectors from 'store/modules/bookingDetails/selectors';

export function* authoriseSecurityDeposit() {
  try {
    const bookingId = yield select(bookingDetailsSelectors.getBookingId);
    const selectedCard = yield select(authorisePaymentSelectors.getSelectedCard);

    yield call(apiService.booking.authoriseSecurityDeposit, bookingId, selectedCard);

    yield put(authorisePaymentActions.authoriseSecurityDepositSuccess());
    yield put(authorisePaymentActions.setOpen(false));
  } catch (error) {
    yield put(authorisePaymentActions.authoriseSecurityDepositFailed());
    yield put(appActions.showError(error.message));
  }
}
