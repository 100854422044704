import moment from 'moment';

import { MIN_HOURS_BETWEEN_BOOKINGS, formatTripDate } from '@evee/evee-ui.utils';

const DEFAULT_HOUR_VALUE = 10;

const createRange = (startDate, endDate, startHour, endHour) => ({
  start: startDate ? moment(startDate).startOf('day').add(startHour, 'hour') : null,
  end: endDate ? moment(endDate).startOf('day').add(endHour, 'hour') : null,
});

const findMinHour = (startDate, vehicleUtcOffset, vehicleNoticePeriod) => {
  for (let i = 0; i < 24; i += 1) {
    const vehicleLocalStartMoment = moment(startDate)
      .clone()
      .utc(true)
      .add(i - vehicleUtcOffset, 'hours');
    const hoursBeforeBooking = vehicleLocalStartMoment.diff(moment(), 'hours');
    if (hoursBeforeBooking >= vehicleNoticePeriod) {
      return i;
    }
  }
  return null;
};

const getDatesAndHours = ({ start, end }, vehicleUtcOffset, vehicleNoticePeriod) => {
  const startDate = moment(start).startOf('day');
  const endDate = moment(end).startOf('day');

  const minHour =
    moment(startDate).isValid() && vehicleUtcOffset && vehicleNoticePeriod
      ? findMinHour(startDate, vehicleUtcOffset, vehicleNoticePeriod)
      : null;

  const startHour = Number(moment(start).format('H'));
  const endHour = Number(moment(end).format('H'));

  const result = {
    startDate: startDate.isValid() ? startDate : null,
    endDate: endDate.isValid() ? endDate : null,
    startHour: !Number.isNaN(startHour) ? startHour : Math.max(DEFAULT_HOUR_VALUE, minHour),
    endHour: !Number.isNaN(endHour) ? endHour : DEFAULT_HOUR_VALUE,
  };

  return result;
};

const getRangeDescription = ({ start, end }) => {
  const startMoment = moment(start);
  const endMoment = moment(end);

  const startString = formatTripDate(startMoment);
  const endString = formatTripDate(endMoment);

  return `${startString} - ${endString !== 'Invalid date' ? endString : ''}`;
};

const getMinStartHour = (occupiedRanges, vehicleUtcOffset, vehicleNoticePeriod, startDate) => {
  const concurrentStart = occupiedRanges.find((d) => d.to.isSame(startDate, 'day'));
  if (concurrentStart) {
    return Number(concurrentStart.to.format('H')) + MIN_HOURS_BETWEEN_BOOKINGS;
  }

  return moment(startDate).isValid()
    ? findMinHour(startDate, vehicleUtcOffset, vehicleNoticePeriod)
    : null;
};

const getMaxEndHour = (occupiedRanges, endDate) => {
  const concurrentEnd = occupiedRanges.find((d) => d.from.isSame(endDate, 'day'));
  return concurrentEnd ? concurrentEnd.from.format('H') - MIN_HOURS_BETWEEN_BOOKINGS : null;
};

export { createRange, getDatesAndHours, getRangeDescription, getMinStartHour, getMaxEndHour };
