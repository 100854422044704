import { all, call, takeEvery } from 'redux-saga/effects';

import * as actionTypes from 'store/modules/listing/actionTypes';

import { loadDictionaries, loadListing } from './utils';
import { loadDefaultCustomPrices } from './customPrices/utils';
import { loadDefaultDates } from './occupations/utils';

function* load(action) {
  yield all([call(loadListing, action.payload), call(loadDictionaries)]);
  // Must set vehicle id before fetching occupied dates and custom prices.
  yield all([call(loadDefaultDates), call(loadDefaultCustomPrices)]);
}

export default function* listenLoad() {
  yield takeEvery(actionTypes.LOAD, load);
}
