import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import { customerHasOwnerRole, getCustomerId } from '@evee/evee-ui.utils';
import { Menu } from '@evee/evee-ui.base';

import useAuth from 'store/modules/auth/hooks/useAuth';
import useHideWebWidget from 'common/hooks/useHideWebWidget';
import useRegion from 'store/modules/app/hooks/useRegion';

import styles from '../styles';

import HostItems from './HostItems';
import RenterItems from './RenterItems';

const AccountMenu = ({ anchorEl, open, classes, onClose }) => {
  const { region } = useRegion();
  const { currentUser, onSignOut } = useAuth();
  const hasOwnerRole = customerHasOwnerRole(currentUser);
  const customerId = getCustomerId(currentUser);

  useHideWebWidget(open);

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      openDirection="bottom"
      classes={{ list: classes.container, paper: classes.paper }}
      onClose={onClose}
    >
      {hasOwnerRole ? (
        <HostItems
          customerId={customerId}
          region={region}
          onSignOut={onSignOut}
          onClose={onClose}
        />
      ) : (
        <RenterItems
          customerId={customerId}
          region={region}
          onSignOut={onSignOut}
          onClose={onClose}
        />
      )}
    </Menu>
  );
};

AccountMenu.propTypes = {
  anchorEl: PropTypes.object,
  open: PropTypes.bool,
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
    paper: PropTypes.string.isRequired,
    menuItem: PropTypes.string.isRequired,
    divider: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func,
};

AccountMenu.defaultProps = {
  anchorEl: {},
  open: false,
  onClose: () => {},
};

export default withStyles(styles)(AccountMenu);
