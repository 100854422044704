import * as actionTypes from './actionTypes';

export const load = (name) => ({
  type: actionTypes.LOAD,
  payload: name,
});

export const loadSuccess = (pageData) => ({
  type: actionTypes.LOAD_SUCCESS,
  payload: pageData,
});

export const loadFailed = () => ({
  type: actionTypes.LOAD_FAILED,
});
