const styles = (theme) => ({
  paper: {
    padding: theme.spacing(1, 2),
    borderRadius: '32px',
  },
  hoursContainer: {
    padding: '10px',
    borderTop: `1 px solid ${theme.palette.border.main}`,
  },
  hoursItem: {
    paddingLeft: '12px',
    width: '50%',
  },
});

export default styles;
