import { connect } from 'react-redux';

import * as homeActions from 'store/modules/home/index';
import * as homeSelectors from 'store/modules/home/selectors';
import * as searchSelectors from 'store/modules/search/selectors';

import TopCitiesColumn from './TopCitiesColumn';

const mapStateToProps = (state) => ({
  tripDates: searchSelectors.getTripDates(state),
  topCities: homeSelectors.getTopCites(state),
});

const mapDispatchToProps = (dispatch) => ({
  onLoad: () => dispatch(homeActions.loadTopCities()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TopCitiesColumn);
