import { all, call, put, select } from 'redux-saga/effects';
import { ValidationError } from 'yup';

import { DeclarationStatus, ErrorMessage } from '@evee/evee-ui.enums';
import { apiService } from '@evee/evee-ui.services';
import { profile as profileModel } from '@evee/evee-ui.models';

import * as appActions from 'store/modules/app/actions';
import * as declarationActions from 'store/modules/profile/declaration';
import * as declarationSelectors from 'store/modules/profile/declaration/selectors';
import * as profileActions from 'store/modules/profile/actions';

function* validateQuestionnaire(data) {
  try {
    yield profileModel.questionnaireSchema.validate(data, {
      abortEarly: false,
      recursive: true,
    });
  } catch (err) {
    if (err instanceof ValidationError) {
      const validationErrors = Object.assign(
        ...err.inner.map((e) => ({
          [e.path]: e.message,
        })),
      );
      yield put(profileActions.setErrors(validationErrors));
    }
    throw new Error(ErrorMessage.completeRequiredFields);
  }
}

export function* submitDeclaration() {
  try {
    const [{ accepted }, questionnaire] = yield all([
      select(declarationSelectors.getDeclaration),
      select(declarationSelectors.getQuestionnaire),
    ]);

    yield validateQuestionnaire({ questionnaire });

    if (!accepted) {
      throw new Error('You must accept declaration statements');
    }

    const failedQuestions = questionnaire.filter((e) => e.answer !== e.validAnswer);
    const newStatus =
      failedQuestions.length > 0 ? DeclarationStatus.failed : DeclarationStatus.verified;

    const customer = yield call(apiService.customer.verifyDeclaration, {
      status: newStatus,
      failedQuestions,
    });
    yield put(declarationActions.setDeclaration(customer.declaration));
    yield put(declarationActions.resetQuestionnaire());
  } catch (error) {
    yield put(appActions.showError(error.message));
  }
}
