const prefix = 'searchPage';

export const LOAD = `${prefix}/LOAD`;
export const SET_LOADING = `${prefix}/SET_LOADING`;
export const SEARCH = `${prefix}/SEARCH`;
export const SEARCH_SUCCESS = `${prefix}/SEARCH_SUCCESS`;

export const SET_SELECTED_VEHICLE = `${prefix}/SET_SELECTED_VEHICLE`;
export const SET_HOVERED_VEHICLE = `${prefix}/SET_HOVERED_VEHICLE`;
export const SET_FIELD_VALUE = `${prefix}/SET_FIELD_VALUE`;
export const SET_ACTIVE_FILTERS = `${prefix}/SET_ACTIVE_FILTERS`;
export const SET_DEFAULT_FILTERS = `${prefix}/SET_DEFAULT_FILTERS`;
export const SET_ACTIVE_FILTER_VALUE = `${prefix}/SET_ACTIVE_FILTER_VALUE`;
export const FILTER_BADGES = `${prefix}/FILTER_BADGES`;
export const CLEAR_FILTERS = `${prefix}/CLEAR_FILTERS`;

export const SET_AIRPORTS = `${prefix}/SET_AIRPORTS`;
export const SET_AIRPORT = `${prefix}/SET_AIRPORT`;
export const SET_SELECTED_AIRPORT = `${prefix}/SET_SELECTED_AIRPORT`;
export const SET_ACTIVE_AIRPORT = `${prefix}/SET_ACTIVE_AIRPORT`;
export const SET_AIRPORT_SEARCH = `${prefix}/SET_AIRPORT_SEARCH`;

export const SET_AVAILABLE_FILTERS = `${prefix}/SET_AVAILABLE_FILTERS`;
export const SET_EDIT_FILTERS = `${prefix}/SET_EDIT_FILTERS`;
export const SET_DIALOG_MAKES = `${prefix}/SET_DIALOG_MAKES`;
export const SET_DIALOG_MODELS = `${prefix}/SET_DIALOG_MODELS`;
export const SET_FILTERS_DIALOG_OPEN = `${prefix}/SET_FILTERS_DIALOG_OPEN`;
export const SET_EDIT_FILTERS_FIELD_VALUE = `${prefix}/SET_EDIT_FILTERS_FIELD_VALUE`;
export const RESET_EDIT_FILTERS = `${prefix}/RESET_EDIT_FILTERS`;

export const SORT_BY_FIELD = `${prefix}/SORT_BY_FIELD`;
export const RESET_SORTING = `${prefix}/RESET_SORTING`;

export const SET_CAROUSEL_OPEN = `${prefix}/SET_CAROUSEL_OPEN`;
export const SET_LOCATION = `${prefix}/SET_LOCATION`;
export const SET_START_LOCATION = `${prefix}/SET_START_LOCATION`;
