/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const updatePasswordSlice = createSlice({
  name: 'auth/updatePassword',
  initialState: {},
  reducers: {
    showUpdatePassword: (state, { payload }) => {
      state.updatePassword.open = payload;
    },

    setUpdatePasswordFieldValue: (state, { payload }) => {
      state.updatePassword[payload.fieldName] = payload.fieldValue;
    },

    updatePassword: (state) => {
      state.updatePassword.loading = true;
    },

    updatePasswordSuccess: (state) => {
      state.updatePassword.currentPassword = '';
      state.updatePassword.password = '';
      state.updatePassword.confirmPassword = '';
      state.updatePassword.success = true;
      state.updatePassword.open = false;
      state.updatePassword.loading = false;
    },

    updatePasswordFailed: (state, { payload }) => {
      state.updatePassword.currentPassword = '';
      state.updatePassword.password = '';
      state.updatePassword.confirmPassword = '';
      state.updatePassword.errorMessage = payload;
      state.updatePassword.loading = false;
    },

    setErrors(state, { payload }) {
      state.updatePassword.errors = payload;
    },

    setFieldError(state, { payload }) {
      state.updatePassword.errors[payload.fieldName] = payload.fieldValue;
    },

    removeFieldError(state, { payload }) {
      state.updatePassword.errors[payload] = undefined;
    },
  },
});

const { actions, reducer } = updatePasswordSlice;

export const {
  showUpdatePassword,
  setUpdatePasswordFieldValue,
  updatePassword,
  updatePasswordSuccess,
  updatePasswordFailed,
  setErrors,
  setFieldError,
  removeFieldError,
} = actions;

export default reducer;
