import { all, fork } from 'redux-saga/effects';

import listenCheckVerificationCode from './listenCheckVerificationCode';
import listenClearPhoneNumber from './listenClearPhoneNumber';
import listenLoad from './listenLoad';
import listenSendVerificationCode from './listenSendVerificationCode';
import listenSetPhoneNumber from './listenSetPhoneNumber';

export default function* phoneSaga() {
  yield all([
    fork(listenCheckVerificationCode),
    fork(listenClearPhoneNumber),
    fork(listenLoad),
    fork(listenSendVerificationCode),
    fork(listenSetPhoneNumber),
  ]);
}
