import { takeEvery } from 'redux-saga/effects';

import * as actions from 'store/modules/extras';
import { loadBookingExtras } from './utils';

function* listenLoad() {
  yield takeEvery(actions.load.type, loadBookingExtras);
}

export default listenLoad;
