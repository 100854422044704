import PropTypes from 'prop-types';

import { Box, Collapse } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { Divider, Switch } from '@evee/evee-ui.base';
import { formatTripDate } from '@evee/evee-ui.utils';

import SearchDateInput from 'widgets/search/SearchDateInput';
import SearchLocationInput from 'widgets/search/SearchLocationInput';

import styles from './styles';

const MobileSearchInputs = ({
  tripDates,
  location,
  classes,
  onLocationChange,
  onFlexibleDatesChange,
  onPickUpClick,
  onDropOffClick,
}) => {
  const tripStart = formatTripDate(tripDates.from);
  const tripEnd = formatTripDate(tripDates.to);

  return (
    <Box display="flex" flexDirection="column">
      <Box className={classes.root}>
        <SearchLocationInput
          multiline
          value={location}
          placeholder="City, airport or suburb"
          className={classes.input}
          classes={{ paper: classes.paper }}
          onChange={onLocationChange}
        />
      </Box>

      <Collapse in={!tripDates.flexible}>
        <Box className={classes.root}>
          <SearchDateInput
            value={tripStart}
            label="Start date"
            className={classes.input}
            InputProps={{
              placeholder: 'Add dates',
            }}
            onClick={onPickUpClick}
          />

          <Divider className={classes.divider} orientation="vertical" />

          <SearchDateInput
            value={tripEnd}
            label="End date"
            className={classes.input}
            InputProps={{
              placeholder: 'Add dates',
            }}
            onClick={onDropOffClick}
          />
        </Box>
      </Collapse>

      <Switch
        label="I'm flexible"
        variant="subtitle1"
        checked={tripDates.flexible}
        onChange={onFlexibleDatesChange}
      />
    </Box>
  );
};

MobileSearchInputs.propTypes = {
  tripDates: PropTypes.object,
  location: PropTypes.object,
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    input: PropTypes.string.isRequired,
    divider: PropTypes.string.isRequired,
    paper: PropTypes.string.isRequired,
  }).isRequired,
  onLocationChange: PropTypes.func,
  onFlexibleDatesChange: PropTypes.func,
  onPickUpClick: PropTypes.func,
  onDropOffClick: PropTypes.func,
};

MobileSearchInputs.defaultProps = {
  tripDates: {},
  location: {},
  onLocationChange: () => {},
  onFlexibleDatesChange: () => {},
  onPickUpClick: () => {},
  onDropOffClick: () => {},
};

export default withStyles(styles)(MobileSearchInputs);
