import PropTypes from 'prop-types';

import { Alert } from '@material-ui/lab';
import { Snackbar } from '@material-ui/core';

const Toast = ({ open, close, message, type }) => {
  const handleClose = (event, reason) => {
    if (reason !== 'clickaway') close();
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert severity={type} style={{ minWidth: 300 }} onClose={handleClose}>
        <span style={{ fontSize: 13 }}>{message}</span>
      </Alert>
    </Snackbar>
  );
};

Toast.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  type: PropTypes.string,
  message: PropTypes.string,
};

Toast.defaultProps = {
  open: false,
  close: () => {},
  type: '',
  message: '',
};

export default Toast;
