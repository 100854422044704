const styles = (theme) => ({
  desktopContainer: {
    padding: theme.spacing(13, 0),
  },
  mobileContainer: {
    padding: theme.spacing(4, 2),
  },
  borderedContainer: {
    borderBottomWidth: '2px',
    borderBottomStyle: 'solid',
    borderColor: theme.palette.background.main,
  },
});

export default styles;
