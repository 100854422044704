const styles = (theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '50px',
    backgroundColor: theme.palette.background.main,
    minWidth: '580px',
  },
  input: {
    padding: theme.spacing(0.5, 2),
    flex: 1,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  iconButton: {
    width: '32px',
    height: '32px',
    marginRight: '4px',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
  },
  flexible: {
    whiteSpace: 'nowrap',
  },
  paper: {
    minWidth: '400px',
    marginTop: theme.spacing(2),
    marginLeft: -theme.spacing(2),
    padding: theme.spacing(0, 2),
    borderRadius: theme.shape.borderRadiusMedium,
  },
});

export default styles;
