/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import initialState from './initialState';

export const basketSlice = createSlice({
  name: 'basket',
  initialState,
  reducers: {
    load(state) {
      state.loading = true;
    },

    loadSuccess(state) {
      state.loading = false;
    },

    loadFailed(state) {
      state.loading = false;
    },

    loadTotals(state) {
      state.totalsLoading = true;
    },

    loadTotalsSuccess(state) {
      state.totalsLoading = false;
    },

    loadTotalsFailed(state) {
      state.totalsLoading = false;
    },

    finaliseRequest(_state) {
      // nothing to do
    },

    setFieldValue(state, { payload }) {
      state.booking[payload.fieldName] = payload.fieldValue;
    },

    setBasket(state, { payload }) {
      state.booking = payload;
    },

    setSigningIn(state, { payload }) {
      state.signingIn = payload;
    },

    createBasket(_state) {
      // nothing to do
    },

    createBasketSuccess(_state) {
      // nothing to do
    },

    createBasketFailed(_state) {
      // nothing to do
    },

    completeStep(_state) {
      // nothing to do
    },

    completeStepSuccess(_state) {
      // nothing to do
    },

    completeStepFailed(_state) {
      // nothing to do
    },

    setActiveStep(state, { payload }) {
      const { steps } = state.form;

      // todo: refactor.
      // eslint-disable-next-line no-restricted-syntax
      for (const key in steps) {
        if (Object.hasOwnProperty.call(steps, key)) {
          const element = steps[key];
          if (key === payload) {
            element.active = true;
          } else {
            element.active = false;
          }
        }
      }

      state.form.steps = steps;
    },

    setStepComplete(state, { payload }) {
      state.form.steps[payload.step] = {
        completed: true,
        active: false,
      };

      state.form.steps[payload.nextStep].active = true;
    },

    applyDiscount(_state) {
      // nothing to do
    },

    applyDiscountSuccess(state, { payload }) {
      state.booking = payload;
    },

    applyDiscountFailed(_state) {
      // nothing to do
    },

    cancelDiscount(_state) {
      // nothing to do
    },

    cancelDiscountSuccess(state, { payload }) {
      state.booking = payload;
    },

    cancelDiscountFailed(_state) {
      // nothing to do
    },

    setErrors(state, { payload }) {
      state.form.errors = payload;
    },

    setFieldError(state, { payload }) {
      state.form.errors[payload.path] = payload.message;
    },

    removeFieldError(state, { payload }) {
      state.form.errors[payload] = undefined;
    },

    setAvailableExtras(state, { payload }) {
      state.availableExtras = payload;
    },

    setExtras(state, { payload }) {
      state.booking.extras = payload;
    },

    addExtra(_state) {
      // nothing to do
    },

    removeExtra(_state) {
      // nothing to do
    },
  },
});

const { actions, reducer } = basketSlice;

export const {
  addExtra,
  applyDiscount,
  applyDiscountFailed,
  applyDiscountSuccess,
  cancelDiscount,
  cancelDiscountFailed,
  cancelDiscountSuccess,
  completeStep,
  completeStepFailed,
  completeStepSuccess,
  createBasket,
  createBasketFailed,
  createBasketSuccess,
  finaliseRequest,
  load,
  loadFailed,
  loadSuccess,
  loadTotals,
  loadTotalsFailed,
  loadTotalsSuccess,
  removeExtra,
  removeFieldError,
  setActiveStep,
  setAvailableExtras,
  setBasket,
  setErrors,
  setExtras,
  setFieldError,
  setFieldValue,
  setSigningIn,
  setStepComplete,
} = actions;

export default reducer;
