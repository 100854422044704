import * as actionTypes from './actionTypes';

export const loadVehicle = (payload) => ({
  type: actionTypes.LOAD_VEHICLE,
  payload,
});

export const loadVehicleSuccess = () => ({
  type: actionTypes.LOAD_VEHICLE_SUCCESS,
});

export const loadVehicleFailed = () => ({
  type: actionTypes.LOAD_VEHICLE_FAILED,
});

export const setVehicle = (payload) => ({
  type: actionTypes.SET_VEHICLE,
  payload,
});

export const setAllFeatures = (payload) => ({
  type: actionTypes.SET_ALL_FEATURES,
  payload,
});

export const loadTotals = () => ({
  type: actionTypes.LOAD_TOTALS,
});

export const loadTotalsSuccess = () => ({
  type: actionTypes.LOAD_TOTALS_SUCCESS,
});

export const loadTotalsFailed = () => ({
  type: actionTypes.LOAD_TOTALS_FAILED,
});

export const setTotals = (payload) => ({
  type: actionTypes.SET_TOTALS,
  payload,
});
