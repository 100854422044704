export const getBookings = (state) => state.hostAllBookings.bookings;
export const getStatusFilter = (state) => state.hostAllBookings.statusFilter;
export const getCurrentPage = (state) => state.hostAllBookings.currentPage;
export const getVehicles = (state) => state.hostAllBookings.vehicles;
export const getCounts = (state) => state.hostAllBookings.counts;
export const getLoading = (state) => state.hostAllBookings.loading;
export const getPageLoading = (state) => state.hostAllBookings.pageLoading;
export const getActiveFilters = (state) => state.hostAllBookings.activeFilters;
export const getAvailableFilters = (state) => state.hostAllBookings.availableFilters;
export const getEditFilters = (state) => state.hostAllBookings.editFilters;
export const getSorting = (state) => state.hostAllBookings.sorting;
