import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Box, Typography, useMediaQuery } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { Button } from '@evee/evee-ui.base';
import { formatPriceNumber } from '@evee/evee-ui.utils';

import useRegion from 'store/modules/app/hooks/useRegion';

import backgroundImg from 'widgets/home/images/insurance_background.jpg';

import styles from './styles';

const MobileListYourCar = ({ classes }) => {
  const { currency } = useRegion();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const titleJsx = (
    <>
      List your EV, {!isMobile && <br />}
      earn extra cash
    </>
  );

  const subtitleJsx = (
    <>
      Earn up to {formatPriceNumber(2000)} {currency.id}/month by sharing your car with others
    </>
  );

  return (
    <Box className={classes.titleContainer}>
      <img src={backgroundImg} alt="" className={classes.image} />

      <Typography paragraph className={classes.title}>
        {titleJsx}
      </Typography>

      <Typography paragraph className={classes.subtitle}>
        {subtitleJsx}
      </Typography>

      <Button
        text="Rent out your EV"
        size="large"
        component={NavLink}
        to="/car-host"
        style={{ width: '100%' }}
      />
    </Box>
  );
};

MobileListYourCar.propTypes = {
  classes: PropTypes.shape({
    titleContainer: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
  }).isRequired,
};

MobileListYourCar.defaultProps = {};

export default withStyles(styles)(MobileListYourCar);
