import { SortDirection } from '@evee/evee-ui.enums';

export const initialFilters = {
  models: [],
};

export default {
  loading: false,
  pageLoading: false,
  statusFilter: null,
  currentPage: 1,
  vehicles: [],
  bookings: {
    items: [],
    totalPages: 0,
    total: 0,
  },
  counts: {
    all: 0,
    upcoming: 0,
    requested: 0,
    ongoing: 0,
    completed: 0,
    cancelled: 0,
  },
  activeFilters: {
    ...initialFilters,
  },
  availableFilters: {
    ...initialFilters,
  },
  editFilters: {
    ...initialFilters,
  },
  sorting: {
    fieldName: 'from',
    direction: SortDirection.descending,
  },
};
