import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { Box, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { ComboBox } from '@evee/evee-ui.base';

import options from './options';
import styles from './styles';

const getOptions = (min, max) =>
  options.filter((o) => (min === null || o.value >= min) && (max === null || o.value <= max));

const TimeSelector = ({
  label,
  value,
  max,
  min,
  defaultOptionGetter,
  disabled,
  classes,
  onChange,
  onOpen,
  onClose,
}) => {
  const filteredOptions = useMemo(() => getOptions(min, max), [min, max]);
  useEffect(() => {
    if (!filteredOptions.some((o) => o.value === value)) {
      const option = defaultOptionGetter(filteredOptions);
      onChange(option ? option.value : null);
    }
  }, [min, max]);

  return (
    <Box display="flex" alignItems="center" flexDirection="row">
      <Typography className={classes.label}>{label}</Typography>
      <ComboBox
        disabled={disabled}
        value={value}
        items={filteredOptions}
        valueName="value"
        displayName="display"
        className={classes.combobox}
        onChange={onChange}
        onOpen={(e) => onOpen(e)}
        onClose={(e) => onClose(e)}
      />
    </Box>
  );
};

TimeSelector.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  defaultOptionGetter: PropTypes.func,
  disabled: PropTypes.bool,
  classes: PropTypes.shape({
    label: PropTypes.string.isRequired,
    combobox: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
};

TimeSelector.defaultProps = {
  min: null,
  max: null,
  defaultOptionGetter: (o) => o[0],
  disabled: false,
  onOpen: () => {},
  onClose: () => {},
};

export default withStyles(styles)(TimeSelector);
