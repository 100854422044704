import { takeEvery } from 'redux-saga/effects';

import * as customerActionsTypes from 'store/modules/customer/actionTypes';

import { loadAccountInfo } from './utils';

function* listenLoadAccountInfo() {
  yield takeEvery(customerActionsTypes.LOAD, loadAccountInfo);
}

export default listenLoadAccountInfo;
