import { takeEvery } from 'redux-saga/effects';

import * as staticPageActionsTypes from 'store/modules/staticPage/actionTypes';

import { load } from './utils';

function* listenLoad() {
  yield takeEvery(staticPageActionsTypes.LOAD, load);
}

export default listenLoad;
