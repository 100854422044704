const styles = (theme) => ({
  actionButton: {
    marginTop: theme.spacing(4),
  },
  checkIcon: {
    width: '64px',
    height: '64px',
    margin: theme.spacing(3, 0),
  },
});

export default styles;
