import { connect } from 'react-redux';

import { closeToast } from 'store/modules/app/actions';

import Toast from './Toast';

const mapStateToProps = ({ app: { toast } }) => ({
  ...toast,
});

const mapDispatchToProps = {
  close: closeToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(Toast);
