import selectionMode from 'widgets/inputs/Calendar/utils/ModeSelector/selectionMode';

export default {
  calendarMode: selectionMode.range,
  currentMonth: {
    year: new Date().getFullYear(),
    month: new Date().getMonth(),
  },
  range: {
    startDate: null,
    endDate: null,
  },
  priceRanges: [],
  selectedPriceRange: {},
  loading: false,
};
