import PropTypes from 'prop-types';

import { Hidden } from '@material-ui/core';

const OnlyTabletAndDesktop = ({ children }) => <Hidden smDown>{children}</Hidden>;

OnlyTabletAndDesktop.propTypes = {
  children: PropTypes.node.isRequired,
};

export default OnlyTabletAndDesktop;
