import PropTypes from 'prop-types';
import { useEffect } from 'react';

import { getHomeUrl } from '@evee/evee-ui.utils';

import useRegion from 'store/modules/app/hooks/useRegion';

import LandingCarousel from '../LandingCarousel';

import BlogPostCard from './BlogPostCard';

const TopBlogPosts = ({ blogPosts, loading, onLoad }) => {
  const { region } = useRegion();

  useEffect(() => {
    onLoad();
  }, []);

  const renderCard = (item, l) => <BlogPostCard post={item} loading={l} />;

  return (
    <LandingCarousel
      title="evee Blog"
      linkText="See all posts"
      linkTarget={`${getHomeUrl(region)}/blog/`}
      items={blogPosts}
      loading={loading}
      renderCard={renderCard}
    />
  );
};

TopBlogPosts.propTypes = {
  blogPosts: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
      thumbnail: PropTypes.string,
      publishedDate: PropTypes.string,
    }),
  ),
  loading: PropTypes.bool,
  onLoad: PropTypes.func,
};

TopBlogPosts.defaultProps = {
  blogPosts: [],
  loading: false,
  onLoad: () => {},
};

export default TopBlogPosts;
