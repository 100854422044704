import { call, put } from 'redux-saga/effects';

import { apiService, tagManager } from '@evee/evee-ui.services';
import { BookingStatus } from '@evee/evee-ui.enums';

import * as appActions from 'store/modules/app/actions';
import * as bookingCardActions from 'store/modules/bookingCard';

export function* processBooking(bookingId, apiMethod, status, cancellationReason = '') {
  try {
    yield put(appActions.setLoading(true));
    yield call(apiMethod, bookingId, cancellationReason);
    yield put(bookingCardActions.setStatus({ bookingId, status }));
  } catch (error) {
    yield put(appActions.showError(error.message));
  } finally {
    yield put(appActions.setLoading(false));
  }
}

export function* confirmBooking({ payload: booking }) {
  yield tagManager.pushBookingEvent(
    'Booking confirmed',
    `${booking._id} - ${booking.vehicle.name}`,
  );
  yield processBooking(booking.id, apiService.booking.confirm, BookingStatus.confirmed);
}

export function* rejectBooking({ payload: { booking, cancellationReason } }) {
  yield tagManager.pushBookingEvent('Booking rejected', `${booking._id} - ${booking.vehicle.name}`);
  yield processBooking(
    booking.id,
    apiService.booking.reject,
    BookingStatus.rejected,
    cancellationReason,
  );
}

export function* cancelBooking({ payload: { booking, cancellationReason } }) {
  yield tagManager.pushBookingEvent(
    'Booking cancelled',
    `${booking._id} - ${booking.vehicle.name}`,
  );
  yield processBooking(
    booking.id,
    apiService.booking.cancel,
    BookingStatus.cancelled,
    cancellationReason,
  );
}
