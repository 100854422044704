import qs from 'qs';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { storage } from '@evee/evee-ui.services';

export default function () {
  const location = useLocation();

  useEffect(() => {
    if (location.search) {
      const { cfclick } = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });

      if (cfclick) {
        storage.cfClick.save(cfclick);
      }
    }
  }, []);
}
