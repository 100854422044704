import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import { getQueryString } from 'saga/search/utils';

import FooterColumn from '../utils/FooterColumn';
import FooterLink from '../utils/FooterLink';

const TopCitiesColumn = ({ topCities, tripDates, onLoad }) => {
  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <FooterColumn title="Top cities">
      {tripDates !== null &&
        topCities.map((tc, index) => (
          <React.Fragment key={index}>
            <FooterLink
              component={NavLink}
              to={`/search?${getQueryString(tc, {
                ...tripDates,
                flexibleDates: true,
              })}`}
            >
              {tc.name}
            </FooterLink>
          </React.Fragment>
        ))}
    </FooterColumn>
  );
};

TopCitiesColumn.propTypes = {
  topCities: PropTypes.array,
  tripDates: PropTypes.object,
  onLoad: PropTypes.func,
};

TopCitiesColumn.defaultProps = {
  topCities: [],
  tripDates: null,
  onLoad: () => {},
};

export default TopCitiesColumn;
