import { all, call, put, select } from 'redux-saga/effects';
import { ValidationError } from 'yup';

import { apiService, history, tagManager } from '@evee/evee-ui.services';
import { password as passwordModel } from '@evee/evee-ui.models';

import * as appActions from 'store/modules/app/actions';
import * as signInActions from 'store/modules/auth/signIn';
import * as verifyEmailActions from 'store/modules/auth/verifyEmail';

import * as signUpActions from 'store/modules/auth/signUp';
import * as signUpSelectors from 'store/modules/auth/signUp/selectors';

import * as authActions from 'store/modules/auth';
import * as authSelectors from 'store/modules/auth/selectors';

import * as forgetPasswordActions from 'store/modules/auth/forgotPassword';
import * as forgetPasswordSelectors from 'store/modules/auth/forgotPassword/selectors';

import * as resetPasswordActions from 'store/modules/auth/resetPassword';
import * as resetPasswordSelectors from 'store/modules/auth/resetPassword/selectors';

import { showError } from 'store/modules/app/actions';

const redirectUrls = [
  'listing',
  'checkout',
  'profile',
  'bookingConfirmation',
  'account/my',
  'account/edit',
  'account/personal-info',
  'account/payments-and-payouts',
  'account/login-and-security',
  'account/bookings',
  'account/cars',
  'account/trips',
  'account/notifications',
  'account/rewards',
  'account/messages',
  'account/all-bookings',
];

export function* cancelSignIn() {
  const signingIn = yield select(authSelectors.getIsSigningIn);
  if (signingIn) {
    yield put(authActions.setSigningIn({ value: false }));
  }
}

export function* forgotPassword() {
  try {
    const { email } = yield select(forgetPasswordSelectors.getForgotPassword);

    yield call(apiService.customer.forgotPassword, email);

    yield put(
      appActions.showSuccess('Instructions for resetting your password were sent by email'),
    );
    yield put(forgetPasswordActions.forgotPasswordSuccess());
  } catch (err) {
    yield put(forgetPasswordActions.forgotPasswordFailed(err.message));
  }
}

export function* resetPassword() {
  try {
    const { password, confirmPassword, token } = yield select(
      resetPasswordSelectors.getResetPassword,
    );

    yield passwordModel.resetPasswordSchema.validate(
      { password, confirmPassword },
      {
        abortEarly: false,
      },
    );

    yield call(apiService.customer.resetPassword, { password, token });

    yield put(signInActions.showSignIn());
    yield put(resetPasswordActions.resetPasswordSuccess());
  } catch (err) {
    if (err instanceof ValidationError) {
      const validationErrors = Object.assign(
        ...err.inner.map((e) => ({
          [e.path]: e.message,
        })),
      );
      yield put(resetPasswordActions.setErrors(validationErrors));
    }
    yield put(resetPasswordActions.resetPasswordFailed(err.message));
  }
}

export function* signInSuccess() {
  const [signingIn, signingInPath] = yield all([
    select(authSelectors.getIsSigningIn),
    select(authSelectors.getSigningInPath),
  ]);

  if (signingIn) {
    yield put(authActions.setSigningIn({ value: false }));
    history.push(signingInPath);
  }
}

export function* signout() {
  try {
    yield call(apiService.customer.signout);
    window.customerStripe = undefined;

    yield put(signInActions.resetForm());

    // todo: create some configuration for site urls that require redirection to home page.
    if (redirectUrls.some((url) => window.location.pathname.includes(url))) {
      history.push('/');
    }
  } catch (err) {
    yield put(showError(err.message));
  }
}

export function* signUp() {
  try {
    const { firstName, lastName, email, password } = yield select(signUpSelectors.getSignUp);

    yield passwordModel.signUpSchema.validate(
      { firstName, lastName, email, password },
      {
        abortEarly: false,
      },
    );

    const user = yield call(apiService.customer.signUp, {
      firstName,
      lastName,
      email,
      password,
    });

    tagManager.pushSignUp({
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      method: 'Standard',
    });

    yield put(verifyEmailActions.showVerifyEmail(email));
    yield put(signUpActions.signUpSuccess(user));
  } catch (err) {
    if (err instanceof ValidationError) {
      const validationErrors = Object.assign(
        ...err.inner.map((e) => ({
          [e.path]: e.message,
        })),
      );
      yield put(signUpActions.setErrors(validationErrors));
    }
    yield put(signUpActions.signUpFailed(err.message));
  }
}

export function* verifyEmail({ payload: token }) {
  try {
    yield put(appActions.setLoading(true));
    yield call(apiService.customer.verifyEmail, token);
    yield put(appActions.showSuccess('Your email address has been successfully verified'));
  } catch (err) {
    yield put(appActions.showError(err.message));
  } finally {
    yield put(appActions.setLoading(false));
  }
}

export function* validateSignUpFieldValue({ payload }) {
  try {
    yield passwordModel.signUpSchema.fields[payload.fieldName].validate(payload.fieldValue);
    yield put(signUpActions.removeFieldError(payload.fieldName));
  } catch (err) {
    if (err instanceof ValidationError) {
      yield put(
        signUpActions.setFieldError({ fieldName: payload.fieldName, fieldValue: err.message }),
      );
    }
  }
}

export function* validateResetPasswordFieldValue({ payload }) {
  try {
    const context = {};
    if (payload.fieldName === 'confirmPassword') {
      context.password = yield select(resetPasswordSelectors.getPassword);
    }

    yield passwordModel.resetPasswordSchema.fields[payload.fieldName].validate(
      payload.fieldValue,
      context,
    );
    yield put(resetPasswordActions.removeFieldError(payload.fieldName));
  } catch (err) {
    if (err instanceof ValidationError) {
      yield put(
        resetPasswordActions.setFieldError({
          fieldName: payload.fieldName,
          fieldValue: err.message,
        }),
      );
    }
  }
}

export function* verifyReCaptchaV2({ payload: token }) {
  try {
    const result = yield call(apiService.customer.reCaptchaVerify, token, 'v2');
    if (result?.success === false) {
      yield put(appActions.showError('Unexpected reCAPTCHA verification error. Try again please.'));
    }
    yield put(signUpActions.setReCaptchaV2(result));
  } catch (err) {
    yield put(appActions.showError(err.message));
  }
}

export function* verifyReCaptchaV3({ payload: token }) {
  try {
    const result = yield call(apiService.customer.reCaptchaVerify, token, 'v3');
    yield put(signUpActions.setReCaptchaV3(result));
  } catch (err) {
    yield put(appActions.showError(err.message));
  }
}
