/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import initialState from './initialState';

export const bookingDetailsSlice = createSlice({
  name: 'extras',
  initialState,
  reducers: {
    load(state) {
      state.loading = true;
    },

    loadSuccess(state, { payload }) {
      state.loading = false;
      state.availableExtras = payload;
    },

    loadFailed(state) {
      state.loading = false;
    },

    setLoading(state, { payload }) {
      state.loading = payload;
    },

    addExtra(state) {
      state.loading = true;
    },

    addExtraSuccess(state) {
      state.loading = false;
    },

    addExtraFailed(state) {
      state.loading = false;
    },

    removeExtra(state) {
      state.loading = true;
    },

    removeExtraSuccess(state) {
      state.loading = false;
    },

    removeExtraFailed(state) {
      state.loading = false;
    },
  },
});

const { actions, reducer } = bookingDetailsSlice;

export const {
  load,
  loadSuccess,
  loadFailed,
  setLoading,
  addExtra,
  addExtraFailed,
  addExtraSuccess,
  removeExtra,
  removeExtraFailed,
  removeExtraSuccess,
} = actions;

export default reducer;
