import { all, put, select } from 'redux-saga/effects';
import { ValidationError } from 'yup';

import { CheckoutSteps, ErrorMessage } from '@evee/evee-ui.enums';
import { basket as basketModel } from '@evee/evee-ui.models';

import * as basketActions from 'store/modules/basket';
import * as basketSelectors from 'store/modules/basket/selectors';
import * as declarationSelectors from 'store/modules/profile/declaration/selectors';
import * as licenceSelectors from 'store/modules/profile/licence/selectors';

function* yourInfoIsValid(schema) {
  const yourInfo = yield select(basketSelectors.getYourInfo);
  const isValid = yield schema.isValid(yourInfo);
  return isValid;
}

function* licenceIsValid(schema) {
  const [licence, { status: declarationStatus }] = yield all([
    select(licenceSelectors.getLicence),
    select(declarationSelectors.getDeclaration),
  ]);

  const licenceAndDeclaration = {
    licence,
    declarationStatus,
    questionnaire: [],
  };

  return yield schema.isValid(licenceAndDeclaration);
}

function* validateBasket(schema, data) {
  try {
    yield schema.validate(data, {
      abortEarly: false,
      recursive: true,
    });
  } catch (err) {
    if (err instanceof ValidationError) {
      const validationErrors = Object.assign(
        ...err.inner.map((e) => ({
          [e.path]: e.message,
        })),
      );
      yield put(basketActions.setErrors(validationErrors));
    }
    throw new Error(ErrorMessage.completeRequiredFields);
  }
}

function* validateFieldValue({ payload }) {
  try {
    yield basketModel.basketSchema.fields[payload.fieldName].validate(payload.fieldValue);
    yield put(basketActions.removeFieldError(payload.fieldName));
  } catch (err) {
    if (err instanceof ValidationError) {
      yield put(basketActions.setFieldError(payload.fieldName, err.message));
    }
  }
}

function* completeValidSteps() {
  if (yield yourInfoIsValid(basketModel.yourInfoSchema)) {
    yield put(
      basketActions.setStepComplete({
        step: CheckoutSteps.yourInfo,
        nextStep: CheckoutSteps.verification,
      }),
    );
  } else {
    return;
  }

  if (yield licenceIsValid(basketModel.licenceAndDeclarationSchema)) {
    yield put(
      basketActions.setStepComplete({
        step: CheckoutSteps.verification,
        nextStep: CheckoutSteps.extras,
      }),
    );
  }
}

export { completeValidSteps, validateBasket, validateFieldValue };
