import * as actionTypes from './actionTypes';

export const setFieldValue = (fieldName, value) => ({
  type: actionTypes.SET_FIELD_VALUE,
  payload: { fieldName, value },
});

export const search = () => ({
  type: actionTypes.SEARCH,
});

export const setQueryFilters = (filters) => ({
  type: actionTypes.SET_QUERY_FILTERS,
  payload: filters,
});

export const setAirportSearch = (payload) => ({
  type: actionTypes.SET_AIRPORT_SEARCH,
  payload,
});

export const changeLocation = (location) => ({
  type: actionTypes.CHANGE_LOCATION,
  payload: location,
});

export const changeLocationSuccess = (location) => ({
  type: actionTypes.CHANGE_LOCATION_SUCCESS,
  payload: location,
});

export const changeLocationFailed = () => ({
  type: actionTypes.CHANGE_LOCATION_FAILED,
});

export const updateAirportSearch = (location) => ({
  type: actionTypes.UPDATE_AIRPORT_SEARCH,
  payload: location,
});
