import { useDispatch, useSelector } from 'react-redux';

import * as profileActions from '../actions';
import * as profileSelectors from '../selectors';

export default function useEditEmail() {
  const dispatch = useDispatch();
  const email = useSelector(profileSelectors.getEmail);
  const emailVerified = useSelector(profileSelectors.getEmailVerified);
  const errors = useSelector(profileSelectors.getErrors);

  const onUpdateEmail = (v) => dispatch(profileActions.changeEmail(v));

  return { email, emailVerified, errors, onUpdateEmail };
}
