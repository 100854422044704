import React from 'react';
import { SvgIcon } from '@material-ui/core';

import theme from 'theme';

const LocationArrow = (props) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M17.625 2.38338C17.3066 2.05885 16.9029 1.83106 16.4605 1.72636C16.0181 1.62166 15.5551 1.64432 15.125 1.79172L3.33333 5.73338C2.85776 5.88905 2.44224 6.18851 2.14413 6.59041C1.84602 6.99232 1.68001 7.47686 1.66904 7.97714C1.65806 8.47742 1.80266 8.96877 2.08286 9.38336C2.36306 9.79795 2.76505 10.1153 3.23333 10.2917L7.6 11.9584C7.69943 11.9964 7.78997 12.0545 7.86595 12.1291C7.94194 12.2036 8.00176 12.293 8.04167 12.3917L9.70833 16.7667C9.87799 17.2284 10.1859 17.6265 10.59 17.9069C10.9941 18.1872 11.4748 18.3361 11.9667 18.3334H12.025C12.5259 18.3243 13.0113 18.1587 13.4133 17.8598C13.8153 17.5609 14.1137 17.1437 14.2667 16.6667L18.2083 4.85838C18.3517 4.4325 18.373 3.975 18.2699 3.53762C18.1669 3.10024 17.9435 2.70043 17.625 2.38338ZM16.6667 4.33338L12.6833 16.15C12.6371 16.2996 12.5442 16.4304 12.4182 16.5234C12.2923 16.6164 12.1399 16.6666 11.9833 16.6667C11.8277 16.6693 11.675 16.6244 11.5455 16.5381C11.416 16.4517 11.3158 16.328 11.2583 16.1834L9.59167 11.8084C9.47084 11.4905 9.28449 11.2016 9.04473 10.9604C8.80498 10.7192 8.51717 10.5311 8.2 10.4084L3.825 8.74172C3.67741 8.68763 3.55054 8.58855 3.46232 8.45846C3.3741 8.32836 3.32898 8.17384 3.33333 8.01672C3.33346 7.86017 3.38368 7.70777 3.47665 7.58182C3.56961 7.45587 3.70044 7.36297 3.85 7.31672L15.6667 3.37505C15.8023 3.31975 15.9511 3.30494 16.095 3.3324C16.2389 3.35986 16.3718 3.42842 16.4776 3.52979C16.5834 3.63116 16.6575 3.76099 16.6911 3.9036C16.7246 4.04621 16.7161 4.19549 16.6667 4.33338Z"
      fill={theme.palette.primary.main}
    />
  </SvgIcon>
);

LocationArrow.displayName = 'LocationArrowIcon';
LocationArrow.muiName = 'SvgIcon';

export default LocationArrow;
