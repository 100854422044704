import PropTypes from 'prop-types';

import { Hidden } from '@material-ui/core';

const OnlyDesktop = ({ children }) => <Hidden mdDown>{children}</Hidden>;

OnlyDesktop.propTypes = {
  children: PropTypes.node.isRequired,
};

OnlyDesktop.defaultProps = {};

export default OnlyDesktop;
