import PropTypes from 'prop-types';

import { Box, Drawer, Fade, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { Button, Link } from '@evee/evee-ui.base';

import styles from './styles';

const CookieNotification = ({ cookiesAccepted, acceptCookies, classes }) => {
  const paperProps = {
    style: {
      zIndex: 1000000, // To hide zendesk widget which has 999999
    },
  };

  return (
    <Fade in={!cookiesAccepted} timeout={1000}>
      <Drawer elevation={0} variant="permanent" anchor="bottom" PaperProps={paperProps}>
        <Grid container alignItems="center" className={classes.drawerContainer}>
          <Grid item xs={12} md={11}>
            <Typography style={{ padding: '5px 15px' }}>
              Electric cars do not run on cookies, but our website does. So by continuing, you agree
              to our use of cookies, the digital ones. Want to know more - check our&nbsp;
              <Link href="/privacy">privacy policy</Link>.
            </Typography>
          </Grid>
          <Grid item xs={12} md={1}>
            <Box display="flex" justify="center">
              <Button
                type="outlined"
                color="primary"
                text="Close"
                className={classes.acceptButton}
                onClick={() => acceptCookies()}
              />
            </Box>
          </Grid>
        </Grid>
      </Drawer>
    </Fade>
  );
};

CookieNotification.propTypes = {
  cookiesAccepted: PropTypes.bool.isRequired,
  acceptCookies: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    drawerContainer: PropTypes.string,
    acceptButton: PropTypes.string,
  }).isRequired,
};

export default withStyles(styles)(CookieNotification);
