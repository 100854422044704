/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { CREATE_SUCCESS, LOAD_SUCCESS } from '../paymentMethods/actionTypes';
import initialState from './initialState';

export const authorisePaymentSlice = createSlice({
  name: 'authorisePayment',
  initialState,
  reducers: {
    setOpen(state, { payload }) {
      state.open = payload.open;
      state.dialogId = payload.open ? payload.dialogId : '';
    },

    setSelectedCard(state, { payload }) {
      state.selectedCard = payload;
    },

    setLoading(state, { payload }) {
      state.loading = payload;
    },

    authoriseSecurityDeposit(state) {
      state.loading = true;
    },

    authoriseSecurityDepositSuccess(state) {
      state.loading = false;
    },

    authoriseSecurityDepositFailed(state) {
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(LOAD_SUCCESS, (state, { payload }) => {
        if (payload && payload.length > 0) {
          state.selectedCard = payload[0].id;
        }
      })
      .addCase(CREATE_SUCCESS, (state, { payload }) => {
        if (payload && payload.id) {
          state.selectedCard = payload.id;
        }
      });
  },
});

const { actions, reducer } = authorisePaymentSlice;

export const {
  setOpen,
  setSelectedCard,
  setLoading,
  authoriseSecurityDeposit,
  authoriseSecurityDepositSuccess,
  authoriseSecurityDepositFailed,
} = actions;

export default reducer;
