const supportUrlAU = 'https://evee.support/hc/en-au';
const supportUrlNZ = 'https://evee.support/hc/en-nz';

export default {
  AU: {
    helpCenter: supportUrlAU,
    addingExtraDriver: `${supportUrlAU}/articles/18606156790681-Adding-Additional-Drivers`,
    choosingDelivery: `${supportUrlAU}/articles/20153523131929-How-Do-I-Add-Delivery-To-My-Rental-`,
    cleaningYourRental: `${supportUrlAU}/articles/20868790931737-evee-Cleaning-Guide`,
    contactSupport: `${supportUrlAU}/requests/new`,
    extraCharges: `${supportUrlAU}/articles/20044087779097-Incurring-and-Paying-for-Extra-Charges`,
    getHelpRenters: `${supportUrlAU}/categories/18100743912473-Renting-through-evee`,
    getHelpHosts: `${supportUrlAU}/categories/18100960782233-Hosting-on-evee`,
    gettingStarted: `${supportUrlAU}/sections/21271199823129--Get-Started`,
    petsAndSmoking: `${supportUrlAU}/articles/18175597128089-Assistant-and-Emotional-Support-Animals`,
    reduceDamageLiability: `${supportUrlAU}/articles/20125013517849-What-is-RentalCover-and-How-Does-It-Work-`,
    securityDeposit: `${supportUrlAU}/articles/19569386104729-How-is-My-Security-Deposit-Processed-`,
    whyWeCollectData: `${supportUrlAU}/articles/18174849516825-Data-Collection-and-Privacy`,
    cancellationPolicy: `${supportUrlAU}/articles/18177380591257-evee-s-Cancellation-Policy`,
    howPaymentWorks: `${supportUrlAU}/articles/19568083384857-How-Pricing-and-Payment-Works`,
    pricingAndPayouts: `${supportUrlAU}/articles/21271524728217-How-payment-works`,
    taxAndGstOverview: `${supportUrlAU}/articles/21271512348825-Tax-and-GST-overview`,
    listingYourCarOnEvee: `${supportUrlAU}/articles/24138607624473-How-Do-I-Create-a-Listing-`,
    gettingPaid: `${supportUrlAU}/articles/21271524728217-How-payment-works`,
    makingChangesToYourBooking: `${supportUrlAU}/articles/21135161540505-Making-Changes-and-Extending-Your-Booking`,
    setupYourAccount: `${supportUrlAU}/articles/21271318144281-Set-up-your-account`,
    carIsDamaged: `${supportUrlAU}/articles/21271465181593-What-if-my-car-is-damaged-`,
    eveeProtectTerms: `${supportUrlAU}/articles/29552864870297-Terms-and-conditions-for-Renters#20-evee-protect`,
    eveeProtectHowItWorks: `${supportUrlAU}/articles/20125013517849-What-is-evee-Protect-and-How-Does-It-Work`,
    ctpInsuranceRequirements: `${supportUrlAU}/articles/21271438003353-CTP-insurance-requirements-for-your-state`,
    rewardsTerms: `${supportUrlAU}/articles/29787992464409-evee-Rewards-Terms-and-Conditions`,
    reportAccident: 'https://www.evee.com.au/report-damage',
    howItWorks: 'https://www.evee.com.au/hosting/',
  },
  NZ: {
    helpCenter: supportUrlNZ,
    addingExtraDriver: `${supportUrlNZ}/articles/18606156790681-Adding-Additional-Drivers`,
    choosingDelivery: `${supportUrlNZ}/articles/20153523131929-How-Do-I-Add-Delivery-To-My-Rental-`,
    cleaningYourRental: `${supportUrlNZ}/articles/20868790931737-evee-Cleaning-Guide`,
    contactSupport: `${supportUrlNZ}/requests/new`,
    extraCharges: `${supportUrlNZ}/articles/20044087779097-Incurring-and-Paying-for-Extra-Charges`,
    getHelpRenters: `${supportUrlNZ}/categories/18100743912473-Renting-through-evee`,
    getHelpHosts: `${supportUrlNZ}/categories/18100960782233-Hosting-on-evee`,
    gettingStarted: `${supportUrlNZ}/sections/21271199823129--Get-Started`,
    petsAndSmoking: `${supportUrlNZ}/articles/18175597128089-Assistant-and-Emotional-Support-Animals`,
    reduceDamageLiability: `${supportUrlNZ}/articles/20125013517849-What-is-RentalCover-and-How-Does-It-Work-`,
    securityDeposit: `${supportUrlNZ}/articles/19569386104729-How-is-My-Security-Deposit-Processed-`,
    whyWeCollectData: `${supportUrlNZ}/articles/18174849516825-Data-Collection-and-Privacy`,
    cancellationPolicy: `${supportUrlNZ}/articles/18177380591257-evee-s-Cancellation-Policy`,
    howPaymentWorks: `${supportUrlNZ}/articles/19568083384857-How-Pricing-and-Payment-Works`,
    pricingAndPayouts: `${supportUrlNZ}/articles/21271524728217-How-payment-works`,
    taxAndGstOverview: `${supportUrlNZ}/articles/21271512348825-Tax-and-GST-overview`,
    listingYourCarOnEvee: `${supportUrlNZ}/articles/24138607624473-How-Do-I-Create-a-Listing-`,
    gettingPaid: `${supportUrlNZ}/articles/21271524728217-How-payment-works`,
    makingChangesToYourBooking: `${supportUrlNZ}/articles/21135161540505-Making-Changes-and-Extending-Your-Booking`,
    setupYourAccount: `${supportUrlNZ}/articles/21271318144281-Set-up-your-account`,
    carIsDamaged: `${supportUrlNZ}/articles/21271465181593-What-if-my-car-is-damaged-`,
    eveeProtectTerms: `${supportUrlNZ}/articles/29552864870297-Terms-and-conditions-for-Renters#20-evee-protect`,
    eveeProtectHowItWorks: `${supportUrlNZ}/articles/20125013517849-What-is-evee-Protect-and-How-Does-It-Work`,
    ctpInsuranceRequirements: `${supportUrlAU}/articles/21271438003353-CTP-insurance-requirements-for-your-state`,
    reportAccident: 'https://www.evee.com.au/report-damage',
    howItWorks: 'https://www.evee.com.au/hosting/',

    // NZ only
    certificateOfFitness: `${supportUrlNZ}/articles/24138786864153-How-To-Apply-for-COF`,
    transportServicesLicence: `${supportUrlNZ}/articles/24138750740889-How-To-Apply-for-a-TSL`,
  },
};
