/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const resetPasswordSlice = createSlice({
  name: 'auth/resetPassword',
  initialState: {},
  reducers: {
    showResetPassword: (state) => {
      state.resetPassword.open = true;
    },

    closeResetPassword: (state) => {
      state.resetPassword.open = false;
    },

    setResetPasswordFieldValue: (state, { payload }) => {
      state.resetPassword[payload.fieldName] = payload.fieldValue;
    },

    resetPassword: (state) => {
      state.resetPassword.loading = true;
    },

    resetPasswordSuccess: (state) => {
      state.resetPassword.success = true;
      state.resetPassword.open = false;
      state.resetPassword.loading = false;
    },

    resetPasswordFailed: (state, { payload }) => {
      state.resetPassword.errorMessage = payload;
      state.resetPassword.loading = false;
    },

    setErrors(state, { payload }) {
      state.resetPassword.errors = payload;
    },

    setFieldError(state, { payload }) {
      state.resetPassword.errors[payload.fieldName] = payload.fieldValue;
    },

    removeFieldError(state, { payload }) {
      state.resetPassword.errors[payload] = undefined;
    },
  },
});

const { actions, reducer } = resetPasswordSlice;

export const {
  showResetPassword,
  closeResetPassword,
  setResetPasswordFieldValue,
  resetPassword,
  resetPasswordSuccess,
  resetPasswordFailed,
  setErrors,
  setFieldError,
  removeFieldError,
} = actions;

export default reducer;
