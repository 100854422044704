import PropTypes from 'prop-types';
import { useState } from 'react';

import { Box, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { Button } from '@evee/evee-ui.base';
import { Region } from '@evee/evee-ui.enums';

import useRegion from 'store/modules/app/hooks/useRegion';
import useSearchProps from 'store/modules/search/hooks/useSearchProps';

import FullscreenCalendar from 'widgets/search/FullscreenCalendar';
import MobileSearchInputs from 'widgets/search/MobileSearchInputs';
import TextSlider from 'widgets/home/TextSlider';

import styles from './styles';

const MobileSearch = ({ classes }) => {
  const [tripDates, location, loading, onChangeLocation, onFieldChanged, onSearch] =
    useSearchProps();
    const { region } = useRegion();

    const headerText = region.id === Region.australia.id
    ? 'Australia’s EV Rental & Car Sharing'
    : 'New Zealand\'s EV Rental & Car Sharing';

  const [calendarOpen, setCalendarOpen] = useState(false);

  return (
    <>
      <Box display="flex" flexDirection="column">
        <Box>
          <Typography gutterBottom component="h1" className={classes.title}>
            {headerText}
          </Typography>

          <TextSlider minHeight={83} justifyContent="left" className={classes.titleSecondary} />
        </Box>

        <MobileSearchInputs
          tripDates={tripDates}
          location={location}
          onLocationChange={onChangeLocation}
          onFlexibleDatesChange={(v) => onFieldChanged('flexibleDates', v)}
          onPickUpClick={() => setCalendarOpen(true)}
          onDropOffClick={() => setCalendarOpen(true)}
        />

        <Button
          size="large"
          disabled={loading}
          text="Find cars"
          style={{ marginTop: '8px' }}
          onClick={onSearch}
        />
      </Box>

      <FullscreenCalendar
        tripDates={tripDates}
        open={calendarOpen}
        onDatesChange={(v) => onFieldChanged('range', v)}
        onFlexibleChange={(v) => {
          onFieldChanged('flexibleDates', v);
        }}
        onClose={() => setCalendarOpen(false)}
      />
    </>
  );
};

MobileSearch.propTypes = {
  classes: PropTypes.shape({
    title: PropTypes.string.isRequired,
    titleSecondary: PropTypes.string.isRequired,
  }).isRequired,
};

MobileSearch.defaultProps = {};

export default withStyles(styles)(MobileSearch);
