import PropTypes from 'prop-types';
import { useEffect } from 'react';

import {
  EReCaptchaV2Size,
  EReCaptchaV2Theme,
  ReCaptchaProvider,
  ReCaptchaV2,
  ReCaptchaV3,
} from 'recaptcha-v2v3';

import { Box, Typography } from '@material-ui/core';
import { useTheme, withStyles } from '@material-ui/core/styles';
import FacebookIcon from '@material-ui/icons/Facebook';

import { Button, Link, Loader, PasswordInput, TextInput } from '@evee/evee-ui.base';
import { password as passwordModel } from '@evee/evee-ui.models';

import GoogleIcon from 'common/icons/Google';

import Dialog from '../../CommonDialog';
import styles from './styles';

const SignUp = ({
  reCaptchaV2,
  reCaptchaV3,
  signUp,
  isMobile,
  loading,
  classes,
  onSignIn,
  onSignUp,
  onFacebookSignUp,
  onGoogleSignUp,
  onVerifyReCaptchaV2,
  onVerifyReCaptchaV3,
  onResetReCaptchaV2,
  onFieldChanged,
  onClose,
}) => {
  const theme = useTheme();
  const { open, firstName, lastName, email, password, errorMessage, errors } = signUp;

  const showReCaptchaV2 = reCaptchaV3?.score <= 0.5 || reCaptchaV3?.success === false;

  const reCaptchaVerified = () => {
    const v2Verified = reCaptchaV2?.success;
    const v3Verified = reCaptchaV3?.success && reCaptchaV3?.score > 0.5;

    return v3Verified || v2Verified;
  };

  const canSignUp =
    passwordModel.signUpSchema.isValidSync({
      firstName,
      lastName,
      email,
      password,
    }) && reCaptchaVerified();

  const socialButtonClass = isMobile ? classes.mobileSocialButton : classes.desktopSocialButton;

  const actions = () => (
    <>
      <Typography className={classes.footerText}>Already have an evee account?</Typography>
      <Button text="Log in" type="outlined" color="primary" onClick={onSignIn} />
    </>
  );

  const onEnter = () => {
    if (!canSignUp) {
      return;
    }

    onSignUp();
  };

  const v2Callback = (token) => {
    if (typeof token === 'string') {
      onVerifyReCaptchaV2(token);
    } else if (typeof token === 'boolean' && !token) {
      onResetReCaptchaV2();
    }
  };

  const v3Callback = (token) => {
    if (typeof token === 'string') {
      onVerifyReCaptchaV3(token);
    }
  };

  useEffect(() => {
    if (reCaptchaV2.success === false && window.grecaptcha) {
      window.grecaptcha.reset();
      onResetReCaptchaV2();
    }
  }, [reCaptchaV2]);

  return (
    <Dialog
      decorateBackground
      open={open}
      title="Sign up"
      isMobile={isMobile}
      actions={actions}
      data-test-id="sign-up-dialog"
      onClose={onClose}
    >
      <ReCaptchaProvider
        hideV3Badge
        siteKeyV2={process.env.REACT_APP_RECAPTCHA_KEY_V2}
        siteKeyV3={process.env.REACT_APP_RECAPTCHA_KEY_V3}
        langCode="en"
      >
        <ReCaptchaV3 action="signUp" callback={v3Callback} />
        <Loader loading={loading}>
          <Box component="form">
            <Box className={classes.input} display="flex" flexDirection="row">
              <Box
                display="flex"
                flexDirection="column"
                alignItems="stretch"
                className={classes.splitContainerLeft}
              >
                <TextInput
                  value={firstName}
                  label="First name"
                  error={!!errors.firstName}
                  helperText={errors.firstName}
                  onEnter={onEnter}
                  onChange={(v) => onFieldChanged('firstName', v)}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="stretch"
                className={classes.splitContainerRight}
              >
                <TextInput
                  value={lastName}
                  label="Last name"
                  error={!!errors.lastName}
                  helperText={errors.lastName}
                  onEnter={onEnter}
                  onChange={(v) => onFieldChanged('lastName', v)}
                />
              </Box>
            </Box>

            <TextInput
              value={email}
              label="E-mail"
              name="email"
              error={!!errors.email || !!errorMessage}
              helperText={errors.email || errorMessage}
              className={classes.input}
              onEnter={onEnter}
              onChange={(v) => onFieldChanged('email', v)}
            />

            <PasswordInput
              value={password}
              label="Password"
              name="password"
              error={!!errors.password}
              helperText={errors.password}
              className={classes.input}
              InputProps={{
                inputProps: {
                  autocomplete: 'new-password',
                },
              }}
              onEnter={onEnter}
              onChange={(v) => onFieldChanged('password', v)}
            />

            {showReCaptchaV2 && (
              <ReCaptchaV2
                id="recaptcha-v2"
                callback={v2Callback}
                theme={EReCaptchaV2Theme.Light}
                size={EReCaptchaV2Size.Normal}
                className={classes.recaptcha}
              />
            )}

            <Button fullWidth disabled={!canSignUp} text="Sign Up with E-mail" onClick={onSignUp} />
          </Box>

          <Box className={classes.socialButtonsContainer}>
            {/* todo: Extract to FacebookButton component */}
            <Button
              text="Sign Up with Facebook"
              startIcon={<FacebookIcon className={classes.facebookIcon} />}
              className={socialButtonClass}
              style={{
                color: theme.palette.common.white,
                backgroundColor: theme.palette.common.navyBlue,
                borderRadius: !isMobile
                  ? `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`
                  : '',
              }}
              onClick={onFacebookSignUp}
            />
            <Button
              text="Sign Up with Google"
              type="outlined"
              color="primary"
              startIcon={<GoogleIcon />}
              className={socialButtonClass}
              style={{
                borderRadius: !isMobile
                  ? `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`
                  : '',
              }}
              onClick={onGoogleSignUp}
            />
          </Box>

          <Typography variant="body2" className={classes.descriptionContainer}>
            By signing up, I agree to <Link href="/terms">evee&apos;s Terms and Conditions,</Link>
            <br />
            <Link href="/pricing">Payments Policy</Link>
            {' and '}
            <Link href="/privacy">Privacy policy</Link>
          </Typography>
        </Loader>
      </ReCaptchaProvider>
    </Dialog>
  );
};

SignUp.propTypes = {
  reCaptchaV2: PropTypes.object,
  reCaptchaV3: PropTypes.object,
  signUp: PropTypes.object,
  isMobile: PropTypes.bool,
  errors: PropTypes.object,
  loading: PropTypes.bool,
  classes: PropTypes.shape({
    label: PropTypes.string.isRequired,
    input: PropTypes.string.isRequired,
    splitContainerRight: PropTypes.string.isRequired,
    splitContainerLeft: PropTypes.string.isRequired,
    footerText: PropTypes.string.isRequired,
    mobileSocialButton: PropTypes.string.isRequired,
    desktopSocialButton: PropTypes.string.isRequired,
    descriptionContainer: PropTypes.string.isRequired,
    socialButtonsContainer: PropTypes.string.isRequired,
    facebookIcon: PropTypes.string.isRequired,
    recaptcha: PropTypes.string.isRequired,
  }).isRequired,
  onSignIn: PropTypes.func,
  onSignUp: PropTypes.func,
  onFacebookSignUp: PropTypes.func,
  onGoogleSignUp: PropTypes.func,
  onVerifyReCaptchaV2: PropTypes.func,
  onVerifyReCaptchaV3: PropTypes.func,
  onResetReCaptchaV2: PropTypes.func,
  onFieldChanged: PropTypes.func,
  onClose: PropTypes.func,
};

SignUp.defaultProps = {
  reCaptchaV2: {},
  reCaptchaV3: {},
  signUp: {},
  isMobile: false,
  errors: {},
  loading: false,
  onSignIn: () => {},
  onSignUp: () => {},
  onFacebookSignUp: () => {},
  onGoogleSignUp: () => {},
  onVerifyReCaptchaV2: () => {},
  onVerifyReCaptchaV3: () => {},
  onResetReCaptchaV2: () => {},
  onFieldChanged: () => {},
  onClose: () => {},
};

export default withStyles(styles)(SignUp);
