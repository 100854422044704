const getPaymentMethods = (state) => state.paymentMethods.methods;
const getPaymentMethodsLoading = (state) => state.paymentMethods.loading;

const getCreateDialogOpen = (state) => state.paymentMethods.createDialog.open;
const getCreateDialogLoading = (state) => state.paymentMethods.createDialog.loading;
const getCardholderName = (state) => state.paymentMethods.createDialog.cardholderName;
const getBillingAddress = (state) => state.paymentMethods.createDialog.billingAddress;
const getSameAsResidential = (state) => state.paymentMethods.createDialog.sameAsResidential;
const getErrors = (state) => state.paymentMethods.createDialog.errors;

const getDefaultPaymentMethod = (state) =>
  state.paymentMethods.methods.find((p) => p.isDefault) || null;

export {
  getPaymentMethods,
  getPaymentMethodsLoading,
  getCreateDialogOpen,
  getCreateDialogLoading,
  getCardholderName,
  getBillingAddress,
  getSameAsResidential,
  getErrors,
  getDefaultPaymentMethod,
};
