import moment from 'moment';

export default {
  stripeAccount: {
    birthDate: moment(),
    isBusiness: false,
    businessNumber: '',
    businessName: '',
    gstRegistered: false,
  },
  loading: false,
  bankAccounts: [],
  addAccountDialog: {
    loading: false,
    open: false,
    accountName: '',
    bsb: '',
    accountNumber: '',
  },
  isCompleteUserProfile: false,
  form: {
    errors: {},
  },
};
