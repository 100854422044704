import { all, fork } from 'redux-saga/effects';

import listenLoadBlogPosts from './listenLoadBlogPosts';
import listenLoadFeaturedLocations from './listenLoadFeaturedLocations';
import listenLoadFeaturedModels from './listenLoadFeaturedModels';
import listenLoadPartners from './listenLoadPartners';
import listenLoadReviews from './listenLoadReviews';
import listenLoadTopCities from './listenLoadTopCities';
import listenLoadTopHosts from './listenLoadTopHosts';
import listenSetSpecialOffer from './listenSetSpecialOffer';

export default function* viewSaga() {
  yield all([
    fork(listenLoadBlogPosts),
    fork(listenLoadFeaturedLocations),
    fork(listenLoadFeaturedModels),
    fork(listenLoadPartners),
    fork(listenLoadReviews),
    fork(listenLoadTopCities),
    fork(listenLoadTopHosts),
    fork(listenSetSpecialOffer),
  ]);
}
