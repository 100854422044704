import * as actionTypes from './actionTypes';

export const setEmail = (value) => ({
  type: actionTypes.SET_EMAIL,
  payload: value,
});

export const subscribe = () => ({
  type: actionTypes.SUBSCRIBE,
});

export const subscribeSuccess = () => ({
  type: actionTypes.SUBSCRIBE_SUCCESS,
});

export const subscribeFailed = (error) => ({
  type: actionTypes.SUBSCRIBE_FAILED,
  payload: error,
});
