import { takeEvery } from 'redux-saga/effects';

import * as phoneActionTypes from 'store/modules/phone/actionTypes';

import { validateFieldValue } from './utils';

function* validatePhone({ payload }) {
  yield validateFieldValue({
    payload: { fieldName: 'phone', fieldValue: payload.phoneNumber },
  });
}

export default function* listenSetPhoneNumber() {
  yield takeEvery(phoneActionTypes.SET_PHONE_NUMBER, validatePhone);
}
