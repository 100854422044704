import { FormControl, InputBase, InputLabel } from '@material-ui/core';
import PropTypes from 'prop-types';

const SearchDateInput = ({ value, label, className, InputProps, onClick, ...rest }) => (
  <FormControl className={className} {...rest}>
    <InputLabel shrink style={{ minWidth: '125px', fontWeight: 500 }}>
      {label}
    </InputLabel>
    <InputBase
      readOnly
      value={value}
      inputProps={{ ...InputProps, style: { padding: '4px 0 0 0' } }}
      onClick={onClick}
    />
  </FormControl>
);

SearchDateInput.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  InputProps: PropTypes.object,
  onClick: PropTypes.func,
};

SearchDateInput.defaultProps = {
  value: '',
  label: '',
  className: '',
  InputProps: {},
  onClick: () => {},
};

export default SearchDateInput;
