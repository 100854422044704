import { useEffect, useState } from 'react';

import { useMediaQuery } from '@material-ui/core';

import theme from 'theme';

export default function useHideWebWidget(hide = false) {
  const [widgetHidden, setWidgetHidden] = useState(false);
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'));
  const alwaysHiddenOnPages = ['messages', 'bookings'];

  const alwaysOnPages = () => {
    if (window.location.pathname === '/') return true;
    if (window.location.pathname.includes('host')) return true;
    return false;
  };

  const hideInMobile = () => (isTabletOrMobile ? !alwaysOnPages() : false);
  const alwaysHidden = () => alwaysHiddenOnPages.some((p) => window.location.pathname.includes(p));

  useEffect(() => {
    if (window.zE && (hide || hideInMobile() || alwaysHidden())) {
      window.zE('webWidget', 'hide');
      setWidgetHidden(true);
    }

    return () => {
      if (window.zE && widgetHidden && !hideInMobile() && !alwaysHidden()) {
        window.zE('webWidget', 'show');
      }
    };
  }, [hide, widgetHidden, window.location.path]);
}
