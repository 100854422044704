import { Region } from '@evee/evee-ui.enums';

import useRegion from 'store/modules/app/hooks/useRegion';

import MetaTags from '../MetaTags';

const CommonMetaTags = () => {
  const { region } = useRegion();

  const getTitle = () => {
    if (region.id === Region.newZealand.id) {
      return 'Electric Car Hire New Zealand | Rent a Tesla | Book now with evee';
    }

    return 'Electric Car Hire Australia | Rent a Tesla | Book now with evee';
  };

  const getDescription = () => {
    if (region.id === Region.newZealand.id) {
      return 'Explore affordable car rental options in New Zealand with evee. Book your next adventure with ease. Browse now!';
    }

    return 'Explore affordable car rental options in Australia with evee. Book your next adventure with ease. Browse now!';
  };

  return (
    <MetaTags
      title={getTitle()}
      keywords="electric car hire, electric car rental, electric vehicles, hire an electric car, rent out
      your car, car sharing, electric car sharing, rent a Tesla, Tesla, electric car, Hyundai
      Kona electric, Nissan Leaf, Hyundai Ioniq, jaguar i-Pace, Porsche Taycan, Tesla Model S,
      Tesla Model X, Tesla Model 3, Tesla Model Y, Tesla Australia, Mercedes EQC, Renault Zoe,
      BMW i3, cybertruck, test drive Tesla, test drive Tesla Model 3, test drive Tesla Model S,
      test drive Tesla Model X, hire a Tesla"
      description={getDescription()}
      ogUrl={`https://${region.domain}/`}
      ogImage="https://eveeh-live.s3.ap-southeast-2.amazonaws.com/assets/preview.jpg"
    />
  );
};

export default CommonMetaTags;
