const prefix = 'listing';

export const LOAD = `${prefix}/LOAD`;
export const LOAD_SUCCESS = `${prefix}/LOAD_SUCCESS`;
export const LOAD_FAILED = `${prefix}/LOAD_FAILED`;

export const SET_FIELD_VALUE = `${prefix}/SET_FIELD_VALUE`;
export const SET_DICTIONARY = `${prefix}/SET_DICTIONARY`;

export const CREATE_EXTRA_LOCATION = `${prefix}/CREATE_EXTRA_LOCATION`;
export const SET_EXTRA_LOCATION = `${prefix}/SET_EXTRA_LOCATION`;
export const REMOVE_EXTRA_LOCATION = `${prefix}/REMOVE_EXTRA_LOCATION`;

export const SAVE = `${prefix}/SAVE`;
export const SAVE_SUCCESS = `${prefix}/SAVE_SUCCESS`;
export const SAVE_FAILED = `${prefix}/SAVE_FAILED`;

export const LOAD_DRAFT_SUCCESS = `${prefix}/LOAD_DRFAT_SUCCESS`;
export const SAVE_DRAFT = `${prefix}/SAVE_DRAFT`;
export const SAVE_DRAFT_SUCCESS = `${prefix}/SAVE_DRAFT_SUCCESS`;
export const SAVE_DRAFT_FAILED = `${prefix}/SAVE_DRAFT_FAILED`;

export const REMOVE = `${prefix}/REMOVE`;
export const HIDE = `${prefix}/HIDE`;

export const UPLOAD_PHOTOS = `${prefix}/UPLOAD_PHOTOS`;
export const UPLOAD_PHOTO_SUCCESS = `${prefix}/UPLOAD_PHOTO_SUCCESS`;
export const UPLOAD_PHOTO_FAILED = `${prefix}/UPLOAD_PHOTO_FAILED`;

export const REMOVE_PHOTO = `${prefix}/REMOVE_PHOTO`;
export const REMOVE_PHOTO_SUCCESS = `${prefix}/REMOVE_PHOTO_SUCCESS`;
export const REMOVE_PHOTO_FAILED = `${prefix}/REMOVE_PHOTO_FAILED`;

export const UPLOAD_DOCUMENT = `${prefix}/UPLOAD_DOCUMENT`;
export const UPLOAD_DOCUMENT_SUCCESS = `${prefix}/UPLOAD_DOCUMENT_SUCCESS`;
export const UPLOAD_DOCUMENT_FAILED = `${prefix}/UPLOAD_DOCUMENT_FAILED`;

export const SET_ERRORS = `${prefix}/SET_ERRORS`;
export const SET_FIELD_ERROR = `${prefix}/SET_FIELD_ERROR`;
export const REMOVE_FIELD_ERROR = `${prefix}/REMOVE_FIELD_ERROR`;

export const SET_CONDITION_FIELD = `${prefix}/SET_CONDITION_FIELD`;
export const SET_APPOINTMENTS_DIALOG_OPEN = `${prefix}/SET_APPOINTMENTS_DIALOG_OPEN`;
export const CLOSE_APPOINTMENTS_DIALOG = `${prefix}/CLOSE_APPOINTMENTS_DIALOG`;
