import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';

import { Box, useMediaQuery } from '@material-ui/core';
import { useTheme, withStyles } from '@material-ui/core/styles';

import styles from './styles';

const SectionContainer = React.forwardRef(
  ({ className, classes, children, withBorder, backgroundColor, ...rest }, ref) => {
    const theme = useTheme();
    const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'));
    const containerClsx = clsx(
      isTabletOrMobile ? classes.mobileContainer : classes.desktopContainer,
      withBorder && classes.borderedContainer,
      className,
    );

    return (
      <Box
        ref={ref}
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={containerClsx}
        bgcolor={backgroundColor}
        {...rest}
      >
        <Box style={{ width: isTabletOrMobile ? '100%' : '1200px' }}>{children}</Box>
      </Box>
    );
  },
);

SectionContainer.propTypes = {
  withBorder: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  backgroundColor: PropTypes.string,
  classes: PropTypes.shape({
    mobileContainer: PropTypes.string.isRequired,
    desktopContainer: PropTypes.string.isRequired,
    borderedContainer: PropTypes.string.isRequired,
  }).isRequired,
};

SectionContainer.defaultProps = {
  withBorder: false,
  className: '',
  children: null,
  backgroundColor: 'common.white',
};

export default withStyles(styles)(SectionContainer);
