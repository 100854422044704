export default {
  open: false,
  password: '',
  confirmPassword: '',
  token: '',
  success: false,
  errorMessage: '',
  loading: false,
  errors: {},
};
