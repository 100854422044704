import { takeEvery } from 'redux-saga/effects';

import * as emailVerificationActions from 'store/modules/auth/verifyEmail';

import { showVerifyEmail } from './utils';

function* listenShowVerifyEmail() {
  yield takeEvery(emailVerificationActions.showVerifyEmail.toString(), showVerifyEmail);
}

export default listenShowVerifyEmail;
