export const defaultTotals = {
  baseRate: 0,
  days: 0,
  discountPerDay: 0,
  discountPercent: 0,
  total: 0,
  totalBeforeDiscount: 0,
  totalDiscount: 0,
  totalPerDay: 0,
  totalPerDayBeforeDiscount: 0,
  extend: {
    days: 0,
    discountPercent: 0,
  },
  loading: true,
};

const defaultVehicleDetails = {
  name: '',
  badge: '',
  year: 0,
  description: '',
  photos: [],
  owner: {},
  options: {},
  features: [],
  reviews: [],
  locations: [],
  tripsCount: 0,
  averageRating: '',
  delivery: {
    radius: 0,
    price: 0,
  },
  compatibleChargingPlugs: [],
  realDrivingRange: '',
};

export default {
  vehicleDetails: defaultVehicleDetails,
  allFeatures: [],
  totals: defaultTotals,
  loading: false,
};
