import * as actionTypes from './actionTypes';
import initialState, { initialFilters } from './initialState';

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SET_LOADING:
      return {
        ...state,
        loading: payload,
      };

    case actionTypes.SEARCH_SUCCESS:
      return {
        ...state,
        vehicles: payload,
        noResults: payload.length === 0,
      };

    case actionTypes.SET_ACTIVE_FILTERS:
      return {
        ...state,
        activeFilters: {
          ...payload,
        },
        sorting: payload.sorting,
      };

    case actionTypes.SET_ACTIVE_FILTER_VALUE:
      return {
        ...state,
        activeFilters: {
          ...state.activeFilters,
          [payload.filterName]: payload.filterValue,
        },
      };

    case actionTypes.CLEAR_FILTERS: {
      return {
        ...state,
        activeFilters: {
          ...initialFilters,
        },
        availableFilters: {
          ...state.availableFilters,
          models: [],
          badges: [],
        },
      };
    }

    case actionTypes.SET_SELECTED_VEHICLE: {
      return {
        ...state,
        selectedVehicle: {
          ...payload,
        },
      };
    }

    case actionTypes.SET_HOVERED_VEHICLE: {
      return {
        ...state,
        hoveredVehicle: {
          ...payload,
        },
      };
    }

    case actionTypes.SET_FIELD_VALUE:
      return {
        ...state,
        [payload.fieldName]: payload.fieldValue,
      };

    case actionTypes.SET_EDIT_FILTERS:
      return {
        ...state,
        editFilters: {
          ...state.editFilters,
          ...payload,
        },
      };

    case actionTypes.SET_AVAILABLE_FILTERS:
      return {
        ...state,
        availableFilters: {
          ...state.availableFilters,
          ...payload,
        },
      };

    case actionTypes.SET_DEFAULT_FILTERS:
      return {
        ...state,
        defaultFilters: {
          ...payload,
        },
      };

    case actionTypes.SET_FILTERS_DIALOG_OPEN:
      return {
        ...state,
        filtersDialogOpen: payload,
      };

    case actionTypes.SET_EDIT_FILTERS_FIELD_VALUE:
      return {
        ...state,
        editFilters: {
          ...state.editFilters,
          [payload.fieldName]: payload.fieldValue,
        },
      };

    case actionTypes.SORT_BY_FIELD: {
      return {
        ...state,
        sorting: [...state.sorting.filter((e) => e.fieldName !== payload.fieldName), payload],
      };
    }

    case actionTypes.RESET_SORTING:
      return {
        ...state,
        sorting: [],
      };

    case actionTypes.SET_START_LOCATION:
      return {
        ...state,
        startLocation: {
          ...payload,
        },
      };

    case actionTypes.SET_LOCATION:
      return {
        ...state,
        location: {
          ...payload,
        },
      };

    case actionTypes.SET_AIRPORTS:
      return {
        ...state,
        availableFilters: {
          ...state.availableFilters,
          airports: payload,
        },
      };

    case actionTypes.SET_AIRPORT:
      return {
        ...state,
        activeFilters: {
          ...state.activeFilters,
          airport: {
            ...payload,
          },
        },
      };

    case actionTypes.SET_SELECTED_AIRPORT:
      return {
        ...state,
        selectedAirport: {
          ...payload,
        },
      };

    case actionTypes.SET_ACTIVE_AIRPORT:
      return {
        ...state,
        activeFilters: {
          ...state.activeFilters,
          airport: payload,
        },
      };

    default:
      return state;
  }
};
