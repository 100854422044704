import { CheckoutSteps } from '@evee/evee-ui.enums';

export default {
  loading: false,
  signingIn: false,
  booking: {},
  availableExtras: {},
  totalsLoading: false,
  form: {
    steps: {
      [CheckoutSteps.yourInfo]: {
        completed: false,
        active: true,
      },
      [CheckoutSteps.verification]: {
        completed: false,
        active: false,
      },
      [CheckoutSteps.extras]: {
        completed: false,
        active: false,
      },
      [CheckoutSteps.payment]: {
        completed: false,
        active: false,
      },
    },
    errors: {},
  },
};
