import { all, call, put, select } from 'redux-saga/effects';

import { ErrorMessage } from '@evee/evee-ui.enums';
import { apiService } from '@evee/evee-ui.services';

import * as additionalDriversSelectors from 'store/modules/additionalDrivers/selectors';
import * as appActions from 'store/modules/app/actions';
import * as bookingDetailsSelectors from 'store/modules/bookingDetails/selectors';

import {
  removeDriverFailed,
  removeDriverSuccess,
  sendInviteFailed,
  sendInviteSuccess,
  setDriver,
} from 'store/modules/additionalDrivers';

export function* load() {
  try {
    const additionalDriver = yield select(bookingDetailsSelectors.getAdditionalDriver);
    yield put(setDriver(additionalDriver));
  } catch (error) {
    yield put(appActions.showError(ErrorMessage.commonError));
  }
}

export function* sendInvite() {
  try {
    const [bookingId, email] = yield all([
      select(bookingDetailsSelectors.getBookingId),
      select(additionalDriversSelectors.getEmail),
    ]);

    const result = yield call(apiService.booking.inviteAdditionalDriver, bookingId, email);

    yield put(setDriver(result.additionalDriver));
    yield put(sendInviteSuccess({ bookingId, additionalDriver: result.additionalDriver }));
  } catch (error) {
    yield put(sendInviteFailed());
    yield put(appActions.showError(error.message));
  }
}

export function* removeDriver() {
  try {
    const bookingId = yield select(bookingDetailsSelectors.getBookingId);

    const result = yield call(apiService.booking.removeDriver, bookingId);

    yield put(setDriver(result.additionalDriver));
    yield put(removeDriverSuccess(bookingId));
  } catch (error) {
    yield put(removeDriverFailed());
    yield put(appActions.showError(error.message));
  }
}
