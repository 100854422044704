import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import { Button, Loader, PasswordInput } from '@evee/evee-ui.base';
import { password as passwordModel } from '@evee/evee-ui.models';

import Dialog from '../../CommonDialog';
import styles from './styles';

const ResetPassword = ({
  resetPassword,
  isMobile,
  loading,
  classes,
  onClose,
  onFieldChanged,
  onResetPassword,
}) => {
  const { open, password, confirmPassword, errorMessage, errors } = resetPassword;

  const canResetPassword = passwordModel.resetPasswordSchema.isValidSync({
    password,
    confirmPassword,
  });

  return (
    <Dialog open={open} isMobile={isMobile} title="Reset password" onClose={onClose}>
      <Loader loading={loading}>
        <PasswordInput
          value={password}
          label="New password"
          name="password"
          className={classes.input}
          InputProps={{
            inputProps: {
              autocomplete: 'new-password',
            },
          }}
          helperText={errors.password || errorMessage}
          error={!!errors.password || errorMessage}
          onChange={(v) => onFieldChanged('password', v)}
        />

        <PasswordInput
          disabled={!password || !!errors.password}
          value={confirmPassword}
          label="Confirm password"
          name="confirmPassword"
          className={classes.input}
          InputProps={{
            inputProps: {
              autocomplete: 'off',
            },
          }}
          helperText={errors.confirmPassword}
          error={!!errors.confirmPassword}
          onChange={(v) => onFieldChanged('confirmPassword', v)}
        />

        <Button
          disabled={!canResetPassword}
          text="Change password"
          className={classes.button}
          onClick={onResetPassword}
        />
      </Loader>
    </Dialog>
  );
};

ResetPassword.propTypes = {
  resetPassword: PropTypes.object,
  isMobile: PropTypes.bool,
  loading: PropTypes.bool,
  classes: PropTypes.shape({
    input: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func,
  onFieldChanged: PropTypes.func,
  onResetPassword: PropTypes.func,
};

ResetPassword.defaultProps = {
  resetPassword: {},
  isMobile: false,
  loading: false,
  onClose: () => {},
  onFieldChanged: () => {},
  onResetPassword: () => {},
};

export default withStyles(styles)(ResetPassword);
