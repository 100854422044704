import { all, call, put, select } from 'redux-saga/effects';
import { ValidationError } from 'yup';

import { apiService } from '@evee/evee-ui.services';

import * as appActions from 'store/modules/app/actions';
import * as appSelectors from 'store/modules/app/selectors';
import * as payoutActions from 'store/modules/payouts';
import * as payoutsSelectors from 'store/modules/payouts/selectors';
import { load as loadPhone } from 'store/modules/phone/actions';
import { showError } from 'store/modules/app/actions';

import { ErrorMessage, Region } from '@evee/evee-ui.enums';
import { getPayoutsRequiredFieldsStatus, getStripeAccount } from '@evee/evee-ui.utils';
import { payout as payoutModel } from '@evee/evee-ui.models';

export const isCompleteUserProfile = (customer) =>
  Object.values(getPayoutsRequiredFieldsStatus(customer)).every((v) => v === true);

export function* load() {
  try {
    const currency = yield select(appSelectors.getCurrency);
    const [customer, payouts] = yield all([
      call(apiService.customer.me),
      call(apiService.payout.getAll, currency.id),
    ]);

    yield put(payoutActions.loadPayoutsSuccess(payouts));

    if (!customer) {
      return;
    }

    yield put(loadPhone());

    const stripeAccountData = getStripeAccount(customer);

    yield put(
      payoutActions.setFieldValue({
        fieldName: 'isCompleteUserProfile',
        fieldValue: isCompleteUserProfile(customer),
      }),
    );
    yield put(payoutActions.setStripeAccount(stripeAccountData));
  } catch (error) {
    yield put(showError(error.message));
    yield put(payoutActions.loadPayoutsFailed());
  }
}

export function* create() {
  try {
    const currency = yield select(appSelectors.getCurrency);
    const region = yield select(appSelectors.getRegion);
    if (!window.stripe) {
      throw new Error(ErrorMessage.commonError);
    }

    const { accountName, accountNumber, bsb } = yield select(payoutsSelectors.getAccountData);
    const isAustralia = region.id === Region.australia.id;

    yield payoutModel.accountSchema.validate(
      { accountName, accountNumber, bsb, isAustralia },
      { abortEarly: false },
    );

    let stripeAccount = yield select(payoutsSelectors.getStripeAccount);
    if (!stripeAccount.id) {
      // Must create stripe account first.
      const customer = yield call(apiService.payout.createStripeAccount, currency.id);
      stripeAccount = getStripeAccount(customer);
      yield put(payoutActions.createStripeAccountSuccess(stripeAccount));
    }

    const stripeToken = yield call(window.stripe.createToken, 'bank_account', {
      country: region.id,
      currency: currency.id,
      routing_number: isAustralia ? bsb : undefined,
      account_number: accountNumber,
      account_holder_type: 'individual',
    });

    if (stripeToken.error) {
      throw new Error(stripeToken.error.message || 'Invalid account data');
    }

    const response = yield call(
      apiService.payout.create,
      {
        stripeToken: stripeToken.token.id,
        accountName,
      },
      currency.id,
    );
    yield put(payoutActions.addPayoutSuccess(response));
  } catch (error) {
    if (error instanceof ValidationError) {
      const validationErrors = Object.assign(
        ...error.inner.map((e) => ({
          [e.path]: e.message,
        })),
      );
      yield put(payoutActions.setErrors(validationErrors));
      yield put(appActions.showError(ErrorMessage.completeRequiredFields));
    } else {
      yield put(showError(error.message));
    }
    yield put(payoutActions.addPayoutFailed());
  }
}

export function* remove(action) {
  try {
    const currency = yield select(appSelectors.getCurrency);
    yield call(apiService.payout.remove, { id: action.payload, currency: currency.id });
    yield put(payoutActions.removeSuccess(action.payload));
  } catch (error) {
    yield put(showError(error.message));
    yield put(payoutActions.removeFailed());
  }
}

export function* setDefault(action) {
  try {
    const currency = yield select(appSelectors.getCurrency);
    yield call(apiService.payout.setDefault, { id: action.payload, currency: currency.id });
    yield put(payoutActions.setDefaultSuccess(action.payload));
  } catch (error) {
    yield put(showError(error.message));
    yield put(payoutActions.setDefaultFailed());
  }
}

export function* createBusinessAccount(action) {
  try {
    const currency = yield select(appSelectors.getCurrency);
    yield payoutModel.businessStripeAccountSchema.validate(action.payload, {
      abortEarly: false,
      recursive: true,
    });

    const customer = yield call(apiService.payout.createStripeAccount, currency.id, {
      ...action.payload,
      businessNumber: action.payload.businessNumber,
    });
    const account = getStripeAccount(customer);
    yield put(payoutActions.createStripeAccountSuccess(account));
  } catch (error) {
    if (error instanceof ValidationError) {
      const validationErrors = Object.assign(
        ...error.inner.map((e) => ({
          [e.path]: e.message,
        })),
      );
      yield put(payoutActions.setErrors(validationErrors));
      yield put(appActions.showError(ErrorMessage.completeRequiredFields));
    } else {
      yield put(showError(error.message));
    }
    yield put(payoutActions.createStripeAccountFailed());
  }
}

export function* updateBusinessAccount(action) {
  try {
    const currency = yield select(appSelectors.getCurrency);
    yield payoutModel.businessStripeAccountSchema.validate(action.payload, {
      abortEarly: false,
      recursive: true,
    });

    const customer = yield call(apiService.payout.updateBusinessAccount, currency.id, {
      ...action.payload,
      businessNumber: action.payload.businessNumber,
    });
    const account = getStripeAccount(customer);
    yield put(payoutActions.updateBusinessAccountSuccess(account));
  } catch (error) {
    if (error instanceof ValidationError) {
      const validationErrors = Object.assign(
        ...error.inner.map((e) => ({
          [e.path]: e.message,
        })),
      );
      yield put(payoutActions.setErrors(validationErrors));
      yield put(appActions.showError(ErrorMessage.completeRequiredFields));
    } else {
      yield put(showError(error.message));
    }
    yield put(payoutActions.updateBusinessAccountFailed());
  }
}

export function* validateFieldValue({ payload }) {
  try {
    yield payoutModel.businessStripeAccountSchema.fields[payload.fieldName].validate(
      payload.fieldValue,
    );
    yield put(payoutActions.removeFieldError(payload.fieldName));
  } catch (err) {
    if (err instanceof ValidationError) {
      yield put(payoutActions.setFieldError(payload.fieldName, err.message));
    }
  }
}

export function* validateDialogFieldValue({ payload }) {
  try {
    yield payoutModel.accountSchema.fields[payload.fieldName].validate(payload.fieldValue);
    yield put(payoutActions.removeFieldError(payload.fieldName));
  } catch (err) {
    if (err instanceof ValidationError) {
      yield put(payoutActions.setFieldError(payload.fieldName, err.message));
    }
  }
}
