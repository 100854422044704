export default {
  tripDates: {
    start: null,
    end: null,
    available: false,
    extended: false,
    errorMessage: '',
  },
  occupiedDates: [],
  location: {
    name: 'Select location',
    price: 0,
  },
  extension: {
    days: 0,
    discountPercent: 0,
  },
  loading: false,
};
