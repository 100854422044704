import { ValidationError } from 'yup';
import { put } from 'redux-saga/effects';

import { extraPayment as extraPaymentModel } from '@evee/evee-ui.models';

import * as extraPaymentsActions from 'store/modules/extraPayments';

export function* validateFieldValue({ payload }) {
  const fieldName = `${payload.fieldName}${payload.id}`;
  try {
    yield extraPaymentModel.paymentSchema.fields[payload.fieldName].validate(payload.fieldValue);
    yield put(extraPaymentsActions.removeFieldError(fieldName));
  } catch (err) {
    if (err instanceof ValidationError) {
      yield put(extraPaymentsActions.setFieldError(fieldName, err.message));
    }
  }
}

export function* validatePayment({ payload }) {
  yield validateFieldValue({
    payload: {
      id: payload.id,
      fieldName: 'name',
      fieldValue: payload.name,
    },
  });
  yield validateFieldValue({
    payload: {
      id: payload.id,
      fieldName: 'amount',
      fieldValue: payload.amount,
    },
  });
}
