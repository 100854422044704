/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const prepareRange = (startDate = null, endDate = null) => ({
  payload: {
    startDate,
    endDate,
  },
});

export const customPricesSlice = createSlice({
  name: 'listing/customPrices',
  initialState: {},
  reducers: {
    load: (state) => {
      state.customPrices.loading = true;
    },

    loadSuccess: (state, { payload }) => {
      state.customPrices.priceRanges = payload;
      state.customPrices.loading = false;
    },

    loadFailed: (state) => {
      state.customPrices.loading = false;
    },

    setCurrentMonth: (state, { payload }) => {
      state.customPrices.currentMonth = payload;
    },

    updateRange: {
      reducer() {
        // nothing to do. used in saga.
      },
      prepare: prepareRange,
    },

    setRange: {
      reducer(state, { payload }) {
        state.customPrices.range = payload;
      },
      prepare: prepareRange,
    },

    setPriceRanges: (state, { payload }) => {
      state.customPrices.priceRanges = payload;
    },

    selectRange: {
      reducer() {
        // nothing to do. used in saga.
      },
      prepare: prepareRange,
    },

    setLoading: (state, { payload }) => {
      state.customPrices.loading = payload;
    },

    create: (state) => {
      state.customPrices.loading = true;
    },

    createSuccess: (state, { payload }) => {
      state.customPrices.priceRanges = [...state.customPrices.priceRanges, payload];
      state.customPrices.loading = false;
    },

    createFailed: (state) => {
      state.customPrices.loading = false;
    },

    update: (state) => {
      state.customPrices.loading = true;
    },

    updateSuccess: (state, { payload }) => {
      const { priceRanges } = state.customPrices;
      const index = priceRanges.findIndex((pr) => pr.id === payload.id);
      priceRanges.splice(index, 1, payload);

      state.customPrices.priceRanges = priceRanges;
      state.customPrices.loading = false;
    },

    updateFailed: (state) => {
      state.customPrices.loading = false;
    },

    remove: (state) => {
      state.customPrices.loading = true;
    },

    removeSuccess: (state, { payload }) => {
      const { priceRanges } = state.customPrices;
      const index = priceRanges.findIndex((l) => l.id === payload);
      priceRanges.splice(index, 1);

      state.customPrices.priceRanges = priceRanges;
      state.customPrices.loading = false;
    },

    removeFailed: (state) => {
      state.customPrices.loading = false;
    },

    setSelectedPriceRange: (state, { payload }) => {
      state.customPrices.selectedPriceRange = payload;
    },

    setCalendarMode: (state, { payload }) => {
      state.customPrices.calendarMode = payload;
      state.customPrices.range = { startDate: null, endDate: null };
      state.customPrices.selectedPriceRange = undefined;
    },
  },
});

const { actions, reducer } = customPricesSlice;

export const {
  create,
  createFailed,
  createSuccess,
  remove,
  removeFailed,
  removeSuccess,
  load,
  loadFailed,
  loadSuccess,
  selectRange,
  setCurrentMonth,
  setDate,
  setLoading,
  setPriceRanges,
  updateRange,
  setRange,
  setSelectedPriceRange,
  update,
  updateFailed,
  updateSuccess,
  setCalendarMode,
} = actions;

export default reducer;
