export default {
  open: false,
  editEmail: false,
  verified: false,
  email: '',
  profileEmail: '',
  code: ['', '', '', ''],
  errorMessage: '',
  loading: false,
};
