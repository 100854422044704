import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { AppBar, Box, Fade, Toolbar } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { Button } from '@evee/evee-ui.base';
import { customerHasOwnerRole } from '@evee/evee-ui.utils';

import { RegionSelector } from 'widgets/home';
import useAuth from 'store/modules/auth/hooks/useAuth';
import useScroll from 'common/hooks/useScroll';

import DesktopLogo from '../../utils/DesktopLogo';
import DesktopMenu from '../../Menu/DesktopMenu';
import RewardsButton from '../../RewardsButton';

import SearchBar from './SearchBar';
import styles from './styles';

const DesktopHeader = ({ initiallyTransparent, hideOnScroll, classes }) => {
  const scrollY = useScroll();
  const { currentUser, signedIn, onSignUp } = useAuth();
  const [transparent, setTransparent] = useState(initiallyTransparent);
  const [visible, setVisible] = useState(true);
  const hasOwnerRole = customerHasOwnerRole(currentUser);

  const mainColor = transparent ? 'inherit' : 'primary';
  const appBarClassName = transparent ? classes.transparentAppBar : classes.defaultAppBar;
  const becomeHostClsx = clsx(classes.becomeHost, transparent && classes.transparentBecomeHost);
  const regionClsx = clsx(classes.region, transparent && classes.transparentRegion);

  useEffect(() => {
    if (hideOnScroll) {
      setVisible(scrollY === 0);
      return;
    }

    if (initiallyTransparent) {
      setTransparent(scrollY <= window.innerHeight);
    }
  }, [initiallyTransparent, hideOnScroll, scrollY]);

  return (
    <Fade in={visible} timeout={500}>
      <AppBar position="sticky" className={appBarClassName}>
        <Toolbar disableGutters className={classes.toolbar}>
          <Box className={classes.headerBlockContainer}>
            <DesktopLogo isTransparent={transparent} />
            <SearchBar hidden={transparent} />
          </Box>
          <Box className={classes.headerBlockContainer}>
            {!hasOwnerRole && (
              <Button
                type="text"
                text="Become a host"
                size="small"
                color={mainColor}
                component={NavLink}
                to="/car-host"
                className={becomeHostClsx}
              />
            )}

            {!signedIn && (
              <Button
                type="text"
                text="Join now"
                size="small"
                color={mainColor}
                className={classes.joinNow}
                onClick={onSignUp}
              />
            )}

            <RegionSelector color={mainColor} isTransparent={transparent} className={regionClsx} />
            <RewardsButton color={mainColor} />
            <DesktopMenu color={mainColor} isTransparent={transparent} />
          </Box>
        </Toolbar>
      </AppBar>
    </Fade>
  );
};

DesktopHeader.propTypes = {
  initiallyTransparent: PropTypes.bool,
  hideOnScroll: PropTypes.bool,
  classes: PropTypes.shape({
    defaultAppBar: PropTypes.string.isRequired,
    transparentAppBar: PropTypes.string.isRequired,
    toolbar: PropTypes.string.isRequired,
    headerBlockContainer: PropTypes.string.isRequired,
    becomeHost: PropTypes.string.isRequired,
    transparentBecomeHost: PropTypes.string.isRequired,
    joinNow: PropTypes.string.isRequired,
    region: PropTypes.string.isRequired,
    transparentRegion: PropTypes.string.isRequired,
  }).isRequired,
};

DesktopHeader.defaultProps = {
  initiallyTransparent: false,
  hideOnScroll: false,
};

export default withStyles(styles)(DesktopHeader);
