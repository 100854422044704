import { connect } from 'react-redux';

import * as homeSelectors from 'store/modules/home/selectors';
import { loadFeaturedLocations } from 'store/modules/home';

import FeaturedLocations from './FeaturedLocations';

const mapStateToProps = (state) => ({
  featuredLocations: homeSelectors.getFeaturedLocations(state),
  loading: homeSelectors.getFeaturedLocationsLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  onLoad: () => dispatch(loadFeaturedLocations()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeaturedLocations);
