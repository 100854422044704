import { useDispatch, useSelector } from 'react-redux';

import * as authActions from '../index';
import * as authSelectors from '../selectors';
import * as profileSelectors from 'store/modules/profile/selectors';
import * as signInActions from '../signIn';
import * as signUpActions from '../signUp';

export default function useAuth() {
  const currentUser = useSelector(profileSelectors.getProfile);
  const signedIn = useSelector(authSelectors.getIsUserAuthorized);

  const dispatch = useDispatch();
  const onSignIn = () => dispatch(signInActions.showSignIn());
  const onSignUp = () => dispatch(signUpActions.showSignUp());
  const onSignOut = () => dispatch(authActions.signOut());

  return { currentUser, signedIn, onSignIn, onSignUp, onSignOut };
}
