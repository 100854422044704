const styles = (theme) => ({
  searchButton: {
    height: '40px',
    width: '100%',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.main,
    borderRadius: '25px',
  },
  dialogContent: {
    marginTop: '60px',
  },
  closeButton: {
    margin: '5px',
  },
  searchContainer: {
    margin: '0 10px 0 10px',
  },
  titleContainer: {
    marginTop: '50px',
  },
  title: {
    fontSize: '10px',
    fontWeight: 500,
    lineHeight: 1.25,
    width: '100%',
    padding: theme.spacing(1, 2),
  },
  ellipsisText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
  },
});

export default styles;
