import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import { IconButton } from '@evee/evee-ui.base';
import useAuth from 'store/modules/auth/hooks/useAuth';

import BatteryAlmost from './icons/BatteryAlmost';
import BatteryEmpty from './icons/BatteryEmpty';
import BatteryFull from './icons/BatteryFull';
import BatteryHalf from './icons/BatteryHalf';
import BatteryLow from './icons/BatteryLow';

const styles = (theme) => ({});




const RewardsButton = ({ color, classes }) => {
  const { currentUser, signedIn } = useAuth();

  const getIcon = () => {
    const points = currentUser.rewardPoints || 0;

    if (points === 0) {
      return <BatteryEmpty fill={color} />;
    } else if (points >= 1 && points <= 2) {
      return <BatteryLow fill={color} />;
    } else if (points >= 3 && points <= 5) {
      return <BatteryHalf fill={color} />;
    } else if (points >= 6 && points <= 8) {
      return <BatteryAlmost fill={color} />;
    } else {
      return <BatteryFull fill={color} />;
    }
  }

  return signedIn ? <IconButton color={color} icon={getIcon()} href="/account/rewards" /> : <></>;
};

RewardsButton.propTypes = {
  color: PropTypes.string,
  classes: PropTypes.shape({
  }).isRequired,
};

RewardsButton.defaultProps = {
};

export default withStyles(styles)(RewardsButton);
