/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import initialState, { initialFilters } from './initialState';

export const myTripsSlice = createSlice({
  name: 'hostAllBookings',
  initialState,
  reducers: {
    load(state) {
      state.loading = true;
    },

    loadFailed(state) {
      state.loading = false;
    },

    loadSuccess(state, { payload }) {
      const { bookings, vehicles, counts } = payload;
      state.bookings = bookings;
      state.vehicles = vehicles;
      state.counts = counts;
      state.loading = false;
    },

    setStatusFilter(state, { payload }) {
      state.statusFilter = payload;
      state.currentPage = 1;
    },

    setCurrentPage(state, { payload }) {
      state.currentPage = payload;
    },

    loadMore(state) {
      state.pageLoading = true;
    },

    loadMoreFailed(state) {
      state.pageLoading = false;
    },

    loadMoreSuccess(state, { payload }) {
      state.pageLoading = false;
      state.currentPage = payload.pageNumber;
      state.bookings.items = state.bookings.items.concat(payload.bookings.items);
    },

    setBookings(state, { payload }) {
      state.bookings = payload;
    },

    setCounts(state, { payload }) {
      state.counts = payload;
    },

    setAvailableFilters(state, { payload }) {
      state.availableFilters = payload;
    },

    setActiveFilters(state, { payload }) {
      state.activeFilters = payload;
    },

    setEditFilters(state, { payload }) {
      state.editFilters = payload;
    },

    setActiveFilterFieldValue(state, { payload }) {
      state.activeFilters[payload.fieldName] = payload.fieldValue;
    },

    setEditFiltersFieldValue(state, { payload }) {
      state.editFilters[payload.fieldName] = payload.fieldValue;
    },

    clearFilters(state) {
      state.statusFilter = null;
      state.activeFilters = initialFilters;
    },

    setSorting(state, { payload }) {
      state.sorting.direction = payload;
    },
  },
});

const { actions, reducer } = myTripsSlice;

export const {
  load,
  loadFailed,
  loadSuccess,
  setStatusFilter,
  setCurrentPage,
  loadMore,
  loadMoreFailed,
  loadMoreSuccess,
  setBookings,
  setCounts,
  setAvailableFilters,
  setActiveFilters,
  setEditFilters,
  setActiveFilterFieldValue,
  setEditFiltersFieldValue,
  clearFilters,
  setSorting,
} = actions;

export default reducer;
