import { put, select, takeEvery } from 'redux-saga/effects';

import * as authActions from 'store/modules/auth';
import * as basketActions from 'store/modules/basket';
import * as basketSelectors from 'store/modules/basket/selectors';

function* cancelSignIn() {
  const signingIn = yield select(basketSelectors.getIsSigningIn);
  if (signingIn) {
    yield put(basketActions.setSigningIn(false));
  }
}

export default function* listenCancelSignIn() {
  yield takeEvery(authActions.cancelSignInActions, cancelSignIn);
}
