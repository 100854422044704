import { mobileHeaderNotificationHeightPx } from 'common/constants/ui';

const styles = (theme) => ({
  container: {
    width: '100%',
    zIndex: 100000,
  },
  gridContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: mobileHeaderNotificationHeightPx,
    backgroundColor: theme.palette.secondary.main,
  },
  closeIcon: {
    width: '24px',
    height: '24px',
  },
  countdown: {
    height: '40px',
    minWidth: '190px',
    marginRight: '16px',
    fontWeight: 700,
    padding: theme.spacing(0, 2),
    borderColor: '4px',
    borderRadius: '4px',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.main,
  },
});

export default styles;
