import { BookingStatusFilter } from '@evee/evee-ui.enums';

export default {
  loading: false,
  pageLoading: false,
  statusFilter: BookingStatusFilter.upcoming,
  currentPage: 1,
  vehicles: [],
  bookings: {
    items: [],
    totalPages: 0,
    total: 0,
  },
  counts: {
    all: 0,
    upcoming: 0,
    requested: 0,
    ongoing: 0,
    completed: 0,
    cancelled: 0,
  },
};
