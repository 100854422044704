const styles = (theme) => ({
  root: {
    '&$expanded': {
      margin: 0,
      '&:last-child': {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
      '&::before': {
        opacity: 1,
        backgroundColor: theme.palette.divider,
      },
    },
    '&::before': {
      backgroundColor: theme.palette.divider,
    },
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: 'inline-block',
  },
  expanded: {},
});

export default styles;
