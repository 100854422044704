import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const MetaTags = ({ title, keywords, description, ogUrl, ogImage }) => (
  <Helmet>
    {title && <title>{title}</title>}

    {keywords && <meta name="keywords" content={keywords} />}

    {description && <meta name="description" content={description} />}

    {title && <meta name="og:title" content={title} />}

    {description && <meta name="og:description" content={description} />}

    {ogUrl && <meta property="og:url" content={ogUrl} />}

    {ogImage && <meta property="og:image" content={ogImage} />}
  </Helmet>
);

MetaTags.propTypes = {
  title: PropTypes.string,
  keywords: PropTypes.string,
  description: PropTypes.string,
  ogUrl: PropTypes.string,
  ogImage: PropTypes.string,
};

MetaTags.defaultProps = {
  title: 'Electric Car Hire Australia | Rent a Tesla | Book now with evee',
  keywords: '',
  description: '',
  ogUrl: '',
  ogImage: '',
};

export default MetaTags;
