import React from 'react';
import { SvgIcon } from '@material-ui/core';

import theme from 'theme';

const Money = ({ ...rest }) => (
  <SvgIcon htmlColor="#fff" viewBox="0 0 56 56" fill="none" {...rest}>
    <path
      d="M38.24 22.0625H9.75993C9.75993 24.6568 7.65684 26.7599 5.0625 26.7599V37.606C7.65684 37.606 9.75993 39.7091 9.75993 42.3035H38.2399C38.2399 39.7091 40.343 37.606 42.9373 37.606V26.7599C40.3431 26.7599 38.24 24.6568 38.24 22.0625ZM13.4519 34.3373C12.2621 34.3373 11.2976 33.3728 11.2976 32.183C11.2976 30.9932 12.2621 30.0287 13.4519 30.0287C14.6417 30.0287 15.6062 30.9932 15.6062 32.183C15.6062 33.3728 14.6417 34.3373 13.4519 34.3373ZM34.548 34.3373C33.3583 34.3373 32.3938 33.3728 32.3938 32.183C32.3938 30.9932 33.3583 30.0287 34.548 30.0287C35.7378 30.0287 36.7023 30.9932 36.7023 32.183C36.7022 33.3728 35.7377 34.3373 34.548 34.3373Z"
      fill={theme.palette.secondary.main}
    />
    <path
      d="M44.0925 17H3.9074C1.75284 17 0 18.7527 0 20.9073V47.9452C0 50.0998 1.75284 51.8526 3.9074 51.8526H44.0926C46.2471 51.8526 48 50.0998 48 47.9452V20.9073C47.9999 18.7527 46.2471 17 44.0925 17ZM1.875 20.9073C1.875 19.7866 2.78671 18.8749 3.9074 18.8749H44.0926C45.2133 18.8749 46.125 19.7866 46.125 20.9073V43.4586C46.125 44.5793 45.2133 45.491 44.0926 45.491H3.9074C2.78671 45.491 1.875 44.5793 1.875 43.4586V20.9073ZM44.0925 49.9776H3.9074C2.78671 49.9776 1.875 49.0659 1.875 47.9452V46.7947C2.4675 47.1571 3.16349 47.366 3.9074 47.366H44.0926C44.8365 47.366 45.5325 47.1571 46.125 46.7947V47.9452C46.1249 49.0659 45.2132 49.9776 44.0925 49.9776Z"
      fill={theme.palette.primary.main}
    />
    <path
      d="M24.3178 35.2452C23.6441 35.3042 22.7548 35.1059 22.3582 34.6134C22.0332 34.2101 21.4432 34.1466 21.0399 34.4714C20.6366 34.7961 20.5731 35.3864 20.8978 35.7896C21.4304 36.4508 22.1966 36.824 22.958 37.0007C22.9875 37.4919 23.3951 37.8811 23.8937 37.8811C24.369 37.8811 24.7617 37.5274 24.8229 37.0688C26.181 36.8335 27.1349 35.9345 27.2905 34.7086C27.4707 33.2901 26.5496 31.9293 25.0505 31.3994C24.0373 31.0412 23.4467 30.8052 23.2489 30.7119C22.9159 30.555 22.7648 30.334 22.7868 30.0365C22.8103 29.72 23.0349 29.41 23.298 29.3308C24.2497 29.044 24.8841 29.3356 25.0604 29.5867C25.3579 30.0106 25.9426 30.113 26.3663 29.8156C26.7902 29.5182 26.8927 28.9335 26.5952 28.5096C26.2445 28.0097 25.6292 27.5865 24.8309 27.4161C24.8258 26.9027 24.408 26.4883 23.8935 26.4883C23.3758 26.4883 22.956 26.908 22.956 27.4258V27.4799C22.8903 27.4967 22.824 27.5152 22.7572 27.5354C21.7544 27.8374 20.9976 28.8089 20.9169 29.8979C20.8367 30.9793 21.4095 31.9176 22.4492 32.4078C22.7714 32.5597 23.5102 32.8436 24.4254 33.1671C25.1952 33.4392 25.4859 34.0345 25.4304 34.4724C25.3754 34.9065 24.9699 35.1882 24.3178 35.2452Z"
      fill={theme.palette.primary.main}
    />
  </SvgIcon>
);

Money.displayName = 'MoneyIcon';
Money.muiName = 'SvgIcon';

export default Money;
