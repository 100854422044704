import { AUSTRALIA_PHONE_CODE } from '@evee/evee-ui.utils';
import { PhoneVerificationStatus } from '@evee/evee-ui.enums';

export default {
  status: PhoneVerificationStatus.unverified,
  phoneNumber: {
    code: AUSTRALIA_PHONE_CODE,
    number: '',
  },
  verificationCode: '',
  loading: false,
  error: '',
};
