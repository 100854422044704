import * as actionTypes from './actionTypes';

export const load = (accountId) => ({
  type: actionTypes.LOAD,
  payload: accountId,
});

export const loadSuccess = (accountInfo) => ({
  type: actionTypes.LOAD_SUCCESS,
  payload: accountInfo,
});

export const loadFailed = () => ({
  type: actionTypes.LOAD_FAILED,
});
