import PropTypes from 'prop-types';

import { Box, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { Button } from '@evee/evee-ui.base';
import { getHomeUrl } from '@evee/evee-ui.utils';

import useAuth from 'store/modules/auth/hooks/useAuth';
import useRegion from 'store/modules/app/hooks/useRegion';

import BenefitItem from './BenefitItem';
import { getItems } from './benefits';
import styles from './styles';

const CarSharingBenefits = ({ classes }) => {
  const { region } = useRegion();
  const { onSignUp } = useAuth();

  return (
    <Box margin={'8px'}>
      <Grid container className={classes.itemsContainer} justifyContent="space-between" spacing={2}>
        {getItems(region).map((b, index) => (
          <Grid item key={index} xs={12} sm={6} md={4} style={{ maxWidth: '320px' }}>
            <Box className={classes.itemContainer}>
              <BenefitItem icon={b.icon} title={b.title} description={b.description} />
            </Box>
          </Grid>
        ))}
        <Grid item xs={12} md={4}>
          <Box display="flex" paddingTop="8px">
            <Button
              fullWidth
              size="large"
              text="Join for free"
              style={{ marginRight: '8px' }}
              onClick={onSignUp}
            />
            <Button
              fullWidth
              size="large"
              type="outlined"
              color="primary"
              text="Learn more"
              href={`${getHomeUrl(region)}/renting`}
              target="_blank"
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

CarSharingBenefits.propTypes = {
  classes: PropTypes.shape({
    itemsContainer: PropTypes.string.isRequired,
    itemContainer: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles)(CarSharingBenefits);
