import { all, call, put, select } from 'redux-saga/effects';
import _ from 'lodash';

import { apiService, storage, tagManager } from '@evee/evee-ui.services';
import { Region } from '@evee/evee-ui.enums';
import { getTopCites } from '@evee/evee-ui.utils';

import * as appSelectors from 'store/modules/app/selectors';
import * as homeActions from 'store/modules/home';
import { getRegion } from 'store/modules/app/selectors';

function* loadReviews() {
  try {
    const response = yield call(apiService.review.getFeatured);
    yield put(homeActions.loadReviewsSuccess(response));
  } catch (error) {
    yield put(homeActions.loadReviewsFailed());
  }
}

function* loadFeaturedLocations() {
  try {
    const region = yield select(getRegion);

    let featuredLocations = [];
    if (region.id === Region.newZealand.id) {
      const [auLocations, nzLocations] = yield all([
        call(apiService.location.getFeatured, Region.australia.id),
        call(apiService.location.getFeatured, Region.newZealand.id),
      ]);

      featuredLocations = nzLocations.concat(auLocations);
    } else {
      featuredLocations = yield call(apiService.location.getFeatured, region.id);
    }

    yield put(homeActions.loadFeaturedLocationsSuccess(featuredLocations));
  } catch (error) {
    yield put(homeActions.loadFeaturedLocationsFailed());
  }
}

function* loadFeaturedModels() {
  try {
    const currency = yield select(appSelectors.getCurrency);
    const featuredModels = yield call(apiService.dictionary.getFeaturedVehicleModels, currency.id);

    yield put(homeActions.loadFeaturedModelsSuccess(featuredModels));
  } catch (error) {
    yield put(homeActions.loadFeaturedModelsFailed());
  }
}

function* loadBlogPosts() {
  try {
    const response = yield call(apiService.blog.getFeatured);
    yield put(homeActions.loadBlogPostsSuccess(response));
  } catch (error) {
    yield put(homeActions.loadBlogPostsFailed());
  }
}

function* loadTopHosts() {
  try {
    const response = yield call(apiService.customer.getFeaturedHosts);
    yield put(homeActions.loadTopHostsSuccess(response));
  } catch (error) {
    yield put(homeActions.loadTopHostsFailed());
  }
}

function* loadTopCities() {
  try {
    const region = yield select(getRegion);
    const items = getTopCites(region);

    const response = yield call(apiService.location.getCities);
    const topCities = response.filter((e) => items.includes(e.name));

    const sortedCities = _.sortBy(topCities, (item) => {
      const index = items.indexOf(item.name);
      return index >= 0 ? index : items.length;
    });

    yield put(homeActions.loadTopCitiesSuccess(sortedCities));
  } catch (error) {
    yield put(homeActions.loadTopCitiesFailed());
  }
}

function* loadPartners() {
  try {
    const partners = yield call(apiService.partner.getPartners);

    yield put(homeActions.loadPartnersSuccess(partners));
  } catch (error) {
    yield put(homeActions.loadPartnersFailed());
  }
}

function* processSpecialOffer(action) {
  const {
    payload: { discountCode },
  } = action;
  yield call(storage.discountCode.save, discountCode);
  yield tagManager.pushSpecialOffer(discountCode);
}

export {
  loadReviews,
  loadFeaturedLocations,
  loadFeaturedModels,
  loadBlogPosts,
  loadTopHosts,
  loadTopCities,
  loadPartners,
  processSpecialOffer,
};
