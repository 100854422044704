import * as actionTypes from './actionTypes';
import initialState from './initialState';

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.LOAD_VEHICLE:
      return { ...initialState, loading: true };

    case actionTypes.LOAD_VEHICLE_SUCCESS:
    case actionTypes.LOAD_VEHICLE_FAILED:
      return { ...state, loading: false };

    case actionTypes.SET_VEHICLE:
      return { ...state, vehicleDetails: payload };

    case actionTypes.SET_ALL_FEATURES:
      return { ...state, allFeatures: payload };

    case actionTypes.SET_TOTALS:
      return { ...state, totals: payload };

    case actionTypes.LOAD_TOTALS:
      return { ...state, totals: { ...state.totals, loading: true } };

    case actionTypes.LOAD_TOTALS_SUCCESS:
    case actionTypes.LOAD_TOTALS_FAILED:
      return { ...state, totals: { ...state.totals, loading: false } };

    default:
      return state;
  }
};
