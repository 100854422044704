import PropTypes from 'prop-types';

import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import CollapsibleColumn from '../CollapsibleColumn';

const FooterColumn = ({ title, children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return isMobile ? (
    <CollapsibleColumn title={title}>{children}</CollapsibleColumn>
  ) : (
    <CollapsibleColumn title={title} collapsible={false}>
      {children}
    </CollapsibleColumn>
  );
};

FooterColumn.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

FooterColumn.defaultProps = {
  title: '',
  children: null,
};

export default FooterColumn;
