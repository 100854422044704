import { connect } from 'react-redux';

import * as subscribeActions from 'store/modules/subscribe/actions';
import * as subscribeSelectors from 'store/modules/subscribe/selectors';
import Subscribe from './Subscribe';

const mapStateToProps = (state) => ({
  loading: subscribeSelectors.getSubscribe(state).loading,
});

const mapDispatchToProps = (dispatch) => ({
  onSubscribe: () => dispatch(subscribeActions.subscribe()),
  onEmailChanged: (value) => dispatch(subscribeActions.setEmail(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Subscribe);
