import { all, fork } from 'redux-saga/effects';

import listenAccept from './listenAccept';
import listenCancel from './listenCancel';
import listenCreate from './listenCreate';
import listenDecline from './listenDecline';
import listenLoad from './listenLoad';
import listenLoadDialog from './listenLoadDialog';
import listenLoadTotals from './listenLoadTotals';

export default function* bookingChangeSaga() {
  yield all([
    fork(listenAccept),
    fork(listenCancel),
    fork(listenCreate),
    fork(listenDecline),
    fork(listenLoad),
    fork(listenLoadDialog),
    fork(listenLoadTotals),
  ]);
}
