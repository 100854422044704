import { all, fork } from 'redux-saga/effects';

import additionalDriversSaga from './additionalDrivers';
import appSaga from './app';
import authSaga from './auth';
import authorisePaymentSaga from './authorisePayment';
import basketSaga from './basket';
import bookingCardSaga from './bookingCard';
import bookingChangeSaga from './bookingChange';
import bookingConfirmationSaga from './bookingConfirmation';
import bookingDetailsSaga from './bookingDetails';
import bookingReviewsSaga from './bookingReviews';
import bookingSaga from './booking';
import customerSaga from './customer';
import extraPaymentsSaga from './extraPayments';
import extrasSaga from './extras';
import homeSaga from './home';
import hostAllBookingsSaga from './hostAllBookings';
import hostPageSaga from './hostPage';
import listYourCarSaga from './listYourCar';
import listingSaga from './listing';
import messagesPageSaga from './messagesPage';
import myCarsSaga from './myCars';
import myTripsSaga from './myTrips';
import paymentMethodSaga from './paymentMethod';
import paymentsAndPayoutsPageSaga from './paymentsAndPayoutsPage';
import payoutSaga from './payout';
import phoneSaga from './phone';
import profileSaga from './profile';
import rewardsPageSaga from './rewardsPage';
import searchPageSaga from './searchPage';
import searchSaga from './search';
import staticPageSaga from './staticPage';
import subscribeSaga from './subscribe';
import vehicleSaga from './vehicle';

function* rootSaga() {
  yield all([
    fork(additionalDriversSaga),
    fork(appSaga),
    fork(authSaga),
    fork(authorisePaymentSaga),
    fork(basketSaga),
    fork(bookingCardSaga),
    fork(bookingChangeSaga),
    fork(bookingConfirmationSaga),
    fork(bookingDetailsSaga),
    fork(bookingReviewsSaga),
    fork(bookingSaga),
    fork(customerSaga),
    fork(extraPaymentsSaga),
    fork(extrasSaga),
    fork(homeSaga),
    fork(hostAllBookingsSaga),
    fork(hostPageSaga),
    fork(listYourCarSaga),
    fork(listingSaga),
    fork(messagesPageSaga),
    fork(myCarsSaga),
    fork(myTripsSaga),
    fork(paymentMethodSaga),
    fork(paymentsAndPayoutsPageSaga),
    fork(payoutSaga),
    fork(phoneSaga),
    fork(profileSaga),
    fork(rewardsPageSaga),
    fork(searchPageSaga),
    fork(searchSaga),
    fork(staticPageSaga),
    fork(subscribeSaga),
    fork(vehicleSaga),
  ]);
}

export default rootSaga;
