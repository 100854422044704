import { call, put, select } from 'redux-saga/effects';

import { CustomerRoles } from '@evee/evee-ui.enums';
import { customerHasOwnerRole } from '@evee/evee-ui.utils';
import { storage } from '@evee/evee-ui.services';

import * as phoneSelectors from 'store/modules/phone/selectors';
import * as profileActions from 'store/modules/profile/actions';
import { showError } from 'store/modules/app/actions';

export function* updatePhone() {
  try {
    const phone = yield select(phoneSelectors.getPhoneNumber);
    const storedUser = yield call(storage.user.get);
    if (storedUser) {
      storedUser.phone = phone;
      storage.user.save(storedUser);
    }
  } catch (error) {
    yield put(showError(error.message));
  }
}

export function* updateUserRoles() {
  try {
    const storedUser = yield call(storage.user.get);
    if (customerHasOwnerRole(storedUser)) {
      return;
    }
    const roles = [...storedUser.roles, CustomerRoles.owner];
    yield put(profileActions.setFieldValue('roles', roles));

    if (storedUser) {
      storedUser.roles = roles;
      storage.user.save(storedUser);
    }
  } catch (error) {
    yield put(showError(error.message));
  }
}

export function* updatePersonalInfo({ payload }) {
  try {
    const { dateOfBirth, address } = payload;

    const storedUser = yield call(storage.user.get);
    if (storedUser) {
      storedUser.address = address;
      storedUser.dateOfBirth = dateOfBirth;
      storage.user.save(storedUser);
    }
  } catch (error) {
    yield put(showError(error.message));
  }
}

export function* setReceiveNewsletter() {
  try {
    const storedUser = yield call(storage.user.get);
    if (storedUser) {
      storedUser.receiveNewsletter = true;
      storage.user.save(storedUser);
    }
  } catch (error) {
    yield put(showError(error.message));
  }
}

export function* updateAvatarPhoto({ payload }) {
  try {
    const storedUser = yield call(storage.user.get);
    if (storedUser) {
      storedUser.photo = payload.url;
      storage.user.save(storedUser);
    }
  } catch (error) {
    yield put(showError(error.message));
  }
}
