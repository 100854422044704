import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Box, Link, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

const ServicesMenu = ({ classes }) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      height="64px"
      maxWidth="920px"
      margin="0 auto"
    >
      <Typography
        variant="body2"
        underline="none"
        className={clsx(classes.link, classes.activeLink)}
      >
        Electric carsharing
      </Typography>

      <Link
        href="https://www.evee.com.au/pages/partnerships/novated-leasing"
        target="_blank"
        variant="body2"
        underline="none"
        color="textSecondary"
        className={classes.link}
      >
        EV Financing
      </Link>

      <Link
        href="https://www.evee.com.au/pages/partnerships/#energy"
        target="_blank"
        variant="body2"
        underline="none"
        color="textSecondary"
        className={classes.link}
      >
        Energy & Charging
      </Link>

      <Link
        href="https://www.evee.com.au/pages/partnerships/ev-stealth-solutions"
        target="_blank"
        variant="body2"
        underline="none"
        color="textSecondary"
        className={classes.link}
      >
        EV Accessories
      </Link>
    </Box>
  );
};

ServicesMenu.propTypes = {
  classes: PropTypes.shape({
    link: PropTypes.string.isRequired,
    activeLink: PropTypes.string.isRequired,
  }).isRequired,
};

ServicesMenu.defaultProps = {};

export default withStyles(styles)(ServicesMenu);
