import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import DefaultDialog from 'widgets/layout/DefaultDialog';

import styles from './styles';

const CommonDialog = ({
  open,
  isMobile,
  title,
  actions,
  loading,
  children,
  classes,
  onClose,
  ...rest
}) => (
  <DefaultDialog
    open={open}
    title={title}
    fullScreen={isMobile}
    renderActions={actions}
    loading={loading}
    classes={{ titleRoot: classes.titleRoot }}
    onClose={onClose}
    {...rest}
  >
    <Box
      display="flex"
      flexDirection="column"
      alignItems="stretch"
      className={isMobile ? classes.mobileContainer : classes.desktopContainer}
    >
      {children}
    </Box>
  </DefaultDialog>
);

CommonDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
  actions: PropTypes.func,
  isMobile: PropTypes.bool,
  loading: PropTypes.bool,
  classes: PropTypes.shape({
    mobileContainer: PropTypes.string.isRequired,
    desktopContainer: PropTypes.string.isRequired,
    titleRoot: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

CommonDialog.defaultProps = {
  title: '',
  children: null,
  isMobile: false,
  actions: null,
  loading: false,
};

export default withStyles(styles)(CommonDialog);
