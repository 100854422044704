import PropTypes from 'prop-types';

import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import items from './items';

const styles = {
  container: {
    maxHeight: '30px',
  },
  image: {
    opacity: 0.4,
  },
};

const FeaturedIn = ({ classes }) => (
  <Grid container alignItems="center" justifyContent="space-between" className={classes.container}>
    {items.map((item, index) => (
      <Grid item xs key={index} style={{ flexGrow: 0 }}>
        <a href={item.href} target="_blank" rel="noopener noreferrer">
          <img alt="" src={item.image} className={classes.image} />
        </a>
      </Grid>
    ))}
  </Grid>
);

FeaturedIn.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles)(FeaturedIn);
