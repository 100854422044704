/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import initialState from './initialState';

export const myTripsSlice = createSlice({
  name: 'myTrips',
  initialState,
  reducers: {
    loadTrips(state) {
      state.loading = true;
    },

    loadTripsFailed(state) {
      state.loading = false;
    },

    loadTripsSuccess(state, { payload }) {
      const { upcoming, ongoing, completed, cancelled } = payload;
      state.bookings.upcoming = upcoming;
      state.bookings.ongoing = ongoing;
      state.bookings.completed = completed;
      state.bookings.cancelled = cancelled;
      state.loading = false;
    },

    setStatusFilter(state, { payload }) {
      state.statusFilter = payload;
      state.currentPage = 1;
    },

    loadMore(state) {
      state.pageLoading = true;
    },

    loadMoreFailed(state) {
      state.pageLoading = false;
    },

    loadMoreSuccess(state, { payload }) {
      state.pageLoading = false;
      state.currentPage = payload.pageNumber;
      state.bookings[state.statusFilter].items = [
        ...state.bookings[state.statusFilter].items,
        ...payload.bookings.items,
      ];
    },
  },
});

const { actions, reducer } = myTripsSlice;

export const {
  loadTrips,
  loadTripsFailed,
  loadTripsSuccess,
  setStatusFilter,
  loadMore,
  loadMoreFailed,
  loadMoreSuccess,
} = actions;

export default reducer;
