import { takeEvery } from 'redux-saga/effects';

import * as profileActions from 'store/modules/profile/actionTypes';

import { save } from './utils';

function* listenSave() {
  yield takeEvery(profileActions.SAVE, save);
}

export default listenSave;
