/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const signUpSlice = createSlice({
  name: 'auth/signUp',
  initialState: {},
  reducers: {
    showSignUp: (state, { payload }) => {
      state.signUp.open = true;
      state.signUp.email = payload || '';
    },

    closeSignUp: (state) => {
      state.signUp.open = false;
    },

    setSignUpFieldValue: (state, { payload }) => {
      state.signUp[payload.fieldName] = payload.fieldValue;
    },

    signUp: (state) => {
      state.signUp.loading = true;
    },

    signUpSuccess: (state) => {
      state.signUp.errorMessage = '';
      state.signUp.open = false;
      state.signUp.loading = false;
    },

    signUpFailed: (state, { payload }) => {
      state.signUp.errorMessage = payload;
      state.signUp.loading = false;
    },

    setErrors(state, { payload }) {
      state.signUp.errors = payload;
    },

    setFieldError(state, { payload }) {
      state.signUp.errors[payload.fieldName] = payload.fieldValue;
    },

    removeFieldError(state, { payload }) {
      state.signUp.errors[payload] = undefined;
    },

    setReCaptchaV2(state, { payload }) {
      state.signUp.reCaptchaV2 = {
        ...state.signUp.reCaptchaV2,
        ...payload,
      };
    },

    setReCaptchaV3(state, { payload }) {
      state.signUp.reCaptchaV3 = {
        ...state.signUp.reCaptchaV3,
        ...payload,
      };
    },

    verifyReCaptchaV2(state, { payload }) {
      state.signUp.reCaptchaV2 = {
        token: payload,
      };
    },

    verifyReCaptchaV3(state, { payload }) {
      state.signUp.reCaptchaV3 = {
        token: payload,
      };
    },

    resetReCaptchaV2(state) {
      state.signUp.reCaptchaV2 = {};
    },

    resetReCaptchaV3(state) {
      state.signUp.reCaptchaV3 = {};
    },
  },
});

const { actions, reducer } = signUpSlice;

export const {
  showSignUp,
  closeSignUp,
  setSignUpFieldValue,
  signUp,
  signUpSuccess,
  signUpFailed,
  setErrors,
  setFieldError,
  setReCaptchaV2,
  setReCaptchaV3,
  verifyReCaptchaV2,
  verifyReCaptchaV3,
  resetReCaptchaV2,
  resetReCaptchaV3,
  removeFieldError,
} = actions;

export default reducer;
