const prefix = 'vehicle';

export const SET_TOTALS = `${prefix}/SET_TOTALS`;

export const LOAD_TOTALS = `${prefix}/LOAD_TOTALS`;
export const LOAD_TOTALS_SUCCESS = `${prefix}/LOAD_TOTALS_SUCCESS`;
export const LOAD_TOTALS_FAILED = `${prefix}/LOAD_TOTALS_FAILED`;

export const LOAD_VEHICLE = `${prefix}/LOAD_VEHICLE`;
export const LOAD_VEHICLE_SUCCESS = `${prefix}/LOAD_VEHICLE_SUCCESS`;
export const LOAD_VEHICLE_FAILED = `${prefix}/LOAD_VEHICLE_FAILED`;

export const SET_VEHICLE = `${prefix}/SET_VEHICLE`;
export const SET_ALL_FEATURES = `${prefix}/SET_ALL_FEATURES`;
