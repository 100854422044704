import { all, fork } from 'redux-saga/effects';

import listenCreate from './listenAddPayout';
import listenCreateBusinessAccount from './listenCreateBusinessAccount';
import listenLoad from './listenLoad';
import listenRemove from './listenRemove';
import listenSetAccountFieldValue from './listenSetAccountFieldValue';
import listenSetDefault from './listenSetDefault';
import listenSetDialogFieldValue from './listenSetDialogFieldValue';
import listenUpdateBusinessAccount from './listenUpdateBusinessAccount';

export default function* payoutSaga() {
  yield all([
    fork(listenCreate),
    fork(listenCreateBusinessAccount),
    fork(listenLoad),
    fork(listenRemove),
    fork(listenSetAccountFieldValue),
    fork(listenSetDefault),
    fork(listenSetDialogFieldValue),
    fork(listenUpdateBusinessAccount),
  ]);
}
