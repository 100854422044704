import { all, fork } from 'redux-saga/effects';

import listenSubmitDeclaration from './declaration/listenSubmitDeclaration';

import listenLoadLicence from './licence/listenLoadLicence';
import listenVerifyLicence from './licence/listenVerifyLicence';

import listenLoadPushSubscription from './pushNotifications/listenLoadPushSubscription';
import listenSetPushSubscription from './pushNotifications/listenSetPushSubscription';

import listenChangeEmail from './listenChangeEmail';
import listenCopy from './listenCopy';
import listenDeactivateAccount from './listenDeactivateAccount';
import listenFacebookConnect from './listenFacebookConnect';
import listenGoogleConnect from './listenGoogleConnect';
import listenLoad from './listenLoad';
import listenSave from './listenSave';
import listenSetField from './listenSetField';
import listenSetProfile from './listenSetProfile';
import listenUploadPhoto from './listenUploadPhoto';

export default function* profileSaga() {
  yield all([
    fork(listenSubmitDeclaration),
    fork(listenLoadLicence),
    fork(listenVerifyLicence),
    fork(listenLoadPushSubscription),
    fork(listenSetPushSubscription),
    fork(listenChangeEmail),
    fork(listenCopy),
    fork(listenDeactivateAccount),
    fork(listenFacebookConnect),
    fork(listenGoogleConnect),
    fork(listenLoad),
    fork(listenSave),
    fork(listenSetField),
    fork(listenSetProfile),
    fork(listenUploadPhoto),
  ]);
}
