import { takeEvery } from 'redux-saga/effects';

import { PhoneVerificationStatus } from '@evee/evee-ui.enums';

import * as phoneActionTypes from 'store/modules/phone/actionTypes';

import { validateFieldValue } from './utils';

function* validatePhoneStatus() {
  yield validateFieldValue({
    payload: {
      fieldName: 'phoneStatus',
      fieldValue: PhoneVerificationStatus.verified,
    },
  });
}

export default function* listenCheckVerificationCodeSuccess() {
  yield takeEvery(phoneActionTypes.CHECK_VERIFICATION_CODE_SUCCESS, validatePhoneStatus);
}
