import { all, fork } from 'redux-saga/effects';

import listenCancelSignIn from './listenCancelSignIn';
import listenFacebookSignIn from './signIn/listenFacebookSignIn';
import listenGoogleSignIn from './signIn/listenGoogleSignIn';
import listenSigInFieldChanged from './signIn/listenSigInFieldChanged';
import listenSignIn from './signIn/listenSignIn';
import listenSignInSuccess from './listenSignInSuccess';

import listenAuthorizedRouteSignIn from './signIn/listenAuthorizedRouteSignIn';
import listenForgotPassword from './listenForgotPassword';
import listenResetPassword from './listenResetPassword';
import listenSetResetPasswordFieldValue from './listenSetResetPasswordFieldValue';
import listenSetSignUpFieldValue from './listenSetSignUpFieldValue';
import listenSignUp from './listenSignUp';
import listenSignout from './listenSignout';
import listenVerifyReCaptchaV2 from './listenVerifyReCaptchaV2';
import listenVerifyReCaptchaV3 from './listenVerifyReCaptchaV3';

import listenPasteCode from './verifyEmail/listenPasteCode';
import listenSendEmailVerification from './verifyEmail/listenSendEmailVerification';
import listenShowVerifyEmail from './verifyEmail/listenShowVerifyEmail';
import listenVerifyEmail from './verifyEmail/listenVerifyEmail';

import listenSetUpdatePasswordFieldValue from './updatePassword/listenSetUpdatePasswordFieldValue';
import listenUpdatePassword from './updatePassword/listenUpdatePassword';

import listenCheckVerificationCodeSuccess from './storedUser/listenCheckVerificationCodeSuccess';
import listenSaveSuccess from './storedUser/listenSaveSuccess';
import listenSetPersonalInfo from './storedUser/listenSetPersonalInfo';
import listenSetReceiveNewsletter from './storedUser/listenSetReceiveNewsletter';
import listenUploadPhotoSuccess from './storedUser/listenUploadPhotoSuccess';

export default function* authSaga() {
  yield all([
    fork(listenFacebookSignIn),
    fork(listenGoogleSignIn),
    fork(listenSigInFieldChanged),
    fork(listenSignIn),

    fork(listenAuthorizedRouteSignIn),
    fork(listenCancelSignIn),
    fork(listenForgotPassword),
    fork(listenResetPassword),
    fork(listenSetResetPasswordFieldValue),
    fork(listenSignInSuccess),
    fork(listenSignout),

    fork(listenSetSignUpFieldValue),
    fork(listenSignUp),

    fork(listenSendEmailVerification),
    fork(listenShowVerifyEmail),
    fork(listenVerifyEmail),
    fork(listenPasteCode),

    fork(listenSetUpdatePasswordFieldValue),
    fork(listenUpdatePassword),
    fork(listenVerifyReCaptchaV2),
    fork(listenVerifyReCaptchaV3),

    // TODO: find a better way to update stored user fields
    fork(listenUploadPhotoSuccess),
    fork(listenSetPersonalInfo),
    fork(listenCheckVerificationCodeSuccess),
    fork(listenSetReceiveNewsletter),
    fork(listenSaveSuccess),
  ]);
}
