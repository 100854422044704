import { takeEvery } from 'redux-saga/effects';

import * as emailVerificationActions from 'store/modules/auth/verifyEmail';

import { sendEmailVerification } from './utils';

function* listenSendEmailVerification() {
  yield takeEvery(emailVerificationActions.sendEmailVerification.toString(), sendEmailVerification);
}

export default listenSendEmailVerification;
