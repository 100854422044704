import { alpha, lighten } from '@material-ui/core/styles';
import { blue, green, grey, orange, red } from '@material-ui/core/colors';

export const navyBlue = '#4366B4';
export const ochre = '#FFAE6E';
export const darkOchre = '#C97E41';
export const lightOchre = '#FBEDE2';
export const midnightBlue = '#1E2832';
export const skyBlue = '#BED7EB';
export const stone = '#F4F0EB';

const lightGrey = grey[100];

const lightTheme = {
  palette: {
    common: {
      red,
      green,
      orange,
      navyBlue,
      blue,
      midnightBlue,
      skyBlue,
      stone,
      grey,
    },
    primary: {
      main: midnightBlue,
      contrastText: midnightBlue,
    },
    secondary: {
      main: ochre,
      dark: darkOchre,
      contrastText: midnightBlue,
    },
    background: {
      main: lightGrey,
      secondary: lightOchre,
    },
    border: {
      main: '#D2D4D6',
      active: midnightBlue,
    },
    text: {
      primary: midnightBlue,
      secondary: alpha(midnightBlue, 0.7),
    },
    error: {
      main: '#F44336',
      light: '#F88078',
      dark: '#E31B0C',
    },
    warning: {
      main: '#ED6C02',
      light: '#FFB547',
      dark: '#4A2E05',
    },
    success: {
      main: '#4CAF50',
      light: '#7BC67E',
      dark: '#3B873E',
    },
    divider: '#D2D4D6',
  },
  typography: {
    caption: {
      color: lighten(midnightBlue, 0.2),
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: lightGrey,
          '&::backdrop': {
            backgroundColor: lightGrey,
          },
        },
      },
    },
    MuiInputLabel: {
      formControl: {
        position: undefined,
        transform: undefined,
        '&$focused': {
          color: midnightBlue,
        },
      },
    },
  },
};

export default lightTheme;
