import { takeEvery } from 'redux-saga/effects';

import * as profileActions from 'store/modules/profile/actionTypes';

import { copy } from './utils';

function* listenCopy() {
  yield takeEvery(profileActions.COPY, copy);
}

export default listenCopy;
