import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';

import TimeSelector from './TimeSelector';

const TimeRangeSelector = ({
  value,
  startMin,
  startMax,
  endMin,
  endMax,
  pickUpDisabled,
  dropOffDisabled,
  containerClassName,
  itemClassName,
  onChange,
}) => {
  const { startHour, endHour } = value;

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-around"
      className={containerClassName}
    >
      <Box className={itemClassName}>
        <TimeSelector
          label="Pick up"
          value={startHour}
          min={startMin}
          max={startMax}
          disabled={pickUpDisabled}
          onChange={(v) => onChange({ startHour: v, endHour })}
        />
      </Box>
      <Box className={itemClassName}>
        <TimeSelector
          label="Drop off"
          value={endHour}
          min={endMin}
          max={endMax}
          disabled={dropOffDisabled}
          defaultOptionGetter={(options) => options[options.length - 1]}
          onChange={(v) => onChange({ startHour, endHour: v })}
        />
      </Box>
    </Box>
  );
};

TimeRangeSelector.propTypes = {
  value: PropTypes.shape({
    startHour: PropTypes.number,
    endHour: PropTypes.number,
  }),
  startMin: PropTypes.number,
  startMax: PropTypes.number,
  endMin: PropTypes.number,
  endMax: PropTypes.number,
  pickUpDisabled: PropTypes.bool,
  dropOffDisabled: PropTypes.bool,
  containerClassName: PropTypes.string,
  itemClassName: PropTypes.string,
  onChange: PropTypes.func,
};

TimeRangeSelector.defaultProps = {
  value: {},
  containerClassName: '',
  itemClassName: '',
  startMin: null,
  startMax: null,
  endMin: null,
  endMax: null,
  pickUpDisabled: false,
  dropOffDisabled: false,
  onChange: () => {},
};

export default TimeRangeSelector;
