import { connect } from 'react-redux';

import * as homeActions from 'store/modules/home';
import * as homeSelectors from 'store/modules/home/selectors';
import AllServices from './AllServices';

const mapStateToProps = (state) => ({
  partners: homeSelectors.getPartners(state),
  loading: homeSelectors.getPartnersLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  onLoad: () => dispatch(homeActions.loadPartners()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AllServices);
