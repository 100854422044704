/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import initialState from './initialState';

export const payoutSlice = createSlice({
  name: 'payouts',
  initialState,
  reducers: {
    loadPayouts(state) {
      state.loading = true;
    },

    loadPayoutsFailed(state) {
      state.loading = false;
    },

    setDefault(state) {
      state.loading = true;
    },

    setDefaultFailed(state) {
      state.loading = false;
    },

    remove(state) {
      state.loading = true;
    },

    removeFailed(state) {
      state.loading = false;
    },

    loadPayoutsSuccess(state, { payload }) {
      state.bankAccounts = payload;
      state.loading = false;
    },

    removeSuccess(state, { payload }) {
      state.bankAccounts = state.bankAccounts.filter((p) => p.id !== payload);
      state.loading = false;
    },

    setDefaultSuccess(state, { payload }) {
      state.bankAccounts = state.bankAccounts.map((p) => ({ ...p, isDefault: p.id === payload }));
      state.loading = false;
    },

    setDialogOpen(state, { payload }) {
      state.addAccountDialog = { ...state.addAccountDialog, open: payload };
    },

    setStripeAccount(state, { payload }) {
      state.stripeAccount = { ...payload };
    },

    setFieldValue(state, { payload }) {
      state[payload.fieldName] = payload.fieldValue;
    },

    setAccountFieldValue(state, { payload }) {
      state.stripeAccount = { ...state.stripeAccount, [payload.fieldName]: payload.fieldValue };
    },

    setDialogFieldValue(state, { payload }) {
      state.addAccountDialog[payload.fieldName] = payload.fieldValue;
    },

    addPayout(state) {
      state.addAccountDialog.loading = true;
    },

    addPayoutSuccess(state, { payload }) {
      state.bankAccounts = [...state.bankAccounts, payload];
      state.addAccountDialog = initialState.addAccountDialog;
    },

    addPayoutFailed(state) {
      state.addAccountDialog.loading = false;
    },

    createStripeAccount(state) {
      state.loading = true;
    },

    updateBusinessAccount(state) {
      state.loading = true;
    },

    createStripeAccountFailed(state) {
      state.loading = false;
    },

    updateBusinessAccountFailed(state) {
      state.loading = false;
    },

    createStripeAccountSuccess(state, { payload }) {
      state.loading = false;
      state.stripeAccount = {
        ...state.stripeAccount,
        ...payload,
      };
    },

    updateBusinessAccountSuccess(state, { payload }) {
      state.loading = false;
      state.stripeAccount = {
        ...state.stripeAccount,
        ...payload,
      };
    },

    setErrors(state, { payload }) {
      state.form.errors = payload;
    },

    setFieldError(state, { payload }) {
      state.form.errors[payload.path] = payload.message;
    },

    removeFieldError(state, { payload }) {
      state.form.errors[payload] = undefined;
    },
  },
});

const { actions, reducer } = payoutSlice;

export const {
  addPayout,
  addPayoutFailed,
  addPayoutSuccess,
  createStripeAccount,
  createStripeAccountFailed,
  createStripeAccountSuccess,
  loadPayouts,
  loadPayoutsFailed,
  loadPayoutsSuccess,
  remove,
  removeFailed,
  removeFieldError,
  removeSuccess,
  setAccountFieldValue,
  setDefault,
  setDefaultFailed,
  setDefaultSuccess,
  setDialogFieldValue,
  setDialogOpen,
  setErrors,
  setFieldError,
  setFieldValue,
  setStripeAccount,
  updateBusinessAccount,
  updateBusinessAccountFailed,
  updateBusinessAccountSuccess,
} = actions;

export default reducer;
