import { configureStore } from '@reduxjs/toolkit';
import saga from 'saga';

import rootReducer from './rootReducer';

const isDevelopment = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export const store = configureStore({
  reducer: rootReducer,
  middleware: [saga],
  devTools: isDevelopment,
});

export default store;
