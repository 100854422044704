import declaration from './declaration/initialState';
import licence from './licence/initialState';

export default {
  address: {
    description: '',
  },
  photo: '',
  loading: false,
  avatarLoading: false,
  receiveNewsletter: false,
  receivePushNotifications: undefined,
  fullName: '',
  firstName: '',
  lastName: '',
  email: '',
  emailVerified: false,
  dateOfBirth: null,
  form: {
    errors: {},
  },
  ...licence,
  licenceLoading: false,
  ...declaration,
  deactivateDialog: {
    open: false,
    loading: false,
    reason: '',
  },
};
