/* eslint-disable no-param-reassign */
import { createAction, createSlice } from '@reduxjs/toolkit';

export const authorizedRouteSignIn = createAction('auth/signIn/authorizedRouteSignIn');
export const googleSignIn = createAction('auth/signIn/googleSignIn');
export const facebookSignIn = createAction('auth/signIn/facebookSignIn');

export const signInSlice = createSlice({
  name: 'auth/signIn',
  initialState: {},
  reducers: {
    showSignIn: (state) => {
      state.signIn.open = true;
    },

    closeSignIn: (state) => {
      state.signIn.open = false;
    },

    setSignInFieldValue: (state, { payload }) => {
      state.signIn[payload.fieldName] = payload.fieldValue;
    },

    signIn: (state) => {
      state.signIn.loading = true;
    },

    signInSuccess: (state) => {
      state.signIn.errorMessage = '';
      state.signIn.open = false;
      state.signIn.loading = false;
    },

    signInFailed: (state, { payload }) => {
      state.signIn.errorMessage = payload;
      state.signIn.loading = false;
    },

    resetForm: (state) => {
      state.signIn.email = '';
      state.signIn.password = '';
      state.signIn.errorMessage = '';
    },
  },
});

const { actions, reducer } = signInSlice;

export const {
  showSignIn,
  closeSignIn,
  setSignInFieldValue,
  signIn,
  signInSuccess,
  signInFailed,
  resetForm,
} = actions;

export default reducer;
