/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const occupationsSlice = createSlice({
  name: 'listing/occupations',
  initialState: {},
  reducers: {
    load: (state) => {
      state.occupations.loading = true;
    },

    loadSuccess: (state, { payload }) => {
      state.occupations.occupiedRanges = payload;
      state.occupations.loading = false;
    },

    loadFailed: (state) => {
      state.occupations.loading = false;
    },

    dateProcessingSuccess: (state) => {
      state.occupations.loading = false;
    },

    dateProcessingFailed: (state) => {
      state.occupations.loading = false;
    },

    setCurrentMonth: (state, { payload }) => {
      state.occupations.currentMonth = payload;
    },

    setRange: (state, { payload }) => {
      state.occupations.range = payload;
    },

    setOccupationsLoading: (state, { payload }) => {
      state.occupations.loading = payload;
    },

    dateToggled: () => {
      // nothing to do. used in saga.
    },
  },
});

const { actions, reducer } = occupationsSlice;

export const {
  load,
  loadFailed,
  loadSuccess,
  dateProcessingFailed,
  dateProcessingSuccess,
  setCurrentMonth,
  setRange,
  setOccupationsLoading,
  dateToggled,
} = actions;

export default reducer;
