import { Facebook, Instagram, LinkedIn } from '@material-ui/icons';
import { Box } from '@material-ui/core';

import { IconButton } from '@evee/evee-ui.base';

import Twitter from 'common/icons/Twitter';

const SocialButtons = ({ ...props }) => (
  <Box {...props} style={{ marginLeft: '-5px' }}>
    <IconButton
      size="small"
      style={{ marginRight: '5px' }}
      icon={<Facebook fontSize="small" />}
      href="https://www.facebook.com/eveecars/"
    />

    <IconButton
      size="small"
      style={{ marginRight: '5px' }}
      icon={<Twitter fontSize="small" />}
      href="https://twitter.com/eveecars/"
    />

    <IconButton
      size="small"
      style={{ marginRight: '5px' }}
      icon={<Instagram fontSize="small" />}
      href="https://www.instagram.com/eveecars/"
    />

    <IconButton
      size="small"
      style={{ marginRight: '5px' }}
      icon={<LinkedIn fontSize="small" />}
      href="https://www.linkedin.com/company/7963773"
    />
  </Box>
);

export default SocialButtons;
