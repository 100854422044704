import PropTypes from 'prop-types';

import { Avatar, ButtonBase } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import {
  AccountCircleOutlined as AccountCircleOutlinedIcon,
  Menu as MenuIcon,
} from '@material-ui/icons';

import { NotificationType } from 'enumsTemp';
import useAuth from 'store/modules/auth/hooks/useAuth';

import NotificationsCount from '../../../Menu/utils/NotificationsCount';
import styles from './styles';

const HamburgerButton = ({ color, disabled, classes, onClick, ...rest }) => {
  const { currentUser, signedIn } = useAuth();
  const { fullName, photo, nickname } = currentUser;

  const noPhotoJsx = signedIn ? (
    <NotificationsCount>
      <AccountCircleOutlinedIcon color={color} className={classes.avatar} />
    </NotificationsCount>
  ) : (
    <AccountCircleOutlinedIcon color={color} className={classes.avatar} />
  );

  return (
    <ButtonBase
      disableRipple
      aria-haspopup="true"
      aria-controls="menu"
      data-test-id="hamburger-button"
      style={{
        display: 'flex',
        alignItems: 'center',
        cursor: !disabled ? 'pointer' : 'initial',
      }}
      onClick={disabled ? null : onClick}
      {...rest}
    >
      {photo ? (
        <NotificationsCount>
          <Avatar color={color} alt={fullName} src={photo} className={classes.avatar}>
            {nickname}
          </Avatar>
        </NotificationsCount>
      ) : (
        noPhotoJsx
      )}

      <MenuIcon color={color} className={classes.icon} />
    </ButtonBase>
  );
};

HamburgerButton.propTypes = {
  color: PropTypes.string,
  disabled: PropTypes.bool,
  classes: PropTypes.shape({
    avatar: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func,
};

HamburgerButton.defaultProps = {
  color: '',
  disabled: false,
  onClick: () => {},
};

export default withStyles(styles)(HamburgerButton);
