import { delay, takeLatest } from 'redux-saga/effects';

import * as basketActions from 'store/modules/basket';
import { updateMessage } from './utils';

function* validateMessage({ payload }) {
  if (payload.fieldName === 'message') {
    yield delay(500);
    yield updateMessage();
  }
}

export default function* listenSetBasketField() {
  yield takeLatest(basketActions.setFieldValue.toString(), validateMessage);
}
