const styles = (theme) => ({
  forgotButton: {
    padding: '0px',
    minWidth: '0px',
  },
  footerText: {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 500,
  },
  desktopSocialButton: {
    margin: '0px',
    width: '50%',
  },
  mobileSocialButton: {
    marginTop: '10px',
    width: '100%',
  },
  facebookIcon: {
    width: '24px',
    height: '24px',
    color: theme.palette.common.white,
  },
});

export default styles;
