/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { nanoid } from 'nanoid';

import initialState from './initialState';

const calcTotal = (items) =>
  items.reduce((prev, current) => prev + Number(current.amount) || 0, 0).toFixed(2);

export const extraPaymentsSlice = createSlice({
  name: 'extraPayments',
  initialState,
  reducers: {
    load() {
      // nothing to do
    },

    setExtraPayments(state, { payload }) {
      state.extraPayments = payload;
    },

    setSelectedExtraPayment(state, { payload }) {
      state.selectedExtraPayment = payload;
    },

    setDraft(state, { payload }) {
      state.draft = { ...state.draft, ...payload };
    },

    addPayment(state, { payload }) {
      state.draft = {
        ...state.draft,
        items: [...state.draft.items, { ...payload, id: nanoid() }],
      };
    },

    setPayment(state, { payload }) {
      const { items } = state.draft;
      const index = items.findIndex((l) => l.id === payload.id);
      items.splice(index, 1, payload);

      state.draft.items = [...items];
      state.draft.total = calcTotal(items);
    },

    removePayment(state, { payload }) {
      const { items } = state.draft;
      const index = items.findIndex((l) => l.id === payload.id);
      items.splice(index, 1);

      state.draft.items = [...items];
      state.draft.total = calcTotal(items);
    },

    uploadFile(state) {
      state.draft.loading = true;
    },

    uploadFileSuccess(state, { payload }) {
      state.draft = {
        ...state.draft,
        id: payload.id,
        documents: payload.documents,
        loading: false,
      };
    },

    uploadFileFailed(state) {
      state.draft.loading = false;
    },

    deleteFile(state) {
      state.draft.loading = true;
    },

    deleteFileSuccess(state, { payload }) {
      state.draft = {
        ...state.draft,
        documents: payload.documents,
        loading: false,
      };
    },

    deleteFileFailed(state) {
      state.draft.loading = false;
    },

    resetDraft(state) {
      state.draft = initialState.draft;
    },

    cancelDraft(state) {
      state.draft.open = false;
    },

    create(state) {
      state.draft.loading = true;
    },

    createSuccess(state, { payload }) {
      state.draft.loading = false;
      state.extraPayments = [...state.extraPayments, payload];
    },

    createFailed(state) {
      state.draft.loading = false;
    },

    changeStatusSuccess(state, { payload }) {
      const extraPayments = [...state.extraPayments];
      const index = extraPayments.findIndex((l) => l.id === payload.id);
      extraPayments.splice(index, 1, payload);

      state.extraPayments = extraPayments;
      state.selectedExtraPayment = "";
    },

    accept() {
      // nothing to do
    },

    decline() {
      // nothing to do
    },

    cancel() {
      // nothing to do
    },

    setDraftLoading(state, { payload }) {
      state.draft.loading = payload;
    },

    setExtraPaymentLoading(state, { payload }) {
      const payment = state.extraPayments.find((l) => l.id === payload.id);
      payment.loading = payload.loading;
    },

    setErrors(state, { payload }) {
      state.form.errors = payload;
    },

    setFieldError(state, { payload }) {
      state.form.errors[payload.fieldName] = payload.fieldValue;
    },

    removeFieldError(state, { payload }) {
      state.form.errors[payload] = undefined;
    },

    setComments(state, { payload }) {
      state.draft.comments = payload;
    },

    setDraftOpen(state, { payload }) {
      state.draft.open = payload;
    },
  },
});

const { actions, reducer } = extraPaymentsSlice;

export const {
  load,
  setExtraPayments,
  setSelectedExtraPayment,
  setDraft,
  addPayment,
  setPayment,
  removePayment,
  uploadFile,
  uploadFileSuccess,
  uploadFileFailed,
  deleteFile,
  deleteFileSuccess,
  deleteFileFailed,
  setComments,
  resetDraft,
  cancelDraft,
  create,
  createSuccess,
  createFailed,
  changeStatusSuccess,
  accept,
  decline,
  cancel,
  setDraftLoading,
  setExtraPaymentLoading,
  setErrors,
  setFieldError,
  removeFieldError,
  setDraftOpen,
} = actions;

export default reducer;
