import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Check = (props) => (
  <SvgIcon {...props} viewBox="0 0 64 64">
    <rect width="64" height="64" rx="32" fill="#4CAF50" fillOpacity="0.24" />
    <path
      d="M49.8934 19.2267C49.6455 18.9767 49.3505 18.7783 49.0256 18.643C48.7006 18.5076 48.3521 18.4379 48 18.4379C47.648 18.4379 47.2994 18.5076 46.9745 18.643C46.6495 18.7783 46.3546 18.9767 46.1067 19.2267L26.24 39.12L17.8934 30.7467C17.636 30.498 17.3321 30.3025 16.9992 30.1713C16.6662 30.0401 16.3107 29.9758 15.9529 29.9819C15.5951 29.9881 15.242 30.0647 14.9138 30.2074C14.5856 30.35 14.2887 30.5559 14.04 30.8133C13.7914 31.0707 13.5959 31.3746 13.4647 31.7075C13.3335 32.0405 13.2691 32.396 13.2753 32.7538C13.2815 33.1116 13.3581 33.4647 13.5008 33.7929C13.6434 34.1211 13.8493 34.418 14.1067 34.6667L24.3467 44.9067C24.5946 45.1566 24.8895 45.355 25.2145 45.4904C25.5394 45.6258 25.888 45.6955 26.24 45.6955C26.5921 45.6955 26.9406 45.6258 27.2656 45.4904C27.5905 45.355 27.8855 45.1566 28.1334 44.9067L49.8934 23.1467C50.164 22.8969 50.3801 22.5939 50.5278 22.2565C50.6756 21.9192 50.7519 21.5549 50.7519 21.1867C50.7519 20.8184 50.6756 20.4541 50.5278 20.1168C50.3801 19.7794 50.164 19.4764 49.8934 19.2267Z"
      fill="#3B873E"
    />
  </SvgIcon>
);
Check.displayName = 'CheckIcon';
Check.muiName = 'SvgIcon';

export default Check;
