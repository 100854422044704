import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import clsx from 'clsx';

import { Divider, Tab, TabPanel, Tabs } from '@evee/evee-ui.base';
import { withStyles } from '@material-ui/core/styles';

import useInterval from 'common/hooks/useInterval';

import CarSharingBenefits from './CarSharingBenefits';
import PartnerOffers from './PartnerOffers';
import styles from './styles';

const AllServices = ({ partners, loading, classes, onLoad }) => {
  const [value, setValue] = useState(0);
  const [playAnimation, setPlayAnimation] = useState(true);

  const handleTabChange = (_event, newValue) => {
    setPlayAnimation(false);
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  useInterval(
    () => {
      setValue(value === 0 ? 1 : 0);
    },
    playAnimation ? 5000 : null,
  );

  const indicatorClsx = clsx(playAnimation && classes.indicator);

  useEffect(() => {
    onLoad();
  }, []);

  return (
    <>
      <Tabs
        variant="fullWidth"
        indicatorColor="primary"
        value={value}
        centered={false}
        classes={{ indicator: indicatorClsx }}
        onChange={handleTabChange}
      >
        <Tab label="Car sharing" />
        <Tab label="Partner offers" />
      </Tabs>

      <Divider />

      <SwipeableViews index={value} onChangeIndex={handleChangeIndex}>
        <TabPanel value={value} index={0} paddingTop="24px">
          <CarSharingBenefits />
        </TabPanel>

        <TabPanel value={value} index={1} paddingTop="24px">
          <PartnerOffers partners={partners} loading={loading} />
        </TabPanel>
      </SwipeableViews>
    </>
  );
};

AllServices.propTypes = {
  partners: PropTypes.array,
  loading: PropTypes.bool,
  classes: PropTypes.shape({
    indicator: PropTypes.string.isRequired,
  }).isRequired,
};

AllServices.defaultProps = {
  loading: false,
  partners: [],
};

export default withStyles(styles)(AllServices);
