import { useDispatch, useSelector } from 'react-redux';

import * as searchActions from '../actions';
import * as searchSelectors from '../selectors';

export default function useSearchProps() {
  const tripDates = useSelector(searchSelectors.getTripDates);
  const location = useSelector(searchSelectors.getLocation);
  const loading = useSelector(searchSelectors.getLoading);

  const dispatch = useDispatch();
  const onChangeLocation = (value) => dispatch(searchActions.changeLocation(value));
  const onFieldChanged = (field, value) => dispatch(searchActions.setFieldValue(field, value));
  const onSearch = () => dispatch(searchActions.search());

  return [tripDates, location, loading, onChangeLocation, onFieldChanged, onSearch];
}
