import { call, put, select } from 'redux-saga/effects';
import { ValidationError } from 'yup';

import { ErrorMessage, PhoneVerificationStatus } from '@evee/evee-ui.enums';
import { apiService } from '@evee/evee-ui.services';
import { getDefaultPhoneCode } from '@evee/evee-ui.utils';
import { phone as phoneModel } from '@evee/evee-ui.models';

import * as appSelectors from 'store/modules/app/selectors';
import * as phoneActions from 'store/modules/phone/actions';
import * as phoneSelectors from 'store/modules/phone/selectors';
import { getPhone } from 'store/modules/profile/selectors';
import { showError } from 'store/modules/app/actions';

export function* load() {
  const phone = yield select(getPhone);
  if (!phone) {
    yield put(phoneActions.resetPhone());
    return;
  }

  yield put(
    phoneActions.setPhoneNumber(phone.code, phone.number, PhoneVerificationStatus.verified),
  );
}

export function* sendVerificationCode() {
  try {
    const phoneNumber = yield select(phoneSelectors.getPhoneNumber);
    if (!phoneNumber.code || !phoneNumber.number) {
      throw new Error('Invalid phone number');
    }

    yield call(apiService.phone.sendCode, phoneNumber);
    yield put(phoneActions.sendVerificationCodeSuccess());
  } catch (error) {
    yield put(showError(error.message));
    yield put(phoneActions.sendVerificationCodeFailed());
  }
}

export function* checkVerificationCode() {
  try {
    const phoneNumber = yield select(phoneSelectors.getPhoneNumber);
    const verificationCode = yield select(phoneSelectors.getVerificationCode);

    yield call(apiService.phone.checkCode, { phoneNumber, verificationCode });
    yield put(phoneActions.checkVerificationCodeSuccess());
  } catch (error) {
    yield put(showError(error.message));
    yield put(phoneActions.checkVerificationCodeFailed());
  }
}

export function* validatePhone() {
  try {
    const phone = yield select(phoneSelectors.getPhoneNumber);
    yield phoneModel.phoneSchema.validate(`${phone.code} ${phone.number}`);
    yield put(phoneActions.setError(''));
  } catch (err) {
    if (err instanceof ValidationError) {
      yield put(phoneActions.setError(err.message));
    } else {
      yield put(showError(err.message));
    }
  }
}

export function* clearPhoneNumber() {
  try {
    const region = yield select(appSelectors.getRegion);
    const defaultCountryCode = getDefaultPhoneCode(region);
    yield put(
      phoneActions.setPhoneNumber(defaultCountryCode, '', PhoneVerificationStatus.unverified),
    );
  } catch (err) {
    yield put(showError(ErrorMessage.commonError));
  }
}
