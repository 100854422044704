import React from 'react';
import { SvgIcon } from '@material-ui/core';

const BrandName = (props) => (
  <SvgIcon viewBox="0 0 1450 700" {...props}>
    <path
      d="M575.4,464.8L461.1,236.3c-0.8-1.6,0.4-3.4,2.1-3.4h24.9c2.1,0,4,1.2,4.9,3l94.4,188.8l94.4-188.8
          c0.9-1.9,2.8-3,4.9-3h24.9c1.8,0,2.9,1.9,2.1,3.4L599.6,464.8c-0.9,1.7-2.6,2.8-4.5,2.8h-15.2C578,467.6,576.2,466.5,575.4,464.8z"
    />

    <path
      d="M1189.5,354.9c-2.8,0-5.1,2.1-5.4,4.8c-2.2,19.6-10.8,38.7-25.8,53.7c-31.7,31.7-81.3,34.8-116.5,9.3
          l142.7-142.7c1.8-1.8,1.8-4.6,0-6.4l-6.9-6.9c-47.1-47.1-124.4-45.8-169.8,4c-40.6,44.5-40.6,113.5,0,157.9
          c45.4,49.8,122.7,51.1,169.8,4c21-21,32.3-47.9,34.1-75.3c0.1-1.4-1-2.5-2.4-2.5H1189.5z M1030.8,286
          c31.7-31.7,81.3-34.8,116.5-9.3l-125.8,125.8C995.9,367.3,999,317.8,1030.8,286z"
    />

    <path
      d="M924.9,354.9c-2.8,0-5.1,2.1-5.4,4.8c-2.2,19.6-10.8,38.7-25.8,53.7c-31.7,31.7-81.3,34.8-116.5,9.3
          l142.7-142.7c1.8-1.8,1.8-4.6,0-6.4l-6.9-6.9c-47.1-47.1-124.4-45.8-169.8,4c-40.6,44.5-40.6,113.5,0,157.9
          c45.4,49.8,122.7,51.1,169.8,4c21-21,32.3-47.9,34.1-75.3c0.1-1.4-1-2.5-2.4-2.5H924.9z M766.2,286c31.7-31.7,81.3-34.8,116.5-9.3
          L756.9,402.5C731.4,367.3,734.5,317.8,766.2,286z"
    />

    <path
      d="M450.6,354.9c-2.8,0-5.1,2.1-5.4,4.8c-2.2,19.6-10.8,38.7-25.8,53.7c-31.7,31.7-81.3,34.8-116.5,9.3
          l142.7-142.7c1.8-1.8,1.8-4.6,0-6.4l-6.9-6.9c-47.1-47.1-124.4-45.8-169.8,4c-40.6,44.5-40.6,113.5,0,157.9
          c45.4,49.8,122.7,51.1,169.8,4c21-21,32.3-47.9,34.1-75.3c0.1-1.4-1-2.5-2.4-2.5H450.6z M291.9,286c31.7-31.7,81.3-34.8,116.5-9.3
          L282.5,402.5C257,367.3,260.1,317.8,291.9,286z"
    />
  </SvgIcon>
);

BrandName.displayName = 'BrandNameIcon';
BrandName.muiName = 'SvgIcon';

export default BrandName;
