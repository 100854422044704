import fonts from '@evee/evee-ui.fonts';

export default {
  shape: {
    borderRadiusSmall: 4,
    borderRadius: 8,
    borderRadiusMedium: 16,
    borderRadiusLarge: 32,
  },
  typography: {
    fontFamily: 'FKGroteskNeue',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    h1: {
      fontSize: '4rem',
      fontWeight: 500,
      lineHeight: 1.1,
    },
    h2: {
      fontSize: '3rem',
      fontWeight: 500,
      lineHeight: 1.1,
    },
    h3: {
      fontSize: '2rem',
      fontWeight: 500,
      lineHeight: 1.25,
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 500,
      lineHeight: 1.25,
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: 500,
      lineHeight: 1.3,
    },
    h6: {
      fontSize: '1.125rem',
      fontWeight: 500,
      lineHeight: 1.3,
    },
    subtitle1: {
      fontSize: '1rem',
      lineHeight: 1.75,
    },
    subtitle2: {
      fontSize: '0.875rem',
      fontWeight: 700,
      lineHeight: 1.57,
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.5,
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: 1.285,
    },
    caption: {
      fontSize: '0.75rem',
      lineHeight: 1.666,
    },
  },
  props: {
    MuiUseMediaQuery: {
      noSsr: true,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': fonts.fKGroteskNeueLocal,
      },
    },
    MuiTypography: {
      root: {
        overflowWrap: 'anywhere',
      },
    },
    MuiButton: {
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
  },
};
