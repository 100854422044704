import PropTypes from 'prop-types';

import Australia from './Australia';
import Car from './Car';
import Listings from './Listings';
import Money from './Money';
import SecurePayments from './SecurePayments';
import Umbrella from './Umbrella';

const BenefitIcon = ({ name, ...rest }) => {
  const icons = {
    australia: <Australia {...rest} />,
    car: <Car {...rest} />,
    listings: <Listings {...rest} />,
    money: <Money {...rest} />,
    securePayments: <SecurePayments {...rest} />,
    umbrella: <Umbrella {...rest} />,
  };

  return icons[name] ? icons[name] : null;
};

BenefitIcon.propTypes = {
  name: PropTypes.string.isRequired,
};

export default BenefitIcon;
