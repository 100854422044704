import { createSelector } from 'reselect';

import initialState from './initialState';

const getLicence = (state) => state.profile.licence || initialState.licence;
const getLicenceLoading = (state) => state.profile.licenceLoading;

const getLicenceStatus = createSelector(getLicence, (licence) => licence.status);

export { getLicence, getLicenceLoading, getLicenceStatus };
