import { call, takeEvery } from 'redux-saga/effects';

import { cookies } from '@evee/evee-ui.services';

import * as actionTypes from 'store/modules/app/actionTypes';

function* onAcceptCookies() {
  yield call(cookies.acceptCookies);
}

export default function* listenAppInit() {
  yield takeEvery(actionTypes.ACCEPT_COOKIES, onAcceptCookies);
}
