import { all, fork } from 'redux-saga/effects';

import listenCreate from './listenCreate';
import listenLoad from './listenLoad';
import listenRemove from './listenRemove';
import listenSetBillingAddress from './listenSetBillingAddress';
import listenSetDefault from './listenSetDefault';
import listenVerify from './listenVerify';

export default function* paymentMethodSaga() {
  yield all([
    fork(listenCreate),
    fork(listenLoad),
    fork(listenSetDefault),
    fork(listenSetBillingAddress),
    fork(listenRemove),
    fork(listenVerify),
  ]);
}
