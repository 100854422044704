import { all, call, put, select } from 'redux-saga/effects';

import { BookingStatusFilter, CustomerRoles, SortDirection } from '@evee/evee-ui.enums';
import { apiService } from '@evee/evee-ui.services';

import * as appActions from 'store/modules/app/actions';
import * as myTripsActions from 'store/modules/myTrips';
import * as myTripsSelector from 'store/modules/myTrips/selectors';

const PAGE_SIZE = 16;
const ascSorting = [{ fieldName: 'from', direction: SortDirection.ascending }];
const descSorting = [{ fieldName: 'from', direction: SortDirection.descending }];

function* load() {
  try {
    yield put(appActions.setLoading(true));

    const [upcoming, ongoing, completed, cancelled] = yield all([
      call(
        apiService.booking.getMyTrips,
        CustomerRoles.renter,
        BookingStatusFilter.upcoming,
        0,
        16,
        [],
        ascSorting,
      ),
      call(
        apiService.booking.getMyTrips,
        CustomerRoles.renter,
        BookingStatusFilter.ongoing,
        0,
        16,
        [],
        ascSorting,
      ),
      call(
        apiService.booking.getMyTrips,
        CustomerRoles.renter,
        BookingStatusFilter.completed,
        0,
        16,
        [],
        descSorting,
      ),
      call(
        apiService.booking.getMyTrips,
        CustomerRoles.renter,
        BookingStatusFilter.cancelled,
        0,
        16,
        [],
        ascSorting,
      ),
    ]);

    yield put(
      myTripsActions.loadTripsSuccess({
        upcoming,
        ongoing,
        completed,
        cancelled,
      }),
    );
  } catch (error) {
    yield put(myTripsActions.loadTripsFailed());
    yield put(appActions.showRequestError());
  } finally {
    yield put(appActions.setLoading(false));
  }
}

function* loadMore({ payload: pageNumber }) {
  try {
    const statusFilter = yield select(myTripsSelector.getStatusFilter);
    const bookings = yield call(
      apiService.booking.getMyTrips,
      CustomerRoles.renter,
      statusFilter,
      pageNumber - 1,
      PAGE_SIZE,
    );
    yield put(myTripsActions.loadMoreSuccess({ pageNumber, bookings }));
  } catch (error) {
    yield put(myTripsActions.loadMoreFailed());
  }
}

export { load, loadMore };
