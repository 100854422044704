import { all, call, put, select } from 'redux-saga/effects';
import moment from 'moment';

import { apiService } from '@evee/evee-ui.services';

import * as bookingActions from 'store/modules/booking/actions';
import { getTripDates } from 'store/modules/booking/selectors';

function* loadOccupiedDates({ payload }) {
  try {
    const { vehicleId, options } = payload;
    let { from, to } = payload;
    const tripDates = yield select(getTripDates);

    if (!from && !to && moment(tripDates.start).isValid()) {
      from = moment.utc(tripDates.start).startOf('month').toDate();
      to = moment.utc(tripDates.start).startOf('month').add(1, 'month').toDate();
    } else if (!from && !to) {
      yield put(bookingActions.setOccupiedDates([]));
      return;
    }

    const [currentMonth, nextMonth] = yield all([
      yield call(apiService.vehicle.getOccupations, {
        vehicleId,
        month: moment(from).utc(true).month() + 1,
        year: moment(from).utc(true).year(),
      }),
      yield call(apiService.vehicle.getOccupations, {
        vehicleId,
        month: moment(to).utc(true).month() + 1,
        year: moment(to).utc(true).year(),
      }),
    ]);

    let allDates = currentMonth.concat(nextMonth);
    if (options?.excludeBookingId) {
      allDates = allDates.filter((e) => e.booking !== options.excludeBookingId);
    }

    yield put(bookingActions.setOccupiedDates(allDates));
  } catch (error) {
    yield put(bookingActions.setOccupiedDates([]));
  }
}

export { loadOccupiedDates };
