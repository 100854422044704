import PropTypes from 'prop-types';
import { useEffect } from 'react';

import { Box, ClickAwayListener, Fade, Paper, Popper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { createRange, getDatesAndHours } from 'widgets/inputs/DateRangePicker/utils';
import Calendar from 'widgets/inputs/Calendar';
import TimeRangeSelector from 'widgets/search/TimeRangeSelector';
import useScroll from 'common/hooks/useScroll';

import styles from './styles';

const SearchCalendar = ({
  value,
  anchorEl,
  open,
  PopoverProps,
  PaperProps,
  classes,
  onClose,
  onChange,
  ...rest
}) => {
  const scrollY = useScroll();
  const { startDate, endDate, startHour, endHour } = getDatesAndHours(value);

  useEffect(() => {
    onClose();
  }, [scrollY]);

  return (
    <Popper transition open={open} anchorEl={anchorEl} {...PopoverProps}>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={250}>
          <Box>
            <ClickAwayListener
              mouseEvent="onMouseUp"
              touchEvent="onTouchStart"
              onClickAway={() => {
                onClose();
              }}
            >
              <Paper elevation={1} className={classes.paper} {...PaperProps}>
                <Calendar
                  dateRangeAvailable
                  value={{ startDate, endDate }}
                  daySize={43}
                  renderDayContents={(day) => (
                    <Box
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                      }}
                    >
                      {day.format('D')}
                    </Box>
                  )}
                  calendarInfoPosition="bottom"
                  renderCalendarInfo={() => (
                    <TimeRangeSelector
                      value={{ startHour, endHour }}
                      containerClassName={classes.hoursContainer}
                      itemClassName={classes.hoursItem}
                      onChange={(v) => {
                        onChange(createRange(startDate, endDate, v.startHour, v.endHour));
                      }}
                    />
                  )}
                  onDatesChange={(v) => {
                    onChange(createRange(v.startDate, v.endDate, startHour, endHour));
                  }}
                  {...rest}
                />
              </Paper>
            </ClickAwayListener>
          </Box>
        </Fade>
      )}
    </Popper>
  );
};

SearchCalendar.propTypes = {
  value: PropTypes.object,
  anchorEl: PropTypes.instanceOf(Element),
  open: PropTypes.bool,
  PopoverProps: PropTypes.object,
  PaperProps: PropTypes.object,
  classes: PropTypes.shape({
    paper: PropTypes.string.isRequired,
    hoursContainer: PropTypes.string.isRequired,
    hoursItem: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
};

SearchCalendar.defaultProps = {
  value: {},
  anchorEl: null,
  open: false,
  PopoverProps: {},
  PaperProps: {},
  onClose: () => {},
  onChange: () => {},
};

export default withStyles(styles)(SearchCalendar);
