const styles = (theme) => ({
  drawerContainer: {
    backgroundColor: theme.palette.common.skyBlue,
    padding: '16px',
  },
  acceptButton: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '35%',
      margin: '0 auto',
    },
  },
});

export default styles;
