import PropTypes from 'prop-types';

import { Box, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { Button } from '@evee/evee-ui.base';
import { history } from '@evee/evee-ui.services';

import { specialOffers } from 'pages/HomePage/utils';

import Dialog from '../CommonDialog';

import Deals from './Icons/Deals';
import styles from './styles';

const SpecialOffer = ({ open, title, description, discountCode, isMobile, classes, onClose }) => {
  const handleClose = () => {
    if (discountCode === specialOffers.first75.discountCode) {
      history.push('/search?flexibleDates=true');
    }
    onClose();
  };

  return (
    <Dialog
      open={open}
      isMobile={isMobile}
      classes={{ titleRoot: classes.titleRoot }}
      onClose={onClose}
    >
      <Box className={classes.contentContainer}>
        <Deals style={{ width: '48px', height: '48px' }} />

        <Typography style={{ fontWeight: 500 }}>SPECIAL OFFER</Typography>

        <Typography variant="h3" style={{ fontWeight: 700 }}>
          {title}
        </Typography>

        <Typography align="center" className={classes.description}>
          {description}
        </Typography>

        <Button text="Let's go!" onClick={handleClose} />
      </Box>
    </Dialog>
  );
};

SpecialOffer.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  discountCode: PropTypes.string,
  isMobile: PropTypes.bool,
  classes: PropTypes.shape({
    contentContainer: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    titleRoot: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

SpecialOffer.defaultProps = {
  isMobile: false,
  discountCode: '',
};

export default withStyles(styles)(SpecialOffer);
