import PropTypes from 'prop-types';
import React from 'react';

import { Box, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';

import PartnerOfferCard from './PartnerOfferCard';

import styles from './styles';

const PartnerOffers = ({ partners, loading, classes }) => {
  const loadingJsx = Array(3)
    .fill(null)
    .map((_v, index) => (
      <React.Fragment key={index}>
        <Grid item xs={12} sm={6} md={true}>
          <Skeleton variant="rect" width="100%" height="190px" />
        </Grid>
      </React.Fragment>
    ));

  return (
    <Box margin={2}>
      <Grid container className={classes.itemsContainer} justifyContent="space-between" spacing={4}>
        {loading
          ? loadingJsx
          : partners.map((partner, index) => (
              <React.Fragment key={index}>
                <Grid item xs={12} sm={6} md={true}>
                  <Box className={classes.itemContainer}>
                    <PartnerOfferCard partner={partner} />
                  </Box>
                </Grid>
              </React.Fragment>
            ))}
      </Grid>
    </Box>
  );
};

PartnerOffers.propTypes = {
  partners: PropTypes.array,
  loading: PropTypes.bool,
  classes: PropTypes.shape({
    itemsContainer: PropTypes.string.isRequired,
    itemContainer: PropTypes.string.isRequired,
  }).isRequired,
};

PartnerOffers.defaultProps = {
  partners: [],
  loading: false,
};

export default withStyles(styles)(PartnerOffers);
