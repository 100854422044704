import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';

import { Loader } from '@evee/evee-ui.base';

import DefaultDialog from 'widgets/layout/DefaultDialog';

import ChangeEmail from './ChangeEmail';
import EnterCode from './EnterCode';
import VerifiedEmail from './VerifiedEmail';

const VerifyEmail = ({
  open,
  verified,
  editEmail,
  profileEmail,
  isMobile,
  loading,
  onEditEmail,
  onClose,
}) => (
  <DefaultDialog
    open={open}
    fullScreen={isMobile}
    title={verified ? 'All done!' : 'We need to verify your email'}
    titleAlign="center"
    onClose={editEmail ? () => onEditEmail(false) : onClose}
  >
    <Loader loading={loading}>
      <Box display="flex" flexDirection="column" alignItems="center">
        {verified && (
          <VerifiedEmail isMobile={isMobile} profileEmail={profileEmail} onClose={onClose} />
        )}
        {editEmail && <ChangeEmail />}
        {!editEmail && !verified && <EnterCode />}
      </Box>
    </Loader>
  </DefaultDialog>
);

VerifyEmail.propTypes = {
  open: PropTypes.bool,
  verified: PropTypes.bool,
  editEmail: PropTypes.bool,
  profileEmail: PropTypes.string,
  isMobile: PropTypes.bool,
  loading: PropTypes.bool,
  onEditEmail: PropTypes.func,
  onClose: PropTypes.func,
};

VerifyEmail.defaultProps = {
  open: false,
  verified: false,
  editEmail: false,
  isMobile: false,
  profileEmail: '',
  loading: false,
  onEditEmail: () => {},
  onClose: () => {},
};

export default VerifyEmail;
