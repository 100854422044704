const styles = (theme) => ({
  label: {
    margin: '0 0 8px 0',
  },
  input: {
    marginBottom: '15px',
  },
  splitContainerRight: {
    paddingLeft: '8px',
    width: '50%',
  },
  splitContainerLeft: {
    paddingRight: '8px',
    width: '50%',
  },
  button: {
    marginBottom: '20px',
  },
  contentContainer: {
    width: '450px',
  },
  footerText: {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 500,
  },
  desktopSocialButton: {
    margin: '0px',
    width: '50%',
  },
  mobileSocialButton: {
    marginTop: '10px',
    width: '100%',
  },
  socialButtonsContainer: {
    marginTop: '20px',
  },
  descriptionContainer: {
    margin: '20px 0 10px 0',
  },
  facebookIcon: {
    width: '24px',
    height: '24px',
    color: theme.palette.common.white,
  },
  recaptcha: {
    width: '100%',
    marginBottom: '16px',
    display: 'flex',
    justifyContent: 'center',
  },
});

export default styles;
