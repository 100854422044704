import { all, call, put, select } from 'redux-saga/effects';
import { ValidationError } from 'yup';

import { ErrorMessage } from '@evee/evee-ui.enums';
import { apiService } from '@evee/evee-ui.services';
import { password as passwordModel } from '@evee/evee-ui.models';

import * as updatePasswordActions from 'store/modules/auth/updatePassword';
import * as updatePasswordSelectors from 'store/modules/auth/updatePassword/selectors';
import { showError, showSuccess } from 'store/modules/app/actions';

export function* validateFieldValue({ payload: { fieldName, fieldValue } }) {
  try {
    const context = {};
    if (fieldName === 'confirmPassword') {
      context.password = yield select(updatePasswordSelectors.getPassword);
    }

    if (fieldName === 'password') {
      context.currentPassword = yield select(updatePasswordSelectors.getCurrentPassword);
    }

    yield passwordModel.updatePasswordSchema.fields[fieldName].validate(fieldValue, context);

    yield put(updatePasswordActions.removeFieldError(fieldName));
  } catch (err) {
    if (err instanceof ValidationError) {
      yield put(
        updatePasswordActions.setFieldError({
          fieldName,
          fieldValue: err.message,
        }),
      );
    }
  }
}

export function* updatePassword() {
  try {
    const [currentPassword, password, confirmPassword] = yield all([
      select(updatePasswordSelectors.getCurrentPassword),
      select(updatePasswordSelectors.getPassword),
      select(updatePasswordSelectors.getConfirmPassword),
    ]);

    yield put(updatePasswordActions.setErrors({}));
    yield passwordModel.updatePasswordSchema.validate({
      currentPassword,
      password,
      confirmPassword,
    });

    yield call(apiService.customer.updatePassword, currentPassword, password);
    yield put(showSuccess('Account password has been updated'));

    yield put(updatePasswordActions.updatePasswordSuccess());
  } catch (err) {
    if (err instanceof ValidationError) {
      const validationErrors = Object.assign(
        ...err.inner.map((e) => ({
          [e.path]: e.message,
        })),
      );
      yield put(updatePasswordActions.setErrors(validationErrors));
      throw new Error(ErrorMessage.completeRequiredFields);
    }
    yield put(updatePasswordActions.updatePasswordFailed(err.message));
    yield put(showError(err.message));
  }
}
