import moment from 'moment';

import * as actionTypes from './actionTypes';
import * as searchPageActionTypes from '../searchPage/actionTypes';
import initialState from './initialState';

const buildFilters = ({
  locationId,
  from,
  to,
  flexibleDates,
  radius,
  latitude,
  longitude,
  name,
  zoom,
  makes,
  models,
  badges,
  features,
  minPrice,
  maxPrice,
  instantBooking,
  unlimitedMileage,
  freeFastCharging,
}) => {
  const result = {};

  if (from && to) {
    result.range = {
      start: moment(from).toISOString(),
      end: moment(to).toISOString(),
    };
  }

  if (flexibleDates) {
    result.flexibleDates = flexibleDates === 'true';
  }

  if (radius && latitude && longitude) {
    result.location = {
      id: locationId || undefined,
      latitude: Number(latitude),
      longitude: Number(longitude),
      radius: Number(radius),
      zoom: Number(zoom) || undefined,
      name: name || undefined,
    };
  }

  result.makes = makes || [];
  result.models = models || [];
  result.badges = badges || [];
  result.features = features || [];

  result.instantBooking = instantBooking === 'true' || false;
  result.unlimitedMileage = unlimitedMileage === 'true' || false;
  result.freeFastCharging = freeFastCharging === 'true' || false;

  if (Number(minPrice) > 0 && Number(maxPrice) > 0 && Number(maxPrice) > Number(minPrice)) {
    result.price = [Number(minPrice), Number(maxPrice)];
  }

  return result;
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SET_FIELD_VALUE:
      return {
        ...state,
        [payload.fieldName]: payload.value,
      };

    case actionTypes.SET_QUERY_FILTERS: {
      return {
        ...state,
        ...buildFilters(payload),
      };
    }

    case actionTypes.SET_AIRPORT_SEARCH: {
      return {
        ...state,
        airportSearch: payload,
      };
    }

    case actionTypes.CHANGE_LOCATION: {
      return {
        ...state,
        loading: true,
      };
    }

    case actionTypes.CHANGE_LOCATION_SUCCESS: {
      return {
        ...state,
        location: payload,
        loading: false,
      };
    }

    case actionTypes.CHANGE_LOCATION_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }

    case searchPageActionTypes.SET_ACTIVE_FILTER_VALUE:
      return {
        ...state,
        [payload.filterName]: payload.filterValue,
      };

    case searchPageActionTypes.SEARCH_SUCCESS:
      return {
        ...state,
        makes: [],
        models: [],
        badges: [],
        price: [],
      };

    default:
      return state;
  }
};
