import { connect } from 'react-redux';

import * as authSelectors from 'store/modules/auth/selectors';
import { setResetPasswordFieldValue, showResetPassword } from 'store/modules/auth/resetPassword';
import { setSpecialOffer } from 'store/modules/home';
import { showSignUp } from 'store/modules/auth/signUp';

import HomePage from './HomePage';

const mapStateToProps = (state) => ({
  showSubscribe: authSelectors.getShowSubscribe(state),
});

const mapDispatchToProps = (dispatch) => ({
  onResetPasswordToken: (value) =>
    dispatch(setResetPasswordFieldValue({ fieldName: 'token', fieldValue: value })),
  onResetPassword: () => dispatch(showResetPassword()),
  onSpecialOffer: (specialOffer) => dispatch(setSpecialOffer(specialOffer)),
  onSignUp: (email) => dispatch(showSignUp(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
