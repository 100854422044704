import { all, fork } from 'redux-saga/effects';

import listenAcceptCookies from './listenAcceptCookies';
import listenAppInit from './listenAppInit';
import listenSetCurrency from './listenSetCurrency';
import listenSignIn from './listenSignIn';

export default function* appSaga() {
  yield all([
    fork(listenAcceptCookies),
    fork(listenAppInit),
    fork(listenSetCurrency),
    fork(listenSignIn),
  ]);
}
