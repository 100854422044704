/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import initialState from './initialState';

export const bookingReviewsSlice = createSlice({
  name: 'bookingReviews',
  initialState,
  reducers: {
    load(state) {
      state.loading = true;
    },

    loadSuccess(state, { payload }) {
      state.loading = false;
      state.data = payload;
    },

    loadFailed(state) {
      state.loading = false;
    },

    sendReview(state) {
      state.form.loading = true;
    },

    sendReviewSuccess(state) {
      state.form.loading = false;
      state.open = false;
    },

    sendReviewFailed(state) {
      state.form.loading = false;
    },

    setFormFieldValue(state, { payload }) {
      state.form[payload.fieldName] = payload.fieldValue;
    },

    setOpen(state, { payload }) {
      state.open = payload;
    },
  },
});

const { actions, reducer } = bookingReviewsSlice;

export const {
  load,
  loadSuccess,
  loadFailed,
  sendReview,
  sendReviewSuccess,
  sendReviewFailed,
  setFormFieldValue,
  setOpen,
} = actions;

export default reducer;
