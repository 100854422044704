const styles = (theme) => ({
  cityName: {
    marginTop: '16px',
  },
  actionArea: {
    height: '150px',
    '&:before': {
      position: 'absolute',
      top: '0',
      width: '100%',
      height: '100%',
      content: '""',
      opacity: '0',
      transitionDuration: 300,
      borderRadius: theme.shape.borderRadius,
    },
    '&:hover:before': {
      opacity: '0.1',
      backgroundColor: theme.palette.common.black,
    },
  },
  image: {
    height: '150px',
    width: '100%',
    objectFit: 'cover',
    objectPosition: 'bottom',
    borderRadius: theme.shape.borderRadius,
  },
});

export default styles;
