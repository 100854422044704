import { all, call, delay, put, select } from 'redux-saga/effects';
import _ from 'lodash';
import { distanceTo } from 'geolocation-utils';
import moment from 'moment';
import { nanoid } from 'nanoid';

import { DEFAULT_TRIP_LENGTH, getDefaultSearchLocation, stringCompare } from '@evee/evee-ui.utils';
import { apiService, history, tagManager } from '@evee/evee-ui.services';
import { DEFAULT_LOCATION_NAME } from '@evee/evee-ui.enums';

import * as appActions from 'store/modules/app/actions';
import * as appSelectors from 'store/modules/app/selectors';
import * as searchActions from 'store/modules/search/actions';
import * as searchPageActions from 'store/modules/searchPage/actions';
import * as searchPageSelectors from 'store/modules/searchPage/selectors';
import * as searchSelectors from 'store/modules/search/selectors';
import { initialFilters } from 'store/modules/searchPage/initialState';
import { showError } from 'store/modules/app/actions';

import { getQueryString, getSearchPathname, isSearchPath } from 'saga/search/utils';

const SEARCH_DEBOUNCE_TIME = 500;

const enrichMakesWithID = (makes) =>
  makes.map((make) => ({
    id: nanoid(),
    name: make.name,
    count: make.count,
    models: make.models.map((model) => ({
      id: nanoid(),
      name: model.name,
      badges: model.badges.map((badge) => ({
        id: nanoid(),
        name: badge,
      })),
    })),
  }));

const getMakes = (makesTree) =>
  makesTree.map((make) => ({
    id: make.id,
    name: make.name,
    count: make.count,
  }));

function getModels(makesTree) {
  const getSortedModels = (make) => ({
    make: {
      id: make.id,
      name: make.name,
    },
    models: make.models
      .map((model) => ({
        id: model.id,
        name: model.name,
      }))
      .sort((a, b) => stringCompare(a.name, b.name)),
  });

  return makesTree
    .map((make) => getSortedModels(make))
    .filter((e) => e.models.length > 0)
    .sort((a, b) => stringCompare(a.make.name, b.make.name));
}

function getBadges(makesTree) {
  const getSortedBadges = (make) =>
    make.models.map((model) => ({
      make: {
        id: make.id,
        name: make.name,
      },
      model: {
        id: model.id,
        name: model.name,
      },
      badges: model.badges
        .map((badge) => ({
          id: badge.id,
          name: badge.name,
        }))
        .sort((a, b) => stringCompare(a.name, b.name)),
    }));

  return makesTree
    .map((make) => getSortedBadges(make))
    .flat()
    .filter((e) => e.badges.length > 0)
    .sort((a, b) => stringCompare(a.make.name, b.make.name))
    .sort((a, b) => stringCompare(a.model.name, b.model.name));
}

function transformMakesTree(makesTree, makes, models, badges) {
  const mapBadges = (defaultBadges) =>
    defaultBadges
      .filter((badge) => badges.some((e) => e.id === badge.id))
      .map((badge) => badge.name);

  const mapModels = (defaultModels) =>
    defaultModels
      .filter((model) => models.some((e) => e.id === model.id))
      .map((model) => ({ name: model.name, badges: mapBadges(model.badges) }));

  return makesTree
    .filter((make) => makes.some((e) => e.id === make.id))
    .map((make) => ({ name: make.name, models: mapModels(make.models) }));
}

function getSearchLocationName(customLocation, headerLocation) {
  if (!headerLocation) return DEFAULT_LOCATION_NAME;

  const dist = distanceTo(
    { lat: customLocation.latitude, lng: customLocation.longitude },
    { lat: headerLocation.latitude, lng: headerLocation.longitude },
  );

  return dist > headerLocation.radius ? DEFAULT_LOCATION_NAME : headerLocation.name;
}

export function* setLocation({ payload }) {
  try {
    const headerLocation = yield select(searchSelectors.getLocation);
    const searchLocationName = getSearchLocationName(payload, headerLocation);

    const searchLocation = {
      ...headerLocation,
      name: searchLocationName,
      latitude: payload.latitude,
      longitude: payload.longitude,
      radius: payload.radius,
      zoom: payload.zoom,
    };

    yield put(searchActions.setFieldValue('location', searchLocation));
  } catch (error) {
    yield put(showError("Can't set search location"));
  }
}

export function* search() {
  try {
    yield delay(SEARCH_DEBOUNCE_TIME);

    const { from, to, flexibleDates } = yield select(searchSelectors.getFilters);

    if (!moment(from).isValid()) {
      return;
    }

    let endDate = moment(to);
    if (!endDate.isValid()) {
      endDate = moment(from).add(DEFAULT_TRIP_LENGTH, 'hours');
      yield put(
        searchActions.setFieldValue('range', {
          start: moment(from),
          end: moment(endDate),
        }),
      );
    }

    yield put(searchPageActions.setLoading(true));

    const headerLocation = yield select(searchSelectors.getLocation);
    const isAirportSearch = yield select(searchSelectors.getAirportSearch);

    if (isAirportSearch) {
      yield put(searchPageActions.setActiveAirport(headerLocation));
      yield put(searchActions.setAirportSearch(false));
    }

    const {
      makes,
      models,
      badges,
      features,
      airport,
      price: [minPrice, maxPrice],
      instantBooking,
      unlimitedMileage,
      freeFastCharging,
    } = yield select(searchPageSelectors.getActiveFilters);

    const [sorting, makesTree, location, defaultFilters] = yield all([
      select(searchPageSelectors.getSorting),
      select(searchPageSelectors.getMakesTree),
      select(searchPageSelectors.getLocation),
      select(searchPageSelectors.getDefaultFilters),
    ]);

    const searchAirportsQuery = {
      // TODO: fix date format
      from: moment(from).utc(true).format('YYYY-MM-DDTHH:mm'),
      to: moment(endDate).utc(true).format('YYYY-MM-DDTHH:mm'),
      flexibleDates,
      latitude: location.latitude,
      longitude: location.longitude,
      radius: location.radius,
      makes: transformMakesTree(makesTree, makes, models, badges),
      features: features.map((f) => f.icon),
      instantBooking,
      unlimitedMileage,
      freeFastCharging,
    };

    const [minDefaultPrice, maxDefaultPrice] = defaultFilters.price;

    if (minPrice !== minDefaultPrice) {
      searchAirportsQuery.minPrice = minPrice;
    }

    if (maxPrice !== maxDefaultPrice) {
      searchAirportsQuery.maxPrice = maxPrice;
    }

    const searchQuery = {
      ...searchAirportsQuery,
      sort: sorting,
      locationIds: airport?.id && [airport.id],
    };

    const currency = yield select(appSelectors.getCurrency);

    const [{ vehicles }, airports] = yield all([
      call(apiService.search.search, searchQuery, currency.id),
      call(apiService.search.searchAirports, searchAirportsQuery),
    ]);

    tagManager.pushSearchRequest({
      ...searchQuery,
      locationName: headerLocation ? headerLocation.name : '',
      makes,
      models,
      badges,
    });

    tagManager.pushSearchResultsImpressions(vehicles);

    const badgesWithModel = badges.map((b) => {
      const modelBadges = defaultFilters.badges.find((mb) =>
        mb.badges.some((e) => e.name === b.name),
      );

      if (modelBadges) {
        return { model: modelBadges.model.name, badges: [b.name] };
      }

      return null;
    });

    const groupedBadgesWithModel = Object.entries(
      _.groupBy(_.compact(badgesWithModel), 'model'),
    ).map((e) => ({
      model: e[0],
      badges: e[1].reduce((prev, cur) => prev.concat(cur.badges), []),
    }));

    const queryString = getQueryString(
      { ...location, id: airport?.id, name: airport?.name },
      {
        ...searchQuery,
        makes,
        models,
        badges: groupedBadgesWithModel,
      },
    );

    // because of the delay sometimes search can be triggered from other pages.
    if (isSearchPath()) {
      history.replace(`/${getSearchPathname()}?${queryString}`);
    }

    yield put(searchPageActions.searchSuccess(vehicles));
    yield put(searchPageActions.setAirports(airports));
    yield put(searchPageActions.setLoading(false));
  } catch (error) {
    yield put(appActions.showError(error.message));
    yield put(searchPageActions.setLoading(false));
  }
}

function* updateDefaultFilters() {
  const { prices, makes } = yield call(apiService.search.getFilters);
  const features = yield call(apiService.dictionary.getFeatures);

  const excludeFeatures = [
    'vehicle-range',
    'free-charging',
    'seats',
    'doors',
    'vehicle-delivery',
    'ride-length',
  ];

  const makesWithId = enrichMakesWithID(makes);
  const enrichedDefaultFilters = {
    makes: getMakes(makesWithId),
    models: getModels(makesWithId),
    badges: getBadges(makesWithId),
    price: [prices.min, prices.max],
    features: features.filter((f) => !excludeFeatures.some((e) => e === f.icon)),
  };

  yield put(searchPageActions.setFieldValue('makesTree', makesWithId));
  yield put(searchPageActions.setDefaultFilters(enrichedDefaultFilters));
  yield put(
    searchPageActions.setAvailableFilters(_.pick(enrichedDefaultFilters, ['models', 'badges'])),
  );

  return enrichedDefaultFilters;
}

export function* setDialogDefaultFilters() {
  try {
    const [activeFilters, editFilters, defaultFilters, sorting] = yield all([
      select(searchPageSelectors.getActiveFilters),
      select(searchPageSelectors.getEditFilters),
      select(searchPageSelectors.getDefaultFilters),
      select(searchPageSelectors.getSorting),
    ]);

    const newEditFilters = {
      ...editFilters,
      makes: _.isEmpty(activeFilters.makes) ? [] : activeFilters.makes,
      models: _.isEmpty(activeFilters.models) ? [] : activeFilters.models,
      badges: _.isEmpty(activeFilters.badges) ? [] : activeFilters.badges,
      features: _.isEmpty(activeFilters.features) ? [] : activeFilters.features,
      sorting: _.isEmpty(sorting) ? [] : sorting,
      price: _.isEmpty(activeFilters.price) ? defaultFilters.price : activeFilters.price,
      year: _.isEmpty(activeFilters.year) ? defaultFilters.year : activeFilters.year,
      airport: _.isEmpty(activeFilters.airport) ? {} : activeFilters.airport,
      instantBooking: activeFilters.instantBooking || false,
      unlimitedMileage: activeFilters.unlimitedMileage || false,
      freeFastCharging: activeFilters.freeFastCharging || false,
    };

    yield put(searchPageActions.setEditFilters(newEditFilters));
  } catch (error) {
    yield put(appActions.showError(error.message));
  }
}

export function* resetEditFilters() {
  try {
    const defaultFilters = yield select(searchPageSelectors.getDefaultFilters);

    const editFilters = {
      ...initialFilters,
      price: defaultFilters.price,
    };

    yield put(searchPageActions.setEditFilters(editFilters));
  } catch (error) {
    yield put(appActions.showError(error.message));
  }
}

function* updateMakeFilters(editFilters, activeFilters) {
  const defaultFilters = yield select(searchPageSelectors.getDefaultFilters);
  const { models: defaultModels, badges: defaultBadges } = defaultFilters;
  const { makes: editMakes } = editFilters;
  const { models: activeModels, badges: activeBadges } = activeFilters;

  const availableModels = defaultModels.filter((model) =>
    editMakes.some((make) => make.id === model.make.id),
  );

  const models = activeModels.filter((model) =>
    availableModels.some((e) => e.models.some((m) => m.id === model.id)),
  );

  const availableBadges = defaultBadges.filter((badge) =>
    models.some((model) => model.id === badge.model.id),
  );

  const badges = activeBadges.filter((badge) =>
    availableBadges.some((e) => e.badges.some((b) => b.id === badge.id)),
  );

  return [
    {
      models,
      badges,
    },
    {
      models: availableModels,
      badges: availableBadges,
    },
  ];
}

export function* updateActiveMakeFilters({ makes, models }) {
  try {
    const [editFilters, activeFilters] = yield all([
      select(searchPageSelectors.getEditFilters),
      select(searchPageSelectors.getActiveFilters),
    ]);

    activeFilters.makes = makes || activeFilters.makes;
    activeFilters.models = models || activeFilters.models;
    const [active, available] = yield updateMakeFilters(editFilters, activeFilters);

    yield put(
      searchPageActions.setActiveFilters({
        ...activeFilters,
        ...active,
      }),
    );
    yield put(searchPageActions.setAvailableFilters(available));
  } catch (error) {
    yield put(appActions.showError(error.message));
  }
}

function* updateDialogFilters({ makes, models, badges, price }) {
  try {
    const editFilters = yield select(searchPageSelectors.getEditFilters);

    editFilters.makes = makes || editFilters.makes;
    editFilters.models = models || editFilters.models;
    editFilters.badges = badges || editFilters.badges;
    editFilters.price = price || editFilters.price;

    const activeFilters = {
      ...editFilters,
    };

    const [active, available] = yield updateMakeFilters(editFilters, activeFilters);

    yield put(
      searchPageActions.setEditFilters({
        ...editFilters,
        ...active,
      }),
    );
    yield put(searchPageActions.setAvailableFilters(available));
  } catch (error) {
    yield put(appActions.showError(error.message));
  }
}

export function* setDialogMakes({ payload }) {
  yield updateDialogFilters({ makes: payload });
}

export function* setDialogModels({ payload }) {
  yield updateDialogFilters({ models: payload });
}

export function setSelectedVehicle({ payload: vehicle }) {
  tagManager.pushSelectedSearchResult(vehicle);
}

export function* load() {
  try {
    yield put(searchPageActions.setLoading(true));

    const {
      makes,
      models,
      badges,
      features,
      price,
      instantBooking,
      unlimitedMileage,
      freeFastCharging,
    } = yield select(searchSelectors.getFilters);

    const getMake = (filters) => {
      const make = filters.makes.filter((e) => makes.includes(e.name));
      return make || [];
    };

    const getMakeModels = (filters) => {
      const makeModels = filters.models.filter((e) => makes.includes(e.make.name));
      if (makeModels) {
        const model = makeModels.flatMap((e) => e.models.filter((m) => models.includes(m.name)));
        return model || [];
      }

      return [];
    };

    const getModelsBadges = (filters) => {
      const modelsBadges = filters.badges.filter((e) =>
        badges.some((b) => b.model === e.model.name),
      );

      if (!modelsBadges) {
        return [];
      }

      const badge = modelsBadges.flatMap((mb) =>
        mb.badges.filter((b) => {
          const modelBadges = badges.find((e) => e.model === mb.model.name);

          if (!Array.isArray(modelBadges.badges)) {
            return modelBadges.badges === b.name;
          }

          return modelBadges.badges.includes(b.name);
        }),
      );

      return badge || [];
    };

    const getFeatures = (filters) => {
      const feature = filters.features.filter((e) => features.includes(e.icon));
      return feature || [];
    };

    const [defaultFilters, activeFilters] = yield all([
      select(searchPageSelectors.getDefaultFilters),
      select(searchPageSelectors.getActiveFilters),
    ]);

    if (_.isEqual(defaultFilters, initialFilters)) {
      const enrichedFilters = yield updateDefaultFilters();
      const qsMake = getMake(enrichedFilters);
      const qsModels = getMakeModels(enrichedFilters);
      const qsBadges = getModelsBadges(enrichedFilters);
      const qsFeatures = getFeatures(enrichedFilters);

      yield put(
        searchPageActions.setActiveFilters({
          ...initialFilters,
          makes: qsMake,
          models: qsModels,
          badges: qsBadges,
          features: qsFeatures,
          price,
          instantBooking,
          unlimitedMileage,
          freeFastCharging,
        }),
      );

      yield updateDialogFilters({
        makes: qsMake,
        models: qsModels,
        badges: qsBadges,
        features: qsFeatures,
        price,
        instantBooking,
        unlimitedMileage,
        freeFastCharging,
      });
    } else {
      const qsMake = getMake(defaultFilters);
      const qsModels = getMakeModels(defaultFilters);
      const qsBadges = getModelsBadges(defaultFilters);
      const qsFeatures = getFeatures(defaultFilters);

      yield put(
        searchPageActions.setActiveFilters({
          ...activeFilters,
          makes: qsMake.length ? qsMake : activeFilters.makes,
          models: qsMake.length || qsModels.length ? qsModels : activeFilters.models,
          badges: qsModels.length || qsBadges.length ? qsBadges : activeFilters.badges,
          features: qsFeatures.length ? qsFeatures : activeFilters.features,
          price: price.length ? price : [],
          instantBooking,
          unlimitedMileage,
          freeFastCharging,
        }),
      );

      yield updateDialogFilters({
        ...activeFilters,
        makes: qsMake.length ? qsMake : activeFilters.makes,
        models: qsMake.length || qsModels.length ? qsModels : activeFilters.models,
        badges: qsModels.length || qsBadges.length ? qsBadges : activeFilters.badges,
        features: qsFeatures.length ? qsFeatures : activeFilters.features,
        price: price.length ? price : [],
        instantBooking,
        unlimitedMileage,
        freeFastCharging,
      });
    }

    // If the search page opens from a direct link, must set a default location.
    const region = yield select(appSelectors.getRegion);
    let startLocation = yield select(searchPageSelectors.getStartLocation);
    if (_.isEmpty(startLocation)) {
      startLocation = getDefaultSearchLocation(region);
      yield put(searchActions.changeLocation(startLocation));
      yield put(searchPageActions.setStartLocation(startLocation));
    }

    yield put(searchPageActions.search());
  } catch (error) {
    yield put(appActions.showError(error.message));
    yield put(searchPageActions.setLoading(false));
  }
}
