import { all, call, put, select } from 'redux-saga/effects';

import { apiService } from '@evee/evee-ui.services';

import * as appActions from 'store/modules/app/actions';
import * as appSelectors from 'store/modules/app/selectors';
import * as bookingDetailsActions from 'store/modules/bookingDetails';

function* loadBookingDetails({ payload: bookingId }) {
  try {
    const currency = yield select(appSelectors.getCurrency);

    const booking = yield call(apiService.booking.get, bookingId, currency.id);

    const [ownerProfile, renterProfile] = yield all([
      call(apiService.customer.getAccountInfo, {
        accountId: booking.owner.id,
      }),
      call(apiService.customer.getAccountInfo, {
        accountId: booking.renter.id,
      }),
    ]);

    yield put(bookingDetailsActions.loadSuccess({ booking, ownerProfile, renterProfile }));
  } catch (error) {
    yield put(bookingDetailsActions.loadFailed());
    yield put(appActions.showRequestError());
  }
}

export { loadBookingDetails };
