import { call, put, select } from 'redux-saga/effects';
import _ from 'lodash';
import moment from 'moment';
import qs from 'qs';

import { CURRENT_LOCATION_NAME, DEFAULT_LOCATION_NAME, LocationType } from '@evee/evee-ui.enums';
import { apiService, history, storage } from '@evee/evee-ui.services';

import {
  CURRENT_LOCATION,
  DEFAULT_TRIP_LENGTH,
  getCurrentPosition,
  getDefaultSearchLocation,
} from '@evee/evee-ui.utils';

import * as appActions from 'store/modules/app/actions';
import * as appSelectors from 'store/modules/app/selectors';
import * as searchActions from 'store/modules/search/actions';
import * as searchPageActions from 'store/modules/searchPage/actions';
import * as searchSelectors from 'store/modules/search/selectors';

export const getFormattedDate = (date) => moment(date).format('YYYY-MM-DDTHH:mm');

export const isSearchPath = () => {
  const pathName = window.location.pathname;
  const isSearch = pathName === '/search';
  const isVehicles = pathName === '/vehicles';

  return isSearch || isVehicles;
};

export const getSearchPathname = () => {
  const isSearch = window.location.pathname === '/search';
  const isVehicles = window.location.pathname === '/vehicles';

  // eslint-disable-next-line no-nested-ternary
  return isSearch ? 'search' : isVehicles ? 'vehicles' : '';
};

export function getQueryString(location, filters = {}) {
  const { id, radius, latitude, longitude, name, zoom } = location;
  const {
    from,
    to,
    flexibleDates,
    makes,
    models,
    badges,
    features,
    minPrice,
    maxPrice,
    instantBooking,
    unlimitedMileage,
    freeFastCharging,
  } = filters;

  const endDate = to || moment(from).add(DEFAULT_TRIP_LENGTH, 'hours');

  const flexibleDatesQuery = `flexibleDates=${flexibleDates}`;
  const rangeQuery = `from=${getFormattedDate(from)}&to=${getFormattedDate(endDate)}`;
  const locationQuery = `radius=${radius}&latitude=${latitude}&longitude=${longitude}`;
  const locationIdQuery = id ? `locationId=${id}` : '';
  const nameQuery = name ? `name=${name}` : '';
  const zoomQuery = zoom ? `zoom=${zoom}` : '';
  const minPriceQuery = minPrice ? `minPrice=${minPrice}` : '';
  const maxPriceQuery = maxPrice ? `maxPrice=${maxPrice}` : '';
  const instantBookingQuery = instantBooking ? `instantBooking=${instantBooking}` : '';
  const unlimitedMileageQuery = unlimitedMileage ? `unlimitedMileage=${unlimitedMileage}` : '';
  const freeFastChargingQuery = freeFastCharging ? `freeFastCharging=${freeFastCharging}` : '';

  const makesQuery = makes
    ? qs.stringify({ makes: makes.map((make) => make.name) }, { arrayFormat: 'indices' })
    : '';

  const modelsQuery = models
    ? qs.stringify({ models: models.map((model) => model.name) }, { arrayFormat: 'indices' })
    : '';

  const badgesQuery = badges ? qs.stringify({ badges }, { arrayFormat: 'indices' }) : '';

  const featuresQuery = features ? qs.stringify({ features }, { arrayFormat: 'indices' }) : '';

  return _.compact([
    flexibleDatesQuery,
    rangeQuery,
    locationQuery,
    locationIdQuery,
    nameQuery,
    zoomQuery,
    makesQuery,
    modelsQuery,
    badgesQuery,
    featuresQuery,
    minPriceQuery,
    maxPriceQuery,
    instantBookingQuery,
    unlimitedMileageQuery,
    freeFastChargingQuery,
  ]).join('&');
}

export function* search() {
  try {
    const region = yield select(appSelectors.getRegion);
    const headerLocation = yield select(searchSelectors.getLocation);
    const searchLocation = headerLocation || getDefaultSearchLocation(region);

    yield put(searchPageActions.setStartLocation(searchLocation));
    yield put(searchActions.updateAirportSearch(searchLocation));

    if (
      searchLocation.name !== DEFAULT_LOCATION_NAME &&
      searchLocation.name !== CURRENT_LOCATION_NAME
    ) {
      yield call(
        storage.latestSearch.save,
        JSON.stringify({ ...searchLocation, icon: LocationType.searchHistory }),
      );
    }

    const { from, to, flexibleDates } = yield select(searchSelectors.getFilters);
    const queryString = getQueryString(searchLocation, { from, to, flexibleDates });
    const pathName = getSearchPathname();

    if (isSearchPath() && pathName) {
      history.replace(`/${pathName}?${queryString}`);
      yield put(searchPageActions.search());
    } else {
      history.push(`/search?${queryString}`);
    }
  } catch (error) {
    yield put(appActions.showError(error.message));
  }
}

export function* updateAirportSearch({ payload: searchLocation }) {
  try {
    let allAirports = yield select(searchSelectors.getAllAirports);
    if (!allAirports.length) {
      allAirports = yield call(apiService.location.getAirports, '', '');
      yield put(searchActions.setFieldValue('allAirports', allAirports));
    }

    if (searchLocation?.id && allAirports.some((a) => a.id === searchLocation.id)) {
      yield put(searchActions.setAirportSearch(true));
    } else {
      yield put(searchPageActions.setActiveAirport({}));
    }
  } catch (error) {
    yield put(appActions.showError(error.message));
  }
}

export function* setQueryFilters() {
  try {
    const region = yield select(appSelectors.getRegion);
    const headerLocation = yield select(searchSelectors.getLocation);
    const searchLocation = headerLocation || getDefaultSearchLocation(region);
    yield put(searchPageActions.setStartLocation(searchLocation));
    yield put(searchActions.updateAirportSearch(searchLocation));
  } catch (error) {
    yield put(appActions.showError(error.message));
  }
}

export function* changeLocation({ payload: location }) {
  try {
    if (location.name === CURRENT_LOCATION.name) {
      const coords = yield call(getCurrentPosition);
      yield put(
        searchActions.changeLocationSuccess({
          ...CURRENT_LOCATION,
          latitude: coords.latitude,
          longitude: coords.longitude,
        }),
      );
    } else {
      yield put(searchActions.changeLocationSuccess(location));
    }
  } catch (error) {
    yield put(searchActions.changeLocationFailed());
    yield put(appActions.showError(error.message));
  }
}
