export default {
  loading: false,
  methods: [],
  createDialog: {
    loading: false,
    open: false,
    cardholderName: '',
    sameAsResidential: true,
    billingAddress: {},
    errors: {},
  },
};
