import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Box, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import { Button } from '@evee/evee-ui.base';

import Carousel from 'widgets/layout/Carousel';

import ModelCard from './ModelCard';

const FeaturedModels = ({ featuredModels, loading, onLoad }) => {
  const theme = useTheme();
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'));

  const cardsJsx = featuredModels.map((item, index) => {
    const { name, price, queryString, thumbnail } = item;

    return (
      <React.Fragment key={index}>
        <ModelCard name={name} price={price} thumbnail={thumbnail} searchQuery={queryString} />
      </React.Fragment>
    );
  });

  const loadingCardsJsx = new Array(5).fill({}).map((_, index) => (
    <React.Fragment key={index}>
      <ModelCard loading />
    </React.Fragment>
  ));

  useEffect(() => {
    onLoad();
  }, []);

  return (
    <Box display="flex" flexDirection="column">
      <Typography gutterBottom variant="h3" component="h2">
        Browse cars
      </Typography>

      <Box position="relative" marginBottom="1em">
        <Carousel
          showArrows
          partialVisible={isTabletOrMobile}
          desktopItemsCount={5}
          tabletItemsCount={3}
          length={featuredModels.length}
        >
          {loading ? loadingCardsJsx : cardsJsx}
        </Carousel>
      </Box>

      <Button
        color="primary"
        type="outlined"
        size="large"
        text="Browse all cars"
        to="/vehicles"
        component={NavLink}
        style={{ width: isTabletOrMobile ? '100%' : '162px' }}
      />
    </Box>
  );
};

FeaturedModels.propTypes = {
  featuredModels: PropTypes.array,
  tripDates: PropTypes.object,
  loading: PropTypes.bool,
  onLoad: PropTypes.func,
};

FeaturedModels.defaultProps = {
  tripDates: null,
  featuredModels: [],
  loading: false,
  onLoad: () => {},
};

export default FeaturedModels;
