import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Box, Typography } from '@material-ui/core';

import useInterval from 'common/hooks/useInterval';

const getTimeLeft = (endDate) => moment.duration(moment(endDate) - moment());

const CountdownTimer = ({ endDate, ...rest }) => {
  const [timeLeft, setTimeLeft] = useState(getTimeLeft(endDate));

  const renderItem = (duration, postfix) => (
    <Box display="flex" alignItems="baseline" style={{ minWidth: '30px' }}>
      <Typography display="inline" style={{ fontWeight: 500 }}>
        {duration > 0 ? duration : 0}
      </Typography>
      <Typography display="inline" variant="body2">
        {postfix}
      </Typography>
    </Box>
  );

  useInterval(() => {
    setTimeLeft(getTimeLeft(endDate));
  }, 1000);

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" {...rest}>
      {renderItem(timeLeft.days(), 'd')}
      {renderItem(timeLeft.hours(), 'h')}
      {renderItem(timeLeft.minutes(), 'm')}
      {renderItem(timeLeft.seconds(), 's')}
    </Box>
  );
};

CountdownTimer.propTypes = {
  endDate: PropTypes.string.isRequired,
};

CountdownTimer.defaultProps = {};

export default CountdownTimer;
