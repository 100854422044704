const styles = (theme) => ({
  title: {
    ...theme.typography.h2,
    fontWeight: 'bold',
  },
  titleSecondary: {
    ...theme.typography.h2,
    fontWeight: 'bold',
    color: theme.palette.secondary.main,
  },
});

export default styles;
