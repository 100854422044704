const styles = (theme) => ({
  codeDigit: {
    margin: theme.spacing(0, 1),
    width: '48px',
    height: '64px',
    fontWeight: 500,
    fontSize: '24px',
  },
  codeError: {
    border: `1px solid ${theme.palette.error.main}`,
  },
  input: {
    textAlign: 'center',
    '&[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
});

export default styles;
