import { takeLatest } from 'redux-saga/effects';

import * as searchPageActionTypes from 'store/modules/searchPage/actionTypes';
import { search } from './utils';

export default function* listenSearch() {
  yield takeLatest(
    [
      searchPageActionTypes.SEARCH,
      searchPageActionTypes.SET_ACTIVE_FILTERS,
      searchPageActionTypes.SET_ACTIVE_FILTER_VALUE,
      searchPageActionTypes.CLEAR_FILTERS,
      searchPageActionTypes.SORT_BY_FIELD,
      searchPageActionTypes.RESET_SORTING,
      searchPageActionTypes.SET_LOCATION,
      searchPageActionTypes.SET_AIRPORT,
    ],
    search,
  );
}
