import { all, call, fork, put, select, take } from 'redux-saga/effects';

import * as appActions from 'store/modules/app/actions';
import * as authSelectors from 'store/modules/auth/selectors';
import * as licenceActions from 'store/modules/profile/licence';
import * as profileActions from 'store/modules/profile/actions';

import { apiService, socket } from '@evee/evee-ui.services';
import { ErrorMessage } from '@evee/evee-ui.enums';

export function* watchLicenceStatus() {
  try {
    const currentUserId = yield select(authSelectors.getUserId);

    const [successChannel, failedChannel, submittedChannel] = yield all([
      call(
        socket.createSocketChannel,
        socket.licenceVerificationEvents.CUSTOMER_VERIFICATION_SUCCESS,
      ),
      call(
        socket.createSocketChannel,
        socket.licenceVerificationEvents.CUSTOMER_VERIFICATION_FAILED,
      ),
      call(
        socket.createSocketChannel,
        socket.licenceVerificationEvents.CUSTOMER_VERIFICATION_SUBMITTED,
      ),
    ]);

    yield all([
      fork(function* licenceSubmitted() {
        while (true) {
          const customer = yield take(submittedChannel);
          if (customer?.id === currentUserId) {
            yield put(licenceActions.verifyLicenceSubmitted(customer.licence));
          }
        }
      }),
      fork(function* licenceSuccess() {
        while (true) {
          const customer = yield take(successChannel);
          if (customer?.id === currentUserId) {
            yield put(profileActions.setFieldValue('dateOfBirth', customer.dateOfBirth));
            yield put(licenceActions.verifyLicenceSuccess(customer.licence));
          }
        }
      }),
      fork(function* licenceFailed() {
        while (true) {
          const result = yield take(failedChannel);
          const { customer, error } = result;
          if (customer?.id === currentUserId) {
            yield put(licenceActions.verifyLicenceFailed());
            yield put(appActions.showError(error));
          }
        }
      }),
    ]);
  } catch (error) {
    yield put(appActions.showError(error.message));
  }
}

export function* openVerificationModal() {
  try {
    if (!window.stripe) {
      throw new Error(ErrorMessage.commonError);
    }

    const { secret } = yield call(apiService.customer.verification);
    const result = yield call(window.stripe.verifyIdentity, secret);

    if (result.error) {
      yield put(licenceActions.verifyLicenceError());
    }
  } catch (error) {
    yield put(licenceActions.verifyLicenceError());
    yield put(appActions.showError(error.message));
  }
}
