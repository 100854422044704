import { createSelector } from 'reselect';
import { getPrice } from '../selectors';

export const getCustomPrices = (state) => state.listing.customPrices;

export const getCurrentMonth = createSelector(
  getCustomPrices,
  (customPrices) => customPrices.currentMonth,
);

export const getRange = createSelector(getCustomPrices, (customPrices) => customPrices.range);

export const getPriceRanges = createSelector(
  getCustomPrices,
  (customPrices) => customPrices.priceRanges,
);

export const getSelectedPriceRange = createSelector(
  getCustomPrices,
  (customPrices) => customPrices.selectedPriceRange,
);

export const getCalendarMode = createSelector(
  getCustomPrices,
  (customPrices) => customPrices.calendarMode,
);

export const getSelectedPrice = createSelector(getCustomPrices, (customPrices) => {
  if (customPrices.selectedPriceRange) {
    return customPrices.selectedPriceRange.price;
  }

  return null;
});

export const getCustomPrice = createSelector(
  getSelectedPrice,
  getPrice,
  (selectedPrice, price) => selectedPrice || price,
);

export const getLoading = createSelector(getCustomPrices, (customPrices) => customPrices.loading);
