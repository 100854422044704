import reduceReducers from 'reduce-reducers';

import * as actionTypes from './actionTypes';
import * as authActions from 'store/modules/auth';
import * as signInActions from 'store/modules/auth/signIn';
import * as signUpActions from 'store/modules/auth/signUp';
import * as verifyEmailActions from 'store/modules/auth/verifyEmail';

import declarationReducer from './declaration';
import initialState from './initialState';
import licenceReducer from './licence';

const profileReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.SET_FIELD_VALUE:
      return {
        ...state,
        [payload.fieldName]: payload.fieldValue,
      };

    case actionTypes.SET_PROFILE:
      return {
        ...state,
        ...payload,
        licence: payload.licence || initialState.licence,
      };

    case actionTypes.UPLOAD_PHOTO:
      return {
        ...state,
        avatarLoading: true,
      };

    case actionTypes.UPLOAD_PHOTO_SUCCESS:
      return {
        ...state,
        photo: payload.url,
        avatarLoading: false,
      };

    case actionTypes.UPLOAD_PHOTO_FAILED:
      return {
        ...state,
        avatarLoading: false,
      };

    case actionTypes.LOAD:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.LOAD_FAILED:
    case actionTypes.LOAD_SUCCESS:
    case actionTypes.SAVE_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case actionTypes.SET_ERRORS: {
      return {
        ...state,
        form: {
          ...state.form,
          errors: payload,
        },
      };
    }

    case actionTypes.SET_FIELD_ERROR: {
      return {
        ...state,
        form: {
          ...state.form,
          errors: {
            ...state.form.errors,
            [payload.path]: payload.message,
          },
        },
      };
    }

    case actionTypes.REMOVE_FIELD_ERROR: {
      return {
        ...state,
        form: {
          ...state.form,
          errors: {
            ...state.form.errors,
            [payload]: undefined,
          },
        },
      };
    }

    case actionTypes.SET_ABOUT:
      return {
        ...state,
        about: payload,
      };

    case actionTypes.SET_NOTIFIED:
      return {
        ...state,
        receiveNewsletter: payload,
      };

    case actionTypes.SET_RECEIVE_PUSH_NOTIFICATIONS:
      return {
        ...state,
        receivePushNotifications: payload,
      };

    case actionTypes.DEACTIVATE_ACCOUNT:
      return {
        ...state,
        deactivateDialog: {
          ...state.deactivateDialog,
          loading: true,
        },
      };

    case actionTypes.SET_DEACTIVATE_DIALOG_OPEN:
      return {
        ...state,
        deactivateDialog: {
          ...state.deactivateDialog,
          open: payload,
        },
      };

    case actionTypes.SET_DEACTIVATE_DIALOG_LOADING:
      return {
        ...state,
        deactivateDialog: {
          ...state.deactivateDialog,
          loading: payload,
        },
      };

    case actionTypes.SET_DEACTIVATE_DIALOG_REASON:
      return {
        ...state,
        deactivateDialog: {
          ...state.deactivateDialog,
          reason: payload,
        },
      };

    case signInActions.signInSuccess.toString():
    case signUpActions.signUpSuccess.toString():
      return {
        ...state,
        ...payload,
        licence: payload.licence || initialState.licence,
      };

    case actionTypes.CHANGE_EMAIL_SUCCESS:
      return {
        ...state,
        emailVerified: false,
      };

    case authActions.signOut.toString():
      return {
        ...initialState,
      };

    case verifyEmailActions.verifyEmailSuccess.toString():
      return {
        ...state,
        emailVerified: true,
      };

    default:
      return state;
  }
};

export default reduceReducers(initialState, profileReducer, licenceReducer, declarationReducer);
