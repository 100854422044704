import { takeEvery } from 'redux-saga/effects';

import * as actionTypes from 'store/modules/paymentMethods/actionTypes';

import { validateBillingAddress } from './utils';

function* validateAddress({ payload }) {
  yield validateBillingAddress(payload);
}

export default function* listenSetBillingAddress() {
  yield takeEvery(actionTypes.SET_BILLING_ADDRESS, validateAddress);
}
