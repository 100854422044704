import { connect } from 'react-redux';

import * as searchSelectors from 'store/modules/search/selectors';
import LocationCard from './LocationCard';

const mapStateToProps = (state) => ({
  tripDates: searchSelectors.getTripDates(state),
});

export default connect(mapStateToProps, null)(LocationCard);
