export const getLoading = (state) => state.phone.loading;

export const getPhone = (state) => state.phone;

export const getPhoneNumber = (state) => state.phone.phoneNumber;

export const getVerificationCode = (state) => state.phone.verificationCode;

export const getStatus = (state) => state.phone.status;

export const getError = (state) => state.phone.error;
