import { Link } from '@evee/evee-ui.base';
import { getHomeUrl } from '@evee/evee-ui.utils';

import useRegion from 'store/modules/app/hooks/useRegion';

const EveeProtectLink = (props) => {
  const { region } = useRegion();

  return (
    <Link
      target="_blank"
      href={`${getHomeUrl(region)}/pages/insurance/#evee-protect`}
      style={{ fontWeight: 'normal' }}
      {...props}
    >
      evee Protect
    </Link>
  );
};

export default EveeProtectLink;
