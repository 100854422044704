export default {
  request: {},
  totals: {},
  message: '',
  loading: false,
  dialog: {
    open: false,
    loading: false,
  },
};
