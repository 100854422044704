import { Region } from '@evee/evee-ui.enums';

export const specialOffers = {
  marcushouse: {
    path: 'marcushouse',
    title: 'Welcome to evee!',
    description: 'You’ve unlocked a special offer: Marcus House viewers get $30 off 🚀',
    discountCode: 'MARCUSHOUSE',
  },
  webbyoncars: {
    path: 'webbyoncars',
    title: 'Welcome to evee!',
    description:
      'You’ve unlocked a special offer: Webby On Cars viewers get $30 off! Your discount will apply automatically at the checkout 😀',
    discountCode: 'WEBBYONCARS',
  },
  chrisvanderstock: {
    path: 'chrisvanderstock',
    title: 'Welcome to evee!',
    description:
      'You’ve unlocked a special offer: Chris Vanderstock viewers get $30 off! Your discount will apply automatically at the checkout 😀',
    discountCode: 'CHRISV30',
  },
  marley: {
    path: 'marley',
    title: 'Welcome to evee!',
    description:
      'You’ve unlocked a special offer: Marley Spoon subscribers get $30 off! Your discount will apply automatically at the checkout 😀',
    discountCode: 'MARLEY30',
  },
  ryancowan: {
    path: 'ryancowan',
    title: 'Welcome to evee!',
    description:
      "You’ve unlocked a special offer: Ryan's Model Y viewers get $30 off! Your discount will apply automatically at the checkout 😀",
    discountCode: 'RYANCOWAN',
  },
  impactx: {
    path: 'impactx',
    title: 'Welcome to evee!',
    description:
      'You’ve unlocked a special offer: Impact X subscribers get $25 off! Your discount will apply automatically at the checkout 😀',
    discountCode: 'IMPACTX25',
  },
  solar10: {
    path: 'solar10',
    title: 'Welcome to evee!',
    description:
      'You’ve unlocked a special offer: Instyle Solar members get 10% off! Your discount will apply automatically at the checkout 😀',
    discountCode: 'SOLAR10',
  },
  perkbox: {
    path: 'perkbox',
    title: 'Welcome to evee!',
    description:
      'You’ve unlocked a special offer: Save $50 off your rental when you spend over $200. The discount will be automatically applied at the checkout.',
    discountCode: 'PERKBOX',
  },
};

const getSpecialOfferName = (location) =>
  location.pathname ? location.pathname.replace('/', '').toLowerCase() : null;

export function getSpecialOfferFromLocation(location) {
  const name = getSpecialOfferName(location);
  if (!name) {
    return null;
  }

  return specialOffers[name];
}

export function getResetPasswordToken(location) {
  if (location.pathname !== '/auth/password/reset') {
    return null;
  }

  const params = new URLSearchParams(location.search);
  return params.get('t');
}

export function getCustomerEmail(location) {
  if (location.pathname !== '/signup') {
    return null;
  }

  const params = new URLSearchParams(location.search);
  return params.get('email');
}

export const getOrganizationStructuredDataJson = () => ({
  '@context': 'https://schema.org/',
  '@type': 'Organization',
  url: 'https://www.evee.com.au',
  logo: 'https://www.evee.com.au/img/logo.png',
});

export const getBackgroundImage = (region) => {
  if (region.id === Region.newZealand.id) {
    return process.env.REACT_APP_ENV === 'production'
      ? 'https://eveeh-live.s3.ap-southeast-2.amazonaws.com/backgrounds/nz/default.jpg'
      : 'https://eveeh-dev.s3.ap-southeast-2.amazonaws.com/backgrounds/nz/default.jpg';
  }

  return process.env.REACT_APP_ENV === 'production'
    ? 'https://eveeh-live.s3.ap-southeast-2.amazonaws.com/backgrounds/au/default.jpg'
    : 'https://eveeh-dev.s3.ap-southeast-2.amazonaws.com/backgrounds/au/default.jpg';
};
