import * as actionTypes from './actionTypes';

export const setTripDates = (start, end, extended = false) => ({
  type: actionTypes.SET_TRIP_DATES,
  payload: { start, end, extended },
});

export const setTripDatesAvailability = (available, errorMessage = '') => ({
  type: actionTypes.SET_TRIP_DATES_AVAILABILITY,
  payload: { available, errorMessage },
});

export const setLocation = (payload) => ({
  type: actionTypes.SET_LOCATION,
  payload,
});

export const loadOccupiedDates = (vehicleId, from, to, options) => ({
  type: actionTypes.LOAD_OCCUPIED_DATES,
  payload: {
    vehicleId,
    from,
    to,
    options,
  },
});

export const setOccupiedDates = (payload) => ({
  type: actionTypes.SET_OCCUPIED_DATES,
  payload,
});

export const setExtension = (payload) => ({
  type: actionTypes.SET_EXTENSION,
  payload,
});
