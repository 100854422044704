import forgotPassword from './forgotPassword/initialState';
import resetPassword from './resetPassword/initialState';
import signIn from './signIn/initialState';
import signUp from './signUp/initialState';
import updatePassword from './updatePassword/initialState';
import verifyEmail from './verifyEmail/initailState';

export default {
  signedIn: false,
  isSubscribed: false,
  signingIn: false,
  signingInPath: '',
  signIn,
  signUp,
  forgotPassword,
  resetPassword,
  verifyEmail,
  updatePassword,
};
