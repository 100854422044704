import { createSelector } from 'reselect';

const getProfile = (state) => state.profile;
const getAddress = (state) => state.profile.address;
const getPhoto = (state) => state.profile.photo;
const getAbout = (state) => state.profile.about;
const getFullName = (state) => state.profile.fullName;
const getNotifications = (state) => state.profile.receiveNewsletter;
const getReceivePushNotifications = (state) => state.profile.receivePushNotifications;
const getAvatarLoading = (state) => state.profile.avatarLoading;
const getErrors = (state) => state.profile.form.errors;
const getPhone = (state) => state.profile.phone;
const getEmail = (state) => state.profile.email;
const getEmailVerified = (state) => state.profile.emailVerified;

const getDeactivateDialogLoading = (state) => state.profile.deactivateDialog.loading;
const getDeactivateDialogOpen = (state) => state.profile.deactivateDialog.open;
const getDeactivateDialogReason = (state) => state.profile.deactivateDialog.reason;

const getPersonalInfo = createSelector(getProfile, (profile) => ({
  dateOfBirth: profile.dateOfBirth,
  firstName: profile.firstName,
}));

const getCreditAmount = (state) => state.profile.credit;

const getCouponData = createSelector(getProfile, (profile) => ({
  referralCode: profile.referralCode || '',
  referralCodeAmount: profile.referralCodeAmount || 0,
}));

const getIsLoading = (state) => state.profile.loading;

const getSocialConnections = createSelector(getProfile, (profile) => ({
  google: profile.google,
  facebook: profile.facebook,
}));

const getUserInfo = createSelector(getProfile, (profile) => ({
  id: profile.id,
  firstName: profile.firstName,
  createdDate: profile.createdDate,
  averageRating: profile.averageRating,
  reviewsCount: profile.reviewsCount,
  about: profile.about,
  photo: profile.photo,
  credit: profile.credit,
}));

const getFormData = createSelector(getProfile, (profile) => ({
  firstName: profile.firstName,
  lastName: profile.lastName,
  documentFirstName: profile.documentFirstName,
  documentLastName: profile.documentLastName,
  dateOfBirth: profile.dateOfBirth,
  address: profile.address,
  about: profile.about,
  receiveNewsletter: profile.receiveNewsletter,
}));

export {
  getProfile,
  getAddress,
  getPhoto,
  getAvatarLoading,
  getPersonalInfo,
  getPhone,
  getCreditAmount,
  getSocialConnections,
  getNotifications,
  getReceivePushNotifications,
  getCouponData,
  getIsLoading,
  getUserInfo,
  getAbout,
  getFormData,
  getErrors,
  getFullName,
  getEmail,
  getEmailVerified,
  getDeactivateDialogLoading,
  getDeactivateDialogOpen,
  getDeactivateDialogReason,
};
