/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import initialState from './initialState';

export const bookingReviewsSlice = createSlice({
  name: 'hostPage',
  initialState,
  reducers: {
    load(state) {
      state.loading = true;
    },

    loadSuccess(state) {
      state.loading = false;
    },

    loadFailed(state) {
      state.loading = false;
    },

    loadFeaturedVehicles(state) {
      state.featuredVehicles.loading = true;
    },

    loadFeaturedVehiclesSuccess(state, { payload }) {
      state.featuredVehicles.items = payload;
      state.featuredVehicles.loading = false;
    },

    loadFeaturedVehiclesFailed(state) {
      state.featuredVehicles.loading = false;
    },

    setSearchLocations(state, { payload }) {
      state.searchLocations = payload;
    },
  },
});

const { actions, reducer } = bookingReviewsSlice;

export const {
  load,
  loadSuccess,
  loadFailed,
  loadFeaturedVehicles,
  loadFeaturedVehiclesSuccess,
  loadFeaturedVehiclesFailed,
  setSearchLocations,
} = actions;

export default reducer;
