import { takeEvery } from 'redux-saga/effects';

import * as actions from 'store/modules/authorisePayment';

import { authoriseSecurityDeposit } from './utils';

function* listenLoad() {
  yield takeEvery(actions.authoriseSecurityDeposit.toString(), authoriseSecurityDeposit);
}

export default listenLoad;
