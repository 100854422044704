import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Logo = (props) => (
  <SvgIcon viewBox="0 0 1000 700" {...props}>
    <g
      id="Oval-3_1_"
      transform="translate(30.234019, 13.234019) rotate(-45.000000) translate(-30.234019, -13.234019) "
    >
      <path
        d="M144.7,769.8c-22.2-22.3-34.2-52.8-34.2-84.3l0-7.7c0-2.5,2-4.5,4.5-4.5l201.4,0
            c-2.9-18.7-11.8-36.1-25.3-49.6c-17-17-39.5-26.3-63.4-26.3l-207,0c-31.3,0-60.8-12.2-82.9-34.4s-34.4-51.6-34.3-82.9
            c0-31.3,12.2-60.8,34.3-82.9c22.2-22.2,51.6-34.4,82.9-34.4c31.3,0,60.7,12.2,82.9,34.4c22.2,22.2,34.4,51.6,34.4,82.9v9.3
            c0,2.5-2,4.5-4.5,4.5l-201.4,0c2.8,18.4,11.1,35.1,24.2,48.7c17.2,17.7,41.2,27.3,65.9,27.3h205.5c31.3,0,60.8,12.2,82.9,34.4
            c22.1,22.1,34.3,51.6,34.3,82.9c0,31.3-12.2,60.8-34.3,82.9c-22.1,22.1-51.6,34.3-82.9,34.3C196.4,804.3,166.9,792.1,144.7,769.8
            z M139.1,700.8c2.9,18.9,11.5,36,25.2,49.7c16.9,16.9,39.5,26.3,63.4,26.3c24,0,46.5-9.3,63.4-26.3
            c13.7-13.7,22.4-30.8,25.2-49.7L139.1,700.8z M84.2,416.6c-17-17-39.5-26.3-63.4-26.3c-24,0-46.5,9.3-63.4,26.3
            c-13.7,13.7-22.4,30.8-25.2,49.7l177.3,0C106.5,447.6,97.7,430.1,84.2,416.6z"
      />
    </g>
  </SvgIcon>
);

Logo.displayName = 'LogoIcon';
Logo.muiName = 'SvgIcon';

export default Logo;
