import { useDispatch, useSelector } from 'react-redux';

import * as subscribeActions from '../actions';
import * as subscribeSelectors from '../selectors';

export default function useSubscribeProps() {
  const { email } = useSelector(subscribeSelectors.getSubscribe);

  const dispatch = useDispatch();
  const onSubscribe = (competition) => dispatch(subscribeActions.subscribe(competition));
  const onEmailChanged = (value) => dispatch(subscribeActions.setEmail(value));

  return [email, onSubscribe, onEmailChanged];
}
