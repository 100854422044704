export default (desktopItemsCount = 1, tabletItemsCount = 1) => ({
  desktop: {
    breakpoint: { max: 3000, min: 1280 },
    items: desktopItemsCount,
    slidesToSlide: desktopItemsCount,
  },
  tablet: {
    breakpoint: { max: 1280, min: 600 },
    items: tabletItemsCount,
    slidesToSlide: tabletItemsCount,
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
    partialVisibilityGutter: 100,
  },
});
