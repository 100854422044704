import { darken } from '@material-ui/core/styles';

const accentColor = '#783728';

const styles = (theme) => ({
  gridContainer: {
    alignItems: 'center',
    borderRadius: theme.shape.borderRadiusMedium,
    backgroundColor: theme.palette.primary.main,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    height: '180px',
    padding: theme.spacing(7, 8),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    borderRadius: `${theme.shape.borderRadiusMedium}px 0 0 ${theme.shape.borderRadiusMedium}px`,
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      padding: '16px',
      borderRadius: `${theme.shape.borderRadiusMedium}px ${theme.shape.borderRadiusMedium}px 0 0`,
    },
  },
  controlsContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  inputContainer: {
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(3, 6),
    height: '180px',
    borderRadius: `100px ${theme.shape.borderRadiusMedium}px ${theme.shape.borderRadiusMedium}px 100px`,
    [theme.breakpoints.down('sm')]: {
      padding: '16px',
      borderRadius: `0 0 ${theme.shape.borderRadiusMedium}px ${theme.shape.borderRadiusMedium}px`,
    },
  },
  input: {
    height: '40px',
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },
  button: {
    backgroundColor: accentColor,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: darken(accentColor, 0.08),
    },
    width: '100px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
  },
  title: {
    color: 'inherit',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  subtitle: {
    ...theme.typography.body1,
    color: 'inherit',
    fontWeight: 300,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      fontWeight: 300,
      width: '80%',
      textAlign: 'center',
    },
  },
});

export default styles;
