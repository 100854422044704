import { all, fork } from 'redux-saga/effects';

import listenAddExtra from './listenAddExtra';
import listenApplyDiscount from './listenApplyDiscount';
import listenCancelDiscount from './listenCancelDiscount';
import listenCancelSignIn from './listenCancelSignIn';
import listenCompleteStep from './listenCompleteStep';
import listenCreateBooking from './listenCreateBasket';
import listenFinalise from './listenFinalise';
import listenLoad from './listenLoad';
import listenLoadTotals from './listenLoadTotals';
import listenRemoveExtra from './listenRemoveExtra';
import listenSetActiveStep from './listenSetActiveStep';
import listenSetMessage from './listenSetMessage';
import listenSignIn from './listenSignIn';

import listenCheckVerificationCodeSuccess from './validation/listenCheckVerificationCodeSuccess';
import listenSetBasketField from './validation/listenSetBasketField';
import listenSetPhoneNumber from './validation/listenSetPhone';
import listenSetProfileField from './validation/listenSetProfileField';
import listenUploadLicenceSuccess from './validation/listenUploadLicenceSuccess';
import listenUploadProfilePhotoSuccess from './validation/listenUploadProfilePhotoSuccess';

export default function* basketSaga() {
  yield all([
    fork(listenAddExtra),
    fork(listenApplyDiscount),
    fork(listenCancelDiscount),
    fork(listenCancelSignIn),
    fork(listenCompleteStep),
    fork(listenCreateBooking),
    fork(listenFinalise),
    fork(listenLoad),
    fork(listenLoadTotals),
    fork(listenRemoveExtra),
    fork(listenSetActiveStep),
    fork(listenSetMessage),
    fork(listenSignIn),
    fork(listenSetProfileField),
    fork(listenSetPhoneNumber),
    fork(listenCheckVerificationCodeSuccess),
    fork(listenUploadLicenceSuccess),
    fork(listenSetBasketField),
    fork(listenUploadProfilePhotoSuccess),
  ]);
}
