import PropTypes from 'prop-types';

import useAuth from 'store/modules/auth/hooks/useAuth';

import HamburgerMenu from '../../utils/HamburgerMenu';

import AccountMenu from './AccountMenu';
import MainMenu from './MainMenu';

const DesktopMenu = ({ color, isTransparent }) => {
  const { currentUser, signedIn } = useAuth();

  return (
    <HamburgerMenu
      buttonProps={{ color, transparent: isTransparent }}
      menu={currentUser && signedIn ? AccountMenu : MainMenu}
    />
  );
};

DesktopMenu.propTypes = {
  color: PropTypes.string,
  isTransparent: PropTypes.bool,
};

DesktopMenu.defaultProps = {
  color: '',
  isTransparent: false,
};

export default DesktopMenu;
