import PropTypes from 'prop-types';

import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@material-ui/core';

import { ExpandMore } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

const CollapsibleColumn = ({ title, collapsible, children, classes }) => {
  if (collapsible) {
    return (
      <Accordion classes={{ root: classes.root, expanded: classes.expanded }} elevation={0}>
        <AccordionSummary title={title} expandIcon={<ExpandMore />}>
          <Typography variant="body1" style={{ fontWeight: 500 }}>
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            {children}
          </Box>
        </AccordionDetails>
      </Accordion>
    );
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start">
      <Typography variant="body1" style={{ fontWeight: 500 }}>
        {title}
      </Typography>
      {children}
    </Box>
  );
};

CollapsibleColumn.propTypes = {
  title: PropTypes.string,
  collapsible: PropTypes.bool,
  children: PropTypes.node,
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    expanded: PropTypes.string.isRequired,
  }).isRequired,
};

CollapsibleColumn.defaultProps = {
  title: '',
  collapsible: true,
  children: null,
};

export default withStyles(styles)(CollapsibleColumn);
