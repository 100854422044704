import { takeEvery } from 'redux-saga/effects';

import * as actions from 'store/modules/bookingReviews';

import { loadBookingReviews } from './utils';

function* listenLoad() {
  yield takeEvery(actions.load.type, loadBookingReviews);
}

export default listenLoad;
