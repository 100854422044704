import { takeEvery } from 'redux-saga/effects';

import * as actionTypes from 'store/modules/searchPage/actionTypes';

import { resetEditFilters, setDialogDefaultFilters } from './utils';

function* setFiltersDialogOpen({ payload }) {
  if (payload) {
    yield resetEditFilters();
    yield setDialogDefaultFilters();
  }
}

export default function* listenFiltersDialogOpen() {
  yield takeEvery(actionTypes.SET_FILTERS_DIALOG_OPEN, setFiltersDialogOpen);
}
