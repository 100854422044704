export const getPayoutIncreasementItems = ({
  dynamicPricing,
  instantBooking,
  deliveryAirport,
  carDecals,
  highQualityPhotos,
  longTripDiscountAvailable,
  unlimitedMileage,
} = {}) => [
  {
    title: 'Dynamic pricing',
    amount: 2,
    active: dynamicPricing,
  },
  {
    title: 'Instant booking',
    amount: 2,
    active: instantBooking && instantBooking.available,
  },
  {
    title: 'Airport delivery',
    amount: 2,
    active: !!deliveryAirport,
  },
  {
    title: 'Car decals',
    amount: 2,
    active: carDecals,
    availableThroughSupport: true,
  },
  {
    title: 'High quality photos',
    amount: 1,
    active: highQualityPhotos,
    availableThroughSupport: true,
  },
  {
    title: 'Long rental discount',
    amount: 1,
    active: longTripDiscountAvailable,
  },
  {
    title: 'Unlimited mileage',
    amount: 1,
    active: unlimitedMileage,
  },
];
