import PropTypes from 'prop-types';

import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';

const HostHeader = ({ initiallyTransparent, hideOnScroll }) => {
  const theme = useTheme();
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'));
  return isTabletOrMobile ? (
    <MobileHeader />
  ) : (
    <DesktopHeader initiallyTransparent={initiallyTransparent} hideOnScroll={hideOnScroll} />
  );
};

HostHeader.propTypes = {
  initiallyTransparent: PropTypes.bool,
  hideOnScroll: PropTypes.bool,
};

HostHeader.defaultProps = {
  initiallyTransparent: false,
  hideOnScroll: false,
};

export default HostHeader;
