const styles = (theme) => ({
  hoursContainer: {
    padding: '10px',
    borderTop: `1 px solid ${theme.palette.border.main}`,
    [theme.breakpoints.down(350)]: {
      flexDirection: 'column',
    },
  },
  hoursItem: {
    [theme.breakpoints.down(350)]: {
      margin: '4px',
    },
  },
  timeContainer: {
    width: '100%',
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
  },
});

export default styles;
