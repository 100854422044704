import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Box, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import Carousel from 'widgets/layout/Carousel';

import LocationCard from './LocationCard';

const FeaturedLocations = ({ featuredLocations, loading, onLoad }) => {
  const theme = useTheme();
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    onLoad();
  }, []);

  const cardsJsx = featuredLocations.map((e, index) => (
    <React.Fragment key={index}>
      <LocationCard location={e} />
    </React.Fragment>
  ));

  const loadingCardsJsx = new Array(4).fill({}).map((_, index) => (
    <React.Fragment key={index}>
      <LocationCard loading />
    </React.Fragment>
  ));

  return (
    <Box display="flex" flexDirection="column">
      <Typography gutterBottom variant="h3" style={{ fontWeight: 500 }}>
        Browse locations
      </Typography>

      <Box position="relative">
        <Carousel
          showArrows
          desktopItemsCount={5}
          tabletItemsCount={3}
          partialVisible={isTabletOrMobile}
          length={featuredLocations.length}
        >
          {loading ? loadingCardsJsx : cardsJsx}
        </Carousel>
      </Box>
    </Box>
  );
};

FeaturedLocations.propTypes = {
  featuredLocations: PropTypes.array,
  loading: PropTypes.bool,
  onLoad: PropTypes.func,
};

FeaturedLocations.defaultProps = {
  featuredLocations: [],
  loading: false,
  onLoad: () => {},
};

export default FeaturedLocations;
