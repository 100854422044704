import { all, fork } from 'redux-saga/effects';

import listenDeleteMessage from './listenDeleteMessage';
import listenDeleteNotification from './listenDeleteNotification';
import listenLoad from './listenLoad';
import listenLoadChat from './listenLoadChat';
import listenLoadMore from './listenLoadMore';
import listenOpenChatDialog from './listenOpenChatDialog';
import listenSendMessage from './listenSendMessage';
import listenUploadFiles from './listenUploadFiles';

export default function* messagesPageSaga() {
  yield all([
    fork(listenDeleteMessage),
    fork(listenDeleteNotification),
    fork(listenLoad),
    fork(listenLoadChat),
    fork(listenLoadMore),
    fork(listenOpenChatDialog),
    fork(listenSendMessage),
    fork(listenUploadFiles),
  ]);
}
