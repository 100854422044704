import * as actionTypes from './actionTypes';

export const load = () => ({
  type: actionTypes.LOAD,
});

export const setPhoneNumber = (code, number, status = null) => ({
  type: actionTypes.SET_PHONE_NUMBER,
  payload: { phoneNumber: { code, number }, status },
});

export const setVerificationCode = (code) => ({
  type: actionTypes.SET_VERIFICATION_CODE,
  payload: code,
});

export const sendVerificationCode = () => ({
  type: actionTypes.SEND_VERIFICATION_CODE,
});

export const sendVerificationCodeSuccess = () => ({
  type: actionTypes.SEND_VERIFICATION_CODE_SUCCESS,
});

export const sendVerificationCodeFailed = () => ({
  type: actionTypes.SEND_VERIFICATION_CODE_FAILED,
});

export const checkVerificationCode = () => ({
  type: actionTypes.CHECK_VERIFICATION_CODE,
});

export const checkVerificationCodeSuccess = () => ({
  type: actionTypes.CHECK_VERIFICATION_CODE_SUCCESS,
});

export const checkVerificationCodeFailed = () => ({
  type: actionTypes.CHECK_VERIFICATION_CODE_FAILED,
});

export const clearPhoneNumber = () => ({
  type: actionTypes.CLEAR_PHONE_NUMBER,
});

export const resetPhone = () => ({
  type: actionTypes.RESET_PHONE,
});

export const setError = (message) => ({
  type: actionTypes.SET_ERROR,
  payload: message,
});
