import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { withStyles } from '@material-ui/core/styles';

import HamburgerButton from './HamburgerButton';
import styles from './styles';

const HamburgerMenu = ({ buttonProps, menu: Menu, classes }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const { color, transparent } = buttonProps;

  const menuButtonClsx = clsx(classes.menuButton, transparent && classes.transparentMenuButton);

  const handleToggle = (event) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
    }

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <HamburgerButton
        disabled={open}
        color={color}
        className={menuButtonClsx}
        onClick={handleToggle}
      />
      {Menu && <Menu anchorEl={anchorEl} open={open} onClose={handleClose} />}
    </>
  );
};

HamburgerMenu.propTypes = {
  buttonProps: PropTypes.object,
  menu: PropTypes.object,
  classes: PropTypes.shape({
    menuButton: PropTypes.string.isRequired,
    transparentMenuButton: PropTypes.string.isRequired,
  }).isRequired,
};

HamburgerMenu.defaultProps = {
  buttonProps: {},
  menu: {},
};

export default withStyles(styles)(HamburgerMenu);
