import PropTypes from 'prop-types';
import moment from 'moment';

import { Box, Collapse, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme, withStyles } from '@material-ui/core/styles';

import { Button, Switch } from '@evee/evee-ui.base';

import { createRange, getDatesAndHours } from 'widgets/inputs/DateRangePicker/utils';
import Calendar from 'widgets/inputs/Calendar';
import DefaultDialog from 'widgets/layout/DefaultDialog';
import TimeRangeSelector from 'widgets/search/TimeRangeSelector';

import styles from './styles';

const FullscreenCalendar = ({
  tripDates,
  open,
  classes,
  onDatesChange,
  onFlexibleChange,
  onClose,
}) => {
  const { startDate, endDate, startHour, endHour } = getDatesAndHours({
    start: moment(tripDates.from),
    end: moment(tripDates.to),
  });

  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down(350));
  const medium = useMediaQuery(theme.breakpoints.down(500));

  const getCalendarHeight = () => {
    let verticalHeight = 680;

    if (small) {
      verticalHeight = 480;
    } else if (medium) {
      verticalHeight = 580;
    }

    return verticalHeight;
  };

  const getDaySize = () => {
    let daySize = 48;

    if (small) {
      daySize = 28;
    } else if (medium) {
      daySize = 38;
    }

    return daySize;
  };

  const timeJsx = (
    <Box className={classes.timeContainer}>
      <TimeRangeSelector
        value={{ startHour, endHour }}
        containerClassName={classes.hoursContainer}
        itemClassName={classes.hoursItem}
        onChange={(v) => {
          onDatesChange(createRange(startDate, endDate, v.startHour, v.endHour));
        }}
      />
    </Box>
  );

  const dialogActionsJsx = () => (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      flexDirection={small ? 'column' : 'row'}
      style={{ width: '100%' }}
    >
      <Switch
        label="I'm flexible"
        variant="subtitle1"
        checked={tripDates.flexible}
        onChange={onFlexibleChange}
      />
      <Button
        text={tripDates.flexible ? 'Apply' : 'Select dates'}
        style={{ width: small ? '100%' : '50%' }}
        onClick={onClose}
      />
    </Box>
  );

  return (
    <DefaultDialog
      fullScreen
      decorateBackground
      contentDividers={!tripDates.flexible}
      open={open}
      title="Select rental period"
      subTitleContent={!tripDates.flexible && timeJsx}
      renderActions={dialogActionsJsx}
      onClose={onClose}
    >
      <Collapse in={tripDates.flexible}>
        <Typography paragraph variant="body1" align="center">
          Press Apply to proceed with flexible dates
        </Typography>
      </Collapse>
      <Collapse in={!tripDates.flexible}>
        <Calendar
          isMobile
          dateRangeAvailable
          verticalHeight={getCalendarHeight()}
          value={{ startDate, endDate }}
          daySize={getDaySize()}
          renderDayContents={(day) => (
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
              }}
            >
              {day.format('D')}
            </Box>
          )}
          onDatesChange={(v) => {
            onDatesChange(createRange(v.startDate, v.endDate, startHour, endHour));
          }}
        />
      </Collapse>
    </DefaultDialog>
  );
};

FullscreenCalendar.propTypes = {
  tripDates: PropTypes.object,
  open: PropTypes.bool,
  classes: PropTypes.shape({
    hoursContainer: PropTypes.string.isRequired,
    hoursItem: PropTypes.string.isRequired,
    timeContainer: PropTypes.string.isRequired,
  }).isRequired,
  onDatesChange: PropTypes.func,
  onFlexibleChange: PropTypes.func,
  onClose: PropTypes.func,
};

FullscreenCalendar.defaultProps = {
  tripDates: {},
  open: false,
  onDatesChange: () => {},
  onFlexibleChange: () => {},
  onClose: () => {},
};

export default withStyles(styles)(FullscreenCalendar);
