/* eslint-disable no-param-reassign */
import { createAction, createSlice } from '@reduxjs/toolkit';

import * as profileActionTypes from 'store/modules/profile/actionTypes';
import initialState from './initailState';

export const pasteCode = createAction('auth/verifyEmail/pasteCode');

export const verifyEmailSlice = createSlice({
  name: 'auth/verifyEmail',
  initialState: {},
  reducers: {
    showVerifyEmail: (state, { payload }) => {
      state.verifyEmail = {
        ...initialState,
        open: true,
        email: payload,
        profileEmail: payload,
      };
    },

    closeVerifyEmail: (state) => {
      state.verifyEmail.open = false;
    },

    sendEmailVerification: (state) => {
      state.verifyEmail.loading = true;
    },

    sendEmailVerificationSuccess: (state) => {
      state.verifyEmail.loading = false;
    },

    sendEmailVerificationFailed: (state) => {
      state.verifyEmail.loading = false;
    },

    verifyEmail: (state) => {
      state.verifyEmail.loading = true;
    },

    verifyEmailSuccess: (state) => {
      state.verifyEmail.verified = true;
      state.verifyEmail.loading = false;
    },

    verifyEmailFailed: (state, { payload }) => {
      state.verifyEmail.errorMessage = payload;
      state.verifyEmail.loading = false;
    },

    setEditEmail: (state, { payload }) => {
      state.verifyEmail.editEmail = payload;
    },

    setEmail: (state, { payload }) => {
      state.verifyEmail.email = payload;
    },

    setProfileEmail: (state, { payload }) => {
      state.verifyEmail.email = payload;
      state.verifyEmail.profileEmail = payload;
    },

    setCode: (state, { payload }) => {
      state.verifyEmail.code = payload;
    },

    setCodeDigit: (state, { payload }) => {
      state.verifyEmail.code[payload.index] = payload.value;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(profileActionTypes.CHANGE_EMAIL_SUCCESS, (state) => {
      state.verifyEmail.editEmail = false;
      state.verifyEmail.profileEmail = state.verifyEmail.email;
    });
  },
});

const { actions, reducer } = verifyEmailSlice;

export const {
  showVerifyEmail,
  closeVerifyEmail,
  sendEmailVerification,
  sendEmailVerificationSuccess,
  sendEmailVerificationFailed,
  verifyEmail,
  verifyEmailSuccess,
  verifyEmailFailed,
  setEditEmail,
  setEmail,
  setProfileEmail,
  setCode,
  setCodeDigit,
} = actions;

export default reducer;
