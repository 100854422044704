import { alpha, lighten } from '@material-ui/core/styles';
import { headerHeightPx } from 'common/constants/ui';

const appBarPadding = '0 24px 0 24px';

const styles = (theme) => ({
  transparentAppBar: {
    background:
      'linear-gradient(to bottom, rgba(0,0,0,0.3) 0%, ' +
      'rgba(0,0,0,0.2) 50%, rgba(0,0,0,0) 100%)',
    boxShadow: 'none',
    padding: appBarPadding,
    color: theme.palette.common.white,
    height: headerHeightPx,
    justifyContent: 'center',
  },
  defaultAppBar: {
    padding: appBarPadding,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.white,
    height: headerHeightPx,
    justifyContent: 'center',
    boxShadow: '0px 3px 14px 2px rgba(0, 0, 0, 0.06)',
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerBlockContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  becomeHost: {
    marginRight: theme.spacing(1),
    borderRadius: '25px',
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.92),
    },
  },
  transparentBecomeHost: {
    '&:hover': {
      backgroundColor: alpha(lighten(theme.palette.primary.main, 0.2), 0.4),
    },
  },
  joinNow: {
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    borderRadius: '25px',
    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.92),
    },
  },
  region: {
    marginRight: theme.spacing(1),
  },
  transparentRegion: {
    '&:hover': {
      backgroundColor: alpha(lighten(theme.palette.primary.main, 0.2), 0.4),
    },
  },
});

export default styles;
