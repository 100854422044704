import { all, fork } from 'redux-saga/effects';

import listenAddExtra from './listenAddExtra';
import listenLoad from './listenLoad';
import listenRemoveExtra from './listenRemoveExtra';

export default function* extrasSaga() {
  yield all([
    fork(listenAddExtra),
    fork(listenLoad),
    fork(listenRemoveExtra),
  ]);
}
