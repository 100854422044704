import { call, put, select, takeEvery } from 'redux-saga/effects';

import { apiService, storage } from '@evee/evee-ui.services';

import * as actionTypes from 'store/modules/subscribe/actionTypes';
import * as selectors from 'store/modules/subscribe/selectors';
import { setEmail, subscribeFailed, subscribeSuccess } from 'store/modules/subscribe/actions';
import { setIsSubscribed, setReceiveNewsletter } from 'store/modules/auth';
import { showRequestError, showSuccess } from 'store/modules/app/actions';

function* subscribe() {
  try {
    const email = yield select(selectors.getEmail);
    yield call(apiService.customer.subscribe, email);
    storage.subscribe.save(true);
    yield put(setIsSubscribed(true));
    yield put(subscribeSuccess());
    yield put(setEmail(''));
    yield put(setReceiveNewsletter());
    yield put(showSuccess('Thank you!'));
  } catch (err) {
    yield put(subscribeFailed(err.message));
    yield put(showRequestError());
  }
}

export default function* listenSubscribe() {
  yield takeEvery(actionTypes.SUBSCRIBE, subscribe);
}
